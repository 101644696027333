import { useState, ReactNode } from "react"

type ActionButtonProps = {
	// ActionValue:React.FunctionComponent
	// value:string
	value: ReactNode
	actionValue: ReactNode
	onClick: () => void
}

export const ActionButton = ({ value, actionValue, onClick }: ActionButtonProps) => {
	const [hover, setHover] = useState(false)

	return (
		<div
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			// onClick={() => hover && onClick()}
			onClick={onClick}
			style={{ cursor: "pointer" }}
		>
			{hover ? actionValue : value}
		</div>
	)
}
