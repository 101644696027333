import { AuthProvider } from "./login/AuthProvider"
import { LoginExperience } from "./login/LoginExperience"

export const App = () => {
	return (
		<AuthProvider>
			<LoginExperience />
		</AuthProvider>
	)
}
