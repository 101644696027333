import { Button } from "@mui/material"
import { useCredentials } from "./AuthProvider"
import { handleAuthenticationResult, msal } from "./msal"

export const LoginForm = () => {
	const { setCredentials } = useCredentials()
	const tryAzureLogin = async () => {
		const result = await msal.loginPopup()
		const credentials = await handleAuthenticationResult(result)
		setCredentials(credentials)
	}

	return (
		<div style={{ maxWidth: "20em", margin: "2em auto", textAlign: "center" }}>
			<h1 style={{ margin: 0, fontSize: "1.5em" }}>EAG Vehicle Monitor</h1>
			<p>Login to view vehicle minor/major service details and active diagnostics</p>
			<Button type="button" variant="contained" color="primary" onClick={tryAzureLogin}>
				Microsoft Login
			</Button>
		</div>
	)
}
