import { format } from "date-fns"
import { Button } from "@mui/material"
import diagIcons from "./DiagnosticsSymbols.json"
import { Obd, Spn } from "../graphql"
import { VehicleDiagnosticSummary } from "./DiagnosticsView"

const spnIcons = diagIcons.spn

export type GenericDiagnostic<T extends Obd | Spn> = Omit<VehicleDiagnosticSummary, "diagnostic"> & { diagnostic: T }
export type DiagnosticViewProps<T extends Obd | Spn> = {
	diagnostic: GenericDiagnostic<T>
	onClear: () => void
}

export const SpnDiagnosticView = ({ diagnostic, onClear }: DiagnosticViewProps<Spn>) => {
	const d = diagnostic.diagnostic
	const diagIcon = d.spn_code in spnIcons ? spnIcons[d.spn_code as keyof typeof spnIcons] : "Master Warning Light"
	return (
		<div
			className="diagnostic-item"
			style={{
				padding: "1em",
				position: "relative",
				fontSize: "smaller",
				opacity: diagnostic.cleared ? 0.7 : 1,
				color: "rgb(191,191,191)",
			}}
		>
			<img
				src={`/clipart/diagnostics/${diagIcon}.svg`}
				alt="Diagnostic Light"
				style={{ position: "absolute", top: "1em", right: "1em", opacity: 0.4, height: "4em" }}
			/>
			<h2
				style={{
					fontSize: "1em",
					// clear space for icon
					marginRight: "4em",
				}}
			>
				{d.spn_code} - {d.spn_name || "No description available"}
			</h2>
			<p>Last occurance: {format(new Date(diagnostic.datetime), "yyyy-MMM-dd HH:mm").toUpperCase()}</p>
			<p>{d.spn_description}</p>
			<footer style={{ display: "flex", position: "relative", height: "2em" }}>
				<span
					className="fmi"
					style={{
						flex: "1 1 auto",
						position: "absolute",
						bottom: 0,
					}}
				>
					<b>FMI:</b> {d.fmi?.fmi_code}
					{d.fmi?.fmi_description ? ` (${d.fmi.fmi_description})` : ""}
				</span>
				<div className="controls" style={{ position: "absolute", bottom: 0, right: 0 }}>
					<Button
						variant="outlined"
						color="primary"
						href={`https://google.ca/search?q=DTC+SPN+${d.spn_code}`}
						target="_blank"
						rel="noopener noreferrer"
						style={{ marginLeft: "0.5em" }}
					>
						Google
					</Button>
					<Button variant="contained" color="primary" onClick={onClear} style={{ marginLeft: "0.5em" }}>
						Clear
					</Button>
				</div>
			</footer>
		</div>
	)
}
