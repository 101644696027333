import { DiagnosticViewProps } from "./SpnDiagnosticView"
import { Button } from "@mui/material"
import { format } from "date-fns"
import { Obd } from "../graphql"

export const ObdDiagnosticView = ({ diagnostic, onClear }: DiagnosticViewProps<Obd>) => {
	const d = diagnostic.diagnostic
	const diagIcon = "Master Warning Light"
	return (
		<div
			className="diagnostic-item"
			style={{
				padding: "1em",
				position: "relative",
				fontSize: "smaller",
				opacity: diagnostic.cleared ? 0.7 : 1,
				color: "rgb(191,191,191)",
			}}
		>
			<img
				src={`/clipart/diagnostics/${diagIcon}.svg`}
				alt="Diagnostic Light"
				style={{ position: "absolute", top: "1em", right: "1em", opacity: 0.4, height: "4em" }}
			/>
			<h2
				style={{
					fontSize: "1em",
					// clear space for icon
					marginRight: "4em",
				}}
			>
				<p>Last occurance: {format(new Date(diagnostic.datetime), "yyyy-MMM-dd HH:mm").toUpperCase()}</p>
				{d.obd_code} - {d.obd_description || "No description available"}
			</h2>
			<footer style={{ display: "flex", position: "relative", height: "2em" }}>
				<p style={{ flex: "1 1 auto", position: "absolute", margin: 0, bottom: 0 }}>
					Last occurance:
					<br />
					{format(new Date(diagnostic.datetime), "yyyy-MMM-dd HH:mm").toUpperCase()}
				</p>
				<div style={{ position: "absolute", bottom: 0, right: 0 }} className="controls">
					<Button
						variant="outlined"
						color="primary"
						href={`https://google.ca/search?q=OBD+${d.obd_code}`}
						target="_blank"
						rel="noopener noreferrer"
						style={{ marginLeft: "0.5em" }}
					>
						Google
					</Button>
					<Button variant="contained" color="primary" onClick={onClear} style={{ marginLeft: "0.5em" }}>
						Clear
					</Button>
				</div>
			</footer>
		</div>
	)
}
