import { useVehicleSummariesQuery, VehicleSummariesQuery } from "./graphql"
import { VehicleSummaryTable } from "./components/VehicleSummaryTable"
// import { V2Table } from "./components/V2Table/V2Table"

export type VehicleSummary = VehicleSummariesQuery["vehicleSummaries"][number]

const isTouch = "ontouchstart" in window

export const Dashboard = () => {
	const { data } = useVehicleSummariesQuery()
	if (!data) return <p>Loading</p>
	const vehicles = data.vehicleSummaries
	// return <V2Table state={vehicles} />

	// one column on touch devices
	if (isTouch) return <VehicleSummaryTable vehicles={vehicles} />
	const splitIndex = vehicles.findIndex(i => i.asset_code.startsWith("55"))
	return (
		<div style={{ display: "flex" }}>
			<VehicleSummaryTable vehicles={vehicles.slice(splitIndex)} />
			<VehicleSummaryTable vehicles={vehicles.slice(0, splitIndex)} />
		</div>
	)
}
