import { VehicleSummaryRow } from "./VehicleSummaryRow"
import { VehicleSummary } from "../Dashboard"

type Props = {
	vehicles: VehicleSummary[]
}
export const VehicleSummaryTable = ({ vehicles }: Props) => {
	return (
		<table className="vehicle-summary">
			<thead>
				<tr>
					<th>Asset</th>
					<th>Hours</th>
					<th>Odo (km)</th>
					<th>Minor Due</th>
					<th>Major Due</th>
					<th style={{ textAlign: "center" }}>CVI Due</th>
					<th style={{ textAlign: "center" }}>
						<abbr title="Diagnostic Trouble Codes">DTC</abbr>
					</th>
				</tr>
			</thead>
			<tbody>
				{vehicles.map(v => (
					<VehicleSummaryRow key={v.asset_code} {...v} />
				))}
			</tbody>
		</table>
	)
}
