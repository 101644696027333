import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A floating point number that requires more precision than IEEE 754 binary 64 */
  BigFloat: any;
  /**
   * A signed eight-byte integer. The upper big integer values are greater than the
   * max value for a JavaScript number. Therefore all big integers will be output as
   * strings and not numbers.
   */
  BigInt: any;
  CSV: any;
  /** The day, does not include a time. */
  Date: any;
  DateTime: any;
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: any;
  /** The `GeoJSON` scalar type represents GeoJSON values as specified by[RFC 7946](https://tools.ietf.org/html/rfc7946). */
  GeoJSON: any;
  JSON: any;
  /** The exact time of day, does not include the date. May or may not have a timezone offset. */
  Time: any;
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: any;
};

export type AccomplishmentUpdate = {
  accomplishment: Scalars['Float'];
  workId: Scalars['ID'];
};

export type AddVehicleServiceResponse = {
  __typename?: 'AddVehicleServiceResponse';
  code: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  vehicleServiceSummary: VehicleServiceSummary;
};

export enum Apex {
  High = 'HIGH',
  Low = 'LOW',
  Steady = 'STEADY'
}

export type Asset = Node & {
  __typename?: 'Asset';
  active?: Maybe<Scalars['Boolean']>;
  /** Reads and enables pagination through a set of `AssetBreadcrumb`. */
  assetBreadcrumbs: Array<AssetBreadcrumb>;
  assetCode: Scalars['String'];
  /** Reads and enables pagination through a set of `AssetWingUnderbody`. */
  assetWingUnderbodies: Array<AssetWingUnderbody>;
  categoryCode?: Maybe<Scalars['String']>;
  categoryPrefix?: Maybe<Scalars['String']>;
  companyNo?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  licensePlate?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `TcEquipment`. */
  tcEquipments: Array<TcEquipment>;
  /** Reads and enables pagination through a set of `VehicleDiagnostic`. */
  vehicleDiagnostics: Array<VehicleDiagnostic>;
  /** Reads and enables pagination through a set of `VehicleService`. */
  vehicleServices: Array<VehicleService>;
  /** Reads and enables pagination through a set of `VehicleStatus`. */
  vehicleStatuses: Array<VehicleStatus>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};


export type AssetAssetBreadcrumbsArgs = {
  condition?: InputMaybe<AssetBreadcrumbCondition>;
  filter?: InputMaybe<AssetBreadcrumbFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetBreadcrumbsOrderBy>>;
};


export type AssetAssetWingUnderbodiesArgs = {
  condition?: InputMaybe<AssetWingUnderbodyCondition>;
  filter?: InputMaybe<AssetWingUnderbodyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetWingUnderbodiesOrderBy>>;
};


export type AssetTcEquipmentsArgs = {
  condition?: InputMaybe<TcEquipmentCondition>;
  filter?: InputMaybe<TcEquipmentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcEquipmentOrderBy>>;
};


export type AssetVehicleDiagnosticsArgs = {
  condition?: InputMaybe<VehicleDiagnosticCondition>;
  filter?: InputMaybe<VehicleDiagnosticFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VehicleDiagnosticsOrderBy>>;
};


export type AssetVehicleServicesArgs = {
  condition?: InputMaybe<VehicleServiceCondition>;
  filter?: InputMaybe<VehicleServiceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VehicleServicesOrderBy>>;
};


export type AssetVehicleStatusesArgs = {
  condition?: InputMaybe<VehicleStatusCondition>;
  filter?: InputMaybe<VehicleStatusFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VehicleStatusesOrderBy>>;
};

export type AssetBreadcrumb = Node & {
  __typename?: 'AssetBreadcrumb';
  /** Reads a single `Asset` that is related to this `AssetBreadcrumb`. */
  asset?: Maybe<Asset>;
  assetId: Scalars['Int'];
  datetime: Scalars['Datetime'];
  geog: GeographyPoint;
  geom?: Maybe<GeometryPoint>;
  id: Scalars['Int'];
  live: Scalars['Boolean'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `ImapRoadClass` that is related to this `AssetBreadcrumb`. */
  roadClass?: Maybe<ImapRoadClass>;
  roadClassId?: Maybe<Scalars['Int']>;
  /** Reads a single `ImapRoadFeature` that is related to this `AssetBreadcrumb`. */
  roadFeature?: Maybe<ImapRoadFeature>;
  roadFeatureId?: Maybe<Scalars['Int']>;
  /** Reads a single `ImapRoadSurface` that is related to this `AssetBreadcrumb`. */
  roadSurface?: Maybe<ImapRoadSurface>;
  roadSurfaceId?: Maybe<Scalars['Int']>;
  speed?: Maybe<Scalars['Float']>;
  /** Reads a single `OpsYard` that is related to this `AssetBreadcrumb`. */
  yard?: Maybe<OpsYard>;
  yardId?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `AssetBreadcrumb` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AssetBreadcrumbCondition = {
  /** Checks for equality with the object’s `assetId` field. */
  assetId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `datetime` field. */
  datetime?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `roadClassId` field. */
  roadClassId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `roadFeatureId` field. */
  roadFeatureId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `roadSurfaceId` field. */
  roadSurfaceId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `yardId` field. */
  yardId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `AssetBreadcrumb` object types. All fields are combined with a logical ‘and.’ */
export type AssetBreadcrumbFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AssetBreadcrumbFilter>>;
  /** Filter by the object’s `assetId` field. */
  assetId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `datetime` field. */
  datetime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AssetBreadcrumbFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AssetBreadcrumbFilter>>;
  /** Filter by the object’s `roadClassId` field. */
  roadClassId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `roadFeatureId` field. */
  roadFeatureId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `roadSurfaceId` field. */
  roadSurfaceId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `yardId` field. */
  yardId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `AssetBreadcrumb` */
export type AssetBreadcrumbInput = {
  assetId: Scalars['Int'];
  datetime: Scalars['Datetime'];
  geog: Scalars['GeoJSON'];
  geom?: InputMaybe<Scalars['GeoJSON']>;
  live?: InputMaybe<Scalars['Boolean']>;
  roadClassId?: InputMaybe<Scalars['Int']>;
  roadFeatureId?: InputMaybe<Scalars['Int']>;
  roadSurfaceId?: InputMaybe<Scalars['Int']>;
  speed?: InputMaybe<Scalars['Float']>;
  yardId?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `AssetBreadcrumb`. Fields that are set will be updated. */
export type AssetBreadcrumbPatch = {
  assetId?: InputMaybe<Scalars['Int']>;
  datetime?: InputMaybe<Scalars['Datetime']>;
  geog?: InputMaybe<Scalars['GeoJSON']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  live?: InputMaybe<Scalars['Boolean']>;
  roadClassId?: InputMaybe<Scalars['Int']>;
  roadFeatureId?: InputMaybe<Scalars['Int']>;
  roadSurfaceId?: InputMaybe<Scalars['Int']>;
  speed?: InputMaybe<Scalars['Float']>;
  yardId?: InputMaybe<Scalars['Int']>;
};

/** Methods to use when ordering `AssetBreadcrumb`. */
export enum AssetBreadcrumbsOrderBy {
  AssetIdAsc = 'ASSET_ID_ASC',
  AssetIdDesc = 'ASSET_ID_DESC',
  DatetimeAsc = 'DATETIME_ASC',
  DatetimeDesc = 'DATETIME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RoadClassIdAsc = 'ROAD_CLASS_ID_ASC',
  RoadClassIdDesc = 'ROAD_CLASS_ID_DESC',
  RoadFeatureIdAsc = 'ROAD_FEATURE_ID_ASC',
  RoadFeatureIdDesc = 'ROAD_FEATURE_ID_DESC',
  RoadSurfaceIdAsc = 'ROAD_SURFACE_ID_ASC',
  RoadSurfaceIdDesc = 'ROAD_SURFACE_ID_DESC',
  YardIdAsc = 'YARD_ID_ASC',
  YardIdDesc = 'YARD_ID_DESC'
}

/** Methods to use when ordering `AssetCategory`. */
export enum AssetCategoriesOrderBy {
  Natural = 'NATURAL',
  PrefixAsc = 'PREFIX_ASC',
  PrefixDesc = 'PREFIX_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type AssetCategory = Node & {
  __typename?: 'AssetCategory';
  description: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  prefix: Scalars['String'];
};

/**
 * A condition to be used against `AssetCategory` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AssetCategoryCondition = {
  /** Checks for equality with the object’s `prefix` field. */
  prefix?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `AssetCategory` object types. All fields are combined with a logical ‘and.’ */
export type AssetCategoryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AssetCategoryFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<AssetCategoryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AssetCategoryFilter>>;
  /** Filter by the object’s `prefix` field. */
  prefix?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `AssetCategory` */
export type AssetCategoryInput = {
  description: Scalars['String'];
  prefix: Scalars['String'];
};

/** Represents an update to a `AssetCategory`. Fields that are set will be updated. */
export type AssetCategoryPatch = {
  description?: InputMaybe<Scalars['String']>;
  prefix?: InputMaybe<Scalars['String']>;
};

/** A condition to be used against `Asset` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type AssetCondition = {
  /** Checks for equality with the object’s `active` field. */
  active?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `assetCode` field. */
  assetCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `categoryPrefix` field. */
  categoryPrefix?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

export type AssetCurrentLocation = {
  __typename?: 'AssetCurrentLocation';
  assetCode?: Maybe<Scalars['String']>;
  assetType?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['Datetime']>;
  geog?: Maybe<GeographyPoint>;
  id?: Maybe<Scalars['BigInt']>;
  speed?: Maybe<Scalars['Float']>;
};

/** Methods to use when ordering `AssetCurrentLocation`. */
export enum AssetCurrentLocationsOrderBy {
  Natural = 'NATURAL'
}

/** A filter to be used against `Asset` object types. All fields are combined with a logical ‘and.’ */
export type AssetFilter = {
  /** Filter by the object’s `active` field. */
  active?: InputMaybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AssetFilter>>;
  /** Filter by the object’s `assetCode` field. */
  assetCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `categoryPrefix` field. */
  categoryPrefix?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AssetFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AssetFilter>>;
};

/** An input for mutations affecting `Asset` */
export type AssetInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  assetCode: Scalars['String'];
  categoryCode?: InputMaybe<Scalars['String']>;
  categoryPrefix?: InputMaybe<Scalars['String']>;
  companyNo?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  licensePlate?: InputMaybe<Scalars['String']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  vin?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type AssetLatestLocation = {
  __typename?: 'AssetLatestLocation';
  assetId?: Maybe<Scalars['Int']>;
  datetime?: Maybe<Scalars['Datetime']>;
  geog?: Maybe<GeographyPoint>;
  speed?: Maybe<Scalars['Float']>;
};

/** Methods to use when ordering `AssetLatestLocation`. */
export enum AssetLatestLocationsOrderBy {
  Natural = 'NATURAL'
}

/** Represents an update to a `Asset`. Fields that are set will be updated. */
export type AssetPatch = {
  active?: InputMaybe<Scalars['Boolean']>;
  assetCode?: InputMaybe<Scalars['String']>;
  categoryCode?: InputMaybe<Scalars['String']>;
  categoryPrefix?: InputMaybe<Scalars['String']>;
  companyNo?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  licensePlate?: InputMaybe<Scalars['String']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  vin?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type AssetType = Node & {
  __typename?: 'AssetType';
  category: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/**
 * A condition to be used against `AssetType` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AssetTypeCondition = {
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `AssetType` object types. All fields are combined with a logical ‘and.’ */
export type AssetTypeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AssetTypeFilter>>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AssetTypeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AssetTypeFilter>>;
};

/** An input for mutations affecting `AssetType` */
export type AssetTypeInput = {
  category: Scalars['String'];
  description: Scalars['String'];
};

/** Represents an update to a `AssetType`. Fields that are set will be updated. */
export type AssetTypePatch = {
  category?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `AssetType`. */
export enum AssetTypesOrderBy {
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** Methods to use when ordering `AssetWingUnderbody`. */
export enum AssetWingUnderbodiesOrderBy {
  AssetIdAsc = 'ASSET_ID_ASC',
  AssetIdDesc = 'ASSET_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type AssetWingUnderbody = Node & {
  __typename?: 'AssetWingUnderbody';
  active: Scalars['Boolean'];
  /** Reads a single `Asset` that is related to this `AssetWingUnderbody`. */
  asset?: Maybe<Asset>;
  assetId: Scalars['Int'];
  effective: Scalars['Datetime'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/**
 * A condition to be used against `AssetWingUnderbody` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AssetWingUnderbodyCondition = {
  /** Checks for equality with the object’s `assetId` field. */
  assetId?: InputMaybe<Scalars['Int']>;
};

export type AssetWingUnderbodyCurrent = {
  __typename?: 'AssetWingUnderbodyCurrent';
  active?: Maybe<Scalars['Boolean']>;
  assetId?: Maybe<Scalars['Int']>;
  effective?: Maybe<Scalars['Datetime']>;
};

/** Methods to use when ordering `AssetWingUnderbodyCurrent`. */
export enum AssetWingUnderbodyCurrentsOrderBy {
  Natural = 'NATURAL'
}

/** A filter to be used against `AssetWingUnderbody` object types. All fields are combined with a logical ‘and.’ */
export type AssetWingUnderbodyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AssetWingUnderbodyFilter>>;
  /** Filter by the object’s `assetId` field. */
  assetId?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AssetWingUnderbodyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AssetWingUnderbodyFilter>>;
};

/** An input for mutations affecting `AssetWingUnderbody` */
export type AssetWingUnderbodyInput = {
  active: Scalars['Boolean'];
  assetId: Scalars['Int'];
  effective: Scalars['Datetime'];
};

/** Represents an update to a `AssetWingUnderbody`. Fields that are set will be updated. */
export type AssetWingUnderbodyPatch = {
  active?: InputMaybe<Scalars['Boolean']>;
  assetId?: InputMaybe<Scalars['Int']>;
  effective?: InputMaybe<Scalars['Datetime']>;
};

export type AssetWingUnderbodyPeriod = {
  __typename?: 'AssetWingUnderbodyPeriod';
  active?: Maybe<Scalars['Boolean']>;
  assetId?: Maybe<Scalars['Int']>;
  effectiveTime?: Maybe<DatetimeRange>;
};

/** Methods to use when ordering `AssetWingUnderbodyPeriod`. */
export enum AssetWingUnderbodyPeriodsOrderBy {
  Natural = 'NATURAL'
}

/** Methods to use when ordering `Asset`. */
export enum AssetsOrderBy {
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  AssetCodeAsc = 'ASSET_CODE_ASC',
  AssetCodeDesc = 'ASSET_CODE_DESC',
  CategoryPrefixAsc = 'CATEGORY_PREFIX_ASC',
  CategoryPrefixDesc = 'CATEGORY_PREFIX_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type AuthSessionToken = Node & {
  __typename?: 'AuthSessionToken';
  activeDate: Scalars['Datetime'];
  issueDate: Scalars['Datetime'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  sessionId: Scalars['Int'];
  /** Reads a single `AuthUser` that is related to this `AuthSessionToken`. */
  user?: Maybe<AuthUser>;
  userId: Scalars['Int'];
  versionId: Scalars['Int'];
};

/**
 * A condition to be used against `AuthSessionToken` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AuthSessionTokenCondition = {
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `AuthSessionToken` object types. All fields are combined with a logical ‘and.’ */
export type AuthSessionTokenFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AuthSessionTokenFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<AuthSessionTokenFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AuthSessionTokenFilter>>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `AuthSessionToken` */
export type AuthSessionTokenInput = {
  activeDate?: InputMaybe<Scalars['Datetime']>;
  issueDate?: InputMaybe<Scalars['Datetime']>;
  sessionId: Scalars['Int'];
  userId: Scalars['Int'];
  versionId?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `AuthSessionToken`. Fields that are set will be updated. */
export type AuthSessionTokenPatch = {
  activeDate?: InputMaybe<Scalars['Datetime']>;
  issueDate?: InputMaybe<Scalars['Datetime']>;
  sessionId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
  versionId?: InputMaybe<Scalars['Int']>;
};

/** Methods to use when ordering `AuthSessionToken`. */
export enum AuthSessionTokensOrderBy {
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type AuthUser = Node & {
  __typename?: 'AuthUser';
  active: Scalars['Boolean'];
  /** Reads and enables pagination through a set of `AuthSessionToken`. */
  authSessionTokensByUserId: Array<AuthSessionToken>;
  companyNo: Scalars['Int'];
  defaultOtCompensationMethod: CompensationMethod;
  email?: Maybe<Scalars['String']>;
  employeeNo: Scalars['Int'];
  fullname: Scalars['String'];
  /** Reads a single `OpsYard` that is related to this `AuthUser`. */
  homeYard?: Maybe<OpsYard>;
  homeYardId: Scalars['Int'];
  id: Scalars['Int'];
  isAdmin: Scalars['Boolean'];
  isSupervisor: Scalars['Boolean'];
  isolationEligible: Scalars['Boolean'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `OpsMembershipCustom`. */
  opsMembershipCustomsByUserId: Array<OpsMembershipCustom>;
  /** Reads and enables pagination through a set of `OpsSchedule`. */
  opsSchedulesByUserId: Array<OpsSchedule>;
  /** Reads and enables pagination through a set of `OpsSupervisor`. */
  opsSupervisorsBySupervisorId: Array<OpsSupervisor>;
  /** Reads and enables pagination through a set of `OpsSupervisor`. */
  opsSupervisorsByUserId: Array<OpsSupervisor>;
  passwordHash?: Maybe<Scalars['String']>;
  resetPassword: Scalars['Boolean'];
  /** Reads and enables pagination through a set of `TcRejection`. */
  reviewedTcRejections: Array<TcRejection>;
  /** Reads and enables pagination through a set of `TcLeave`. */
  tcLeavesByReviewedBy: Array<TcLeave>;
  /** Reads and enables pagination through a set of `TcLeave`. */
  tcLeavesByUserId: Array<TcLeave>;
  /** Reads and enables pagination through a set of `TcTimecard`. */
  tcTimecardsByApprovedBy: Array<TcTimecard>;
  /** Reads and enables pagination through a set of `TcTimecard`. */
  tcTimecardsByReviewedBy: Array<TcTimecard>;
  /** Reads and enables pagination through a set of `TcTimecard`. */
  tcTimecardsBySubmissionBy: Array<TcTimecard>;
  /** Reads and enables pagination through a set of `TcTimecard`. */
  tcTimecardsByWorkerId: Array<TcTimecard>;
  /** Reads and enables pagination through a set of `TcWork`. */
  tcWorksByAccomplishmentUserId: Array<TcWork>;
};


export type AuthUserAuthSessionTokensByUserIdArgs = {
  condition?: InputMaybe<AuthSessionTokenCondition>;
  filter?: InputMaybe<AuthSessionTokenFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthSessionTokensOrderBy>>;
};


export type AuthUserOpsMembershipCustomsByUserIdArgs = {
  condition?: InputMaybe<OpsMembershipCustomCondition>;
  filter?: InputMaybe<OpsMembershipCustomFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsMembershipCustomsOrderBy>>;
};


export type AuthUserOpsSchedulesByUserIdArgs = {
  condition?: InputMaybe<OpsScheduleCondition>;
  filter?: InputMaybe<OpsScheduleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsSchedulesOrderBy>>;
};


export type AuthUserOpsSupervisorsBySupervisorIdArgs = {
  condition?: InputMaybe<OpsSupervisorCondition>;
  filter?: InputMaybe<OpsSupervisorFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsSupervisorsOrderBy>>;
};


export type AuthUserOpsSupervisorsByUserIdArgs = {
  condition?: InputMaybe<OpsSupervisorCondition>;
  filter?: InputMaybe<OpsSupervisorFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsSupervisorsOrderBy>>;
};


export type AuthUserReviewedTcRejectionsArgs = {
  condition?: InputMaybe<TcRejectionCondition>;
  filter?: InputMaybe<TcRejectionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcRejectionsOrderBy>>;
};


export type AuthUserTcLeavesByReviewedByArgs = {
  condition?: InputMaybe<TcLeaveCondition>;
  filter?: InputMaybe<TcLeaveFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcLeavesOrderBy>>;
};


export type AuthUserTcLeavesByUserIdArgs = {
  condition?: InputMaybe<TcLeaveCondition>;
  filter?: InputMaybe<TcLeaveFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcLeavesOrderBy>>;
};


export type AuthUserTcTimecardsByApprovedByArgs = {
  condition?: InputMaybe<TcTimecardCondition>;
  filter?: InputMaybe<TcTimecardFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcTimecardsOrderBy>>;
};


export type AuthUserTcTimecardsByReviewedByArgs = {
  condition?: InputMaybe<TcTimecardCondition>;
  filter?: InputMaybe<TcTimecardFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcTimecardsOrderBy>>;
};


export type AuthUserTcTimecardsBySubmissionByArgs = {
  condition?: InputMaybe<TcTimecardCondition>;
  filter?: InputMaybe<TcTimecardFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcTimecardsOrderBy>>;
};


export type AuthUserTcTimecardsByWorkerIdArgs = {
  condition?: InputMaybe<TcTimecardCondition>;
  filter?: InputMaybe<TcTimecardFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcTimecardsOrderBy>>;
};


export type AuthUserTcWorksByAccomplishmentUserIdArgs = {
  condition?: InputMaybe<TcWorkCondition>;
  filter?: InputMaybe<TcWorkFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcWorksOrderBy>>;
};

/**
 * A condition to be used against `AuthUser` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AuthUserCondition = {
  /** Checks for equality with the object’s `companyNo` field. */
  companyNo?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `employeeNo` field. */
  employeeNo?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `fullname` field. */
  fullname?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `homeYardId` field. */
  homeYardId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `AuthUser` object types. All fields are combined with a logical ‘and.’ */
export type AuthUserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AuthUserFilter>>;
  /** Filter by the object’s `companyNo` field. */
  companyNo?: InputMaybe<IntFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `employeeNo` field. */
  employeeNo?: InputMaybe<IntFilter>;
  /** Filter by the object’s `fullname` field. */
  fullname?: InputMaybe<StringFilter>;
  /** Filter by the object’s `homeYardId` field. */
  homeYardId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AuthUserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AuthUserFilter>>;
};

/** An input for mutations affecting `AuthUser` */
export type AuthUserInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  companyNo: Scalars['Int'];
  defaultOtCompensationMethod?: InputMaybe<CompensationMethod>;
  email?: InputMaybe<Scalars['String']>;
  employeeNo: Scalars['Int'];
  fullname: Scalars['String'];
  homeYardId: Scalars['Int'];
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  isSupervisor?: InputMaybe<Scalars['Boolean']>;
  isolationEligible?: InputMaybe<Scalars['Boolean']>;
  passwordHash?: InputMaybe<Scalars['String']>;
  resetPassword?: InputMaybe<Scalars['Boolean']>;
};

/** Represents an update to a `AuthUser`. Fields that are set will be updated. */
export type AuthUserPatch = {
  active?: InputMaybe<Scalars['Boolean']>;
  companyNo?: InputMaybe<Scalars['Int']>;
  defaultOtCompensationMethod?: InputMaybe<CompensationMethod>;
  email?: InputMaybe<Scalars['String']>;
  employeeNo?: InputMaybe<Scalars['Int']>;
  fullname?: InputMaybe<Scalars['String']>;
  homeYardId?: InputMaybe<Scalars['Int']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  isSupervisor?: InputMaybe<Scalars['Boolean']>;
  isolationEligible?: InputMaybe<Scalars['Boolean']>;
  passwordHash?: InputMaybe<Scalars['String']>;
  resetPassword?: InputMaybe<Scalars['Boolean']>;
};

/** Methods to use when ordering `AuthUser`. */
export enum AuthUsersOrderBy {
  CompanyNoAsc = 'COMPANY_NO_ASC',
  CompanyNoDesc = 'COMPANY_NO_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  EmployeeNoAsc = 'EMPLOYEE_NO_ASC',
  EmployeeNoDesc = 'EMPLOYEE_NO_DESC',
  FullnameAsc = 'FULLNAME_ASC',
  FullnameDesc = 'FULLNAME_DESC',
  HomeYardIdAsc = 'HOME_YARD_ID_ASC',
  HomeYardIdDesc = 'HOME_YARD_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type BecBiogeoclimatic = Node & {
  __typename?: 'BecBiogeoclimatic';
  bgcLabel?: Maybe<Scalars['String']>;
  featureArea?: Maybe<Scalars['Float']>;
  featureAreaSqm?: Maybe<Scalars['Float']>;
  featureClassSkey?: Maybe<Scalars['Int']>;
  featureLength?: Maybe<Scalars['Float']>;
  featureLengthM?: Maybe<Scalars['Float']>;
  geom?: Maybe<GeometryMultiPolygon>;
  id: Scalars['Int'];
  mapLabel?: Maybe<Scalars['String']>;
  naturalDisturbance?: Maybe<Scalars['String']>;
  naturalDisturbanceName?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  objectid?: Maybe<Scalars['Int']>;
  phase?: Maybe<Scalars['String']>;
  phaseName?: Maybe<Scalars['String']>;
  subzone?: Maybe<Scalars['String']>;
  subzoneName?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  variantName?: Maybe<Scalars['String']>;
  zone?: Maybe<Scalars['String']>;
  zoneName?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `BecBiogeoclimatic` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type BecBiogeoclimaticCondition = {
  /** Checks for equality with the object’s `geom` field. */
  geom?: InputMaybe<Scalars['GeoJSON']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `BecBiogeoclimatic` object types. All fields are combined with a logical ‘and.’ */
export type BecBiogeoclimaticFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BecBiogeoclimaticFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BecBiogeoclimaticFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BecBiogeoclimaticFilter>>;
};

/** An input for mutations affecting `BecBiogeoclimatic` */
export type BecBiogeoclimaticInput = {
  bgcLabel?: InputMaybe<Scalars['String']>;
  featureArea?: InputMaybe<Scalars['Float']>;
  featureAreaSqm?: InputMaybe<Scalars['Float']>;
  featureClassSkey?: InputMaybe<Scalars['Int']>;
  featureLength?: InputMaybe<Scalars['Float']>;
  featureLengthM?: InputMaybe<Scalars['Float']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  id?: InputMaybe<Scalars['Int']>;
  mapLabel?: InputMaybe<Scalars['String']>;
  naturalDisturbance?: InputMaybe<Scalars['String']>;
  naturalDisturbanceName?: InputMaybe<Scalars['String']>;
  objectid?: InputMaybe<Scalars['Int']>;
  phase?: InputMaybe<Scalars['String']>;
  phaseName?: InputMaybe<Scalars['String']>;
  subzone?: InputMaybe<Scalars['String']>;
  subzoneName?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<Scalars['String']>;
  variantName?: InputMaybe<Scalars['String']>;
  zone?: InputMaybe<Scalars['String']>;
  zoneName?: InputMaybe<Scalars['String']>;
};

/** Represents an update to a `BecBiogeoclimatic`. Fields that are set will be updated. */
export type BecBiogeoclimaticPatch = {
  bgcLabel?: InputMaybe<Scalars['String']>;
  featureArea?: InputMaybe<Scalars['Float']>;
  featureAreaSqm?: InputMaybe<Scalars['Float']>;
  featureClassSkey?: InputMaybe<Scalars['Int']>;
  featureLength?: InputMaybe<Scalars['Float']>;
  featureLengthM?: InputMaybe<Scalars['Float']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  id?: InputMaybe<Scalars['Int']>;
  mapLabel?: InputMaybe<Scalars['String']>;
  naturalDisturbance?: InputMaybe<Scalars['String']>;
  naturalDisturbanceName?: InputMaybe<Scalars['String']>;
  objectid?: InputMaybe<Scalars['Int']>;
  phase?: InputMaybe<Scalars['String']>;
  phaseName?: InputMaybe<Scalars['String']>;
  subzone?: InputMaybe<Scalars['String']>;
  subzoneName?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<Scalars['String']>;
  variantName?: InputMaybe<Scalars['String']>;
  zone?: InputMaybe<Scalars['String']>;
  zoneName?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `BecBiogeoclimatic`. */
export enum BecBiogeoclimaticsOrderBy {
  GeomAsc = 'GEOM_ASC',
  GeomDesc = 'GEOM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type BiForecast = Node & {
  __typename?: 'BiForecast';
  authorUsername: Scalars['String'];
  /** Reads and enables pagination through a set of `BiForecastPermission`. */
  biForecastPermissionsByForecastId: Array<BiForecastPermission>;
  /** Reads and enables pagination through a set of `BiForecastPhase`. */
  biForecastPhasesByForecastId: Array<BiForecastPhase>;
  companyCode: Scalars['Int'];
  description: Scalars['String'];
  endDate: Scalars['Date'];
  id: Scalars['Int'];
  jobCode: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  saveTime: Scalars['Datetime'];
  startDate: Scalars['Date'];
};


export type BiForecastBiForecastPermissionsByForecastIdArgs = {
  condition?: InputMaybe<BiForecastPermissionCondition>;
  filter?: InputMaybe<BiForecastPermissionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BiForecastPermissionsOrderBy>>;
};


export type BiForecastBiForecastPhasesByForecastIdArgs = {
  condition?: InputMaybe<BiForecastPhaseCondition>;
  filter?: InputMaybe<BiForecastPhaseFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BiForecastPhasesOrderBy>>;
};

/**
 * A condition to be used against `BiForecast` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type BiForecastCondition = {
  /** Checks for equality with the object’s `authorUsername` field. */
  authorUsername?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `companyCode` field. */
  companyCode?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `jobCode` field. */
  jobCode?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `BiForecast` object types. All fields are combined with a logical ‘and.’ */
export type BiForecastFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BiForecastFilter>>;
  /** Filter by the object’s `authorUsername` field. */
  authorUsername?: InputMaybe<StringFilter>;
  /** Filter by the object’s `companyCode` field. */
  companyCode?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `jobCode` field. */
  jobCode?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BiForecastFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BiForecastFilter>>;
};

/** An input for mutations affecting `BiForecast` */
export type BiForecastInput = {
  authorUsername: Scalars['String'];
  companyCode: Scalars['Int'];
  description: Scalars['String'];
  endDate: Scalars['Date'];
  jobCode: Scalars['String'];
  saveTime: Scalars['Datetime'];
  startDate: Scalars['Date'];
};

/** Represents an update to a `BiForecast`. Fields that are set will be updated. */
export type BiForecastPatch = {
  authorUsername?: InputMaybe<Scalars['String']>;
  companyCode?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  jobCode?: InputMaybe<Scalars['String']>;
  saveTime?: InputMaybe<Scalars['Datetime']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type BiForecastPermission = Node & {
  __typename?: 'BiForecastPermission';
  /** Reads a single `BiForecast` that is related to this `BiForecastPermission`. */
  forecast?: Maybe<BiForecast>;
  forecastId: Scalars['Int'];
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  username: Scalars['String'];
};

/**
 * A condition to be used against `BiForecastPermission` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type BiForecastPermissionCondition = {
  /** Checks for equality with the object’s `forecastId` field. */
  forecastId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `BiForecastPermission` object types. All fields are combined with a logical ‘and.’ */
export type BiForecastPermissionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BiForecastPermissionFilter>>;
  /** Filter by the object’s `forecastId` field. */
  forecastId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BiForecastPermissionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BiForecastPermissionFilter>>;
};

/** An input for mutations affecting `BiForecastPermission` */
export type BiForecastPermissionInput = {
  forecastId: Scalars['Int'];
  username: Scalars['String'];
};

/** Represents an update to a `BiForecastPermission`. Fields that are set will be updated. */
export type BiForecastPermissionPatch = {
  forecastId?: InputMaybe<Scalars['Int']>;
  username?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `BiForecastPermission`. */
export enum BiForecastPermissionsOrderBy {
  ForecastIdAsc = 'FORECAST_ID_ASC',
  ForecastIdDesc = 'FORECAST_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type BiForecastPhase = Node & {
  __typename?: 'BiForecastPhase';
  fc?: Maybe<Scalars['Float']>;
  /** Reads a single `BiForecast` that is related to this `BiForecastPhase`. */
  forecast?: Maybe<BiForecast>;
  forecastId: Scalars['Int'];
  fq?: Maybe<Scalars['Float']>;
  fu?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  phaseCode: Scalars['String'];
  rc?: Maybe<Scalars['Float']>;
  rq?: Maybe<Scalars['Float']>;
  ru?: Maybe<Scalars['Float']>;
};

/**
 * A condition to be used against `BiForecastPhase` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type BiForecastPhaseCondition = {
  /** Checks for equality with the object’s `forecastId` field. */
  forecastId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `BiForecastPhase` object types. All fields are combined with a logical ‘and.’ */
export type BiForecastPhaseFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BiForecastPhaseFilter>>;
  /** Filter by the object’s `forecastId` field. */
  forecastId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BiForecastPhaseFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BiForecastPhaseFilter>>;
};

/** An input for mutations affecting `BiForecastPhase` */
export type BiForecastPhaseInput = {
  fc?: InputMaybe<Scalars['Float']>;
  forecastId: Scalars['Int'];
  fq?: InputMaybe<Scalars['Float']>;
  fu?: InputMaybe<Scalars['Float']>;
  phaseCode: Scalars['String'];
  rc?: InputMaybe<Scalars['Float']>;
  rq?: InputMaybe<Scalars['Float']>;
  ru?: InputMaybe<Scalars['Float']>;
};

/** Represents an update to a `BiForecastPhase`. Fields that are set will be updated. */
export type BiForecastPhasePatch = {
  fc?: InputMaybe<Scalars['Float']>;
  forecastId?: InputMaybe<Scalars['Int']>;
  fq?: InputMaybe<Scalars['Float']>;
  fu?: InputMaybe<Scalars['Float']>;
  phaseCode?: InputMaybe<Scalars['String']>;
  rc?: InputMaybe<Scalars['Float']>;
  rq?: InputMaybe<Scalars['Float']>;
  ru?: InputMaybe<Scalars['Float']>;
};

/** Methods to use when ordering `BiForecastPhase`. */
export enum BiForecastPhasesOrderBy {
  ForecastIdAsc = 'FORECAST_ID_ASC',
  ForecastIdDesc = 'FORECAST_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** Methods to use when ordering `BiForecast`. */
export enum BiForecastsOrderBy {
  AuthorUsernameAsc = 'AUTHOR_USERNAME_ASC',
  AuthorUsernameDesc = 'AUTHOR_USERNAME_DESC',
  CompanyCodeAsc = 'COMPANY_CODE_ASC',
  CompanyCodeDesc = 'COMPANY_CODE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  JobCodeAsc = 'JOB_CODE_ASC',
  JobCodeDesc = 'JOB_CODE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Boolean']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Boolean']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Boolean']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Boolean']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Boolean']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type Breadcrumb = {
  __typename?: 'Breadcrumb';
  asset_code: Scalars['String'];
  asset_type?: Maybe<Scalars['String']>;
  datetime: Scalars['DateTime'];
  position: Point;
  speed?: Maybe<Scalars['Float']>;
};

export type BreadcrumbYard = {
  __typename?: 'BreadcrumbYard';
  id?: Maybe<Scalars['Int']>;
  yardId?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `BreadcrumbYard` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type BreadcrumbYardCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `yardId` field. */
  yardId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `BreadcrumbYard` object types. All fields are combined with a logical ‘and.’ */
export type BreadcrumbYardFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BreadcrumbYardFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BreadcrumbYardFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BreadcrumbYardFilter>>;
  /** Filter by the object’s `yardId` field. */
  yardId?: InputMaybe<IntFilter>;
};

/** Methods to use when ordering `BreadcrumbYard`. */
export enum BreadcrumbYardsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  YardIdAsc = 'YARD_ID_ASC',
  YardIdDesc = 'YARD_ID_DESC'
}

export type ClearDiagnosticResponse = {
  __typename?: 'ClearDiagnosticResponse';
  code: Scalars['String'];
  diagnostics: Array<VehicleDiagnosticSummary>;
  message?: Maybe<Scalars['String']>;
};

export type Collision = Node & {
  __typename?: 'Collision';
  atDesc?: Maybe<Scalars['String']>;
  caseNo: Scalars['String'];
  collisionDate?: Maybe<Scalars['Date']>;
  collisionTime?: Maybe<Scalars['Time']>;
  contributingFactor: Scalars['String'];
  highwayNo: Scalars['String'];
  injured: Scalars['Int'];
  killed: Scalars['Int'];
  km: Scalars['Float'];
  locationDesc: Scalars['String'];
  locationType: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  onDesc?: Maybe<Scalars['String']>;
  primaryOccurance: Scalars['String'];
  segmentId: Scalars['Int'];
  surfaceCondition: Scalars['String'];
  vehicle1Dir: Scalars['String'];
  vehicle2Dir?: Maybe<Scalars['String']>;
  vehicles: Scalars['Int'];
  weatherCondition: Scalars['String'];
};

/**
 * A condition to be used against `Collision` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CollisionCondition = {
  /** Checks for equality with the object’s `caseNo` field. */
  caseNo?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `Collision` object types. All fields are combined with a logical ‘and.’ */
export type CollisionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CollisionFilter>>;
  /** Filter by the object’s `caseNo` field. */
  caseNo?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CollisionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CollisionFilter>>;
};

export type CollisionGeom = {
  __typename?: 'CollisionGeom';
  atDesc?: Maybe<Scalars['String']>;
  caseNo?: Maybe<Scalars['String']>;
  collisionDate?: Maybe<Scalars['Date']>;
  collisionTime?: Maybe<Scalars['Time']>;
  contributingFactor?: Maybe<Scalars['String']>;
  geom?: Maybe<GeometryInterface>;
  injured?: Maybe<Scalars['Int']>;
  killed?: Maybe<Scalars['Int']>;
  locationType?: Maybe<Scalars['String']>;
  onDesc?: Maybe<Scalars['String']>;
  primaryOccurance?: Maybe<Scalars['String']>;
  surfaceCondition?: Maybe<Scalars['String']>;
  vehicle1Dir?: Maybe<Scalars['String']>;
  vehicle2Dir?: Maybe<Scalars['String']>;
  vehicles?: Maybe<Scalars['Int']>;
  weatherCondition?: Maybe<Scalars['String']>;
};

/** Methods to use when ordering `CollisionGeom`. */
export enum CollisionGeomsOrderBy {
  Natural = 'NATURAL'
}

/** An input for mutations affecting `Collision` */
export type CollisionInput = {
  atDesc?: InputMaybe<Scalars['String']>;
  caseNo: Scalars['String'];
  collisionDate?: InputMaybe<Scalars['Date']>;
  collisionTime?: InputMaybe<Scalars['Time']>;
  contributingFactor: Scalars['String'];
  highwayNo: Scalars['String'];
  injured?: InputMaybe<Scalars['Int']>;
  killed?: InputMaybe<Scalars['Int']>;
  km: Scalars['Float'];
  locationDesc: Scalars['String'];
  locationType: Scalars['String'];
  onDesc?: InputMaybe<Scalars['String']>;
  primaryOccurance: Scalars['String'];
  segmentId: Scalars['Int'];
  surfaceCondition: Scalars['String'];
  vehicle1Dir: Scalars['String'];
  vehicle2Dir?: InputMaybe<Scalars['String']>;
  vehicles?: InputMaybe<Scalars['Int']>;
  weatherCondition: Scalars['String'];
};

/** Represents an update to a `Collision`. Fields that are set will be updated. */
export type CollisionPatch = {
  atDesc?: InputMaybe<Scalars['String']>;
  caseNo?: InputMaybe<Scalars['String']>;
  collisionDate?: InputMaybe<Scalars['Date']>;
  collisionTime?: InputMaybe<Scalars['Time']>;
  contributingFactor?: InputMaybe<Scalars['String']>;
  highwayNo?: InputMaybe<Scalars['String']>;
  injured?: InputMaybe<Scalars['Int']>;
  killed?: InputMaybe<Scalars['Int']>;
  km?: InputMaybe<Scalars['Float']>;
  locationDesc?: InputMaybe<Scalars['String']>;
  locationType?: InputMaybe<Scalars['String']>;
  onDesc?: InputMaybe<Scalars['String']>;
  primaryOccurance?: InputMaybe<Scalars['String']>;
  segmentId?: InputMaybe<Scalars['Int']>;
  surfaceCondition?: InputMaybe<Scalars['String']>;
  vehicle1Dir?: InputMaybe<Scalars['String']>;
  vehicle2Dir?: InputMaybe<Scalars['String']>;
  vehicles?: InputMaybe<Scalars['Int']>;
  weatherCondition?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `Collision`. */
export enum CollisionsOrderBy {
  CaseNoAsc = 'CASE_NO_ASC',
  CaseNoDesc = 'CASE_NO_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export enum CompensationMethod {
  Cash = 'CASH',
  Cto = 'CTO'
}

export type Contract = {
  __typename?: 'Contract';
  BilledAmt: Scalars['Float'];
  ContractAmt: Scalars['Float'];
  ContractCode: Scalars['ID'];
  ContractStatus: Scalars['Int'];
  CurrentDays: Scalars['Int'];
  CurrentRetainAmt: Scalars['Float'];
  Description?: Maybe<Scalars['String']>;
  JCCo: Scalars['ID'];
  MonthClosed?: Maybe<Scalars['String']>;
  OriginalContractCode: Scalars['ID'];
  OriginalDays: Scalars['Int'];
  ReceivedAmt: Scalars['Float'];
  StartMonth: Scalars['String'];
  invoices: Array<Invoice>;
  items: Array<ContractItem>;
};

export type ContractItem = {
  __typename?: 'ContractItem';
  BilledAmt: Scalars['Float'];
  BilledUnits: Scalars['Float'];
  ContractAmt: Scalars['Float'];
  ContractCode: Scalars['ID'];
  ContractUnits: Scalars['Float'];
  Department: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  ItemCode: Scalars['ID'];
  JCCo?: Maybe<Scalars['ID']>;
  OriginalContractCode: Scalars['ID'];
  OriginalItemCode: Scalars['ID'];
  ReceivedAmt: Scalars['Float'];
  UM: Scalars['String'];
  UnitPrice: Scalars['Float'];
};

/** All input for the create `AssetBreadcrumb` mutation. */
export type CreateAssetBreadcrumbInput = {
  /** The `AssetBreadcrumb` to be created by this mutation. */
  assetBreadcrumb: AssetBreadcrumbInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `AssetBreadcrumb` mutation. */
export type CreateAssetBreadcrumbPayload = {
  __typename?: 'CreateAssetBreadcrumbPayload';
  /** Reads a single `Asset` that is related to this `AssetBreadcrumb`. */
  asset?: Maybe<Asset>;
  /** The `AssetBreadcrumb` that was created by this mutation. */
  assetBreadcrumb?: Maybe<AssetBreadcrumb>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `ImapRoadClass` that is related to this `AssetBreadcrumb`. */
  roadClass?: Maybe<ImapRoadClass>;
  /** Reads a single `ImapRoadFeature` that is related to this `AssetBreadcrumb`. */
  roadFeature?: Maybe<ImapRoadFeature>;
  /** Reads a single `ImapRoadSurface` that is related to this `AssetBreadcrumb`. */
  roadSurface?: Maybe<ImapRoadSurface>;
  /** Reads a single `OpsYard` that is related to this `AssetBreadcrumb`. */
  yard?: Maybe<OpsYard>;
};

/** All input for the create `AssetCategory` mutation. */
export type CreateAssetCategoryInput = {
  /** The `AssetCategory` to be created by this mutation. */
  assetCategory: AssetCategoryInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `AssetCategory` mutation. */
export type CreateAssetCategoryPayload = {
  __typename?: 'CreateAssetCategoryPayload';
  /** The `AssetCategory` that was created by this mutation. */
  assetCategory?: Maybe<AssetCategory>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `Asset` mutation. */
export type CreateAssetInput = {
  /** The `Asset` to be created by this mutation. */
  asset: AssetInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `Asset` mutation. */
export type CreateAssetPayload = {
  __typename?: 'CreateAssetPayload';
  /** The `Asset` that was created by this mutation. */
  asset?: Maybe<Asset>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `AssetType` mutation. */
export type CreateAssetTypeInput = {
  /** The `AssetType` to be created by this mutation. */
  assetType: AssetTypeInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `AssetType` mutation. */
export type CreateAssetTypePayload = {
  __typename?: 'CreateAssetTypePayload';
  /** The `AssetType` that was created by this mutation. */
  assetType?: Maybe<AssetType>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `AssetWingUnderbody` mutation. */
export type CreateAssetWingUnderbodyInput = {
  /** The `AssetWingUnderbody` to be created by this mutation. */
  assetWingUnderbody: AssetWingUnderbodyInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `AssetWingUnderbody` mutation. */
export type CreateAssetWingUnderbodyPayload = {
  __typename?: 'CreateAssetWingUnderbodyPayload';
  /** Reads a single `Asset` that is related to this `AssetWingUnderbody`. */
  asset?: Maybe<Asset>;
  /** The `AssetWingUnderbody` that was created by this mutation. */
  assetWingUnderbody?: Maybe<AssetWingUnderbody>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `AuthSessionToken` mutation. */
export type CreateAuthSessionTokenInput = {
  /** The `AuthSessionToken` to be created by this mutation. */
  authSessionToken: AuthSessionTokenInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `AuthSessionToken` mutation. */
export type CreateAuthSessionTokenPayload = {
  __typename?: 'CreateAuthSessionTokenPayload';
  /** The `AuthSessionToken` that was created by this mutation. */
  authSessionToken?: Maybe<AuthSessionToken>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AuthUser` that is related to this `AuthSessionToken`. */
  user?: Maybe<AuthUser>;
};

/** All input for the create `AuthUser` mutation. */
export type CreateAuthUserInput = {
  /** The `AuthUser` to be created by this mutation. */
  authUser: AuthUserInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `AuthUser` mutation. */
export type CreateAuthUserPayload = {
  __typename?: 'CreateAuthUserPayload';
  /** The `AuthUser` that was created by this mutation. */
  authUser?: Maybe<AuthUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `OpsYard` that is related to this `AuthUser`. */
  homeYard?: Maybe<OpsYard>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `BecBiogeoclimatic` mutation. */
export type CreateBecBiogeoclimaticInput = {
  /** The `BecBiogeoclimatic` to be created by this mutation. */
  becBiogeoclimatic: BecBiogeoclimaticInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `BecBiogeoclimatic` mutation. */
export type CreateBecBiogeoclimaticPayload = {
  __typename?: 'CreateBecBiogeoclimaticPayload';
  /** The `BecBiogeoclimatic` that was created by this mutation. */
  becBiogeoclimatic?: Maybe<BecBiogeoclimatic>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `BiForecast` mutation. */
export type CreateBiForecastInput = {
  /** The `BiForecast` to be created by this mutation. */
  biForecast: BiForecastInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `BiForecast` mutation. */
export type CreateBiForecastPayload = {
  __typename?: 'CreateBiForecastPayload';
  /** The `BiForecast` that was created by this mutation. */
  biForecast?: Maybe<BiForecast>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `BiForecastPermission` mutation. */
export type CreateBiForecastPermissionInput = {
  /** The `BiForecastPermission` to be created by this mutation. */
  biForecastPermission: BiForecastPermissionInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `BiForecastPermission` mutation. */
export type CreateBiForecastPermissionPayload = {
  __typename?: 'CreateBiForecastPermissionPayload';
  /** The `BiForecastPermission` that was created by this mutation. */
  biForecastPermission?: Maybe<BiForecastPermission>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `BiForecast` that is related to this `BiForecastPermission`. */
  forecast?: Maybe<BiForecast>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `BiForecastPhase` mutation. */
export type CreateBiForecastPhaseInput = {
  /** The `BiForecastPhase` to be created by this mutation. */
  biForecastPhase: BiForecastPhaseInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `BiForecastPhase` mutation. */
export type CreateBiForecastPhasePayload = {
  __typename?: 'CreateBiForecastPhasePayload';
  /** The `BiForecastPhase` that was created by this mutation. */
  biForecastPhase?: Maybe<BiForecastPhase>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `BiForecast` that is related to this `BiForecastPhase`. */
  forecast?: Maybe<BiForecast>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `Collision` mutation. */
export type CreateCollisionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Collision` to be created by this mutation. */
  collision: CollisionInput;
};

/** The output of our create `Collision` mutation. */
export type CreateCollisionPayload = {
  __typename?: 'CreateCollisionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Collision` that was created by this mutation. */
  collision?: Maybe<Collision>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `DtcFmi` mutation. */
export type CreateDtcFmiInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `DtcFmi` to be created by this mutation. */
  dtcFmi: DtcFmiInput;
};

/** The output of our create `DtcFmi` mutation. */
export type CreateDtcFmiPayload = {
  __typename?: 'CreateDtcFmiPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `DtcFmi` that was created by this mutation. */
  dtcFmi?: Maybe<DtcFmi>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `DtcObd` mutation. */
export type CreateDtcObdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `DtcObd` to be created by this mutation. */
  dtcObd: DtcObdInput;
};

/** The output of our create `DtcObd` mutation. */
export type CreateDtcObdPayload = {
  __typename?: 'CreateDtcObdPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `DtcObd` that was created by this mutation. */
  dtcObd?: Maybe<DtcObd>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `DtcSpn` mutation. */
export type CreateDtcSpnInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `DtcSpn` to be created by this mutation. */
  dtcSpn: DtcSpnInput;
};

/** The output of our create `DtcSpn` mutation. */
export type CreateDtcSpnPayload = {
  __typename?: 'CreateDtcSpnPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `DtcSpn` that was created by this mutation. */
  dtcSpn?: Maybe<DtcSpn>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `GisAtlasZone` mutation. */
export type CreateGisAtlasZoneInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `GisAtlasZone` to be created by this mutation. */
  gisAtlasZone: GisAtlasZoneInput;
};

/** The output of our create `GisAtlasZone` mutation. */
export type CreateGisAtlasZonePayload = {
  __typename?: 'CreateGisAtlasZonePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GisAtlasZone` that was created by this mutation. */
  gisAtlasZone?: Maybe<GisAtlasZone>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `ImapActiveStructure` mutation. */
export type CreateImapActiveStructureInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ImapActiveStructure` to be created by this mutation. */
  imapActiveStructure: ImapActiveStructureInput;
};

/** The output of our create `ImapActiveStructure` mutation. */
export type CreateImapActiveStructurePayload = {
  __typename?: 'CreateImapActiveStructurePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapActiveStructure` that was created by this mutation. */
  imapActiveStructure?: Maybe<ImapActiveStructure>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `ImapCulvert` mutation. */
export type CreateImapCulvertInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ImapCulvert` to be created by this mutation. */
  imapCulvert: ImapCulvertInput;
};

/** The output of our create `ImapCulvert` mutation. */
export type CreateImapCulvertPayload = {
  __typename?: 'CreateImapCulvertPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapCulvert` that was created by this mutation. */
  imapCulvert?: Maybe<ImapCulvert>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `ImapDrainage` mutation. */
export type CreateImapDrainageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ImapDrainage` to be created by this mutation. */
  imapDrainage: ImapDrainageInput;
};

/** The output of our create `ImapDrainage` mutation. */
export type CreateImapDrainagePayload = {
  __typename?: 'CreateImapDrainagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapDrainage` that was created by this mutation. */
  imapDrainage?: Maybe<ImapDrainage>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `ImapGuardrail` mutation. */
export type CreateImapGuardrailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ImapGuardrail` to be created by this mutation. */
  imapGuardrail: ImapGuardrailInput;
};

/** The output of our create `ImapGuardrail` mutation. */
export type CreateImapGuardrailPayload = {
  __typename?: 'CreateImapGuardrailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapGuardrail` that was created by this mutation. */
  imapGuardrail?: Maybe<ImapGuardrail>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `ImapHighwayProfile` mutation. */
export type CreateImapHighwayProfileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ImapHighwayProfile` to be created by this mutation. */
  imapHighwayProfile: ImapHighwayProfileInput;
};

/** The output of our create `ImapHighwayProfile` mutation. */
export type CreateImapHighwayProfilePayload = {
  __typename?: 'CreateImapHighwayProfilePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapHighwayProfile` that was created by this mutation. */
  imapHighwayProfile?: Maybe<ImapHighwayProfile>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `ImapLandmark` mutation. */
export type CreateImapLandmarkInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ImapLandmark` to be created by this mutation. */
  imapLandmark: ImapLandmarkInput;
};

/** The output of our create `ImapLandmark` mutation. */
export type CreateImapLandmarkPayload = {
  __typename?: 'CreateImapLandmarkPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapLandmark` that was created by this mutation. */
  imapLandmark?: Maybe<ImapLandmark>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `ImapRestarea` mutation. */
export type CreateImapRestareaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ImapRestarea` to be created by this mutation. */
  imapRestarea: ImapRestareaInput;
};

/** The output of our create `ImapRestarea` mutation. */
export type CreateImapRestareaPayload = {
  __typename?: 'CreateImapRestareaPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapRestarea` that was created by this mutation. */
  imapRestarea?: Maybe<ImapRestarea>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `ImapRetainingWall` mutation. */
export type CreateImapRetainingWallInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ImapRetainingWall` to be created by this mutation. */
  imapRetainingWall: ImapRetainingWallInput;
};

/** The output of our create `ImapRetainingWall` mutation. */
export type CreateImapRetainingWallPayload = {
  __typename?: 'CreateImapRetainingWallPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapRetainingWall` that was created by this mutation. */
  imapRetainingWall?: Maybe<ImapRetainingWall>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `ImapRoadClassFeature` mutation. */
export type CreateImapRoadClassFeatureInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ImapRoadClassFeature` to be created by this mutation. */
  imapRoadClassFeature: ImapRoadClassFeatureInput;
};

/** The output of our create `ImapRoadClassFeature` mutation. */
export type CreateImapRoadClassFeaturePayload = {
  __typename?: 'CreateImapRoadClassFeaturePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapRoadClassFeature` that was created by this mutation. */
  imapRoadClassFeature?: Maybe<ImapRoadClassFeature>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `ImapRoadClass` that is related to this `ImapRoadClassFeature`. */
  roadClass?: Maybe<ImapRoadClass>;
  /** Reads a single `ImapRoadFeature` that is related to this `ImapRoadClassFeature`. */
  roadFeature?: Maybe<ImapRoadFeature>;
};

/** All input for the create `ImapRoadClass` mutation. */
export type CreateImapRoadClassInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ImapRoadClass` to be created by this mutation. */
  imapRoadClass: ImapRoadClassInput;
};

/** The output of our create `ImapRoadClass` mutation. */
export type CreateImapRoadClassPayload = {
  __typename?: 'CreateImapRoadClassPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapRoadClass` that was created by this mutation. */
  imapRoadClass?: Maybe<ImapRoadClass>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `ImapRoadFeature` mutation. */
export type CreateImapRoadFeatureInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ImapRoadFeature` to be created by this mutation. */
  imapRoadFeature: ImapRoadFeatureInput;
};

/** The output of our create `ImapRoadFeature` mutation. */
export type CreateImapRoadFeaturePayload = {
  __typename?: 'CreateImapRoadFeaturePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapRoadFeature` that was created by this mutation. */
  imapRoadFeature?: Maybe<ImapRoadFeature>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `ImapRoadStructure` mutation. */
export type CreateImapRoadStructureInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ImapRoadStructure` to be created by this mutation. */
  imapRoadStructure: ImapRoadStructureInput;
};

/** The output of our create `ImapRoadStructure` mutation. */
export type CreateImapRoadStructurePayload = {
  __typename?: 'CreateImapRoadStructurePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapRoadStructure` that was created by this mutation. */
  imapRoadStructure?: Maybe<ImapRoadStructure>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `ImapRoadSurfaceFeature` mutation. */
export type CreateImapRoadSurfaceFeatureInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ImapRoadSurfaceFeature` to be created by this mutation. */
  imapRoadSurfaceFeature: ImapRoadSurfaceFeatureInput;
};

/** The output of our create `ImapRoadSurfaceFeature` mutation. */
export type CreateImapRoadSurfaceFeaturePayload = {
  __typename?: 'CreateImapRoadSurfaceFeaturePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapRoadSurfaceFeature` that was created by this mutation. */
  imapRoadSurfaceFeature?: Maybe<ImapRoadSurfaceFeature>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `ImapRoadFeature` that is related to this `ImapRoadSurfaceFeature`. */
  roadFeature?: Maybe<ImapRoadFeature>;
  /** Reads a single `ImapRoadSurface` that is related to this `ImapRoadSurfaceFeature`. */
  roadSurface?: Maybe<ImapRoadSurface>;
};

/** All input for the create `ImapRoadSurface` mutation. */
export type CreateImapRoadSurfaceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ImapRoadSurface` to be created by this mutation. */
  imapRoadSurface: ImapRoadSurfaceInput;
};

/** The output of our create `ImapRoadSurface` mutation. */
export type CreateImapRoadSurfacePayload = {
  __typename?: 'CreateImapRoadSurfacePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapRoadSurface` that was created by this mutation. */
  imapRoadSurface?: Maybe<ImapRoadSurface>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `ImapSafety` mutation. */
export type CreateImapSafetyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ImapSafety` to be created by this mutation. */
  imapSafety: ImapSafetyInput;
};

/** The output of our create `ImapSafety` mutation. */
export type CreateImapSafetyPayload = {
  __typename?: 'CreateImapSafetyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapSafety` that was created by this mutation. */
  imapSafety?: Maybe<ImapSafety>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `ImapSign` mutation. */
export type CreateImapSignInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ImapSign` to be created by this mutation. */
  imapSign: ImapSignInput;
};

/** The output of our create `ImapSign` mutation. */
export type CreateImapSignPayload = {
  __typename?: 'CreateImapSignPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapSign` that was created by this mutation. */
  imapSign?: Maybe<ImapSign>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `OpsActivity` mutation. */
export type CreateOpsActivityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `OpsActivity` to be created by this mutation. */
  opsActivity: OpsActivityInput;
};

/** The output of our create `OpsActivity` mutation. */
export type CreateOpsActivityPayload = {
  __typename?: 'CreateOpsActivityPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `OpsActivity` that was created by this mutation. */
  opsActivity?: Maybe<OpsActivity>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Uom` that is related to this `OpsActivity`. */
  uom?: Maybe<Uom>;
};

/** All input for the create `OpsActivityRate` mutation. */
export type CreateOpsActivityRateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `OpsActivityRate` to be created by this mutation. */
  opsActivityRate: OpsActivityRateInput;
};

/** The output of our create `OpsActivityRate` mutation. */
export type CreateOpsActivityRatePayload = {
  __typename?: 'CreateOpsActivityRatePayload';
  /** Reads a single `OpsActivity` that is related to this `OpsActivityRate`. */
  activity?: Maybe<OpsActivity>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `OpsActivityRate` that was created by this mutation. */
  opsActivityRate?: Maybe<OpsActivityRate>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `OpsBlock` mutation. */
export type CreateOpsBlockInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `OpsBlock` to be created by this mutation. */
  opsBlock: OpsBlockInput;
};

/** The output of our create `OpsBlock` mutation. */
export type CreateOpsBlockPayload = {
  __typename?: 'CreateOpsBlockPayload';
  /** Reads a single `OpsBlockType` that is related to this `OpsBlock`. */
  blockType?: Maybe<OpsBlockType>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `OpsBlock` that was created by this mutation. */
  opsBlock?: Maybe<OpsBlock>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `OpsBlockRoad` mutation. */
export type CreateOpsBlockRoadInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `OpsBlockRoad` to be created by this mutation. */
  opsBlockRoad: OpsBlockRoadInput;
};

/** The output of our create `OpsBlockRoad` mutation. */
export type CreateOpsBlockRoadPayload = {
  __typename?: 'CreateOpsBlockRoadPayload';
  /** Reads a single `OpsBlock` that is related to this `OpsBlockRoad`. */
  block?: Maybe<OpsBlock>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `OpsBlockRoad` that was created by this mutation. */
  opsBlockRoad?: Maybe<OpsBlockRoad>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `ImapRoadFeature` that is related to this `OpsBlockRoad`. */
  roadFeature?: Maybe<ImapRoadFeature>;
};

/** All input for the create `OpsBlockType` mutation. */
export type CreateOpsBlockTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `OpsBlockType` to be created by this mutation. */
  opsBlockType: OpsBlockTypeInput;
};

/** The output of our create `OpsBlockType` mutation. */
export type CreateOpsBlockTypePayload = {
  __typename?: 'CreateOpsBlockTypePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `OpsBlockType` that was created by this mutation. */
  opsBlockType?: Maybe<OpsBlockType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `OpsGroup` mutation. */
export type CreateOpsGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `OpsGroup` to be created by this mutation. */
  opsGroup: OpsGroupInput;
};

/** The output of our create `OpsGroup` mutation. */
export type CreateOpsGroupPayload = {
  __typename?: 'CreateOpsGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `OpsGroupType` that is related to this `OpsGroup`. */
  groupType?: Maybe<OpsGroupType>;
  /** The `OpsGroup` that was created by this mutation. */
  opsGroup?: Maybe<OpsGroup>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `OpsGroupType` mutation. */
export type CreateOpsGroupTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `OpsGroupType` to be created by this mutation. */
  opsGroupType: OpsGroupTypeInput;
};

/** The output of our create `OpsGroupType` mutation. */
export type CreateOpsGroupTypePayload = {
  __typename?: 'CreateOpsGroupTypePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `OpsGroupType` that was created by this mutation. */
  opsGroupType?: Maybe<OpsGroupType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `OpsMaterial` mutation. */
export type CreateOpsMaterialInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `OpsMaterial` to be created by this mutation. */
  opsMaterial: OpsMaterialInput;
};

/** The output of our create `OpsMaterial` mutation. */
export type CreateOpsMaterialPayload = {
  __typename?: 'CreateOpsMaterialPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `OpsMaterial` that was created by this mutation. */
  opsMaterial?: Maybe<OpsMaterial>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Uom` that is related to this `OpsMaterial`. */
  uom?: Maybe<Uom>;
};

/** All input for the create `OpsMembershipCustom` mutation. */
export type CreateOpsMembershipCustomInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `OpsMembershipCustom` to be created by this mutation. */
  opsMembershipCustom: OpsMembershipCustomInput;
};

/** The output of our create `OpsMembershipCustom` mutation. */
export type CreateOpsMembershipCustomPayload = {
  __typename?: 'CreateOpsMembershipCustomPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `OpsGroup` that is related to this `OpsMembershipCustom`. */
  group?: Maybe<OpsGroup>;
  /** The `OpsMembershipCustom` that was created by this mutation. */
  opsMembershipCustom?: Maybe<OpsMembershipCustom>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AuthUser` that is related to this `OpsMembershipCustom`. */
  user?: Maybe<AuthUser>;
};

/** All input for the create `OpsSchedule` mutation. */
export type CreateOpsScheduleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `OpsSchedule` to be created by this mutation. */
  opsSchedule: OpsScheduleInput;
};

/** The output of our create `OpsSchedule` mutation. */
export type CreateOpsSchedulePayload = {
  __typename?: 'CreateOpsSchedulePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `OpsSchedule` that was created by this mutation. */
  opsSchedule?: Maybe<OpsSchedule>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `OpsShiftPattern` that is related to this `OpsSchedule`. */
  shiftPattern?: Maybe<OpsShiftPattern>;
  /** Reads a single `AuthUser` that is related to this `OpsSchedule`. */
  user?: Maybe<AuthUser>;
};

/** All input for the create `OpsShiftPattern` mutation. */
export type CreateOpsShiftPatternInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `OpsShiftPattern` to be created by this mutation. */
  opsShiftPattern: OpsShiftPatternInput;
};

/** The output of our create `OpsShiftPattern` mutation. */
export type CreateOpsShiftPatternPayload = {
  __typename?: 'CreateOpsShiftPatternPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `OpsShiftPattern` that was created by this mutation. */
  opsShiftPattern?: Maybe<OpsShiftPattern>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `OpsShiftWeek` mutation. */
export type CreateOpsShiftWeekInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `OpsShiftWeek` to be created by this mutation. */
  opsShiftWeek: OpsShiftWeekInput;
};

/** The output of our create `OpsShiftWeek` mutation. */
export type CreateOpsShiftWeekPayload = {
  __typename?: 'CreateOpsShiftWeekPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `OpsShiftWeek` that was created by this mutation. */
  opsShiftWeek?: Maybe<OpsShiftWeek>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `OpsShiftPattern` that is related to this `OpsShiftWeek`. */
  shiftPattern?: Maybe<OpsShiftPattern>;
};

/** All input for the create `OpsSupervisor` mutation. */
export type CreateOpsSupervisorInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `OpsSupervisor` to be created by this mutation. */
  opsSupervisor: OpsSupervisorInput;
};

/** The output of our create `OpsSupervisor` mutation. */
export type CreateOpsSupervisorPayload = {
  __typename?: 'CreateOpsSupervisorPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `OpsSupervisor` that was created by this mutation. */
  opsSupervisor?: Maybe<OpsSupervisor>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AuthUser` that is related to this `OpsSupervisor`. */
  supervisor?: Maybe<AuthUser>;
  /** Reads a single `AuthUser` that is related to this `OpsSupervisor`. */
  user?: Maybe<AuthUser>;
};

/** All input for the create `OpsYard` mutation. */
export type CreateOpsYardInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `OpsYard` to be created by this mutation. */
  opsYard: OpsYardInput;
};

/** The output of our create `OpsYard` mutation. */
export type CreateOpsYardPayload = {
  __typename?: 'CreateOpsYardPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `OpsYard` that was created by this mutation. */
  opsYard?: Maybe<OpsYard>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `RwisForecast` mutation. */
export type CreateRwisForecastInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `RwisForecast` to be created by this mutation. */
  rwisForecast: RwisForecastInput;
};

/** The output of our create `RwisForecast` mutation. */
export type CreateRwisForecastPayload = {
  __typename?: 'CreateRwisForecastPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `RwisForecast` that was created by this mutation. */
  rwisForecast?: Maybe<RwisForecast>;
  /** Reads a single `RwisStation` that is related to this `RwisForecast`. */
  station?: Maybe<RwisStation>;
};

/** All input for the create `RwisForecastTarget` mutation. */
export type CreateRwisForecastTargetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `RwisForecastTarget` to be created by this mutation. */
  rwisForecastTarget: RwisForecastTargetInput;
};

/** The output of our create `RwisForecastTarget` mutation. */
export type CreateRwisForecastTargetPayload = {
  __typename?: 'CreateRwisForecastTargetPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `RwisForecast` that is related to this `RwisForecastTarget`. */
  forecast?: Maybe<RwisForecast>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `RwisForecastTarget` that was created by this mutation. */
  rwisForecastTarget?: Maybe<RwisForecastTarget>;
};

/** All input for the create `RwisReading` mutation. */
export type CreateRwisReadingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `RwisReading` to be created by this mutation. */
  rwisReading: RwisReadingInput;
};

/** The output of our create `RwisReading` mutation. */
export type CreateRwisReadingPayload = {
  __typename?: 'CreateRwisReadingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `RwisReading` that was created by this mutation. */
  rwisReading?: Maybe<RwisReading>;
  /** Reads a single `RwisStation` that is related to this `RwisReading`. */
  station?: Maybe<RwisStation>;
};

/** All input for the create `RwisStation` mutation. */
export type CreateRwisStationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `RwisStation` to be created by this mutation. */
  rwisStation: RwisStationInput;
};

/** The output of our create `RwisStation` mutation. */
export type CreateRwisStationPayload = {
  __typename?: 'CreateRwisStationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `RwisStation` that was created by this mutation. */
  rwisStation?: Maybe<RwisStation>;
};

/** All input for the create `SdCompany` mutation. */
export type CreateSdCompanyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `SdCompany` to be created by this mutation. */
  sdCompany: SdCompanyInput;
};

/** The output of our create `SdCompany` mutation. */
export type CreateSdCompanyPayload = {
  __typename?: 'CreateSdCompanyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SdCompany` that was created by this mutation. */
  sdCompany?: Maybe<SdCompany>;
};

/** All input for the create `SdForm` mutation. */
export type CreateSdFormInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `SdForm` to be created by this mutation. */
  sdForm: SdFormInput;
};

/** All input for the create `SdFormItem` mutation. */
export type CreateSdFormItemInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `SdFormItem` to be created by this mutation. */
  sdFormItem: SdFormItemInput;
};

/** The output of our create `SdFormItem` mutation. */
export type CreateSdFormItemPayload = {
  __typename?: 'CreateSdFormItemPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `SdFormType` that is related to this `SdFormItem`. */
  documentTemplate?: Maybe<SdFormType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SdFormItem` that was created by this mutation. */
  sdFormItem?: Maybe<SdFormItem>;
};

/** The output of our create `SdForm` mutation. */
export type CreateSdFormPayload = {
  __typename?: 'CreateSdFormPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `SdCompany` that is related to this `SdForm`. */
  creatingCompany?: Maybe<SdCompany>;
  /** Reads a single `SdLocation` that is related to this `SdForm`. */
  location?: Maybe<SdLocation>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SdForm` that was created by this mutation. */
  sdForm?: Maybe<SdForm>;
};

/** All input for the create `SdFormType` mutation. */
export type CreateSdFormTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `SdFormType` to be created by this mutation. */
  sdFormType: SdFormTypeInput;
};

/** The output of our create `SdFormType` mutation. */
export type CreateSdFormTypePayload = {
  __typename?: 'CreateSdFormTypePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `SdCompany` that is related to this `SdFormType`. */
  creatingCompany?: Maybe<SdCompany>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SdFormType` that was created by this mutation. */
  sdFormType?: Maybe<SdFormType>;
};

/** All input for the create `SdFormWorkerValue` mutation. */
export type CreateSdFormWorkerValueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `SdFormWorkerValue` to be created by this mutation. */
  sdFormWorkerValue: SdFormWorkerValueInput;
};

/** The output of our create `SdFormWorkerValue` mutation. */
export type CreateSdFormWorkerValuePayload = {
  __typename?: 'CreateSdFormWorkerValuePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `SdFormItem` that is related to this `SdFormWorkerValue`. */
  item?: Maybe<SdFormItem>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SdFormWorkerValue` that was created by this mutation. */
  sdFormWorkerValue?: Maybe<SdFormWorkerValue>;
};

/** All input for the create `SdLocation` mutation. */
export type CreateSdLocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `SdLocation` to be created by this mutation. */
  sdLocation: SdLocationInput;
};

/** The output of our create `SdLocation` mutation. */
export type CreateSdLocationPayload = {
  __typename?: 'CreateSdLocationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `SdCompany` that is related to this `SdLocation`. */
  creatingCompany?: Maybe<SdCompany>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SdLocation` that was created by this mutation. */
  sdLocation?: Maybe<SdLocation>;
};

/** All input for the create `SdSignature` mutation. */
export type CreateSdSignatureInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `SdSignature` to be created by this mutation. */
  sdSignature: SdSignatureInput;
};

/** The output of our create `SdSignature` mutation. */
export type CreateSdSignaturePayload = {
  __typename?: 'CreateSdSignaturePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `SdCompany` that is related to this `SdSignature`. */
  creatingCompany?: Maybe<SdCompany>;
  /** Reads a single `SdWorker` that is related to this `SdSignature`. */
  employee?: Maybe<SdWorker>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SdSignature` that was created by this mutation. */
  sdSignature?: Maybe<SdSignature>;
  /** Reads a single `SdWorker` that is related to this `SdSignature`. */
  sdWorkerByCreatedBy?: Maybe<SdWorker>;
  /** Reads a single `SdWorker` that is related to this `SdSignature`. */
  sdWorkerByLastModifiedBy?: Maybe<SdWorker>;
};

/** All input for the create `SdWorker` mutation. */
export type CreateSdWorkerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `SdWorker` to be created by this mutation. */
  sdWorker: SdWorkerInput;
};

/** The output of our create `SdWorker` mutation. */
export type CreateSdWorkerPayload = {
  __typename?: 'CreateSdWorkerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `SdCompany` that is related to this `SdWorker`. */
  employer?: Maybe<SdCompany>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SdWorker` that was created by this mutation. */
  sdWorker?: Maybe<SdWorker>;
};

/** All input for the create `TcCollectiveAgreement` mutation. */
export type CreateTcCollectiveAgreementInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TcCollectiveAgreement` to be created by this mutation. */
  tcCollectiveAgreement: TcCollectiveAgreementInput;
};

/** The output of our create `TcCollectiveAgreement` mutation. */
export type CreateTcCollectiveAgreementPayload = {
  __typename?: 'CreateTcCollectiveAgreementPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcCollectiveAgreement` that was created by this mutation. */
  tcCollectiveAgreement?: Maybe<TcCollectiveAgreement>;
};

/** All input for the create `TcCompanyAgreement` mutation. */
export type CreateTcCompanyAgreementInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TcCompanyAgreement` to be created by this mutation. */
  tcCompanyAgreement: TcCompanyAgreementInput;
};

/** The output of our create `TcCompanyAgreement` mutation. */
export type CreateTcCompanyAgreementPayload = {
  __typename?: 'CreateTcCompanyAgreementPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcCompanyAgreement` that was created by this mutation. */
  tcCompanyAgreement?: Maybe<TcCompanyAgreement>;
};

/** All input for the create `TcCompany` mutation. */
export type CreateTcCompanyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TcCompany` to be created by this mutation. */
  tcCompany: TcCompanyInput;
};

/** The output of our create `TcCompany` mutation. */
export type CreateTcCompanyPayload = {
  __typename?: 'CreateTcCompanyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcCompany` that was created by this mutation. */
  tcCompany?: Maybe<TcCompany>;
};

/** All input for the create `TcEarningCodeRate` mutation. */
export type CreateTcEarningCodeRateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TcEarningCodeRate` to be created by this mutation. */
  tcEarningCodeRate: TcEarningCodeRateInput;
};

/** The output of our create `TcEarningCodeRate` mutation. */
export type CreateTcEarningCodeRatePayload = {
  __typename?: 'CreateTcEarningCodeRatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcEarningCodeRate` that was created by this mutation. */
  tcEarningCodeRate?: Maybe<TcEarningCodeRate>;
};

/** All input for the create `TcEquipment` mutation. */
export type CreateTcEquipmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TcEquipment` to be created by this mutation. */
  tcEquipment: TcEquipmentInput;
};

/** The output of our create `TcEquipment` mutation. */
export type CreateTcEquipmentPayload = {
  __typename?: 'CreateTcEquipmentPayload';
  /** Reads a single `Asset` that is related to this `TcEquipment`. */
  asset?: Maybe<Asset>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcEquipment` that was created by this mutation. */
  tcEquipment?: Maybe<TcEquipment>;
  /** Reads a single `TcWork` that is related to this `TcEquipment`. */
  tcWork?: Maybe<TcWork>;
};

/** All input for the create `TcHolidayAlternate` mutation. */
export type CreateTcHolidayAlternateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TcHolidayAlternate` to be created by this mutation. */
  tcHolidayAlternate: TcHolidayAlternateInput;
};

/** The output of our create `TcHolidayAlternate` mutation. */
export type CreateTcHolidayAlternatePayload = {
  __typename?: 'CreateTcHolidayAlternatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcHolidayAlternate` that was created by this mutation. */
  tcHolidayAlternate?: Maybe<TcHolidayAlternate>;
};

/** All input for the create `TcHoliday` mutation. */
export type CreateTcHolidayInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TcHoliday` to be created by this mutation. */
  tcHoliday: TcHolidayInput;
};

/** The output of our create `TcHoliday` mutation. */
export type CreateTcHolidayPayload = {
  __typename?: 'CreateTcHolidayPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcHoliday` that was created by this mutation. */
  tcHoliday?: Maybe<TcHoliday>;
};

/** All input for the create `TcLeave` mutation. */
export type CreateTcLeaveInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TcLeave` to be created by this mutation. */
  tcLeave: TcLeaveInput;
};

/** The output of our create `TcLeave` mutation. */
export type CreateTcLeavePayload = {
  __typename?: 'CreateTcLeavePayload';
  /** Reads a single `AuthUser` that is related to this `TcLeave`. */
  authUserByReviewedBy?: Maybe<AuthUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `TcHoliday` that is related to this `TcLeave`. */
  holiday?: Maybe<TcHoliday>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcLeave` that was created by this mutation. */
  tcLeave?: Maybe<TcLeave>;
  /** Reads a single `AuthUser` that is related to this `TcLeave`. */
  user?: Maybe<AuthUser>;
};

/** All input for the create `TcMaterial` mutation. */
export type CreateTcMaterialInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TcMaterial` to be created by this mutation. */
  tcMaterial: TcMaterialInput;
};

/** The output of our create `TcMaterial` mutation. */
export type CreateTcMaterialPayload = {
  __typename?: 'CreateTcMaterialPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `OpsMaterial` that is related to this `TcMaterial`. */
  opsMaterialByMaterialCode?: Maybe<OpsMaterial>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcMaterial` that was created by this mutation. */
  tcMaterial?: Maybe<TcMaterial>;
  /** Reads a single `TcWork` that is related to this `TcMaterial`. */
  tcWork?: Maybe<TcWork>;
};

/** All input for the create `TcRejection` mutation. */
export type CreateTcRejectionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TcRejection` to be created by this mutation. */
  tcRejection: TcRejectionInput;
};

/** The output of our create `TcRejection` mutation. */
export type CreateTcRejectionPayload = {
  __typename?: 'CreateTcRejectionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AuthUser` that is related to this `TcRejection`. */
  reviewer?: Maybe<AuthUser>;
  /** The `TcRejection` that was created by this mutation. */
  tcRejection?: Maybe<TcRejection>;
  /** Reads a single `TcTimecard` that is related to this `TcRejection`. */
  timecard?: Maybe<TcTimecard>;
};

/** All input for the create `TcTimecard` mutation. */
export type CreateTcTimecardInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TcTimecard` to be created by this mutation. */
  tcTimecard: TcTimecardInput;
};

/** The output of our create `TcTimecard` mutation. */
export type CreateTcTimecardPayload = {
  __typename?: 'CreateTcTimecardPayload';
  /** Reads a single `AuthUser` that is related to this `TcTimecard`. */
  authUserByApprovedBy?: Maybe<AuthUser>;
  /** Reads a single `AuthUser` that is related to this `TcTimecard`. */
  authUserByReviewedBy?: Maybe<AuthUser>;
  /** Reads a single `AuthUser` that is related to this `TcTimecard`. */
  authUserBySubmissionBy?: Maybe<AuthUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcTimecard` that was created by this mutation. */
  tcTimecard?: Maybe<TcTimecard>;
  /** Reads a single `AuthUser` that is related to this `TcTimecard`. */
  worker?: Maybe<AuthUser>;
  /** Reads a single `OpsYard` that is related to this `TcTimecard`. */
  worksite?: Maybe<OpsYard>;
};

/** All input for the create `TcVacationAllotment` mutation. */
export type CreateTcVacationAllotmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TcVacationAllotment` to be created by this mutation. */
  tcVacationAllotment: TcVacationAllotmentInput;
};

/** The output of our create `TcVacationAllotment` mutation. */
export type CreateTcVacationAllotmentPayload = {
  __typename?: 'CreateTcVacationAllotmentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcVacationAllotment` that was created by this mutation. */
  tcVacationAllotment?: Maybe<TcVacationAllotment>;
};

/** All input for the create `TcWork` mutation. */
export type CreateTcWorkInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TcWork` to be created by this mutation. */
  tcWork: TcWorkInput;
};

/** All input for the create `TcWorkLeave` mutation. */
export type CreateTcWorkLeaveInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TcWorkLeave` to be created by this mutation. */
  tcWorkLeave: TcWorkLeaveInput;
};

/** The output of our create `TcWorkLeave` mutation. */
export type CreateTcWorkLeavePayload = {
  __typename?: 'CreateTcWorkLeavePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcWorkLeave` that was created by this mutation. */
  tcWorkLeave?: Maybe<TcWorkLeave>;
  /** Reads a single `TcTimecard` that is related to this `TcWorkLeave`. */
  timecard?: Maybe<TcTimecard>;
};

/** The output of our create `TcWork` mutation. */
export type CreateTcWorkPayload = {
  __typename?: 'CreateTcWorkPayload';
  /** Reads a single `AuthUser` that is related to this `TcWork`. */
  accomplishmentUser?: Maybe<AuthUser>;
  /** Reads a single `OpsBlock` that is related to this `TcWork`. */
  block?: Maybe<OpsBlock>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `ImapRoadFeature` that is related to this `TcWork`. */
  road?: Maybe<ImapRoadFeature>;
  /** Reads a single `ImapRestarea` that is related to this `TcWork`. */
  site?: Maybe<ImapRestarea>;
  /** Reads a single `ImapRoadStructure` that is related to this `TcWork`. */
  structure?: Maybe<ImapRoadStructure>;
  /** The `TcWork` that was created by this mutation. */
  tcWork?: Maybe<TcWork>;
  /** Reads a single `TcTimecard` that is related to this `TcWork`. */
  timecard?: Maybe<TcTimecard>;
};

/** All input for the create `TcWorkTest` mutation. */
export type CreateTcWorkTestInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TcWorkTest` to be created by this mutation. */
  tcWorkTest: TcWorkTestInput;
};

/** The output of our create `TcWorkTest` mutation. */
export type CreateTcWorkTestPayload = {
  __typename?: 'CreateTcWorkTestPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcWorkTest` that was created by this mutation. */
  tcWorkTest?: Maybe<TcWorkTest>;
};

/** All input for the create `TcWorkTime` mutation. */
export type CreateTcWorkTimeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TcWorkTime` to be created by this mutation. */
  tcWorkTime: TcWorkTimeInput;
};

/** The output of our create `TcWorkTime` mutation. */
export type CreateTcWorkTimePayload = {
  __typename?: 'CreateTcWorkTimePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcWorkTime` that was created by this mutation. */
  tcWorkTime?: Maybe<TcWorkTime>;
  /** Reads a single `TcTimecard` that is related to this `TcWorkTime`. */
  timecard?: Maybe<TcTimecard>;
};

/** All input for the create `Uom` mutation. */
export type CreateUomInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Uom` to be created by this mutation. */
  uom: UomInput;
};

/** The output of our create `Uom` mutation. */
export type CreateUomPayload = {
  __typename?: 'CreateUomPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Uom` that was created by this mutation. */
  uom?: Maybe<Uom>;
};

/** All input for the create `VehicleDiagnostic` mutation. */
export type CreateVehicleDiagnosticInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `VehicleDiagnostic` to be created by this mutation. */
  vehicleDiagnostic: VehicleDiagnosticInput;
};

/** The output of our create `VehicleDiagnostic` mutation. */
export type CreateVehicleDiagnosticPayload = {
  __typename?: 'CreateVehicleDiagnosticPayload';
  /** Reads a single `Asset` that is related to this `VehicleDiagnostic`. */
  asset?: Maybe<Asset>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `VehicleDiagnostic` that was created by this mutation. */
  vehicleDiagnostic?: Maybe<VehicleDiagnostic>;
};

/** All input for the create `VehicleService` mutation. */
export type CreateVehicleServiceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `VehicleService` to be created by this mutation. */
  vehicleService: VehicleServiceInput;
};

/** The output of our create `VehicleService` mutation. */
export type CreateVehicleServicePayload = {
  __typename?: 'CreateVehicleServicePayload';
  /** Reads a single `Asset` that is related to this `VehicleService`. */
  asset?: Maybe<Asset>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `VehicleService` that was created by this mutation. */
  vehicleService?: Maybe<VehicleService>;
};

/** All input for the create `VehicleStatus` mutation. */
export type CreateVehicleStatusInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `VehicleStatus` to be created by this mutation. */
  vehicleStatus: VehicleStatusInput;
};

/** The output of our create `VehicleStatus` mutation. */
export type CreateVehicleStatusPayload = {
  __typename?: 'CreateVehicleStatusPayload';
  /** Reads a single `Asset` that is related to this `VehicleStatus`. */
  asset?: Maybe<Asset>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `VehicleStatus` that was created by this mutation. */
  vehicleStatus?: Maybe<VehicleStatus>;
};

/** All input for the create `VpJobOverride` mutation. */
export type CreateVpJobOverrideInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `VpJobOverride` to be created by this mutation. */
  vpJobOverride: VpJobOverrideInput;
};

/** The output of our create `VpJobOverride` mutation. */
export type CreateVpJobOverridePayload = {
  __typename?: 'CreateVpJobOverridePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `VpJobOverride` that was created by this mutation. */
  vpJobOverride?: Maybe<VpJobOverride>;
};

/** All input for the create `VpPhaseOverride` mutation. */
export type CreateVpPhaseOverrideInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `VpPhaseOverride` to be created by this mutation. */
  vpPhaseOverride: VpPhaseOverrideInput;
};

/** The output of our create `VpPhaseOverride` mutation. */
export type CreateVpPhaseOverridePayload = {
  __typename?: 'CreateVpPhaseOverridePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `VpPhaseOverride` that was created by this mutation. */
  vpPhaseOverride?: Maybe<VpPhaseOverride>;
};

/** All input for the create `WrRecordFull` mutation. */
export type CreateWrRecordFullInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `WrRecordFull` to be created by this mutation. */
  wrRecordFull: WrRecordFullInput;
};

/** The output of our create `WrRecordFull` mutation. */
export type CreateWrRecordFullPayload = {
  __typename?: 'CreateWrRecordFullPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `WrRecordFull` that was created by this mutation. */
  wrRecordFull?: Maybe<WrRecordFull>;
};

export enum DtcType {
  Obd = 'OBD',
  Spn = 'SPN'
}

export type DashcamRecord = {
  __typename?: 'DashcamRecord';
  assetno: Scalars['String'];
  end: Scalars['DateTime'];
  path: Scalars['String'];
  start: Scalars['DateTime'];
};

/** A filter to be used against Date fields. All fields are combined with a logical ‘and.’ */
export type DateFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Date']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Date']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Date']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Date']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Date']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Date']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Date']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Date']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Date']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Date']>>;
};

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Datetime']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Datetime']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Datetime']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Datetime']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Datetime']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Datetime']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Datetime']>>;
};

/** A range of `Datetime`. */
export type DatetimeRange = {
  __typename?: 'DatetimeRange';
  /** The ending bound of our range. */
  end?: Maybe<DatetimeRangeBound>;
  /** The starting bound of our range. */
  start?: Maybe<DatetimeRangeBound>;
};

/** The value at one end of a range. A range can either include this value, or not. */
export type DatetimeRangeBound = {
  __typename?: 'DatetimeRangeBound';
  /** Whether or not the value of this bound is included in the range. */
  inclusive: Scalars['Boolean'];
  /** The value at one end of our range. */
  value: Scalars['Datetime'];
};

/** The value at one end of a range. A range can either include this value, or not. */
export type DatetimeRangeBoundInput = {
  /** Whether or not the value of this bound is included in the range. */
  inclusive: Scalars['Boolean'];
  /** The value at one end of our range. */
  value: Scalars['Datetime'];
};

/** A filter to be used against DatetimeRange fields. All fields are combined with a logical ‘and.’ */
export type DatetimeRangeFilter = {
  /** Adjacent to the specified range. */
  adjacentTo?: InputMaybe<DatetimeRangeInput>;
  /** Contained by the specified range. */
  containedBy?: InputMaybe<DatetimeRangeInput>;
  /** Contains the specified range. */
  contains?: InputMaybe<DatetimeRangeInput>;
  /** Contains the specified value. */
  containsElement?: InputMaybe<Scalars['Datetime']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<DatetimeRangeInput>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<DatetimeRangeInput>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<DatetimeRangeInput>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<DatetimeRangeInput>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<DatetimeRangeInput>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<DatetimeRangeInput>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<DatetimeRangeInput>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<DatetimeRangeInput>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<DatetimeRangeInput>;
  /** Does not extend left of the specified range. */
  notExtendsLeftOf?: InputMaybe<DatetimeRangeInput>;
  /** Does not extend right of the specified range. */
  notExtendsRightOf?: InputMaybe<DatetimeRangeInput>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<DatetimeRangeInput>>;
  /** Overlaps the specified range. */
  overlaps?: InputMaybe<DatetimeRangeInput>;
  /** Strictly left of the specified range. */
  strictlyLeftOf?: InputMaybe<DatetimeRangeInput>;
  /** Strictly right of the specified range. */
  strictlyRightOf?: InputMaybe<DatetimeRangeInput>;
};

/** A range of `Datetime`. */
export type DatetimeRangeInput = {
  /** The ending bound of our range. */
  end?: InputMaybe<DatetimeRangeBoundInput>;
  /** The starting bound of our range. */
  start?: InputMaybe<DatetimeRangeBoundInput>;
};

/** All input for the `deleteAssetBreadcrumbByAssetIdAndGeogAndDatetime` mutation. */
export type DeleteAssetBreadcrumbByAssetIdAndGeogAndDatetimeInput = {
  assetId: Scalars['Int'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  datetime: Scalars['Datetime'];
  geog: Scalars['GeoJSON'];
};

/** All input for the `deleteAssetBreadcrumbByNodeId` mutation. */
export type DeleteAssetBreadcrumbByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AssetBreadcrumb` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteAssetBreadcrumb` mutation. */
export type DeleteAssetBreadcrumbInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `AssetBreadcrumb` mutation. */
export type DeleteAssetBreadcrumbPayload = {
  __typename?: 'DeleteAssetBreadcrumbPayload';
  /** Reads a single `Asset` that is related to this `AssetBreadcrumb`. */
  asset?: Maybe<Asset>;
  /** The `AssetBreadcrumb` that was deleted by this mutation. */
  assetBreadcrumb?: Maybe<AssetBreadcrumb>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedAssetBreadcrumbNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `ImapRoadClass` that is related to this `AssetBreadcrumb`. */
  roadClass?: Maybe<ImapRoadClass>;
  /** Reads a single `ImapRoadFeature` that is related to this `AssetBreadcrumb`. */
  roadFeature?: Maybe<ImapRoadFeature>;
  /** Reads a single `ImapRoadSurface` that is related to this `AssetBreadcrumb`. */
  roadSurface?: Maybe<ImapRoadSurface>;
  /** Reads a single `OpsYard` that is related to this `AssetBreadcrumb`. */
  yard?: Maybe<OpsYard>;
};

/** All input for the `deleteAssetByAssetCode` mutation. */
export type DeleteAssetByAssetCodeInput = {
  assetCode: Scalars['String'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** All input for the `deleteAssetByNodeId` mutation. */
export type DeleteAssetByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Asset` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteAssetCategoryByNodeId` mutation. */
export type DeleteAssetCategoryByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AssetCategory` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteAssetCategory` mutation. */
export type DeleteAssetCategoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  prefix: Scalars['String'];
};

/** The output of our delete `AssetCategory` mutation. */
export type DeleteAssetCategoryPayload = {
  __typename?: 'DeleteAssetCategoryPayload';
  /** The `AssetCategory` that was deleted by this mutation. */
  assetCategory?: Maybe<AssetCategory>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedAssetCategoryNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteAsset` mutation. */
export type DeleteAssetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Asset` mutation. */
export type DeleteAssetPayload = {
  __typename?: 'DeleteAssetPayload';
  /** The `Asset` that was deleted by this mutation. */
  asset?: Maybe<Asset>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedAssetNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteAssetTypeByDescription` mutation. */
export type DeleteAssetTypeByDescriptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
};

/** All input for the `deleteAssetTypeByNodeId` mutation. */
export type DeleteAssetTypeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AssetType` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteAssetType` mutation. */
export type DeleteAssetTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `AssetType` mutation. */
export type DeleteAssetTypePayload = {
  __typename?: 'DeleteAssetTypePayload';
  /** The `AssetType` that was deleted by this mutation. */
  assetType?: Maybe<AssetType>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedAssetTypeNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteAssetWingUnderbodyByNodeId` mutation. */
export type DeleteAssetWingUnderbodyByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AssetWingUnderbody` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteAssetWingUnderbody` mutation. */
export type DeleteAssetWingUnderbodyInput = {
  assetId: Scalars['Int'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  effective: Scalars['Datetime'];
};

/** The output of our delete `AssetWingUnderbody` mutation. */
export type DeleteAssetWingUnderbodyPayload = {
  __typename?: 'DeleteAssetWingUnderbodyPayload';
  /** Reads a single `Asset` that is related to this `AssetWingUnderbody`. */
  asset?: Maybe<Asset>;
  /** The `AssetWingUnderbody` that was deleted by this mutation. */
  assetWingUnderbody?: Maybe<AssetWingUnderbody>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedAssetWingUnderbodyNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteAuthSessionTokenByNodeId` mutation. */
export type DeleteAuthSessionTokenByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AuthSessionToken` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteAuthSessionToken` mutation. */
export type DeleteAuthSessionTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  sessionId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** The output of our delete `AuthSessionToken` mutation. */
export type DeleteAuthSessionTokenPayload = {
  __typename?: 'DeleteAuthSessionTokenPayload';
  /** The `AuthSessionToken` that was deleted by this mutation. */
  authSessionToken?: Maybe<AuthSessionToken>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedAuthSessionTokenNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AuthUser` that is related to this `AuthSessionToken`. */
  user?: Maybe<AuthUser>;
};

/** All input for the `deleteAuthUserByEmail` mutation. */
export type DeleteAuthUserByEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
};

/** All input for the `deleteAuthUserByEmployeeNo` mutation. */
export type DeleteAuthUserByEmployeeNoInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  employeeNo: Scalars['Int'];
};

/** All input for the `deleteAuthUserByNodeId` mutation. */
export type DeleteAuthUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AuthUser` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteAuthUser` mutation. */
export type DeleteAuthUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `AuthUser` mutation. */
export type DeleteAuthUserPayload = {
  __typename?: 'DeleteAuthUserPayload';
  /** The `AuthUser` that was deleted by this mutation. */
  authUser?: Maybe<AuthUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedAuthUserNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `OpsYard` that is related to this `AuthUser`. */
  homeYard?: Maybe<OpsYard>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteBecBiogeoclimaticByNodeId` mutation. */
export type DeleteBecBiogeoclimaticByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BecBiogeoclimatic` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteBecBiogeoclimatic` mutation. */
export type DeleteBecBiogeoclimaticInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `BecBiogeoclimatic` mutation. */
export type DeleteBecBiogeoclimaticPayload = {
  __typename?: 'DeleteBecBiogeoclimaticPayload';
  /** The `BecBiogeoclimatic` that was deleted by this mutation. */
  becBiogeoclimatic?: Maybe<BecBiogeoclimatic>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedBecBiogeoclimaticNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteBiForecastByNodeId` mutation. */
export type DeleteBiForecastByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BiForecast` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteBiForecast` mutation. */
export type DeleteBiForecastInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `BiForecast` mutation. */
export type DeleteBiForecastPayload = {
  __typename?: 'DeleteBiForecastPayload';
  /** The `BiForecast` that was deleted by this mutation. */
  biForecast?: Maybe<BiForecast>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedBiForecastNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteBiForecastPermissionByNodeId` mutation. */
export type DeleteBiForecastPermissionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BiForecastPermission` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteBiForecastPermission` mutation. */
export type DeleteBiForecastPermissionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `BiForecastPermission` mutation. */
export type DeleteBiForecastPermissionPayload = {
  __typename?: 'DeleteBiForecastPermissionPayload';
  /** The `BiForecastPermission` that was deleted by this mutation. */
  biForecastPermission?: Maybe<BiForecastPermission>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedBiForecastPermissionNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `BiForecast` that is related to this `BiForecastPermission`. */
  forecast?: Maybe<BiForecast>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteBiForecastPhaseByNodeId` mutation. */
export type DeleteBiForecastPhaseByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BiForecastPhase` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteBiForecastPhase` mutation. */
export type DeleteBiForecastPhaseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `BiForecastPhase` mutation. */
export type DeleteBiForecastPhasePayload = {
  __typename?: 'DeleteBiForecastPhasePayload';
  /** The `BiForecastPhase` that was deleted by this mutation. */
  biForecastPhase?: Maybe<BiForecastPhase>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedBiForecastPhaseNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `BiForecast` that is related to this `BiForecastPhase`. */
  forecast?: Maybe<BiForecast>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteCollisionByNodeId` mutation. */
export type DeleteCollisionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Collision` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCollision` mutation. */
export type DeleteCollisionInput = {
  caseNo: Scalars['String'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our delete `Collision` mutation. */
export type DeleteCollisionPayload = {
  __typename?: 'DeleteCollisionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Collision` that was deleted by this mutation. */
  collision?: Maybe<Collision>;
  deletedCollisionNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteDtcFmiByDescription` mutation. */
export type DeleteDtcFmiByDescriptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
};

/** All input for the `deleteDtcFmiByNodeId` mutation. */
export type DeleteDtcFmiByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `DtcFmi` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteDtcFmi` mutation. */
export type DeleteDtcFmiInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  code: Scalars['Int'];
};

/** The output of our delete `DtcFmi` mutation. */
export type DeleteDtcFmiPayload = {
  __typename?: 'DeleteDtcFmiPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedDtcFmiNodeId?: Maybe<Scalars['ID']>;
  /** The `DtcFmi` that was deleted by this mutation. */
  dtcFmi?: Maybe<DtcFmi>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteDtcObdByNodeId` mutation. */
export type DeleteDtcObdByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `DtcObd` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteDtcObd` mutation. */
export type DeleteDtcObdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
};

/** The output of our delete `DtcObd` mutation. */
export type DeleteDtcObdPayload = {
  __typename?: 'DeleteDtcObdPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedDtcObdNodeId?: Maybe<Scalars['ID']>;
  /** The `DtcObd` that was deleted by this mutation. */
  dtcObd?: Maybe<DtcObd>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteDtcSpnByNodeId` mutation. */
export type DeleteDtcSpnByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `DtcSpn` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteDtcSpn` mutation. */
export type DeleteDtcSpnInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  code: Scalars['Int'];
};

/** The output of our delete `DtcSpn` mutation. */
export type DeleteDtcSpnPayload = {
  __typename?: 'DeleteDtcSpnPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedDtcSpnNodeId?: Maybe<Scalars['ID']>;
  /** The `DtcSpn` that was deleted by this mutation. */
  dtcSpn?: Maybe<DtcSpn>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteGisAtlasZoneByNodeId` mutation. */
export type DeleteGisAtlasZoneByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `GisAtlasZone` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteGisAtlasZone` mutation. */
export type DeleteGisAtlasZoneInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `GisAtlasZone` mutation. */
export type DeleteGisAtlasZonePayload = {
  __typename?: 'DeleteGisAtlasZonePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedGisAtlasZoneNodeId?: Maybe<Scalars['ID']>;
  /** The `GisAtlasZone` that was deleted by this mutation. */
  gisAtlasZone?: Maybe<GisAtlasZone>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteImapCulvertByNodeId` mutation. */
export type DeleteImapCulvertByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapCulvert` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteImapCulvert` mutation. */
export type DeleteImapCulvertInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `ImapCulvert` mutation. */
export type DeleteImapCulvertPayload = {
  __typename?: 'DeleteImapCulvertPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedImapCulvertNodeId?: Maybe<Scalars['ID']>;
  /** The `ImapCulvert` that was deleted by this mutation. */
  imapCulvert?: Maybe<ImapCulvert>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteImapDrainageByNodeId` mutation. */
export type DeleteImapDrainageByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapDrainage` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteImapDrainage` mutation. */
export type DeleteImapDrainageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `ImapDrainage` mutation. */
export type DeleteImapDrainagePayload = {
  __typename?: 'DeleteImapDrainagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedImapDrainageNodeId?: Maybe<Scalars['ID']>;
  /** The `ImapDrainage` that was deleted by this mutation. */
  imapDrainage?: Maybe<ImapDrainage>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteImapGuardrailByNodeId` mutation. */
export type DeleteImapGuardrailByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapGuardrail` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteImapGuardrail` mutation. */
export type DeleteImapGuardrailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `ImapGuardrail` mutation. */
export type DeleteImapGuardrailPayload = {
  __typename?: 'DeleteImapGuardrailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedImapGuardrailNodeId?: Maybe<Scalars['ID']>;
  /** The `ImapGuardrail` that was deleted by this mutation. */
  imapGuardrail?: Maybe<ImapGuardrail>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteImapHighwayProfileByNodeId` mutation. */
export type DeleteImapHighwayProfileByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapHighwayProfile` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteImapHighwayProfile` mutation. */
export type DeleteImapHighwayProfileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `ImapHighwayProfile` mutation. */
export type DeleteImapHighwayProfilePayload = {
  __typename?: 'DeleteImapHighwayProfilePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedImapHighwayProfileNodeId?: Maybe<Scalars['ID']>;
  /** The `ImapHighwayProfile` that was deleted by this mutation. */
  imapHighwayProfile?: Maybe<ImapHighwayProfile>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteImapLandmarkByNodeId` mutation. */
export type DeleteImapLandmarkByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapLandmark` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteImapLandmark` mutation. */
export type DeleteImapLandmarkInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `ImapLandmark` mutation. */
export type DeleteImapLandmarkPayload = {
  __typename?: 'DeleteImapLandmarkPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedImapLandmarkNodeId?: Maybe<Scalars['ID']>;
  /** The `ImapLandmark` that was deleted by this mutation. */
  imapLandmark?: Maybe<ImapLandmark>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteImapRestareaByNodeId` mutation. */
export type DeleteImapRestareaByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapRestarea` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteImapRestarea` mutation. */
export type DeleteImapRestareaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `ImapRestarea` mutation. */
export type DeleteImapRestareaPayload = {
  __typename?: 'DeleteImapRestareaPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedImapRestareaNodeId?: Maybe<Scalars['ID']>;
  /** The `ImapRestarea` that was deleted by this mutation. */
  imapRestarea?: Maybe<ImapRestarea>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteImapRetainingWallByNodeId` mutation. */
export type DeleteImapRetainingWallByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapRetainingWall` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteImapRetainingWall` mutation. */
export type DeleteImapRetainingWallInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `ImapRetainingWall` mutation. */
export type DeleteImapRetainingWallPayload = {
  __typename?: 'DeleteImapRetainingWallPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedImapRetainingWallNodeId?: Maybe<Scalars['ID']>;
  /** The `ImapRetainingWall` that was deleted by this mutation. */
  imapRetainingWall?: Maybe<ImapRetainingWall>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteImapRoadClassByHwyMaintenanceClassId` mutation. */
export type DeleteImapRoadClassByHwyMaintenanceClassIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  hwyMaintenanceClassId: Scalars['Int'];
};

/** All input for the `deleteImapRoadClassByNodeId` mutation. */
export type DeleteImapRoadClassByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapRoadClass` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteImapRoadClassFeatureByNodeId` mutation. */
export type DeleteImapRoadClassFeatureByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapRoadClassFeature` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteImapRoadClassFeature` mutation. */
export type DeleteImapRoadClassFeatureInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  roadClassId: Scalars['Int'];
};

/** The output of our delete `ImapRoadClassFeature` mutation. */
export type DeleteImapRoadClassFeaturePayload = {
  __typename?: 'DeleteImapRoadClassFeaturePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedImapRoadClassFeatureNodeId?: Maybe<Scalars['ID']>;
  /** The `ImapRoadClassFeature` that was deleted by this mutation. */
  imapRoadClassFeature?: Maybe<ImapRoadClassFeature>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `ImapRoadClass` that is related to this `ImapRoadClassFeature`. */
  roadClass?: Maybe<ImapRoadClass>;
  /** Reads a single `ImapRoadFeature` that is related to this `ImapRoadClassFeature`. */
  roadFeature?: Maybe<ImapRoadFeature>;
};

/** All input for the `deleteImapRoadClass` mutation. */
export type DeleteImapRoadClassInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `ImapRoadClass` mutation. */
export type DeleteImapRoadClassPayload = {
  __typename?: 'DeleteImapRoadClassPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedImapRoadClassNodeId?: Maybe<Scalars['ID']>;
  /** The `ImapRoadClass` that was deleted by this mutation. */
  imapRoadClass?: Maybe<ImapRoadClass>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteImapRoadFeatureByNodeId` mutation. */
export type DeleteImapRoadFeatureByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapRoadFeature` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteImapRoadFeatureByRfiHighwayName` mutation. */
export type DeleteImapRoadFeatureByRfiHighwayNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  rfiHighwayName: Scalars['String'];
};

/** All input for the `deleteImapRoadFeatureByRoadFeatureInvntryId` mutation. */
export type DeleteImapRoadFeatureByRoadFeatureInvntryIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  roadFeatureInvntryId: Scalars['Int'];
};

/** All input for the `deleteImapRoadFeature` mutation. */
export type DeleteImapRoadFeatureInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `ImapRoadFeature` mutation. */
export type DeleteImapRoadFeaturePayload = {
  __typename?: 'DeleteImapRoadFeaturePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedImapRoadFeatureNodeId?: Maybe<Scalars['ID']>;
  /** The `ImapRoadFeature` that was deleted by this mutation. */
  imapRoadFeature?: Maybe<ImapRoadFeature>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteImapRoadStructureByNodeId` mutation. */
export type DeleteImapRoadStructureByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapRoadStructure` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteImapRoadStructure` mutation. */
export type DeleteImapRoadStructureInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `ImapRoadStructure` mutation. */
export type DeleteImapRoadStructurePayload = {
  __typename?: 'DeleteImapRoadStructurePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedImapRoadStructureNodeId?: Maybe<Scalars['ID']>;
  /** The `ImapRoadStructure` that was deleted by this mutation. */
  imapRoadStructure?: Maybe<ImapRoadStructure>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteImapRoadSurfaceByNodeId` mutation. */
export type DeleteImapRoadSurfaceByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapRoadSurface` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteImapRoadSurfaceByRoadSurfaceTypeId` mutation. */
export type DeleteImapRoadSurfaceByRoadSurfaceTypeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  roadSurfaceTypeId: Scalars['Int'];
};

/** All input for the `deleteImapRoadSurfaceFeatureByNodeId` mutation. */
export type DeleteImapRoadSurfaceFeatureByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapRoadSurfaceFeature` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteImapRoadSurfaceFeature` mutation. */
export type DeleteImapRoadSurfaceFeatureInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  roadSurfaceId: Scalars['Int'];
};

/** The output of our delete `ImapRoadSurfaceFeature` mutation. */
export type DeleteImapRoadSurfaceFeaturePayload = {
  __typename?: 'DeleteImapRoadSurfaceFeaturePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedImapRoadSurfaceFeatureNodeId?: Maybe<Scalars['ID']>;
  /** The `ImapRoadSurfaceFeature` that was deleted by this mutation. */
  imapRoadSurfaceFeature?: Maybe<ImapRoadSurfaceFeature>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `ImapRoadFeature` that is related to this `ImapRoadSurfaceFeature`. */
  roadFeature?: Maybe<ImapRoadFeature>;
  /** Reads a single `ImapRoadSurface` that is related to this `ImapRoadSurfaceFeature`. */
  roadSurface?: Maybe<ImapRoadSurface>;
};

/** All input for the `deleteImapRoadSurface` mutation. */
export type DeleteImapRoadSurfaceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `ImapRoadSurface` mutation. */
export type DeleteImapRoadSurfacePayload = {
  __typename?: 'DeleteImapRoadSurfacePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedImapRoadSurfaceNodeId?: Maybe<Scalars['ID']>;
  /** The `ImapRoadSurface` that was deleted by this mutation. */
  imapRoadSurface?: Maybe<ImapRoadSurface>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteImapSafetyByNodeId` mutation. */
export type DeleteImapSafetyByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapSafety` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteImapSafety` mutation. */
export type DeleteImapSafetyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `ImapSafety` mutation. */
export type DeleteImapSafetyPayload = {
  __typename?: 'DeleteImapSafetyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedImapSafetyNodeId?: Maybe<Scalars['ID']>;
  /** The `ImapSafety` that was deleted by this mutation. */
  imapSafety?: Maybe<ImapSafety>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteImapSignByNodeId` mutation. */
export type DeleteImapSignByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapSign` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteImapSign` mutation. */
export type DeleteImapSignInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `ImapSign` mutation. */
export type DeleteImapSignPayload = {
  __typename?: 'DeleteImapSignPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedImapSignNodeId?: Maybe<Scalars['ID']>;
  /** The `ImapSign` that was deleted by this mutation. */
  imapSign?: Maybe<ImapSign>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteOpsActivityByActivityCode` mutation. */
export type DeleteOpsActivityByActivityCodeInput = {
  activityCode: Scalars['String'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** All input for the `deleteOpsActivityByNodeId` mutation. */
export type DeleteOpsActivityByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OpsActivity` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteOpsActivity` mutation. */
export type DeleteOpsActivityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `OpsActivity` mutation. */
export type DeleteOpsActivityPayload = {
  __typename?: 'DeleteOpsActivityPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedOpsActivityNodeId?: Maybe<Scalars['ID']>;
  /** The `OpsActivity` that was deleted by this mutation. */
  opsActivity?: Maybe<OpsActivity>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Uom` that is related to this `OpsActivity`. */
  uom?: Maybe<Uom>;
};

/** All input for the `deleteOpsActivityRateByActivityIdAndServiceAreaAndStartDate` mutation. */
export type DeleteOpsActivityRateByActivityIdAndServiceAreaAndStartDateInput = {
  activityId: Scalars['Int'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  serviceArea: Scalars['String'];
  startDate: Scalars['Date'];
};

/** All input for the `deleteOpsActivityRateByNodeId` mutation. */
export type DeleteOpsActivityRateByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OpsActivityRate` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteOpsActivityRate` mutation. */
export type DeleteOpsActivityRateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `OpsActivityRate` mutation. */
export type DeleteOpsActivityRatePayload = {
  __typename?: 'DeleteOpsActivityRatePayload';
  /** Reads a single `OpsActivity` that is related to this `OpsActivityRate`. */
  activity?: Maybe<OpsActivity>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedOpsActivityRateNodeId?: Maybe<Scalars['ID']>;
  /** The `OpsActivityRate` that was deleted by this mutation. */
  opsActivityRate?: Maybe<OpsActivityRate>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteOpsBlockByNodeId` mutation. */
export type DeleteOpsBlockByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OpsBlock` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteOpsBlockByServiceAreaAndBlockCode` mutation. */
export type DeleteOpsBlockByServiceAreaAndBlockCodeInput = {
  blockCode: Scalars['String'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  serviceArea: Scalars['String'];
};

/** All input for the `deleteOpsBlock` mutation. */
export type DeleteOpsBlockInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `OpsBlock` mutation. */
export type DeleteOpsBlockPayload = {
  __typename?: 'DeleteOpsBlockPayload';
  /** Reads a single `OpsBlockType` that is related to this `OpsBlock`. */
  blockType?: Maybe<OpsBlockType>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedOpsBlockNodeId?: Maybe<Scalars['ID']>;
  /** The `OpsBlock` that was deleted by this mutation. */
  opsBlock?: Maybe<OpsBlock>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteOpsBlockRoadByNodeId` mutation. */
export type DeleteOpsBlockRoadByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OpsBlockRoad` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteOpsBlockRoad` mutation. */
export type DeleteOpsBlockRoadInput = {
  blockId: Scalars['Int'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  roadFeatureId: Scalars['Int'];
};

/** The output of our delete `OpsBlockRoad` mutation. */
export type DeleteOpsBlockRoadPayload = {
  __typename?: 'DeleteOpsBlockRoadPayload';
  /** Reads a single `OpsBlock` that is related to this `OpsBlockRoad`. */
  block?: Maybe<OpsBlock>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedOpsBlockRoadNodeId?: Maybe<Scalars['ID']>;
  /** The `OpsBlockRoad` that was deleted by this mutation. */
  opsBlockRoad?: Maybe<OpsBlockRoad>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `ImapRoadFeature` that is related to this `OpsBlockRoad`. */
  roadFeature?: Maybe<ImapRoadFeature>;
};

/** All input for the `deleteOpsBlockTypeByDescription` mutation. */
export type DeleteOpsBlockTypeByDescriptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
};

/** All input for the `deleteOpsBlockTypeByNodeId` mutation. */
export type DeleteOpsBlockTypeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OpsBlockType` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteOpsBlockType` mutation. */
export type DeleteOpsBlockTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `OpsBlockType` mutation. */
export type DeleteOpsBlockTypePayload = {
  __typename?: 'DeleteOpsBlockTypePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedOpsBlockTypeNodeId?: Maybe<Scalars['ID']>;
  /** The `OpsBlockType` that was deleted by this mutation. */
  opsBlockType?: Maybe<OpsBlockType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteOpsGroupByDescription` mutation. */
export type DeleteOpsGroupByDescriptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
};

/** All input for the `deleteOpsGroupByNodeId` mutation. */
export type DeleteOpsGroupByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OpsGroup` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteOpsGroup` mutation. */
export type DeleteOpsGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `OpsGroup` mutation. */
export type DeleteOpsGroupPayload = {
  __typename?: 'DeleteOpsGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedOpsGroupNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `OpsGroupType` that is related to this `OpsGroup`. */
  groupType?: Maybe<OpsGroupType>;
  /** The `OpsGroup` that was deleted by this mutation. */
  opsGroup?: Maybe<OpsGroup>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteOpsGroupTypeByDescription` mutation. */
export type DeleteOpsGroupTypeByDescriptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
};

/** All input for the `deleteOpsGroupTypeByNodeId` mutation. */
export type DeleteOpsGroupTypeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OpsGroupType` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteOpsGroupType` mutation. */
export type DeleteOpsGroupTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `OpsGroupType` mutation. */
export type DeleteOpsGroupTypePayload = {
  __typename?: 'DeleteOpsGroupTypePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedOpsGroupTypeNodeId?: Maybe<Scalars['ID']>;
  /** The `OpsGroupType` that was deleted by this mutation. */
  opsGroupType?: Maybe<OpsGroupType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteOpsMaterialByMaterialCode` mutation. */
export type DeleteOpsMaterialByMaterialCodeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  materialCode: Scalars['String'];
};

/** All input for the `deleteOpsMaterialByNodeId` mutation. */
export type DeleteOpsMaterialByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OpsMaterial` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteOpsMaterial` mutation. */
export type DeleteOpsMaterialInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `OpsMaterial` mutation. */
export type DeleteOpsMaterialPayload = {
  __typename?: 'DeleteOpsMaterialPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedOpsMaterialNodeId?: Maybe<Scalars['ID']>;
  /** The `OpsMaterial` that was deleted by this mutation. */
  opsMaterial?: Maybe<OpsMaterial>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Uom` that is related to this `OpsMaterial`. */
  uom?: Maybe<Uom>;
};

/** All input for the `deleteOpsMembershipCustomByNodeId` mutation. */
export type DeleteOpsMembershipCustomByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OpsMembershipCustom` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteOpsMembershipCustom` mutation. */
export type DeleteOpsMembershipCustomInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  groupId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** The output of our delete `OpsMembershipCustom` mutation. */
export type DeleteOpsMembershipCustomPayload = {
  __typename?: 'DeleteOpsMembershipCustomPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedOpsMembershipCustomNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `OpsGroup` that is related to this `OpsMembershipCustom`. */
  group?: Maybe<OpsGroup>;
  /** The `OpsMembershipCustom` that was deleted by this mutation. */
  opsMembershipCustom?: Maybe<OpsMembershipCustom>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AuthUser` that is related to this `OpsMembershipCustom`. */
  user?: Maybe<AuthUser>;
};

/** All input for the `deleteOpsScheduleByNodeId` mutation. */
export type DeleteOpsScheduleByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OpsSchedule` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteOpsSchedule` mutation. */
export type DeleteOpsScheduleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `OpsSchedule` mutation. */
export type DeleteOpsSchedulePayload = {
  __typename?: 'DeleteOpsSchedulePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedOpsScheduleNodeId?: Maybe<Scalars['ID']>;
  /** The `OpsSchedule` that was deleted by this mutation. */
  opsSchedule?: Maybe<OpsSchedule>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `OpsShiftPattern` that is related to this `OpsSchedule`. */
  shiftPattern?: Maybe<OpsShiftPattern>;
  /** Reads a single `AuthUser` that is related to this `OpsSchedule`. */
  user?: Maybe<AuthUser>;
};

/** All input for the `deleteOpsShiftPatternByDescription` mutation. */
export type DeleteOpsShiftPatternByDescriptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
};

/** All input for the `deleteOpsShiftPatternByNodeId` mutation. */
export type DeleteOpsShiftPatternByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OpsShiftPattern` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteOpsShiftPattern` mutation. */
export type DeleteOpsShiftPatternInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `OpsShiftPattern` mutation. */
export type DeleteOpsShiftPatternPayload = {
  __typename?: 'DeleteOpsShiftPatternPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedOpsShiftPatternNodeId?: Maybe<Scalars['ID']>;
  /** The `OpsShiftPattern` that was deleted by this mutation. */
  opsShiftPattern?: Maybe<OpsShiftPattern>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteOpsShiftWeekByNodeId` mutation. */
export type DeleteOpsShiftWeekByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OpsShiftWeek` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteOpsShiftWeek` mutation. */
export type DeleteOpsShiftWeekInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `OpsShiftWeek` mutation. */
export type DeleteOpsShiftWeekPayload = {
  __typename?: 'DeleteOpsShiftWeekPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedOpsShiftWeekNodeId?: Maybe<Scalars['ID']>;
  /** The `OpsShiftWeek` that was deleted by this mutation. */
  opsShiftWeek?: Maybe<OpsShiftWeek>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `OpsShiftPattern` that is related to this `OpsShiftWeek`. */
  shiftPattern?: Maybe<OpsShiftPattern>;
};

/** All input for the `deleteOpsSupervisorByNodeId` mutation. */
export type DeleteOpsSupervisorByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OpsSupervisor` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteOpsSupervisor` mutation. */
export type DeleteOpsSupervisorInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  supervisorId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** The output of our delete `OpsSupervisor` mutation. */
export type DeleteOpsSupervisorPayload = {
  __typename?: 'DeleteOpsSupervisorPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedOpsSupervisorNodeId?: Maybe<Scalars['ID']>;
  /** The `OpsSupervisor` that was deleted by this mutation. */
  opsSupervisor?: Maybe<OpsSupervisor>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AuthUser` that is related to this `OpsSupervisor`. */
  supervisor?: Maybe<AuthUser>;
  /** Reads a single `AuthUser` that is related to this `OpsSupervisor`. */
  user?: Maybe<AuthUser>;
};

/** All input for the `deleteOpsYardByDescription` mutation. */
export type DeleteOpsYardByDescriptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
};

/** All input for the `deleteOpsYardByNodeId` mutation. */
export type DeleteOpsYardByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OpsYard` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteOpsYard` mutation. */
export type DeleteOpsYardInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `OpsYard` mutation. */
export type DeleteOpsYardPayload = {
  __typename?: 'DeleteOpsYardPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedOpsYardNodeId?: Maybe<Scalars['ID']>;
  /** The `OpsYard` that was deleted by this mutation. */
  opsYard?: Maybe<OpsYard>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteRwisForecastByNodeId` mutation. */
export type DeleteRwisForecastByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `RwisForecast` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteRwisForecastByStationIdAndIssueTime` mutation. */
export type DeleteRwisForecastByStationIdAndIssueTimeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  issueTime: Scalars['Datetime'];
  stationId: Scalars['Int'];
};

/** All input for the `deleteRwisForecast` mutation. */
export type DeleteRwisForecastInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `RwisForecast` mutation. */
export type DeleteRwisForecastPayload = {
  __typename?: 'DeleteRwisForecastPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedRwisForecastNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `RwisForecast` that was deleted by this mutation. */
  rwisForecast?: Maybe<RwisForecast>;
  /** Reads a single `RwisStation` that is related to this `RwisForecast`. */
  station?: Maybe<RwisStation>;
};

/** All input for the `deleteRwisForecastTargetByNodeId` mutation. */
export type DeleteRwisForecastTargetByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `RwisForecastTarget` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteRwisForecastTarget` mutation. */
export type DeleteRwisForecastTargetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  forecastId: Scalars['Int'];
  targetTime: Scalars['Datetime'];
};

/** The output of our delete `RwisForecastTarget` mutation. */
export type DeleteRwisForecastTargetPayload = {
  __typename?: 'DeleteRwisForecastTargetPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedRwisForecastTargetNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `RwisForecast` that is related to this `RwisForecastTarget`. */
  forecast?: Maybe<RwisForecast>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `RwisForecastTarget` that was deleted by this mutation. */
  rwisForecastTarget?: Maybe<RwisForecastTarget>;
};

/** All input for the `deleteRwisReadingByNodeId` mutation. */
export type DeleteRwisReadingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `RwisReading` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteRwisReading` mutation. */
export type DeleteRwisReadingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  readingTime: Scalars['Datetime'];
  stationId: Scalars['Int'];
};

/** The output of our delete `RwisReading` mutation. */
export type DeleteRwisReadingPayload = {
  __typename?: 'DeleteRwisReadingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedRwisReadingNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `RwisReading` that was deleted by this mutation. */
  rwisReading?: Maybe<RwisReading>;
  /** Reads a single `RwisStation` that is related to this `RwisReading`. */
  station?: Maybe<RwisStation>;
};

/** All input for the `deleteRwisStationByNodeId` mutation. */
export type DeleteRwisStationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `RwisStation` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteRwisStationByStationName` mutation. */
export type DeleteRwisStationByStationNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  stationName: Scalars['String'];
};

/** All input for the `deleteRwisStation` mutation. */
export type DeleteRwisStationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `RwisStation` mutation. */
export type DeleteRwisStationPayload = {
  __typename?: 'DeleteRwisStationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedRwisStationNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `RwisStation` that was deleted by this mutation. */
  rwisStation?: Maybe<RwisStation>;
};

/** All input for the `deleteSdCompanyByDescription` mutation. */
export type DeleteSdCompanyByDescriptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
};

/** All input for the `deleteSdCompanyByNodeId` mutation. */
export type DeleteSdCompanyByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SdCompany` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteSdCompany` mutation. */
export type DeleteSdCompanyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `SdCompany` mutation. */
export type DeleteSdCompanyPayload = {
  __typename?: 'DeleteSdCompanyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedSdCompanyNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SdCompany` that was deleted by this mutation. */
  sdCompany?: Maybe<SdCompany>;
};

/** All input for the `deleteSdFormByNodeId` mutation. */
export type DeleteSdFormByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SdForm` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteSdForm` mutation. */
export type DeleteSdFormInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteSdFormItemByNodeId` mutation. */
export type DeleteSdFormItemByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SdFormItem` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteSdFormItem` mutation. */
export type DeleteSdFormItemInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  itemId: Scalars['UUID'];
};

/** The output of our delete `SdFormItem` mutation. */
export type DeleteSdFormItemPayload = {
  __typename?: 'DeleteSdFormItemPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedSdFormItemNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `SdFormType` that is related to this `SdFormItem`. */
  documentTemplate?: Maybe<SdFormType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SdFormItem` that was deleted by this mutation. */
  sdFormItem?: Maybe<SdFormItem>;
};

/** The output of our delete `SdForm` mutation. */
export type DeleteSdFormPayload = {
  __typename?: 'DeleteSdFormPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `SdCompany` that is related to this `SdForm`. */
  creatingCompany?: Maybe<SdCompany>;
  deletedSdFormNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `SdLocation` that is related to this `SdForm`. */
  location?: Maybe<SdLocation>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SdForm` that was deleted by this mutation. */
  sdForm?: Maybe<SdForm>;
};

/** All input for the `deleteSdFormTypeByDocumentTemplateId` mutation. */
export type DeleteSdFormTypeByDocumentTemplateIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  documentTemplateId: Scalars['UUID'];
};

/** All input for the `deleteSdFormTypeByNodeId` mutation. */
export type DeleteSdFormTypeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SdFormType` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteSdFormType` mutation. */
export type DeleteSdFormTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `SdFormType` mutation. */
export type DeleteSdFormTypePayload = {
  __typename?: 'DeleteSdFormTypePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `SdCompany` that is related to this `SdFormType`. */
  creatingCompany?: Maybe<SdCompany>;
  deletedSdFormTypeNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SdFormType` that was deleted by this mutation. */
  sdFormType?: Maybe<SdFormType>;
};

/** All input for the `deleteSdFormWorkerValueByNodeId` mutation. */
export type DeleteSdFormWorkerValueByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SdFormWorkerValue` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteSdFormWorkerValue` mutation. */
export type DeleteSdFormWorkerValueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  employeeId: Scalars['UUID'];
  formId: Scalars['UUID'];
  itemId: Scalars['UUID'];
};

/** The output of our delete `SdFormWorkerValue` mutation. */
export type DeleteSdFormWorkerValuePayload = {
  __typename?: 'DeleteSdFormWorkerValuePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedSdFormWorkerValueNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `SdFormItem` that is related to this `SdFormWorkerValue`. */
  item?: Maybe<SdFormItem>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SdFormWorkerValue` that was deleted by this mutation. */
  sdFormWorkerValue?: Maybe<SdFormWorkerValue>;
};

/** All input for the `deleteSdLocationByNodeId` mutation. */
export type DeleteSdLocationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SdLocation` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteSdLocation` mutation. */
export type DeleteSdLocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `SdLocation` mutation. */
export type DeleteSdLocationPayload = {
  __typename?: 'DeleteSdLocationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `SdCompany` that is related to this `SdLocation`. */
  creatingCompany?: Maybe<SdCompany>;
  deletedSdLocationNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SdLocation` that was deleted by this mutation. */
  sdLocation?: Maybe<SdLocation>;
};

/** All input for the `deleteSdSignatureByNodeId` mutation. */
export type DeleteSdSignatureByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SdSignature` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteSdSignature` mutation. */
export type DeleteSdSignatureInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `SdSignature` mutation. */
export type DeleteSdSignaturePayload = {
  __typename?: 'DeleteSdSignaturePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `SdCompany` that is related to this `SdSignature`. */
  creatingCompany?: Maybe<SdCompany>;
  deletedSdSignatureNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `SdWorker` that is related to this `SdSignature`. */
  employee?: Maybe<SdWorker>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SdSignature` that was deleted by this mutation. */
  sdSignature?: Maybe<SdSignature>;
  /** Reads a single `SdWorker` that is related to this `SdSignature`. */
  sdWorkerByCreatedBy?: Maybe<SdWorker>;
  /** Reads a single `SdWorker` that is related to this `SdSignature`. */
  sdWorkerByLastModifiedBy?: Maybe<SdWorker>;
};

/** All input for the `deleteSdWorkerByNodeId` mutation. */
export type DeleteSdWorkerByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SdWorker` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteSdWorker` mutation. */
export type DeleteSdWorkerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `SdWorker` mutation. */
export type DeleteSdWorkerPayload = {
  __typename?: 'DeleteSdWorkerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedSdWorkerNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `SdCompany` that is related to this `SdWorker`. */
  employer?: Maybe<SdCompany>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SdWorker` that was deleted by this mutation. */
  sdWorker?: Maybe<SdWorker>;
};

/** All input for the `deleteTcCollectiveAgreementByNodeId` mutation. */
export type DeleteTcCollectiveAgreementByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcCollectiveAgreement` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTcCollectiveAgreement` mutation. */
export type DeleteTcCollectiveAgreementInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Each collective agreement is associated to a service area, but potentially also limited by StdCraft */
  id: Scalars['String'];
};

/** The output of our delete `TcCollectiveAgreement` mutation. */
export type DeleteTcCollectiveAgreementPayload = {
  __typename?: 'DeleteTcCollectiveAgreementPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTcCollectiveAgreementNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcCollectiveAgreement` that was deleted by this mutation. */
  tcCollectiveAgreement?: Maybe<TcCollectiveAgreement>;
};

/** All input for the `deleteTcCompanyAgreementByNodeId` mutation. */
export type DeleteTcCompanyAgreementByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcCompanyAgreement` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTcCompanyAgreement` mutation. */
export type DeleteTcCompanyAgreementInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyNo: Scalars['Int'];
};

/** The output of our delete `TcCompanyAgreement` mutation. */
export type DeleteTcCompanyAgreementPayload = {
  __typename?: 'DeleteTcCompanyAgreementPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTcCompanyAgreementNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcCompanyAgreement` that was deleted by this mutation. */
  tcCompanyAgreement?: Maybe<TcCompanyAgreement>;
};

/** All input for the `deleteTcCompanyByNodeId` mutation. */
export type DeleteTcCompanyByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcCompany` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTcCompany` mutation. */
export type DeleteTcCompanyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyNo: Scalars['Int'];
};

/** The output of our delete `TcCompany` mutation. */
export type DeleteTcCompanyPayload = {
  __typename?: 'DeleteTcCompanyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTcCompanyNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcCompany` that was deleted by this mutation. */
  tcCompany?: Maybe<TcCompany>;
};

/** All input for the `deleteTcEarningCodeRateByNodeId` mutation. */
export type DeleteTcEarningCodeRateByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcEarningCodeRate` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTcEarningCodeRate` mutation. */
export type DeleteTcEarningCodeRateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  collectiveAgreement: Scalars['String'];
  earningCode: Scalars['String'];
};

/** The output of our delete `TcEarningCodeRate` mutation. */
export type DeleteTcEarningCodeRatePayload = {
  __typename?: 'DeleteTcEarningCodeRatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTcEarningCodeRateNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcEarningCodeRate` that was deleted by this mutation. */
  tcEarningCodeRate?: Maybe<TcEarningCodeRate>;
};

/** All input for the `deleteTcEquipmentByNodeId` mutation. */
export type DeleteTcEquipmentByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcEquipment` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTcEquipment` mutation. */
export type DeleteTcEquipmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `TcEquipment` mutation. */
export type DeleteTcEquipmentPayload = {
  __typename?: 'DeleteTcEquipmentPayload';
  /** Reads a single `Asset` that is related to this `TcEquipment`. */
  asset?: Maybe<Asset>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTcEquipmentNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcEquipment` that was deleted by this mutation. */
  tcEquipment?: Maybe<TcEquipment>;
  /** Reads a single `TcWork` that is related to this `TcEquipment`. */
  tcWork?: Maybe<TcWork>;
};

/** All input for the `deleteTcHolidayByNodeId` mutation. */
export type DeleteTcHolidayByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcHoliday` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTcHoliday` mutation. */
export type DeleteTcHolidayInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `TcHoliday` mutation. */
export type DeleteTcHolidayPayload = {
  __typename?: 'DeleteTcHolidayPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTcHolidayNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcHoliday` that was deleted by this mutation. */
  tcHoliday?: Maybe<TcHoliday>;
};

/** All input for the `deleteTcLeaveByNodeId` mutation. */
export type DeleteTcLeaveByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcLeave` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTcLeave` mutation. */
export type DeleteTcLeaveInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `TcLeave` mutation. */
export type DeleteTcLeavePayload = {
  __typename?: 'DeleteTcLeavePayload';
  /** Reads a single `AuthUser` that is related to this `TcLeave`. */
  authUserByReviewedBy?: Maybe<AuthUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTcLeaveNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `TcHoliday` that is related to this `TcLeave`. */
  holiday?: Maybe<TcHoliday>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcLeave` that was deleted by this mutation. */
  tcLeave?: Maybe<TcLeave>;
  /** Reads a single `AuthUser` that is related to this `TcLeave`. */
  user?: Maybe<AuthUser>;
};

/** All input for the `deleteTcMaterialByNodeId` mutation. */
export type DeleteTcMaterialByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcMaterial` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTcMaterial` mutation. */
export type DeleteTcMaterialInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `TcMaterial` mutation. */
export type DeleteTcMaterialPayload = {
  __typename?: 'DeleteTcMaterialPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTcMaterialNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `OpsMaterial` that is related to this `TcMaterial`. */
  opsMaterialByMaterialCode?: Maybe<OpsMaterial>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcMaterial` that was deleted by this mutation. */
  tcMaterial?: Maybe<TcMaterial>;
  /** Reads a single `TcWork` that is related to this `TcMaterial`. */
  tcWork?: Maybe<TcWork>;
};

/** All input for the `deleteTcRejectionByNodeId` mutation. */
export type DeleteTcRejectionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcRejection` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTcRejection` mutation. */
export type DeleteTcRejectionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `TcRejection` mutation. */
export type DeleteTcRejectionPayload = {
  __typename?: 'DeleteTcRejectionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTcRejectionNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AuthUser` that is related to this `TcRejection`. */
  reviewer?: Maybe<AuthUser>;
  /** The `TcRejection` that was deleted by this mutation. */
  tcRejection?: Maybe<TcRejection>;
  /** Reads a single `TcTimecard` that is related to this `TcRejection`. */
  timecard?: Maybe<TcTimecard>;
};

/** All input for the `deleteTcTimecardByNodeId` mutation. */
export type DeleteTcTimecardByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcTimecard` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTcTimecard` mutation. */
export type DeleteTcTimecardInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `TcTimecard` mutation. */
export type DeleteTcTimecardPayload = {
  __typename?: 'DeleteTcTimecardPayload';
  /** Reads a single `AuthUser` that is related to this `TcTimecard`. */
  authUserByApprovedBy?: Maybe<AuthUser>;
  /** Reads a single `AuthUser` that is related to this `TcTimecard`. */
  authUserByReviewedBy?: Maybe<AuthUser>;
  /** Reads a single `AuthUser` that is related to this `TcTimecard`. */
  authUserBySubmissionBy?: Maybe<AuthUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTcTimecardNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcTimecard` that was deleted by this mutation. */
  tcTimecard?: Maybe<TcTimecard>;
  /** Reads a single `AuthUser` that is related to this `TcTimecard`. */
  worker?: Maybe<AuthUser>;
  /** Reads a single `OpsYard` that is related to this `TcTimecard`. */
  worksite?: Maybe<OpsYard>;
};

/** All input for the `deleteTcVacationAllotmentByNodeId` mutation. */
export type DeleteTcVacationAllotmentByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcVacationAllotment` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTcVacationAllotment` mutation. */
export type DeleteTcVacationAllotmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  collectiveAgreementCode: Scalars['String'];
  workDuration: IntervalInput;
  workdays: Scalars['Int'];
};

/** The output of our delete `TcVacationAllotment` mutation. */
export type DeleteTcVacationAllotmentPayload = {
  __typename?: 'DeleteTcVacationAllotmentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTcVacationAllotmentNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcVacationAllotment` that was deleted by this mutation. */
  tcVacationAllotment?: Maybe<TcVacationAllotment>;
};

/** All input for the `deleteTcWorkByNodeId` mutation. */
export type DeleteTcWorkByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcWork` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTcWork` mutation. */
export type DeleteTcWorkInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** All input for the `deleteTcWorkLeaveByNodeId` mutation. */
export type DeleteTcWorkLeaveByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcWorkLeave` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTcWorkLeave` mutation. */
export type DeleteTcWorkLeaveInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `TcWorkLeave` mutation. */
export type DeleteTcWorkLeavePayload = {
  __typename?: 'DeleteTcWorkLeavePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTcWorkLeaveNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcWorkLeave` that was deleted by this mutation. */
  tcWorkLeave?: Maybe<TcWorkLeave>;
  /** Reads a single `TcTimecard` that is related to this `TcWorkLeave`. */
  timecard?: Maybe<TcTimecard>;
};

/** The output of our delete `TcWork` mutation. */
export type DeleteTcWorkPayload = {
  __typename?: 'DeleteTcWorkPayload';
  /** Reads a single `AuthUser` that is related to this `TcWork`. */
  accomplishmentUser?: Maybe<AuthUser>;
  /** Reads a single `OpsBlock` that is related to this `TcWork`. */
  block?: Maybe<OpsBlock>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTcWorkNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `ImapRoadFeature` that is related to this `TcWork`. */
  road?: Maybe<ImapRoadFeature>;
  /** Reads a single `ImapRestarea` that is related to this `TcWork`. */
  site?: Maybe<ImapRestarea>;
  /** Reads a single `ImapRoadStructure` that is related to this `TcWork`. */
  structure?: Maybe<ImapRoadStructure>;
  /** The `TcWork` that was deleted by this mutation. */
  tcWork?: Maybe<TcWork>;
  /** Reads a single `TcTimecard` that is related to this `TcWork`. */
  timecard?: Maybe<TcTimecard>;
};

/** All input for the `deleteTcWorkTestByNodeId` mutation. */
export type DeleteTcWorkTestByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcWorkTest` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTcWorkTest` mutation. */
export type DeleteTcWorkTestInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  timecardId: Scalars['Int'];
};

/** The output of our delete `TcWorkTest` mutation. */
export type DeleteTcWorkTestPayload = {
  __typename?: 'DeleteTcWorkTestPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTcWorkTestNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcWorkTest` that was deleted by this mutation. */
  tcWorkTest?: Maybe<TcWorkTest>;
};

/** All input for the `deleteTcWorkTimeByNodeId` mutation. */
export type DeleteTcWorkTimeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcWorkTime` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTcWorkTime` mutation. */
export type DeleteTcWorkTimeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  sequenceNo: Scalars['Int'];
  timecardId: Scalars['Int'];
  workRange: DatetimeRangeInput;
};

/** The output of our delete `TcWorkTime` mutation. */
export type DeleteTcWorkTimePayload = {
  __typename?: 'DeleteTcWorkTimePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTcWorkTimeNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcWorkTime` that was deleted by this mutation. */
  tcWorkTime?: Maybe<TcWorkTime>;
  /** Reads a single `TcTimecard` that is related to this `TcWorkTime`. */
  timecard?: Maybe<TcTimecard>;
};

/** All input for the `deleteUomByDescription` mutation. */
export type DeleteUomByDescriptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
};

/** All input for the `deleteUomByMotiCode` mutation. */
export type DeleteUomByMotiCodeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  motiCode: Scalars['String'];
};

/** All input for the `deleteUomByNodeId` mutation. */
export type DeleteUomByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Uom` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteUomByUomCode` mutation. */
export type DeleteUomByUomCodeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  uomCode: Scalars['String'];
};

/** All input for the `deleteUom` mutation. */
export type DeleteUomInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Uom` mutation. */
export type DeleteUomPayload = {
  __typename?: 'DeleteUomPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedUomNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Uom` that was deleted by this mutation. */
  uom?: Maybe<Uom>;
};

/** All input for the `deleteVehicleDiagnosticByAssetIdAndObdCodeAndDatetime` mutation. */
export type DeleteVehicleDiagnosticByAssetIdAndObdCodeAndDatetimeInput = {
  assetId: Scalars['Int'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  datetime: Scalars['Datetime'];
  obdCode: Scalars['String'];
};

/** All input for the `deleteVehicleDiagnosticByAssetIdAndSpnCodeAndFmiCodeAndDatetime` mutation. */
export type DeleteVehicleDiagnosticByAssetIdAndSpnCodeAndFmiCodeAndDatetimeInput = {
  assetId: Scalars['Int'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  datetime: Scalars['Datetime'];
  fmiCode: Scalars['Int'];
  spnCode: Scalars['Int'];
};

/** All input for the `deleteVehicleDiagnosticByNodeId` mutation. */
export type DeleteVehicleDiagnosticByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `VehicleDiagnostic` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteVehicleDiagnostic` mutation. */
export type DeleteVehicleDiagnosticInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `VehicleDiagnostic` mutation. */
export type DeleteVehicleDiagnosticPayload = {
  __typename?: 'DeleteVehicleDiagnosticPayload';
  /** Reads a single `Asset` that is related to this `VehicleDiagnostic`. */
  asset?: Maybe<Asset>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedVehicleDiagnosticNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `VehicleDiagnostic` that was deleted by this mutation. */
  vehicleDiagnostic?: Maybe<VehicleDiagnostic>;
};

/** All input for the `deleteVehicleServiceByNodeId` mutation. */
export type DeleteVehicleServiceByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `VehicleService` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteVehicleService` mutation. */
export type DeleteVehicleServiceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `VehicleService` mutation. */
export type DeleteVehicleServicePayload = {
  __typename?: 'DeleteVehicleServicePayload';
  /** Reads a single `Asset` that is related to this `VehicleService`. */
  asset?: Maybe<Asset>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedVehicleServiceNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `VehicleService` that was deleted by this mutation. */
  vehicleService?: Maybe<VehicleService>;
};

/** All input for the `deleteVehicleStatusByNodeId` mutation. */
export type DeleteVehicleStatusByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `VehicleStatus` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteVehicleStatus` mutation. */
export type DeleteVehicleStatusInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  statusId: Scalars['Int'];
};

/** The output of our delete `VehicleStatus` mutation. */
export type DeleteVehicleStatusPayload = {
  __typename?: 'DeleteVehicleStatusPayload';
  /** Reads a single `Asset` that is related to this `VehicleStatus`. */
  asset?: Maybe<Asset>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedVehicleStatusNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `VehicleStatus` that was deleted by this mutation. */
  vehicleStatus?: Maybe<VehicleStatus>;
};

/** All input for the `deleteVpJobOverrideByNodeId` mutation. */
export type DeleteVpJobOverrideByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `VpJobOverride` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteVpJobOverride` mutation. */
export type DeleteVpJobOverrideInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyNo: Scalars['Int'];
  jobCode: Scalars['String'];
};

/** The output of our delete `VpJobOverride` mutation. */
export type DeleteVpJobOverridePayload = {
  __typename?: 'DeleteVpJobOverridePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedVpJobOverrideNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `VpJobOverride` that was deleted by this mutation. */
  vpJobOverride?: Maybe<VpJobOverride>;
};

/** All input for the `deleteVpPhaseOverrideByNodeId` mutation. */
export type DeleteVpPhaseOverrideByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `VpPhaseOverride` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteVpPhaseOverride` mutation. */
export type DeleteVpPhaseOverrideInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyNo: Scalars['Int'];
  jobCode: Scalars['String'];
  phaseCode: Scalars['String'];
};

/** The output of our delete `VpPhaseOverride` mutation. */
export type DeleteVpPhaseOverridePayload = {
  __typename?: 'DeleteVpPhaseOverridePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedVpPhaseOverrideNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `VpPhaseOverride` that was deleted by this mutation. */
  vpPhaseOverride?: Maybe<VpPhaseOverride>;
};

export type DiagnosticCode = {
  code: Scalars['ID'];
  codeType: DtcType;
};

export type DriveBcEvent = {
  __typename?: 'DriveBcEvent';
  description: Scalars['String'];
  directions?: Maybe<Scalars['String']>;
  eventType: Scalars['String'];
  id: Scalars['ID'];
  position: Array<Scalars['Float']>;
  region: Scalars['String'];
  route: Scalars['String'];
  severity: Scalars['String'];
  status: Scalars['String'];
  updated: Scalars['DateTime'];
};

export type DtcFmi = Node & {
  __typename?: 'DtcFmi';
  code: Scalars['Int'];
  description: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/** A condition to be used against `DtcFmi` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type DtcFmiCondition = {
  /** Checks for equality with the object’s `code` field. */
  code?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `DtcFmi` object types. All fields are combined with a logical ‘and.’ */
export type DtcFmiFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DtcFmiFilter>>;
  /** Filter by the object’s `code` field. */
  code?: InputMaybe<IntFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DtcFmiFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DtcFmiFilter>>;
};

/** An input for mutations affecting `DtcFmi` */
export type DtcFmiInput = {
  code: Scalars['Int'];
  description: Scalars['String'];
};

/** Represents an update to a `DtcFmi`. Fields that are set will be updated. */
export type DtcFmiPatch = {
  code?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `DtcFmi`. */
export enum DtcFmisOrderBy {
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type DtcObd = Node & {
  __typename?: 'DtcObd';
  code: Scalars['String'];
  description: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/** A condition to be used against `DtcObd` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type DtcObdCondition = {
  /** Checks for equality with the object’s `code` field. */
  code?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `DtcObd` object types. All fields are combined with a logical ‘and.’ */
export type DtcObdFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DtcObdFilter>>;
  /** Filter by the object’s `code` field. */
  code?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DtcObdFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DtcObdFilter>>;
};

/** An input for mutations affecting `DtcObd` */
export type DtcObdInput = {
  code: Scalars['String'];
  description: Scalars['String'];
};

/** Represents an update to a `DtcObd`. Fields that are set will be updated. */
export type DtcObdPatch = {
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `DtcObd`. */
export enum DtcObdsOrderBy {
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type DtcSpn = Node & {
  __typename?: 'DtcSpn';
  code: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/** A condition to be used against `DtcSpn` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type DtcSpnCondition = {
  /** Checks for equality with the object’s `code` field. */
  code?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `DtcSpn` object types. All fields are combined with a logical ‘and.’ */
export type DtcSpnFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DtcSpnFilter>>;
  /** Filter by the object’s `code` field. */
  code?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DtcSpnFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DtcSpnFilter>>;
};

/** An input for mutations affecting `DtcSpn` */
export type DtcSpnInput = {
  code: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Represents an update to a `DtcSpn`. Fields that are set will be updated. */
export type DtcSpnPatch = {
  code?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `DtcSpn`. */
export enum DtcSpnsOrderBy {
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type EditUserInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  companyNo?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  employeeNo?: InputMaybe<Scalars['Int']>;
  fullname?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<Array<TrinaryInput>>;
  homeYardId?: InputMaybe<Scalars['Int']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  isSupervisor?: InputMaybe<Scalars['Boolean']>;
  reviewers?: InputMaybe<Array<TrinaryInput>>;
  schedule?: InputMaybe<ScheduleInput>;
  supervisors?: InputMaybe<Array<TrinaryInput>>;
  temporaryPassword?: InputMaybe<Scalars['String']>;
};

/** As per Olga's rqeuest for a custom holiday compensation report */
export type EsaHolidayCompensation = {
  __typename?: 'EsaHolidayCompensation';
  avgDailyCompensation?: Maybe<Scalars['BigFloat']>;
  avgDailyCompensationInHr?: Maybe<Scalars['BigFloat']>;
  avgDailyStHours?: Maybe<Scalars['BigFloat']>;
  classCode?: Maybe<Scalars['String']>;
  companyNo?: Maybe<Scalars['Int']>;
  craftCode?: Maybe<Scalars['String']>;
  earningCode?: Maybe<Scalars['String']>;
  employeeNo?: Maybe<Scalars['Int']>;
  entryDate?: Maybe<Scalars['Date']>;
  firstName?: Maybe<Scalars['String']>;
  holidayDescription?: Maybe<Scalars['String']>;
  holidayPayEligible?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['BigFloat']>;
  jobCode?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phaseCode?: Maybe<Scalars['String']>;
  previousCompensation?: Maybe<Scalars['BigFloat']>;
  previousDays?: Maybe<Scalars['BigInt']>;
  previousStHours?: Maybe<Scalars['BigFloat']>;
};

/** Methods to use when ordering `EsaHolidayCompensation`. */
export enum EsaHolidayCompensationsOrderBy {
  Natural = 'NATURAL'
}

export type Fmi = {
  __typename?: 'FMI';
  fmi_code: Scalars['ID'];
  fmi_description?: Maybe<Scalars['String']>;
};

export type FleetShop = {
  __typename?: 'FleetShop';
  assetCode?: Maybe<Scalars['String']>;
  dayRatioClosest?: Maybe<Scalars['BigFloat']>;
  dayYard?: Maybe<Scalars['String']>;
  monthRatioClosest?: Maybe<Scalars['BigFloat']>;
  monthYard?: Maybe<Scalars['String']>;
};

export type FleetShopDay = {
  __typename?: 'FleetShopDay';
  assetCode?: Maybe<Scalars['String']>;
  ratioClosest?: Maybe<Scalars['BigFloat']>;
  yard?: Maybe<Scalars['String']>;
};

/** Methods to use when ordering `FleetShopDay`. */
export enum FleetShopDaysOrderBy {
  Natural = 'NATURAL'
}

export type FleetShopMonth = {
  __typename?: 'FleetShopMonth';
  assetCode?: Maybe<Scalars['String']>;
  ratioClosest?: Maybe<Scalars['BigFloat']>;
  yard?: Maybe<Scalars['String']>;
};

/** Methods to use when ordering `FleetShopMonth`. */
export enum FleetShopMonthsOrderBy {
  Natural = 'NATURAL'
}

/** Methods to use when ordering `FleetShop`. */
export enum FleetShopsOrderBy {
  Natural = 'NATURAL'
}

export type Forecast = {
  __typename?: 'Forecast';
  discussion: ForecastDiscussion;
  issue_time: Scalars['DateTime'];
  station: Station;
  targets: Array<ForecastTarget>;
};

export type ForecastDiscussion = {
  __typename?: 'ForecastDiscussion';
  amendmentTime?: Maybe<Scalars['String']>;
  confidence: Scalars['String'];
  forecaster: Scalars['String'];
  fulltext: Scalars['String'];
  periods?: Maybe<Array<ForecastDiscussionPeriod>>;
};

export type ForecastDiscussionPeriod = {
  __typename?: 'ForecastDiscussionPeriod';
  description: Scalars['String'];
  period: Scalars['String'];
};

export type ForecastTarget = {
  __typename?: 'ForecastTarget';
  surface_status?: Maybe<Scalars['Int']>;
  surface_temp: Scalars['Float'];
  target_time: Scalars['DateTime'];
};

/** All geography XY types implement this interface */
export type GeographyGeometry = {
  /** Converts the object to GeoJSON */
  geojson?: Maybe<Scalars['GeoJSON']>;
  /** Spatial reference identifier (SRID) */
  srid: Scalars['Int'];
};

/** All geography types implement this interface */
export type GeographyInterface = {
  /** Converts the object to GeoJSON */
  geojson?: Maybe<Scalars['GeoJSON']>;
  /** Spatial reference identifier (SRID) */
  srid: Scalars['Int'];
};

export type GeographyPoint = GeographyGeometry & GeographyInterface & {
  __typename?: 'GeographyPoint';
  geojson?: Maybe<Scalars['GeoJSON']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  srid: Scalars['Int'];
};

/** All geometry XY types implement this interface */
export type GeometryGeometry = {
  /** Converts the object to GeoJSON */
  geojson?: Maybe<Scalars['GeoJSON']>;
  /** Spatial reference identifier (SRID) */
  srid: Scalars['Int'];
};

export type GeometryGeometryCollection = GeometryGeometry & GeometryInterface & {
  __typename?: 'GeometryGeometryCollection';
  geojson?: Maybe<Scalars['GeoJSON']>;
  geometries?: Maybe<Array<Maybe<GeometryGeometry>>>;
  srid: Scalars['Int'];
};

export type GeometryGeometryCollectionM = GeometryGeometryM & GeometryInterface & {
  __typename?: 'GeometryGeometryCollectionM';
  geojson?: Maybe<Scalars['GeoJSON']>;
  geometries?: Maybe<Array<Maybe<GeometryGeometryM>>>;
  srid: Scalars['Int'];
};

export type GeometryGeometryCollectionZ = GeometryGeometryZ & GeometryInterface & {
  __typename?: 'GeometryGeometryCollectionZ';
  geojson?: Maybe<Scalars['GeoJSON']>;
  geometries?: Maybe<Array<Maybe<GeometryGeometryZ>>>;
  srid: Scalars['Int'];
};

export type GeometryGeometryCollectionZm = GeometryGeometryZm & GeometryInterface & {
  __typename?: 'GeometryGeometryCollectionZM';
  geojson?: Maybe<Scalars['GeoJSON']>;
  geometries?: Maybe<Array<Maybe<GeometryGeometryZm>>>;
  srid: Scalars['Int'];
};

/** All geometry XYM types implement this interface */
export type GeometryGeometryM = {
  /** Converts the object to GeoJSON */
  geojson?: Maybe<Scalars['GeoJSON']>;
  /** Spatial reference identifier (SRID) */
  srid: Scalars['Int'];
};

/** All geometry XYZ types implement this interface */
export type GeometryGeometryZ = {
  /** Converts the object to GeoJSON */
  geojson?: Maybe<Scalars['GeoJSON']>;
  /** Spatial reference identifier (SRID) */
  srid: Scalars['Int'];
};

/** All geometry XYZM types implement this interface */
export type GeometryGeometryZm = {
  /** Converts the object to GeoJSON */
  geojson?: Maybe<Scalars['GeoJSON']>;
  /** Spatial reference identifier (SRID) */
  srid: Scalars['Int'];
};

/** All geometry types implement this interface */
export type GeometryInterface = {
  /** Converts the object to GeoJSON */
  geojson?: Maybe<Scalars['GeoJSON']>;
  /** Spatial reference identifier (SRID) */
  srid: Scalars['Int'];
};

export type GeometryLineString = GeometryGeometry & GeometryInterface & {
  __typename?: 'GeometryLineString';
  geojson?: Maybe<Scalars['GeoJSON']>;
  points?: Maybe<Array<Maybe<GeometryPoint>>>;
  srid: Scalars['Int'];
};

export type GeometryLineStringM = GeometryGeometryM & GeometryInterface & {
  __typename?: 'GeometryLineStringM';
  geojson?: Maybe<Scalars['GeoJSON']>;
  points?: Maybe<Array<Maybe<GeometryPointM>>>;
  srid: Scalars['Int'];
};

export type GeometryLineStringZ = GeometryGeometryZ & GeometryInterface & {
  __typename?: 'GeometryLineStringZ';
  geojson?: Maybe<Scalars['GeoJSON']>;
  points?: Maybe<Array<Maybe<GeometryPointZ>>>;
  srid: Scalars['Int'];
};

export type GeometryLineStringZm = GeometryGeometryZm & GeometryInterface & {
  __typename?: 'GeometryLineStringZM';
  geojson?: Maybe<Scalars['GeoJSON']>;
  points?: Maybe<Array<Maybe<GeometryPointZm>>>;
  srid: Scalars['Int'];
};

export type GeometryMultiLineString = GeometryGeometry & GeometryInterface & {
  __typename?: 'GeometryMultiLineString';
  geojson?: Maybe<Scalars['GeoJSON']>;
  lines?: Maybe<Array<Maybe<GeometryLineString>>>;
  srid: Scalars['Int'];
};

export type GeometryMultiLineStringM = GeometryGeometryM & GeometryInterface & {
  __typename?: 'GeometryMultiLineStringM';
  geojson?: Maybe<Scalars['GeoJSON']>;
  lines?: Maybe<Array<Maybe<GeometryLineStringM>>>;
  srid: Scalars['Int'];
};

export type GeometryMultiLineStringZ = GeometryGeometryZ & GeometryInterface & {
  __typename?: 'GeometryMultiLineStringZ';
  geojson?: Maybe<Scalars['GeoJSON']>;
  lines?: Maybe<Array<Maybe<GeometryLineStringZ>>>;
  srid: Scalars['Int'];
};

export type GeometryMultiLineStringZm = GeometryGeometryZm & GeometryInterface & {
  __typename?: 'GeometryMultiLineStringZM';
  geojson?: Maybe<Scalars['GeoJSON']>;
  lines?: Maybe<Array<Maybe<GeometryLineStringZm>>>;
  srid: Scalars['Int'];
};

export type GeometryMultiPoint = GeometryGeometry & GeometryInterface & {
  __typename?: 'GeometryMultiPoint';
  geojson?: Maybe<Scalars['GeoJSON']>;
  points?: Maybe<Array<Maybe<GeometryPoint>>>;
  srid: Scalars['Int'];
};

export type GeometryMultiPointM = GeometryGeometryM & GeometryInterface & {
  __typename?: 'GeometryMultiPointM';
  geojson?: Maybe<Scalars['GeoJSON']>;
  points?: Maybe<Array<Maybe<GeometryPointM>>>;
  srid: Scalars['Int'];
};

export type GeometryMultiPointZ = GeometryGeometryZ & GeometryInterface & {
  __typename?: 'GeometryMultiPointZ';
  geojson?: Maybe<Scalars['GeoJSON']>;
  points?: Maybe<Array<Maybe<GeometryPointZ>>>;
  srid: Scalars['Int'];
};

export type GeometryMultiPointZm = GeometryGeometryZm & GeometryInterface & {
  __typename?: 'GeometryMultiPointZM';
  geojson?: Maybe<Scalars['GeoJSON']>;
  points?: Maybe<Array<Maybe<GeometryPointZm>>>;
  srid: Scalars['Int'];
};

export type GeometryMultiPolygon = GeometryGeometry & GeometryInterface & {
  __typename?: 'GeometryMultiPolygon';
  geojson?: Maybe<Scalars['GeoJSON']>;
  polygons?: Maybe<Array<Maybe<GeometryPolygon>>>;
  srid: Scalars['Int'];
};

export type GeometryMultiPolygonM = GeometryGeometryM & GeometryInterface & {
  __typename?: 'GeometryMultiPolygonM';
  geojson?: Maybe<Scalars['GeoJSON']>;
  polygons?: Maybe<Array<Maybe<GeometryPolygonM>>>;
  srid: Scalars['Int'];
};

export type GeometryMultiPolygonZ = GeometryGeometryZ & GeometryInterface & {
  __typename?: 'GeometryMultiPolygonZ';
  geojson?: Maybe<Scalars['GeoJSON']>;
  polygons?: Maybe<Array<Maybe<GeometryPolygonZ>>>;
  srid: Scalars['Int'];
};

export type GeometryMultiPolygonZm = GeometryGeometryZm & GeometryInterface & {
  __typename?: 'GeometryMultiPolygonZM';
  geojson?: Maybe<Scalars['GeoJSON']>;
  polygons?: Maybe<Array<Maybe<GeometryPolygonZm>>>;
  srid: Scalars['Int'];
};

export type GeometryPoint = GeometryGeometry & GeometryInterface & {
  __typename?: 'GeometryPoint';
  geojson?: Maybe<Scalars['GeoJSON']>;
  srid: Scalars['Int'];
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type GeometryPointM = GeometryGeometryM & GeometryInterface & {
  __typename?: 'GeometryPointM';
  geojson?: Maybe<Scalars['GeoJSON']>;
  srid: Scalars['Int'];
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type GeometryPointZ = GeometryGeometryZ & GeometryInterface & {
  __typename?: 'GeometryPointZ';
  geojson?: Maybe<Scalars['GeoJSON']>;
  srid: Scalars['Int'];
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type GeometryPointZm = GeometryGeometryZm & GeometryInterface & {
  __typename?: 'GeometryPointZM';
  geojson?: Maybe<Scalars['GeoJSON']>;
  srid: Scalars['Int'];
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type GeometryPolygon = GeometryGeometry & GeometryInterface & {
  __typename?: 'GeometryPolygon';
  exterior?: Maybe<GeometryLineString>;
  geojson?: Maybe<Scalars['GeoJSON']>;
  interiors?: Maybe<Array<Maybe<GeometryLineString>>>;
  srid: Scalars['Int'];
};

export type GeometryPolygonM = GeometryGeometryM & GeometryInterface & {
  __typename?: 'GeometryPolygonM';
  exterior?: Maybe<GeometryLineStringM>;
  geojson?: Maybe<Scalars['GeoJSON']>;
  interiors?: Maybe<Array<Maybe<GeometryLineStringM>>>;
  srid: Scalars['Int'];
};

export type GeometryPolygonZ = GeometryGeometryZ & GeometryInterface & {
  __typename?: 'GeometryPolygonZ';
  exterior?: Maybe<GeometryLineStringZ>;
  geojson?: Maybe<Scalars['GeoJSON']>;
  interiors?: Maybe<Array<Maybe<GeometryLineStringZ>>>;
  srid: Scalars['Int'];
};

export type GeometryPolygonZm = GeometryGeometryZm & GeometryInterface & {
  __typename?: 'GeometryPolygonZM';
  exterior?: Maybe<GeometryLineStringZm>;
  geojson?: Maybe<Scalars['GeoJSON']>;
  interiors?: Maybe<Array<Maybe<GeometryLineStringZm>>>;
  srid: Scalars['Int'];
};

/** All input for the `getImapRoadClass` mutation. */
export type GetImapRoadClassInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  gps?: InputMaybe<Scalars['GeoJSON']>;
};

/** The output of our `getImapRoadClass` mutation. */
export type GetImapRoadClassPayload = {
  __typename?: 'GetImapRoadClassPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  integer?: Maybe<Scalars['Int']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `getImapRoadFeature` mutation. */
export type GetImapRoadFeatureInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  gps?: InputMaybe<Scalars['GeoJSON']>;
};

/** The output of our `getImapRoadFeature` mutation. */
export type GetImapRoadFeaturePayload = {
  __typename?: 'GetImapRoadFeaturePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  integer?: Maybe<Scalars['Int']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `getImapRoadSurface` mutation. */
export type GetImapRoadSurfaceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  gps?: InputMaybe<Scalars['GeoJSON']>;
};

/** The output of our `getImapRoadSurface` mutation. */
export type GetImapRoadSurfacePayload = {
  __typename?: 'GetImapRoadSurfacePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  integer?: Maybe<Scalars['Int']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `getOpsYard` mutation. */
export type GetOpsYardInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  gps?: InputMaybe<Scalars['GeoJSON']>;
};

/** The output of our `getOpsYard` mutation. */
export type GetOpsYardPayload = {
  __typename?: 'GetOpsYardPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  integer?: Maybe<Scalars['Int']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type GeuHoliday = {
  __typename?: 'GeuHoliday';
  alternateDay: Scalars['String'];
  description: Scalars['String'];
  holiday: Scalars['String'];
  holidayId: Scalars['ID'];
  lieuDay?: Maybe<Scalars['String']>;
  lieuLimit: Scalars['String'];
  userId: Scalars['ID'];
};

export type GisAtlasZone = Node & {
  __typename?: 'GisAtlasZone';
  description: Scalars['String'];
  geom: GeometryPolygon;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  serviceArea: Scalars['String'];
};

/**
 * A condition to be used against `GisAtlasZone` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type GisAtlasZoneCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `GisAtlasZone` object types. All fields are combined with a logical ‘and.’ */
export type GisAtlasZoneFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<GisAtlasZoneFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<GisAtlasZoneFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<GisAtlasZoneFilter>>;
};

/** An input for mutations affecting `GisAtlasZone` */
export type GisAtlasZoneInput = {
  description: Scalars['String'];
  geom: Scalars['GeoJSON'];
  serviceArea: Scalars['String'];
};

/** Represents an update to a `GisAtlasZone`. Fields that are set will be updated. */
export type GisAtlasZonePatch = {
  description?: InputMaybe<Scalars['String']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  serviceArea?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `GisAtlasZone`. */
export enum GisAtlasZonesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type IdSuccessResponse = {
  __typename?: 'IdSuccessResponse';
  id: Scalars['Int'];
  success: Scalars['Boolean'];
};

export type ImapActiveStructure = {
  __typename?: 'ImapActiveStructure';
  adminUnitCode?: Maybe<Scalars['String']>;
  bmisStructStatusTypeDesc?: Maybe<Scalars['String']>;
  bmisStructureName?: Maybe<Scalars['String']>;
  bmisStructureNumber?: Maybe<Scalars['String']>;
  bmisStructureRoadId?: Maybe<Scalars['String']>;
  bmisStructureType?: Maybe<Scalars['String']>;
  chrisAnchorSectionId?: Maybe<Scalars['Int']>;
  chrisHwyStructureRoadId?: Maybe<Scalars['Int']>;
  crossSectionPosition?: Maybe<Scalars['String']>;
  featureLengthM?: Maybe<Scalars['Float']>;
  geom?: Maybe<GeometryLineStringM>;
  highwayNumber?: Maybe<Scalars['String']>;
  hwyStructureClassId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  objectid?: Maybe<Scalars['Int']>;
  rfiHighwayName?: Maybe<Scalars['String']>;
};

/** An input for mutations affecting `ImapActiveStructure` */
export type ImapActiveStructureInput = {
  adminUnitCode?: InputMaybe<Scalars['String']>;
  bmisStructStatusTypeDesc?: InputMaybe<Scalars['String']>;
  bmisStructureName?: InputMaybe<Scalars['String']>;
  bmisStructureNumber?: InputMaybe<Scalars['String']>;
  bmisStructureRoadId?: InputMaybe<Scalars['String']>;
  bmisStructureType?: InputMaybe<Scalars['String']>;
  chrisAnchorSectionId?: InputMaybe<Scalars['Int']>;
  chrisHwyStructureRoadId?: InputMaybe<Scalars['Int']>;
  crossSectionPosition?: InputMaybe<Scalars['String']>;
  featureLengthM?: InputMaybe<Scalars['Float']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  highwayNumber?: InputMaybe<Scalars['String']>;
  hwyStructureClassId?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  objectid?: InputMaybe<Scalars['Int']>;
  rfiHighwayName?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `ImapActiveStructure`. */
export enum ImapActiveStructuresOrderBy {
  Natural = 'NATURAL'
}

export type ImapContinuousRoad = {
  __typename?: 'ImapContinuousRoad';
  geom?: Maybe<GeometryInterface>;
  rfiHighwayName?: Maybe<Scalars['String']>;
};

/** Methods to use when ordering `ImapContinuousRoad`. */
export enum ImapContinuousRoadsOrderBy {
  Natural = 'NATURAL'
}

export type ImapCulvert = Node & {
  __typename?: 'ImapCulvert';
  adminUnitCode?: Maybe<Scalars['String']>;
  chrisAnchorSectionId?: Maybe<Scalars['Int']>;
  chrisCulvertId?: Maybe<Scalars['Int']>;
  crossSectionPosition?: Maybe<Scalars['String']>;
  culvertDiameter?: Maybe<Scalars['Float']>;
  culvertId?: Maybe<Scalars['Int']>;
  culvertMaterial?: Maybe<Scalars['String']>;
  culvertType?: Maybe<Scalars['String']>;
  fishPassageType?: Maybe<Scalars['String']>;
  geom?: Maybe<GeometryPoint>;
  highwayNumber?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  installationDate?: Maybe<Scalars['Datetime']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  objectid?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `ImapCulvert` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ImapCulvertCondition = {
  /** Checks for equality with the object’s `adminUnitCode` field. */
  adminUnitCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `geom` field. */
  geom?: InputMaybe<Scalars['GeoJSON']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `ImapCulvert` object types. All fields are combined with a logical ‘and.’ */
export type ImapCulvertFilter = {
  /** Filter by the object’s `adminUnitCode` field. */
  adminUnitCode?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ImapCulvertFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ImapCulvertFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ImapCulvertFilter>>;
};

/** An input for mutations affecting `ImapCulvert` */
export type ImapCulvertInput = {
  adminUnitCode?: InputMaybe<Scalars['String']>;
  chrisAnchorSectionId?: InputMaybe<Scalars['Int']>;
  chrisCulvertId?: InputMaybe<Scalars['Int']>;
  crossSectionPosition?: InputMaybe<Scalars['String']>;
  culvertDiameter?: InputMaybe<Scalars['Float']>;
  culvertId?: InputMaybe<Scalars['Int']>;
  culvertMaterial?: InputMaybe<Scalars['String']>;
  culvertType?: InputMaybe<Scalars['String']>;
  fishPassageType?: InputMaybe<Scalars['String']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  highwayNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  installationDate?: InputMaybe<Scalars['Datetime']>;
  objectid?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `ImapCulvert`. Fields that are set will be updated. */
export type ImapCulvertPatch = {
  adminUnitCode?: InputMaybe<Scalars['String']>;
  chrisAnchorSectionId?: InputMaybe<Scalars['Int']>;
  chrisCulvertId?: InputMaybe<Scalars['Int']>;
  crossSectionPosition?: InputMaybe<Scalars['String']>;
  culvertDiameter?: InputMaybe<Scalars['Float']>;
  culvertId?: InputMaybe<Scalars['Int']>;
  culvertMaterial?: InputMaybe<Scalars['String']>;
  culvertType?: InputMaybe<Scalars['String']>;
  fishPassageType?: InputMaybe<Scalars['String']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  highwayNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  installationDate?: InputMaybe<Scalars['Datetime']>;
  objectid?: InputMaybe<Scalars['Int']>;
};

/** Methods to use when ordering `ImapCulvert`. */
export enum ImapCulvertsOrderBy {
  AdminUnitCodeAsc = 'ADMIN_UNIT_CODE_ASC',
  AdminUnitCodeDesc = 'ADMIN_UNIT_CODE_DESC',
  GeomAsc = 'GEOM_ASC',
  GeomDesc = 'GEOM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type ImapDrainage = Node & {
  __typename?: 'ImapDrainage';
  adminUnitCode?: Maybe<Scalars['String']>;
  applianceCount?: Maybe<Scalars['Float']>;
  chrisAnchorSectionId?: Maybe<Scalars['Int']>;
  chrisDrainageApplianceId?: Maybe<Scalars['Int']>;
  confinedSpaceInd?: Maybe<Scalars['Boolean']>;
  crossSectionPosition?: Maybe<Scalars['String']>;
  drainageApplianceId?: Maybe<Scalars['Int']>;
  drainageApplianceType?: Maybe<Scalars['String']>;
  geom?: Maybe<GeometryPoint>;
  highwayNumber?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  objectid?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `ImapDrainage` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ImapDrainageCondition = {
  /** Checks for equality with the object’s `adminUnitCode` field. */
  adminUnitCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `geom` field. */
  geom?: InputMaybe<Scalars['GeoJSON']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `ImapDrainage` object types. All fields are combined with a logical ‘and.’ */
export type ImapDrainageFilter = {
  /** Filter by the object’s `adminUnitCode` field. */
  adminUnitCode?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ImapDrainageFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ImapDrainageFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ImapDrainageFilter>>;
};

/** An input for mutations affecting `ImapDrainage` */
export type ImapDrainageInput = {
  adminUnitCode?: InputMaybe<Scalars['String']>;
  applianceCount?: InputMaybe<Scalars['Float']>;
  chrisAnchorSectionId?: InputMaybe<Scalars['Int']>;
  chrisDrainageApplianceId?: InputMaybe<Scalars['Int']>;
  confinedSpaceInd?: InputMaybe<Scalars['Boolean']>;
  crossSectionPosition?: InputMaybe<Scalars['String']>;
  drainageApplianceId?: InputMaybe<Scalars['Int']>;
  drainageApplianceType?: InputMaybe<Scalars['String']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  highwayNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  objectid?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `ImapDrainage`. Fields that are set will be updated. */
export type ImapDrainagePatch = {
  adminUnitCode?: InputMaybe<Scalars['String']>;
  applianceCount?: InputMaybe<Scalars['Float']>;
  chrisAnchorSectionId?: InputMaybe<Scalars['Int']>;
  chrisDrainageApplianceId?: InputMaybe<Scalars['Int']>;
  confinedSpaceInd?: InputMaybe<Scalars['Boolean']>;
  crossSectionPosition?: InputMaybe<Scalars['String']>;
  drainageApplianceId?: InputMaybe<Scalars['Int']>;
  drainageApplianceType?: InputMaybe<Scalars['String']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  highwayNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  objectid?: InputMaybe<Scalars['Int']>;
};

/** Methods to use when ordering `ImapDrainage`. */
export enum ImapDrainagesOrderBy {
  AdminUnitCodeAsc = 'ADMIN_UNIT_CODE_ASC',
  AdminUnitCodeDesc = 'ADMIN_UNIT_CODE_DESC',
  GeomAsc = 'GEOM_ASC',
  GeomDesc = 'GEOM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type ImapGuardrail = Node & {
  __typename?: 'ImapGuardrail';
  adminUnitCode?: Maybe<Scalars['String']>;
  chrisAnchorSectionId?: Maybe<Scalars['Int']>;
  chrisGuardrailId?: Maybe<Scalars['Int']>;
  crossSectionPosition?: Maybe<Scalars['String']>;
  geom?: Maybe<GeometryLineStringM>;
  guardrailType?: Maybe<Scalars['String']>;
  highwayNumber?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  installationDate?: Maybe<Scalars['Date']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  objectid?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `ImapGuardrail` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ImapGuardrailCondition = {
  /** Checks for equality with the object’s `adminUnitCode` field. */
  adminUnitCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `ImapGuardrail` object types. All fields are combined with a logical ‘and.’ */
export type ImapGuardrailFilter = {
  /** Filter by the object’s `adminUnitCode` field. */
  adminUnitCode?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ImapGuardrailFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ImapGuardrailFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ImapGuardrailFilter>>;
};

/** An input for mutations affecting `ImapGuardrail` */
export type ImapGuardrailInput = {
  adminUnitCode?: InputMaybe<Scalars['String']>;
  chrisAnchorSectionId?: InputMaybe<Scalars['Int']>;
  chrisGuardrailId?: InputMaybe<Scalars['Int']>;
  crossSectionPosition?: InputMaybe<Scalars['String']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  guardrailType?: InputMaybe<Scalars['String']>;
  highwayNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  installationDate?: InputMaybe<Scalars['Date']>;
  objectid?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `ImapGuardrail`. Fields that are set will be updated. */
export type ImapGuardrailPatch = {
  adminUnitCode?: InputMaybe<Scalars['String']>;
  chrisAnchorSectionId?: InputMaybe<Scalars['Int']>;
  chrisGuardrailId?: InputMaybe<Scalars['Int']>;
  crossSectionPosition?: InputMaybe<Scalars['String']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  guardrailType?: InputMaybe<Scalars['String']>;
  highwayNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  installationDate?: InputMaybe<Scalars['Date']>;
  objectid?: InputMaybe<Scalars['Int']>;
};

/** Methods to use when ordering `ImapGuardrail`. */
export enum ImapGuardrailsOrderBy {
  AdminUnitCodeAsc = 'ADMIN_UNIT_CODE_ASC',
  AdminUnitCodeDesc = 'ADMIN_UNIT_CODE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type ImapHighwayProfile = Node & {
  __typename?: 'ImapHighwayProfile';
  adminUnitCode?: Maybe<Scalars['String']>;
  chrisAnchorSectionId?: Maybe<Scalars['Int']>;
  chrisHighwayProfileId?: Maybe<Scalars['String']>;
  dividedHighwayInd?: Maybe<Scalars['Boolean']>;
  geom?: Maybe<GeometryMultiLineStringM>;
  highwayNumber?: Maybe<Scalars['String']>;
  highwayProfileId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  numberOfLanes?: Maybe<Scalars['Int']>;
  objectid?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `ImapHighwayProfile` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ImapHighwayProfileCondition = {
  /** Checks for equality with the object’s `geom` field. */
  geom?: InputMaybe<Scalars['GeoJSON']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `ImapHighwayProfile` object types. All fields are combined with a logical ‘and.’ */
export type ImapHighwayProfileFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ImapHighwayProfileFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ImapHighwayProfileFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ImapHighwayProfileFilter>>;
};

/** An input for mutations affecting `ImapHighwayProfile` */
export type ImapHighwayProfileInput = {
  adminUnitCode?: InputMaybe<Scalars['String']>;
  chrisAnchorSectionId?: InputMaybe<Scalars['Int']>;
  chrisHighwayProfileId?: InputMaybe<Scalars['String']>;
  dividedHighwayInd?: InputMaybe<Scalars['Boolean']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  highwayNumber?: InputMaybe<Scalars['String']>;
  highwayProfileId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  numberOfLanes?: InputMaybe<Scalars['Int']>;
  objectid?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `ImapHighwayProfile`. Fields that are set will be updated. */
export type ImapHighwayProfilePatch = {
  adminUnitCode?: InputMaybe<Scalars['String']>;
  chrisAnchorSectionId?: InputMaybe<Scalars['Int']>;
  chrisHighwayProfileId?: InputMaybe<Scalars['String']>;
  dividedHighwayInd?: InputMaybe<Scalars['Boolean']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  highwayNumber?: InputMaybe<Scalars['String']>;
  highwayProfileId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  numberOfLanes?: InputMaybe<Scalars['Int']>;
  objectid?: InputMaybe<Scalars['Int']>;
};

/** Methods to use when ordering `ImapHighwayProfile`. */
export enum ImapHighwayProfilesOrderBy {
  GeomAsc = 'GEOM_ASC',
  GeomDesc = 'GEOM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type ImapLandmark = Node & {
  __typename?: 'ImapLandmark';
  chrisLkiSegmentId?: Maybe<Scalars['Int']>;
  featureLengthM?: Maybe<Scalars['Float']>;
  geom?: Maybe<GeometryMultiLineStringM>;
  highwayNumber?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lkiRouteId?: Maybe<Scalars['String']>;
  lkiSegmentDescription?: Maybe<Scalars['String']>;
  lkiSegmentDirection?: Maybe<Scalars['String']>;
  lkiSegmentLength?: Maybe<Scalars['Float']>;
  lkiSegmentName?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  objectid?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `ImapLandmark` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ImapLandmarkCondition = {
  /** Checks for equality with the object’s `geom` field. */
  geom?: InputMaybe<Scalars['GeoJSON']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `ImapLandmark` object types. All fields are combined with a logical ‘and.’ */
export type ImapLandmarkFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ImapLandmarkFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ImapLandmarkFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ImapLandmarkFilter>>;
};

/** An input for mutations affecting `ImapLandmark` */
export type ImapLandmarkInput = {
  chrisLkiSegmentId?: InputMaybe<Scalars['Int']>;
  featureLengthM?: InputMaybe<Scalars['Float']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  highwayNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lkiRouteId?: InputMaybe<Scalars['String']>;
  lkiSegmentDescription?: InputMaybe<Scalars['String']>;
  lkiSegmentDirection?: InputMaybe<Scalars['String']>;
  lkiSegmentLength?: InputMaybe<Scalars['Float']>;
  lkiSegmentName?: InputMaybe<Scalars['String']>;
  objectid?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `ImapLandmark`. Fields that are set will be updated. */
export type ImapLandmarkPatch = {
  chrisLkiSegmentId?: InputMaybe<Scalars['Int']>;
  featureLengthM?: InputMaybe<Scalars['Float']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  highwayNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lkiRouteId?: InputMaybe<Scalars['String']>;
  lkiSegmentDescription?: InputMaybe<Scalars['String']>;
  lkiSegmentDirection?: InputMaybe<Scalars['String']>;
  lkiSegmentLength?: InputMaybe<Scalars['Float']>;
  lkiSegmentName?: InputMaybe<Scalars['String']>;
  objectid?: InputMaybe<Scalars['Int']>;
};

/** Methods to use when ordering `ImapLandmark`. */
export enum ImapLandmarksOrderBy {
  GeomAsc = 'GEOM_ASC',
  GeomDesc = 'GEOM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type ImapRestarea = Node & {
  __typename?: 'ImapRestarea';
  accelerationLaneInd?: Maybe<Scalars['Boolean']>;
  accessRestriction?: Maybe<Scalars['String']>;
  accomCommercialTrucksInd?: Maybe<Scalars['String']>;
  adminUnitCode?: Maybe<Scalars['String']>;
  adminUnitName?: Maybe<Scalars['String']>;
  associatedNumberedRoute?: Maybe<Scalars['String']>;
  chrisAnchorSectionId?: Maybe<Scalars['Int']>;
  chrisRestAreaId?: Maybe<Scalars['Int']>;
  closeDate?: Maybe<Scalars['String']>;
  crossSectionPosition?: Maybe<Scalars['String']>;
  decelerationLaneInd?: Maybe<Scalars['Boolean']>;
  directAccessInd?: Maybe<Scalars['Boolean']>;
  directionOfTraffic?: Maybe<Scalars['String']>;
  distanceFromMunicipality?: Maybe<Scalars['String']>;
  geom?: Maybe<GeometryPoint>;
  highwayNumber?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  numberOfBearProofBarrels?: Maybe<Scalars['Float']>;
  numberOfStandardBarrels?: Maybe<Scalars['Float']>;
  numberOfTables?: Maybe<Scalars['Float']>;
  numberOfToilets?: Maybe<Scalars['Float']>;
  objectid?: Maybe<Scalars['Int']>;
  openDate?: Maybe<Scalars['String']>;
  openYearRoundInd?: Maybe<Scalars['Boolean']>;
  powerResponsibility?: Maybe<Scalars['String']>;
  powerType?: Maybe<Scalars['String']>;
  restAreaClass?: Maybe<Scalars['String']>;
  restAreaId?: Maybe<Scalars['Int']>;
  restAreaName?: Maybe<Scalars['String']>;
  restAreaNumber?: Maybe<Scalars['String']>;
  rfiHighwayName?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `TcWork`. */
  tcWorksBySiteId: Array<TcWork>;
  toiletType?: Maybe<Scalars['String']>;
  wheelchairAccessToiletInd?: Maybe<Scalars['Boolean']>;
};


export type ImapRestareaTcWorksBySiteIdArgs = {
  condition?: InputMaybe<TcWorkCondition>;
  filter?: InputMaybe<TcWorkFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcWorksOrderBy>>;
};

/**
 * A condition to be used against `ImapRestarea` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ImapRestareaCondition = {
  /** Checks for equality with the object’s `adminUnitCode` field. */
  adminUnitCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `geom` field. */
  geom?: InputMaybe<Scalars['GeoJSON']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `ImapRestarea` object types. All fields are combined with a logical ‘and.’ */
export type ImapRestareaFilter = {
  /** Filter by the object’s `adminUnitCode` field. */
  adminUnitCode?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ImapRestareaFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ImapRestareaFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ImapRestareaFilter>>;
};

/** An input for mutations affecting `ImapRestarea` */
export type ImapRestareaInput = {
  accelerationLaneInd?: InputMaybe<Scalars['Boolean']>;
  accessRestriction?: InputMaybe<Scalars['String']>;
  accomCommercialTrucksInd?: InputMaybe<Scalars['String']>;
  adminUnitCode?: InputMaybe<Scalars['String']>;
  adminUnitName?: InputMaybe<Scalars['String']>;
  associatedNumberedRoute?: InputMaybe<Scalars['String']>;
  chrisAnchorSectionId?: InputMaybe<Scalars['Int']>;
  chrisRestAreaId?: InputMaybe<Scalars['Int']>;
  closeDate?: InputMaybe<Scalars['String']>;
  crossSectionPosition?: InputMaybe<Scalars['String']>;
  decelerationLaneInd?: InputMaybe<Scalars['Boolean']>;
  directAccessInd?: InputMaybe<Scalars['Boolean']>;
  directionOfTraffic?: InputMaybe<Scalars['String']>;
  distanceFromMunicipality?: InputMaybe<Scalars['String']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  highwayNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  numberOfBearProofBarrels?: InputMaybe<Scalars['Float']>;
  numberOfStandardBarrels?: InputMaybe<Scalars['Float']>;
  numberOfTables?: InputMaybe<Scalars['Float']>;
  numberOfToilets?: InputMaybe<Scalars['Float']>;
  objectid?: InputMaybe<Scalars['Int']>;
  openDate?: InputMaybe<Scalars['String']>;
  openYearRoundInd?: InputMaybe<Scalars['Boolean']>;
  powerResponsibility?: InputMaybe<Scalars['String']>;
  powerType?: InputMaybe<Scalars['String']>;
  restAreaClass?: InputMaybe<Scalars['String']>;
  restAreaId?: InputMaybe<Scalars['Int']>;
  restAreaName?: InputMaybe<Scalars['String']>;
  restAreaNumber?: InputMaybe<Scalars['String']>;
  rfiHighwayName?: InputMaybe<Scalars['String']>;
  toiletType?: InputMaybe<Scalars['String']>;
  wheelchairAccessToiletInd?: InputMaybe<Scalars['Boolean']>;
};

/** Represents an update to a `ImapRestarea`. Fields that are set will be updated. */
export type ImapRestareaPatch = {
  accelerationLaneInd?: InputMaybe<Scalars['Boolean']>;
  accessRestriction?: InputMaybe<Scalars['String']>;
  accomCommercialTrucksInd?: InputMaybe<Scalars['String']>;
  adminUnitCode?: InputMaybe<Scalars['String']>;
  adminUnitName?: InputMaybe<Scalars['String']>;
  associatedNumberedRoute?: InputMaybe<Scalars['String']>;
  chrisAnchorSectionId?: InputMaybe<Scalars['Int']>;
  chrisRestAreaId?: InputMaybe<Scalars['Int']>;
  closeDate?: InputMaybe<Scalars['String']>;
  crossSectionPosition?: InputMaybe<Scalars['String']>;
  decelerationLaneInd?: InputMaybe<Scalars['Boolean']>;
  directAccessInd?: InputMaybe<Scalars['Boolean']>;
  directionOfTraffic?: InputMaybe<Scalars['String']>;
  distanceFromMunicipality?: InputMaybe<Scalars['String']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  highwayNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  numberOfBearProofBarrels?: InputMaybe<Scalars['Float']>;
  numberOfStandardBarrels?: InputMaybe<Scalars['Float']>;
  numberOfTables?: InputMaybe<Scalars['Float']>;
  numberOfToilets?: InputMaybe<Scalars['Float']>;
  objectid?: InputMaybe<Scalars['Int']>;
  openDate?: InputMaybe<Scalars['String']>;
  openYearRoundInd?: InputMaybe<Scalars['Boolean']>;
  powerResponsibility?: InputMaybe<Scalars['String']>;
  powerType?: InputMaybe<Scalars['String']>;
  restAreaClass?: InputMaybe<Scalars['String']>;
  restAreaId?: InputMaybe<Scalars['Int']>;
  restAreaName?: InputMaybe<Scalars['String']>;
  restAreaNumber?: InputMaybe<Scalars['String']>;
  rfiHighwayName?: InputMaybe<Scalars['String']>;
  toiletType?: InputMaybe<Scalars['String']>;
  wheelchairAccessToiletInd?: InputMaybe<Scalars['Boolean']>;
};

/** Methods to use when ordering `ImapRestarea`. */
export enum ImapRestareasOrderBy {
  AdminUnitCodeAsc = 'ADMIN_UNIT_CODE_ASC',
  AdminUnitCodeDesc = 'ADMIN_UNIT_CODE_DESC',
  GeomAsc = 'GEOM_ASC',
  GeomDesc = 'GEOM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type ImapRetainingWall = Node & {
  __typename?: 'ImapRetainingWall';
  adminUnitCode?: Maybe<Scalars['String']>;
  chrisAnchorSectionId?: Maybe<Scalars['Int']>;
  chrisRetainingWallId?: Maybe<Scalars['Int']>;
  crossSectionPosition?: Maybe<Scalars['String']>;
  geom?: Maybe<GeometryLineStringM>;
  highwayNumber?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  installationDate?: Maybe<Scalars['Datetime']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  objectid?: Maybe<Scalars['Int']>;
  retainingWallId?: Maybe<Scalars['Int']>;
  retainingWallType?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `ImapRetainingWall` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ImapRetainingWallCondition = {
  /** Checks for equality with the object’s `adminUnitCode` field. */
  adminUnitCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `geom` field. */
  geom?: InputMaybe<Scalars['GeoJSON']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `ImapRetainingWall` object types. All fields are combined with a logical ‘and.’ */
export type ImapRetainingWallFilter = {
  /** Filter by the object’s `adminUnitCode` field. */
  adminUnitCode?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ImapRetainingWallFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ImapRetainingWallFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ImapRetainingWallFilter>>;
};

/** An input for mutations affecting `ImapRetainingWall` */
export type ImapRetainingWallInput = {
  adminUnitCode?: InputMaybe<Scalars['String']>;
  chrisAnchorSectionId?: InputMaybe<Scalars['Int']>;
  chrisRetainingWallId?: InputMaybe<Scalars['Int']>;
  crossSectionPosition?: InputMaybe<Scalars['String']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  highwayNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  installationDate?: InputMaybe<Scalars['Datetime']>;
  objectid?: InputMaybe<Scalars['Int']>;
  retainingWallId?: InputMaybe<Scalars['Int']>;
  retainingWallType?: InputMaybe<Scalars['String']>;
};

/** Represents an update to a `ImapRetainingWall`. Fields that are set will be updated. */
export type ImapRetainingWallPatch = {
  adminUnitCode?: InputMaybe<Scalars['String']>;
  chrisAnchorSectionId?: InputMaybe<Scalars['Int']>;
  chrisRetainingWallId?: InputMaybe<Scalars['Int']>;
  crossSectionPosition?: InputMaybe<Scalars['String']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  highwayNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  installationDate?: InputMaybe<Scalars['Datetime']>;
  objectid?: InputMaybe<Scalars['Int']>;
  retainingWallId?: InputMaybe<Scalars['Int']>;
  retainingWallType?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `ImapRetainingWall`. */
export enum ImapRetainingWallsOrderBy {
  AdminUnitCodeAsc = 'ADMIN_UNIT_CODE_ASC',
  AdminUnitCodeDesc = 'ADMIN_UNIT_CODE_DESC',
  GeomAsc = 'GEOM_ASC',
  GeomDesc = 'GEOM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type ImapRoadClass = Node & {
  __typename?: 'ImapRoadClass';
  adminUnitCode?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `AssetBreadcrumb`. */
  assetBreadcrumbsByRoadClassId: Array<AssetBreadcrumb>;
  bus?: Maybe<Scalars['Boolean']>;
  chrisAnchorSectionId?: Maybe<Scalars['Int']>;
  chrisHwyMaintenanceClassId?: Maybe<Scalars['Int']>;
  geom?: Maybe<GeometryLineStringM>;
  highwayNumber?: Maybe<Scalars['String']>;
  hwyMaintenanceClassId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** Reads a single `ImapRoadClassFeature` that is related to this `ImapRoadClass`. */
  imapRoadClassFeatureByRoadClassId?: Maybe<ImapRoadClassFeature>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  objectid?: Maybe<Scalars['Int']>;
  summerClass?: Maybe<Scalars['String']>;
  winterClass?: Maybe<Scalars['String']>;
};


export type ImapRoadClassAssetBreadcrumbsByRoadClassIdArgs = {
  condition?: InputMaybe<AssetBreadcrumbCondition>;
  filter?: InputMaybe<AssetBreadcrumbFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetBreadcrumbsOrderBy>>;
};

/**
 * A condition to be used against `ImapRoadClass` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ImapRoadClassCondition = {
  /** Checks for equality with the object’s `adminUnitCode` field. */
  adminUnitCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `geom` field. */
  geom?: InputMaybe<Scalars['GeoJSON']>;
  /** Checks for equality with the object’s `hwyMaintenanceClassId` field. */
  hwyMaintenanceClassId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

export type ImapRoadClassFeature = Node & {
  __typename?: 'ImapRoadClassFeature';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `ImapRoadClass` that is related to this `ImapRoadClassFeature`. */
  roadClass?: Maybe<ImapRoadClass>;
  roadClassId: Scalars['Int'];
  /** Reads a single `ImapRoadFeature` that is related to this `ImapRoadClassFeature`. */
  roadFeature?: Maybe<ImapRoadFeature>;
  roadFeatureId: Scalars['Int'];
};

/**
 * A condition to be used against `ImapRoadClassFeature` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ImapRoadClassFeatureCondition = {
  /** Checks for equality with the object’s `roadClassId` field. */
  roadClassId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `roadFeatureId` field. */
  roadFeatureId?: InputMaybe<Scalars['Int']>;
};

export type ImapRoadClassFeatureExpanded = {
  __typename?: 'ImapRoadClassFeatureExpanded';
  bus?: Maybe<Scalars['Boolean']>;
  geom?: Maybe<GeometryLineStringM>;
  highwayNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  rfiHighwayDescription?: Maybe<Scalars['String']>;
  rfiHighwayName?: Maybe<Scalars['String']>;
  serviceArea?: Maybe<Scalars['String']>;
  summerClass?: Maybe<Scalars['String']>;
  winterClass?: Maybe<Scalars['String']>;
};

/** Methods to use when ordering `ImapRoadClassFeatureExpanded`. */
export enum ImapRoadClassFeatureExpandedsOrderBy {
  Natural = 'NATURAL'
}

/** A filter to be used against `ImapRoadClassFeature` object types. All fields are combined with a logical ‘and.’ */
export type ImapRoadClassFeatureFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ImapRoadClassFeatureFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<ImapRoadClassFeatureFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ImapRoadClassFeatureFilter>>;
  /** Filter by the object’s `roadClassId` field. */
  roadClassId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `roadFeatureId` field. */
  roadFeatureId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `ImapRoadClassFeature` */
export type ImapRoadClassFeatureInput = {
  roadClassId: Scalars['Int'];
  roadFeatureId: Scalars['Int'];
};

/** Represents an update to a `ImapRoadClassFeature`. Fields that are set will be updated. */
export type ImapRoadClassFeaturePatch = {
  roadClassId?: InputMaybe<Scalars['Int']>;
  roadFeatureId?: InputMaybe<Scalars['Int']>;
};

/** Methods to use when ordering `ImapRoadClassFeature`. */
export enum ImapRoadClassFeaturesOrderBy {
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RoadClassIdAsc = 'ROAD_CLASS_ID_ASC',
  RoadClassIdDesc = 'ROAD_CLASS_ID_DESC',
  RoadFeatureIdAsc = 'ROAD_FEATURE_ID_ASC',
  RoadFeatureIdDesc = 'ROAD_FEATURE_ID_DESC'
}

/** A filter to be used against `ImapRoadClass` object types. All fields are combined with a logical ‘and.’ */
export type ImapRoadClassFilter = {
  /** Filter by the object’s `adminUnitCode` field. */
  adminUnitCode?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ImapRoadClassFilter>>;
  /** Filter by the object’s `hwyMaintenanceClassId` field. */
  hwyMaintenanceClassId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ImapRoadClassFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ImapRoadClassFilter>>;
};

/** An input for mutations affecting `ImapRoadClass` */
export type ImapRoadClassInput = {
  adminUnitCode?: InputMaybe<Scalars['String']>;
  bus?: InputMaybe<Scalars['Boolean']>;
  chrisAnchorSectionId?: InputMaybe<Scalars['Int']>;
  chrisHwyMaintenanceClassId?: InputMaybe<Scalars['Int']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  highwayNumber?: InputMaybe<Scalars['String']>;
  hwyMaintenanceClassId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  objectid?: InputMaybe<Scalars['Int']>;
  summerClass?: InputMaybe<Scalars['String']>;
  winterClass?: InputMaybe<Scalars['String']>;
};

/** Represents an update to a `ImapRoadClass`. Fields that are set will be updated. */
export type ImapRoadClassPatch = {
  adminUnitCode?: InputMaybe<Scalars['String']>;
  bus?: InputMaybe<Scalars['Boolean']>;
  chrisAnchorSectionId?: InputMaybe<Scalars['Int']>;
  chrisHwyMaintenanceClassId?: InputMaybe<Scalars['Int']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  highwayNumber?: InputMaybe<Scalars['String']>;
  hwyMaintenanceClassId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  objectid?: InputMaybe<Scalars['Int']>;
  summerClass?: InputMaybe<Scalars['String']>;
  winterClass?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `ImapRoadClass`. */
export enum ImapRoadClassesOrderBy {
  AdminUnitCodeAsc = 'ADMIN_UNIT_CODE_ASC',
  AdminUnitCodeDesc = 'ADMIN_UNIT_CODE_DESC',
  GeomAsc = 'GEOM_ASC',
  GeomDesc = 'GEOM_DESC',
  HwyMaintenanceClassIdAsc = 'HWY_MAINTENANCE_CLASS_ID_ASC',
  HwyMaintenanceClassIdDesc = 'HWY_MAINTENANCE_CLASS_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type ImapRoadFeature = Node & {
  __typename?: 'ImapRoadFeature';
  areaManagerArea?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `AssetBreadcrumb`. */
  assetBreadcrumbsByRoadFeatureId: Array<AssetBreadcrumb>;
  chrisRfiHighwayId?: Maybe<Scalars['Int']>;
  continuousGeom?: Maybe<GeometryLineStringM>;
  geom?: Maybe<GeometryMultiLineStringM>;
  highwayNumber?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** Reads and enables pagination through a set of `ImapRoadClassFeature`. */
  imapRoadClassFeaturesByRoadFeatureId: Array<ImapRoadClassFeature>;
  /** Reads and enables pagination through a set of `ImapRoadSurfaceFeature`. */
  imapRoadSurfaceFeaturesByRoadFeatureId: Array<ImapRoadSurfaceFeature>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  objectid?: Maybe<Scalars['Int']>;
  /** Reads and enables pagination through a set of `OpsBlockRoad`. */
  opsBlockRoadsByRoadFeatureId: Array<OpsBlockRoad>;
  rfiHighwayAlpha?: Maybe<Scalars['String']>;
  rfiHighwayDescription?: Maybe<Scalars['String']>;
  rfiHighwayDirection?: Maybe<Scalars['String']>;
  rfiHighwayLength?: Maybe<Scalars['Float']>;
  rfiHighwayName: Scalars['String'];
  rfiHighwayNumber?: Maybe<Scalars['String']>;
  rfiHighwayType?: Maybe<Scalars['String']>;
  roadFeatureInvntryId?: Maybe<Scalars['Int']>;
  serviceArea?: Maybe<Scalars['String']>;
  subArea?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `TcWork`. */
  tcWorksByRoadId: Array<TcWork>;
};


export type ImapRoadFeatureAssetBreadcrumbsByRoadFeatureIdArgs = {
  condition?: InputMaybe<AssetBreadcrumbCondition>;
  filter?: InputMaybe<AssetBreadcrumbFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetBreadcrumbsOrderBy>>;
};


export type ImapRoadFeatureImapRoadClassFeaturesByRoadFeatureIdArgs = {
  condition?: InputMaybe<ImapRoadClassFeatureCondition>;
  filter?: InputMaybe<ImapRoadClassFeatureFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImapRoadClassFeaturesOrderBy>>;
};


export type ImapRoadFeatureImapRoadSurfaceFeaturesByRoadFeatureIdArgs = {
  condition?: InputMaybe<ImapRoadSurfaceFeatureCondition>;
  filter?: InputMaybe<ImapRoadSurfaceFeatureFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImapRoadSurfaceFeaturesOrderBy>>;
};


export type ImapRoadFeatureOpsBlockRoadsByRoadFeatureIdArgs = {
  condition?: InputMaybe<OpsBlockRoadCondition>;
  filter?: InputMaybe<OpsBlockRoadFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsBlockRoadsOrderBy>>;
};


export type ImapRoadFeatureTcWorksByRoadIdArgs = {
  condition?: InputMaybe<TcWorkCondition>;
  filter?: InputMaybe<TcWorkFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcWorksOrderBy>>;
};

/**
 * A condition to be used against `ImapRoadFeature` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ImapRoadFeatureCondition = {
  /** Checks for equality with the object’s `geom` field. */
  geom?: InputMaybe<Scalars['GeoJSON']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `rfiHighwayName` field. */
  rfiHighwayName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `roadFeatureInvntryId` field. */
  roadFeatureInvntryId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `serviceArea` field. */
  serviceArea?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `ImapRoadFeature` object types. All fields are combined with a logical ‘and.’ */
export type ImapRoadFeatureFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ImapRoadFeatureFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ImapRoadFeatureFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ImapRoadFeatureFilter>>;
  /** Filter by the object’s `rfiHighwayName` field. */
  rfiHighwayName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `roadFeatureInvntryId` field. */
  roadFeatureInvntryId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `serviceArea` field. */
  serviceArea?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `ImapRoadFeature` */
export type ImapRoadFeatureInput = {
  areaManagerArea?: InputMaybe<Scalars['String']>;
  chrisRfiHighwayId?: InputMaybe<Scalars['Int']>;
  continuousGeom?: InputMaybe<Scalars['GeoJSON']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  highwayNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  objectid?: InputMaybe<Scalars['Int']>;
  rfiHighwayAlpha?: InputMaybe<Scalars['String']>;
  rfiHighwayDescription?: InputMaybe<Scalars['String']>;
  rfiHighwayDirection?: InputMaybe<Scalars['String']>;
  rfiHighwayLength?: InputMaybe<Scalars['Float']>;
  rfiHighwayName: Scalars['String'];
  rfiHighwayNumber?: InputMaybe<Scalars['String']>;
  rfiHighwayType?: InputMaybe<Scalars['String']>;
  roadFeatureInvntryId?: InputMaybe<Scalars['Int']>;
  serviceArea?: InputMaybe<Scalars['String']>;
  subArea?: InputMaybe<Scalars['String']>;
};

/** Represents an update to a `ImapRoadFeature`. Fields that are set will be updated. */
export type ImapRoadFeaturePatch = {
  areaManagerArea?: InputMaybe<Scalars['String']>;
  chrisRfiHighwayId?: InputMaybe<Scalars['Int']>;
  continuousGeom?: InputMaybe<Scalars['GeoJSON']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  highwayNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  objectid?: InputMaybe<Scalars['Int']>;
  rfiHighwayAlpha?: InputMaybe<Scalars['String']>;
  rfiHighwayDescription?: InputMaybe<Scalars['String']>;
  rfiHighwayDirection?: InputMaybe<Scalars['String']>;
  rfiHighwayLength?: InputMaybe<Scalars['Float']>;
  rfiHighwayName?: InputMaybe<Scalars['String']>;
  rfiHighwayNumber?: InputMaybe<Scalars['String']>;
  rfiHighwayType?: InputMaybe<Scalars['String']>;
  roadFeatureInvntryId?: InputMaybe<Scalars['Int']>;
  serviceArea?: InputMaybe<Scalars['String']>;
  subArea?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `ImapRoadFeature`. */
export enum ImapRoadFeaturesOrderBy {
  GeomAsc = 'GEOM_ASC',
  GeomDesc = 'GEOM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RfiHighwayNameAsc = 'RFI_HIGHWAY_NAME_ASC',
  RfiHighwayNameDesc = 'RFI_HIGHWAY_NAME_DESC',
  RoadFeatureInvntryIdAsc = 'ROAD_FEATURE_INVNTRY_ID_ASC',
  RoadFeatureInvntryIdDesc = 'ROAD_FEATURE_INVNTRY_ID_DESC',
  ServiceAreaAsc = 'SERVICE_AREA_ASC',
  ServiceAreaDesc = 'SERVICE_AREA_DESC'
}

export type ImapRoadStructure = Node & {
  __typename?: 'ImapRoadStructure';
  adminUnitCode?: Maybe<Scalars['String']>;
  bmisStructStatusTypeDesc?: Maybe<Scalars['String']>;
  bmisStructureName?: Maybe<Scalars['String']>;
  bmisStructureNumber?: Maybe<Scalars['String']>;
  bmisStructureRoadId?: Maybe<Scalars['String']>;
  bmisStructureType?: Maybe<Scalars['String']>;
  chrisAnchorSectionId?: Maybe<Scalars['Int']>;
  chrisHwyStructureRoadId?: Maybe<Scalars['Int']>;
  crossSectionPosition?: Maybe<Scalars['String']>;
  featureLengthM?: Maybe<Scalars['Float']>;
  geom?: Maybe<GeometryLineStringM>;
  highwayNumber?: Maybe<Scalars['String']>;
  hwyStructureClassId?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  objectid?: Maybe<Scalars['Int']>;
  rfiHighwayName?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `TcWork`. */
  tcWorksByStructureId: Array<TcWork>;
};


export type ImapRoadStructureTcWorksByStructureIdArgs = {
  condition?: InputMaybe<TcWorkCondition>;
  filter?: InputMaybe<TcWorkFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcWorksOrderBy>>;
};

/**
 * A condition to be used against `ImapRoadStructure` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ImapRoadStructureCondition = {
  /** Checks for equality with the object’s `adminUnitCode` field. */
  adminUnitCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `bmisStructStatusTypeDesc` field. */
  bmisStructStatusTypeDesc?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `geom` field. */
  geom?: InputMaybe<Scalars['GeoJSON']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `ImapRoadStructure` object types. All fields are combined with a logical ‘and.’ */
export type ImapRoadStructureFilter = {
  /** Filter by the object’s `adminUnitCode` field. */
  adminUnitCode?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ImapRoadStructureFilter>>;
  /** Filter by the object’s `bmisStructStatusTypeDesc` field. */
  bmisStructStatusTypeDesc?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ImapRoadStructureFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ImapRoadStructureFilter>>;
};

/** An input for mutations affecting `ImapRoadStructure` */
export type ImapRoadStructureInput = {
  adminUnitCode?: InputMaybe<Scalars['String']>;
  bmisStructStatusTypeDesc?: InputMaybe<Scalars['String']>;
  bmisStructureName?: InputMaybe<Scalars['String']>;
  bmisStructureNumber?: InputMaybe<Scalars['String']>;
  bmisStructureRoadId?: InputMaybe<Scalars['String']>;
  bmisStructureType?: InputMaybe<Scalars['String']>;
  chrisAnchorSectionId?: InputMaybe<Scalars['Int']>;
  chrisHwyStructureRoadId?: InputMaybe<Scalars['Int']>;
  crossSectionPosition?: InputMaybe<Scalars['String']>;
  featureLengthM?: InputMaybe<Scalars['Float']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  highwayNumber?: InputMaybe<Scalars['String']>;
  hwyStructureClassId?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  objectid?: InputMaybe<Scalars['Int']>;
  rfiHighwayName?: InputMaybe<Scalars['String']>;
};

/** Represents an update to a `ImapRoadStructure`. Fields that are set will be updated. */
export type ImapRoadStructurePatch = {
  adminUnitCode?: InputMaybe<Scalars['String']>;
  bmisStructStatusTypeDesc?: InputMaybe<Scalars['String']>;
  bmisStructureName?: InputMaybe<Scalars['String']>;
  bmisStructureNumber?: InputMaybe<Scalars['String']>;
  bmisStructureRoadId?: InputMaybe<Scalars['String']>;
  bmisStructureType?: InputMaybe<Scalars['String']>;
  chrisAnchorSectionId?: InputMaybe<Scalars['Int']>;
  chrisHwyStructureRoadId?: InputMaybe<Scalars['Int']>;
  crossSectionPosition?: InputMaybe<Scalars['String']>;
  featureLengthM?: InputMaybe<Scalars['Float']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  highwayNumber?: InputMaybe<Scalars['String']>;
  hwyStructureClassId?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  objectid?: InputMaybe<Scalars['Int']>;
  rfiHighwayName?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `ImapRoadStructure`. */
export enum ImapRoadStructuresOrderBy {
  AdminUnitCodeAsc = 'ADMIN_UNIT_CODE_ASC',
  AdminUnitCodeDesc = 'ADMIN_UNIT_CODE_DESC',
  BmisStructStatusTypeDescAsc = 'BMIS_STRUCT_STATUS_TYPE_DESC_ASC',
  BmisStructStatusTypeDescDesc = 'BMIS_STRUCT_STATUS_TYPE_DESC_DESC',
  GeomAsc = 'GEOM_ASC',
  GeomDesc = 'GEOM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type ImapRoadSurface = Node & {
  __typename?: 'ImapRoadSurface';
  adminUnitCode?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `AssetBreadcrumb`. */
  assetBreadcrumbsByRoadSurfaceId: Array<AssetBreadcrumb>;
  chrisAnchorSectionId?: Maybe<Scalars['Int']>;
  chrisRoadSurfaceTypeId?: Maybe<Scalars['Int']>;
  geom?: Maybe<GeometryLineStringM>;
  highwayNumber?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** Reads a single `ImapRoadSurfaceFeature` that is related to this `ImapRoadSurface`. */
  imapRoadSurfaceFeatureByRoadSurfaceId?: Maybe<ImapRoadSurfaceFeature>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  objectid?: Maybe<Scalars['Int']>;
  roadSurfaceTypeId?: Maybe<Scalars['Int']>;
  surfaceType?: Maybe<Scalars['String']>;
  yearHotInPlace?: Maybe<Scalars['Int']>;
  yearPaved?: Maybe<Scalars['Int']>;
  yearSurfaceTreated?: Maybe<Scalars['Int']>;
};


export type ImapRoadSurfaceAssetBreadcrumbsByRoadSurfaceIdArgs = {
  condition?: InputMaybe<AssetBreadcrumbCondition>;
  filter?: InputMaybe<AssetBreadcrumbFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetBreadcrumbsOrderBy>>;
};

/**
 * A condition to be used against `ImapRoadSurface` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ImapRoadSurfaceCondition = {
  /** Checks for equality with the object’s `adminUnitCode` field. */
  adminUnitCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `geom` field. */
  geom?: InputMaybe<Scalars['GeoJSON']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `roadSurfaceTypeId` field. */
  roadSurfaceTypeId?: InputMaybe<Scalars['Int']>;
};

export type ImapRoadSurfaceFeature = Node & {
  __typename?: 'ImapRoadSurfaceFeature';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `ImapRoadFeature` that is related to this `ImapRoadSurfaceFeature`. */
  roadFeature?: Maybe<ImapRoadFeature>;
  roadFeatureId: Scalars['Int'];
  /** Reads a single `ImapRoadSurface` that is related to this `ImapRoadSurfaceFeature`. */
  roadSurface?: Maybe<ImapRoadSurface>;
  roadSurfaceId: Scalars['Int'];
};

/**
 * A condition to be used against `ImapRoadSurfaceFeature` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ImapRoadSurfaceFeatureCondition = {
  /** Checks for equality with the object’s `roadFeatureId` field. */
  roadFeatureId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `roadSurfaceId` field. */
  roadSurfaceId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `ImapRoadSurfaceFeature` object types. All fields are combined with a logical ‘and.’ */
export type ImapRoadSurfaceFeatureFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ImapRoadSurfaceFeatureFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<ImapRoadSurfaceFeatureFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ImapRoadSurfaceFeatureFilter>>;
  /** Filter by the object’s `roadFeatureId` field. */
  roadFeatureId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `roadSurfaceId` field. */
  roadSurfaceId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `ImapRoadSurfaceFeature` */
export type ImapRoadSurfaceFeatureInput = {
  roadFeatureId: Scalars['Int'];
  roadSurfaceId: Scalars['Int'];
};

/** Represents an update to a `ImapRoadSurfaceFeature`. Fields that are set will be updated. */
export type ImapRoadSurfaceFeaturePatch = {
  roadFeatureId?: InputMaybe<Scalars['Int']>;
  roadSurfaceId?: InputMaybe<Scalars['Int']>;
};

/** Methods to use when ordering `ImapRoadSurfaceFeature`. */
export enum ImapRoadSurfaceFeaturesOrderBy {
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RoadFeatureIdAsc = 'ROAD_FEATURE_ID_ASC',
  RoadFeatureIdDesc = 'ROAD_FEATURE_ID_DESC',
  RoadSurfaceIdAsc = 'ROAD_SURFACE_ID_ASC',
  RoadSurfaceIdDesc = 'ROAD_SURFACE_ID_DESC'
}

/** A filter to be used against `ImapRoadSurface` object types. All fields are combined with a logical ‘and.’ */
export type ImapRoadSurfaceFilter = {
  /** Filter by the object’s `adminUnitCode` field. */
  adminUnitCode?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ImapRoadSurfaceFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ImapRoadSurfaceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ImapRoadSurfaceFilter>>;
  /** Filter by the object’s `roadSurfaceTypeId` field. */
  roadSurfaceTypeId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `ImapRoadSurface` */
export type ImapRoadSurfaceInput = {
  adminUnitCode?: InputMaybe<Scalars['String']>;
  chrisAnchorSectionId?: InputMaybe<Scalars['Int']>;
  chrisRoadSurfaceTypeId?: InputMaybe<Scalars['Int']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  highwayNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  objectid?: InputMaybe<Scalars['Int']>;
  roadSurfaceTypeId?: InputMaybe<Scalars['Int']>;
  surfaceType?: InputMaybe<Scalars['String']>;
  yearHotInPlace?: InputMaybe<Scalars['Int']>;
  yearPaved?: InputMaybe<Scalars['Int']>;
  yearSurfaceTreated?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `ImapRoadSurface`. Fields that are set will be updated. */
export type ImapRoadSurfacePatch = {
  adminUnitCode?: InputMaybe<Scalars['String']>;
  chrisAnchorSectionId?: InputMaybe<Scalars['Int']>;
  chrisRoadSurfaceTypeId?: InputMaybe<Scalars['Int']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  highwayNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  objectid?: InputMaybe<Scalars['Int']>;
  roadSurfaceTypeId?: InputMaybe<Scalars['Int']>;
  surfaceType?: InputMaybe<Scalars['String']>;
  yearHotInPlace?: InputMaybe<Scalars['Int']>;
  yearPaved?: InputMaybe<Scalars['Int']>;
  yearSurfaceTreated?: InputMaybe<Scalars['Int']>;
};

/** Methods to use when ordering `ImapRoadSurface`. */
export enum ImapRoadSurfacesOrderBy {
  AdminUnitCodeAsc = 'ADMIN_UNIT_CODE_ASC',
  AdminUnitCodeDesc = 'ADMIN_UNIT_CODE_DESC',
  GeomAsc = 'GEOM_ASC',
  GeomDesc = 'GEOM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RoadSurfaceTypeIdAsc = 'ROAD_SURFACE_TYPE_ID_ASC',
  RoadSurfaceTypeIdDesc = 'ROAD_SURFACE_TYPE_ID_DESC'
}

/** Methods to use when ordering `ImapSafety`. */
export enum ImapSafetiesOrderBy {
  AdminUnitCodeAsc = 'ADMIN_UNIT_CODE_ASC',
  AdminUnitCodeDesc = 'ADMIN_UNIT_CODE_DESC',
  GeomAsc = 'GEOM_ASC',
  GeomDesc = 'GEOM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type ImapSafety = Node & {
  __typename?: 'ImapSafety';
  adminUnitCode?: Maybe<Scalars['String']>;
  chrisAnchorSectionId?: Maybe<Scalars['Int']>;
  chrisLinearSafetyFeatureId?: Maybe<Scalars['Int']>;
  crossSectionPosition?: Maybe<Scalars['String']>;
  geom?: Maybe<GeometryLineStringM>;
  highwayNumber?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  installationDate?: Maybe<Scalars['Date']>;
  linearSafetyFeatureId?: Maybe<Scalars['Int']>;
  lsfMaterialType?: Maybe<Scalars['String']>;
  lsfType?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  objectid?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `ImapSafety` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ImapSafetyCondition = {
  /** Checks for equality with the object’s `adminUnitCode` field. */
  adminUnitCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `geom` field. */
  geom?: InputMaybe<Scalars['GeoJSON']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `ImapSafety` object types. All fields are combined with a logical ‘and.’ */
export type ImapSafetyFilter = {
  /** Filter by the object’s `adminUnitCode` field. */
  adminUnitCode?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ImapSafetyFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ImapSafetyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ImapSafetyFilter>>;
};

/** An input for mutations affecting `ImapSafety` */
export type ImapSafetyInput = {
  adminUnitCode?: InputMaybe<Scalars['String']>;
  chrisAnchorSectionId?: InputMaybe<Scalars['Int']>;
  chrisLinearSafetyFeatureId?: InputMaybe<Scalars['Int']>;
  crossSectionPosition?: InputMaybe<Scalars['String']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  highwayNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  installationDate?: InputMaybe<Scalars['Date']>;
  linearSafetyFeatureId?: InputMaybe<Scalars['Int']>;
  lsfMaterialType?: InputMaybe<Scalars['String']>;
  lsfType?: InputMaybe<Scalars['String']>;
  objectid?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `ImapSafety`. Fields that are set will be updated. */
export type ImapSafetyPatch = {
  adminUnitCode?: InputMaybe<Scalars['String']>;
  chrisAnchorSectionId?: InputMaybe<Scalars['Int']>;
  chrisLinearSafetyFeatureId?: InputMaybe<Scalars['Int']>;
  crossSectionPosition?: InputMaybe<Scalars['String']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  highwayNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  installationDate?: InputMaybe<Scalars['Date']>;
  linearSafetyFeatureId?: InputMaybe<Scalars['Int']>;
  lsfMaterialType?: InputMaybe<Scalars['String']>;
  lsfType?: InputMaybe<Scalars['String']>;
  objectid?: InputMaybe<Scalars['Int']>;
};

export type ImapSign = Node & {
  __typename?: 'ImapSign';
  adminUnitCode?: Maybe<Scalars['String']>;
  chrisAnchorSectionId?: Maybe<Scalars['Int']>;
  chrisSignId?: Maybe<Scalars['Int']>;
  crossSectionPosition?: Maybe<Scalars['String']>;
  directionFacing?: Maybe<Scalars['String']>;
  districtSignNumber?: Maybe<Scalars['String']>;
  geom?: Maybe<GeometryPoint>;
  highwayNumber?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  indLarge?: Maybe<Scalars['Boolean']>;
  installationDate?: Maybe<Scalars['Date']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  numberOfPosts?: Maybe<Scalars['Float']>;
  objectid?: Maybe<Scalars['Int']>;
  signCatalogDescription?: Maybe<Scalars['String']>;
  signId?: Maybe<Scalars['Int']>;
  signPostType?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `ImapSign` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ImapSignCondition = {
  /** Checks for equality with the object’s `adminUnitCode` field. */
  adminUnitCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `geom` field. */
  geom?: InputMaybe<Scalars['GeoJSON']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `ImapSign` object types. All fields are combined with a logical ‘and.’ */
export type ImapSignFilter = {
  /** Filter by the object’s `adminUnitCode` field. */
  adminUnitCode?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ImapSignFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ImapSignFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ImapSignFilter>>;
};

/** An input for mutations affecting `ImapSign` */
export type ImapSignInput = {
  adminUnitCode?: InputMaybe<Scalars['String']>;
  chrisAnchorSectionId?: InputMaybe<Scalars['Int']>;
  chrisSignId?: InputMaybe<Scalars['Int']>;
  crossSectionPosition?: InputMaybe<Scalars['String']>;
  directionFacing?: InputMaybe<Scalars['String']>;
  districtSignNumber?: InputMaybe<Scalars['String']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  highwayNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  indLarge?: InputMaybe<Scalars['Boolean']>;
  installationDate?: InputMaybe<Scalars['Date']>;
  numberOfPosts?: InputMaybe<Scalars['Float']>;
  objectid?: InputMaybe<Scalars['Int']>;
  signCatalogDescription?: InputMaybe<Scalars['String']>;
  signId?: InputMaybe<Scalars['Int']>;
  signPostType?: InputMaybe<Scalars['String']>;
};

/** Represents an update to a `ImapSign`. Fields that are set will be updated. */
export type ImapSignPatch = {
  adminUnitCode?: InputMaybe<Scalars['String']>;
  chrisAnchorSectionId?: InputMaybe<Scalars['Int']>;
  chrisSignId?: InputMaybe<Scalars['Int']>;
  crossSectionPosition?: InputMaybe<Scalars['String']>;
  directionFacing?: InputMaybe<Scalars['String']>;
  districtSignNumber?: InputMaybe<Scalars['String']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  highwayNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  indLarge?: InputMaybe<Scalars['Boolean']>;
  installationDate?: InputMaybe<Scalars['Date']>;
  numberOfPosts?: InputMaybe<Scalars['Float']>;
  objectid?: InputMaybe<Scalars['Int']>;
  signCatalogDescription?: InputMaybe<Scalars['String']>;
  signId?: InputMaybe<Scalars['Int']>;
  signPostType?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `ImapSign`. */
export enum ImapSignsOrderBy {
  AdminUnitCodeAsc = 'ADMIN_UNIT_CODE_ASC',
  AdminUnitCodeDesc = 'ADMIN_UNIT_CODE_DESC',
  GeomAsc = 'GEOM_ASC',
  GeomDesc = 'GEOM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type InputPoint = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type InputShiftWeek = {
  endTime: Scalars['String'];
  startTime: Scalars['String'];
  weekInterval: Scalars['Int'];
  weekOffset: Scalars['Int'];
  weekdays: Array<Scalars['Boolean']>;
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Int']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Int']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Int']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Int']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Int']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Int']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Int']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

/** An interval of time that has passed where the smallest distinct unit is a second. */
export type Interval = {
  __typename?: 'Interval';
  /** A quantity of days. */
  days?: Maybe<Scalars['Int']>;
  /** A quantity of hours. */
  hours?: Maybe<Scalars['Int']>;
  /** A quantity of minutes. */
  minutes?: Maybe<Scalars['Int']>;
  /** A quantity of months. */
  months?: Maybe<Scalars['Int']>;
  /**
   * A quantity of seconds. This is the only non-integer field, as all the other
   * fields will dump their overflow into a smaller unit of time. Intervals don’t
   * have a smaller unit than seconds.
   */
  seconds?: Maybe<Scalars['Float']>;
  /** A quantity of years. */
  years?: Maybe<Scalars['Int']>;
};

/** An interval of time that has passed where the smallest distinct unit is a second. */
export type IntervalInput = {
  /** A quantity of days. */
  days?: InputMaybe<Scalars['Int']>;
  /** A quantity of hours. */
  hours?: InputMaybe<Scalars['Int']>;
  /** A quantity of minutes. */
  minutes?: InputMaybe<Scalars['Int']>;
  /** A quantity of months. */
  months?: InputMaybe<Scalars['Int']>;
  /**
   * A quantity of seconds. This is the only non-integer field, as all the other
   * fields will dump their overflow into a smaller unit of time. Intervals don’t
   * have a smaller unit than seconds.
   */
  seconds?: InputMaybe<Scalars['Float']>;
  /** A quantity of years. */
  years?: InputMaybe<Scalars['Int']>;
};

export type InventoryItem = {
  __typename?: 'InventoryItem';
  bin?: Maybe<Scalars['String']>;
  dangerous?: Maybe<Scalars['Boolean']>;
  date_onhand?: Maybe<Scalars['DateTime']>;
  date_onorder?: Maybe<Scalars['DateTime']>;
  desc1?: Maybe<Scalars['String']>;
  desc2?: Maybe<Scalars['String']>;
  desc3?: Maybe<Scalars['String']>;
  desc4?: Maybe<Scalars['String']>;
  desc5?: Maybe<Scalars['String']>;
  item_number?: Maybe<Scalars['String']>;
  last_purchased?: Maybe<Scalars['DateTime']>;
  material_group?: Maybe<Scalars['String']>;
  oem?: Maybe<Scalars['String']>;
  oem_part?: Maybe<Scalars['String']>;
  qty_onhand?: Maybe<Scalars['Float']>;
  qty_onorder?: Maybe<Scalars['Float']>;
  seasonal?: Maybe<Scalars['Boolean']>;
  uom?: Maybe<Scalars['String']>;
  vendor1?: Maybe<Scalars['String']>;
  vendor1_part?: Maybe<Scalars['String']>;
  vendor2?: Maybe<Scalars['String']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  Contract: Scalars['ID'];
  DueDate: Scalars['DateTime'];
  InvDate: Scalars['DateTime'];
  InvoiceCode: Scalars['ID'];
  JBCo: Scalars['ID'];
  OriginalInvoiceCode: Scalars['ID'];
  items: Array<InvoiceItem>;
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  AmtBilled: Scalars['Float'];
  ItemCode: Scalars['ID'];
  OriginalItemCode: Scalars['ID'];
  UnitsBilled: Scalars['Float'];
};

/** All input for the `isDate` mutation. */
export type IsDateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  s?: InputMaybe<Scalars['String']>;
};

/** The output of our `isDate` mutation. */
export type IsDatePayload = {
  __typename?: 'IsDatePayload';
  boolean?: Maybe<Scalars['Boolean']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type Job = {
  __typename?: 'Job';
  companyCode: Scalars['ID'];
  contract?: Maybe<Contract>;
  contractCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  details: Array<JobDetail>;
  forecasts: Array<JobForecast>;
  jobCode: Scalars['ID'];
  openCommitments: Array<OpenCommitment>;
  originalContractCode?: Maybe<Scalars['String']>;
  originalJobCode: Scalars['ID'];
  phases: Array<Phase>;
};

export type JobDetail = {
  __typename?: 'JobDetail';
  actualCost: Scalars['Float'];
  actualDate: Scalars['String'];
  actualHours: Scalars['Float'];
  actualUnits: Scalars['Float'];
  costType: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  estCost: Scalars['Float'];
  estHours: Scalars['Float'];
  estUnits: Scalars['Float'];
  originalPhaseCode: Scalars['String'];
  phaseCode: Scalars['String'];
  um?: Maybe<Scalars['String']>;
};

export type JobForecast = {
  __typename?: 'JobForecast';
  author: Scalars['ID'];
  description: Scalars['String'];
  endDate: Scalars['String'];
  id: Scalars['ID'];
  permissions: Array<Scalars['String']>;
  phases: Array<JobForecastPhase>;
  startDate: Scalars['String'];
};

export type JobForecastPhase = {
  __typename?: 'JobForecastPhase';
  fc?: Maybe<Scalars['Float']>;
  fq?: Maybe<Scalars['Float']>;
  fu?: Maybe<Scalars['Float']>;
  phaseCode: Scalars['ID'];
  rc?: Maybe<Scalars['Float']>;
  rq?: Maybe<Scalars['Float']>;
  ru?: Maybe<Scalars['Float']>;
};

export type JobForecastPhaseInput = {
  fc?: InputMaybe<Scalars['Float']>;
  fq?: InputMaybe<Scalars['Float']>;
  fu?: InputMaybe<Scalars['Float']>;
  phaseCode: Scalars['ID'];
  rc?: InputMaybe<Scalars['Float']>;
  rq?: InputMaybe<Scalars['Float']>;
  ru?: InputMaybe<Scalars['Float']>;
};

export type LeaveInput = {
  earningCode?: InputMaybe<Scalars['ID']>;
  end: Scalars['DateTime'];
  holidayId?: InputMaybe<Scalars['ID']>;
  notes?: InputMaybe<Scalars['String']>;
  sick?: InputMaybe<Scalars['Boolean']>;
  start: Scalars['DateTime'];
  workerId: Scalars['ID'];
};

export type LocationRelationship = {
  __typename?: 'LocationRelationship';
  distance: Scalars['Float'];
  point: Point;
};

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['String']>;
  addTimecard?: Maybe<Scalars['ID']>;
  addVehicleService?: Maybe<AddVehicleServiceResponse>;
  approveTimecard: Scalars['Boolean'];
  clearDiagnostic: ClearDiagnosticResponse;
  /** Creates a single `Asset`. */
  createAsset?: Maybe<CreateAssetPayload>;
  /** Creates a single `AssetBreadcrumb`. */
  createAssetBreadcrumb?: Maybe<CreateAssetBreadcrumbPayload>;
  /** Creates a single `AssetCategory`. */
  createAssetCategory?: Maybe<CreateAssetCategoryPayload>;
  /** Creates a single `AssetType`. */
  createAssetType?: Maybe<CreateAssetTypePayload>;
  /** Creates a single `AssetWingUnderbody`. */
  createAssetWingUnderbody?: Maybe<CreateAssetWingUnderbodyPayload>;
  /** Creates a single `AuthSessionToken`. */
  createAuthSessionToken?: Maybe<CreateAuthSessionTokenPayload>;
  /** Creates a single `AuthUser`. */
  createAuthUser?: Maybe<CreateAuthUserPayload>;
  /** Creates a single `BecBiogeoclimatic`. */
  createBecBiogeoclimatic?: Maybe<CreateBecBiogeoclimaticPayload>;
  /** Creates a single `BiForecast`. */
  createBiForecast?: Maybe<CreateBiForecastPayload>;
  /** Creates a single `BiForecastPermission`. */
  createBiForecastPermission?: Maybe<CreateBiForecastPermissionPayload>;
  /** Creates a single `BiForecastPhase`. */
  createBiForecastPhase?: Maybe<CreateBiForecastPhasePayload>;
  /** Creates a single `Collision`. */
  createCollision?: Maybe<CreateCollisionPayload>;
  /** Creates a single `DtcFmi`. */
  createDtcFmi?: Maybe<CreateDtcFmiPayload>;
  /** Creates a single `DtcObd`. */
  createDtcObd?: Maybe<CreateDtcObdPayload>;
  /** Creates a single `DtcSpn`. */
  createDtcSpn?: Maybe<CreateDtcSpnPayload>;
  /** Creates a single `GisAtlasZone`. */
  createGisAtlasZone?: Maybe<CreateGisAtlasZonePayload>;
  /** Creates a single `ImapActiveStructure`. */
  createImapActiveStructure?: Maybe<CreateImapActiveStructurePayload>;
  /** Creates a single `ImapCulvert`. */
  createImapCulvert?: Maybe<CreateImapCulvertPayload>;
  /** Creates a single `ImapDrainage`. */
  createImapDrainage?: Maybe<CreateImapDrainagePayload>;
  /** Creates a single `ImapGuardrail`. */
  createImapGuardrail?: Maybe<CreateImapGuardrailPayload>;
  /** Creates a single `ImapHighwayProfile`. */
  createImapHighwayProfile?: Maybe<CreateImapHighwayProfilePayload>;
  /** Creates a single `ImapLandmark`. */
  createImapLandmark?: Maybe<CreateImapLandmarkPayload>;
  /** Creates a single `ImapRestarea`. */
  createImapRestarea?: Maybe<CreateImapRestareaPayload>;
  /** Creates a single `ImapRetainingWall`. */
  createImapRetainingWall?: Maybe<CreateImapRetainingWallPayload>;
  /** Creates a single `ImapRoadClass`. */
  createImapRoadClass?: Maybe<CreateImapRoadClassPayload>;
  /** Creates a single `ImapRoadClassFeature`. */
  createImapRoadClassFeature?: Maybe<CreateImapRoadClassFeaturePayload>;
  /** Creates a single `ImapRoadFeature`. */
  createImapRoadFeature?: Maybe<CreateImapRoadFeaturePayload>;
  /** Creates a single `ImapRoadStructure`. */
  createImapRoadStructure?: Maybe<CreateImapRoadStructurePayload>;
  /** Creates a single `ImapRoadSurface`. */
  createImapRoadSurface?: Maybe<CreateImapRoadSurfacePayload>;
  /** Creates a single `ImapRoadSurfaceFeature`. */
  createImapRoadSurfaceFeature?: Maybe<CreateImapRoadSurfaceFeaturePayload>;
  /** Creates a single `ImapSafety`. */
  createImapSafety?: Maybe<CreateImapSafetyPayload>;
  /** Creates a single `ImapSign`. */
  createImapSign?: Maybe<CreateImapSignPayload>;
  /** Creates a single `OpsActivity`. */
  createOpsActivity?: Maybe<CreateOpsActivityPayload>;
  /** Creates a single `OpsActivityRate`. */
  createOpsActivityRate?: Maybe<CreateOpsActivityRatePayload>;
  /** Creates a single `OpsBlock`. */
  createOpsBlock?: Maybe<CreateOpsBlockPayload>;
  /** Creates a single `OpsBlockRoad`. */
  createOpsBlockRoad?: Maybe<CreateOpsBlockRoadPayload>;
  /** Creates a single `OpsBlockType`. */
  createOpsBlockType?: Maybe<CreateOpsBlockTypePayload>;
  /** Creates a single `OpsGroup`. */
  createOpsGroup?: Maybe<CreateOpsGroupPayload>;
  /** Creates a single `OpsGroupType`. */
  createOpsGroupType?: Maybe<CreateOpsGroupTypePayload>;
  /** Creates a single `OpsMaterial`. */
  createOpsMaterial?: Maybe<CreateOpsMaterialPayload>;
  /** Creates a single `OpsMembershipCustom`. */
  createOpsMembershipCustom?: Maybe<CreateOpsMembershipCustomPayload>;
  /** Creates a single `OpsSchedule`. */
  createOpsSchedule?: Maybe<CreateOpsSchedulePayload>;
  /** Creates a single `OpsShiftPattern`. */
  createOpsShiftPattern?: Maybe<CreateOpsShiftPatternPayload>;
  /** Creates a single `OpsShiftWeek`. */
  createOpsShiftWeek?: Maybe<CreateOpsShiftWeekPayload>;
  /** Creates a single `OpsSupervisor`. */
  createOpsSupervisor?: Maybe<CreateOpsSupervisorPayload>;
  /** Creates a single `OpsYard`. */
  createOpsYard?: Maybe<CreateOpsYardPayload>;
  /** Creates a single `RwisForecast`. */
  createRwisForecast?: Maybe<CreateRwisForecastPayload>;
  /** Creates a single `RwisForecastTarget`. */
  createRwisForecastTarget?: Maybe<CreateRwisForecastTargetPayload>;
  /** Creates a single `RwisReading`. */
  createRwisReading?: Maybe<CreateRwisReadingPayload>;
  /** Creates a single `RwisStation`. */
  createRwisStation?: Maybe<CreateRwisStationPayload>;
  createSchedules: Array<IdSuccessResponse>;
  /** Creates a single `SdCompany`. */
  createSdCompany?: Maybe<CreateSdCompanyPayload>;
  /** Creates a single `SdForm`. */
  createSdForm?: Maybe<CreateSdFormPayload>;
  /** Creates a single `SdFormItem`. */
  createSdFormItem?: Maybe<CreateSdFormItemPayload>;
  /** Creates a single `SdFormType`. */
  createSdFormType?: Maybe<CreateSdFormTypePayload>;
  /** Creates a single `SdFormWorkerValue`. */
  createSdFormWorkerValue?: Maybe<CreateSdFormWorkerValuePayload>;
  /** Creates a single `SdLocation`. */
  createSdLocation?: Maybe<CreateSdLocationPayload>;
  /** Creates a single `SdSignature`. */
  createSdSignature?: Maybe<CreateSdSignaturePayload>;
  /** Creates a single `SdWorker`. */
  createSdWorker?: Maybe<CreateSdWorkerPayload>;
  createShiftPattern: IdSuccessResponse;
  /** Creates a single `TcCollectiveAgreement`. */
  createTcCollectiveAgreement?: Maybe<CreateTcCollectiveAgreementPayload>;
  /** Creates a single `TcCompany`. */
  createTcCompany?: Maybe<CreateTcCompanyPayload>;
  /** Creates a single `TcCompanyAgreement`. */
  createTcCompanyAgreement?: Maybe<CreateTcCompanyAgreementPayload>;
  /** Creates a single `TcEarningCodeRate`. */
  createTcEarningCodeRate?: Maybe<CreateTcEarningCodeRatePayload>;
  /** Creates a single `TcEquipment`. */
  createTcEquipment?: Maybe<CreateTcEquipmentPayload>;
  /** Creates a single `TcHoliday`. */
  createTcHoliday?: Maybe<CreateTcHolidayPayload>;
  /** Creates a single `TcHolidayAlternate`. */
  createTcHolidayAlternate?: Maybe<CreateTcHolidayAlternatePayload>;
  /** Creates a single `TcLeave`. */
  createTcLeave?: Maybe<CreateTcLeavePayload>;
  /** Creates a single `TcMaterial`. */
  createTcMaterial?: Maybe<CreateTcMaterialPayload>;
  /** Creates a single `TcRejection`. */
  createTcRejection?: Maybe<CreateTcRejectionPayload>;
  /** Creates a single `TcTimecard`. */
  createTcTimecard?: Maybe<CreateTcTimecardPayload>;
  /** Creates a single `TcVacationAllotment`. */
  createTcVacationAllotment?: Maybe<CreateTcVacationAllotmentPayload>;
  /** Creates a single `TcWork`. */
  createTcWork?: Maybe<CreateTcWorkPayload>;
  /** Creates a single `TcWorkLeave`. */
  createTcWorkLeave?: Maybe<CreateTcWorkLeavePayload>;
  /** Creates a single `TcWorkTest`. */
  createTcWorkTest?: Maybe<CreateTcWorkTestPayload>;
  /** Creates a single `TcWorkTime`. */
  createTcWorkTime?: Maybe<CreateTcWorkTimePayload>;
  /** Creates a single `Uom`. */
  createUom?: Maybe<CreateUomPayload>;
  createUsers: Array<Scalars['Int']>;
  /** Creates a single `VehicleDiagnostic`. */
  createVehicleDiagnostic?: Maybe<CreateVehicleDiagnosticPayload>;
  /** Creates a single `VehicleService`. */
  createVehicleService?: Maybe<CreateVehicleServicePayload>;
  /** Creates a single `VehicleStatus`. */
  createVehicleStatus?: Maybe<CreateVehicleStatusPayload>;
  /** Creates a single `VpJobOverride`. */
  createVpJobOverride?: Maybe<CreateVpJobOverridePayload>;
  /** Creates a single `VpPhaseOverride`. */
  createVpPhaseOverride?: Maybe<CreateVpPhaseOverridePayload>;
  /** Creates a single `WrRecordFull`. */
  createWrRecordFull?: Maybe<CreateWrRecordFullPayload>;
  /** Deletes a single `Asset` using a unique key. */
  deleteAsset?: Maybe<DeleteAssetPayload>;
  /** Deletes a single `AssetBreadcrumb` using a unique key. */
  deleteAssetBreadcrumb?: Maybe<DeleteAssetBreadcrumbPayload>;
  /** Deletes a single `AssetBreadcrumb` using a unique key. */
  deleteAssetBreadcrumbByAssetIdAndGeogAndDatetime?: Maybe<DeleteAssetBreadcrumbPayload>;
  /** Deletes a single `AssetBreadcrumb` using its globally unique id. */
  deleteAssetBreadcrumbByNodeId?: Maybe<DeleteAssetBreadcrumbPayload>;
  /** Deletes a single `Asset` using a unique key. */
  deleteAssetByAssetCode?: Maybe<DeleteAssetPayload>;
  /** Deletes a single `Asset` using its globally unique id. */
  deleteAssetByNodeId?: Maybe<DeleteAssetPayload>;
  /** Deletes a single `AssetCategory` using a unique key. */
  deleteAssetCategory?: Maybe<DeleteAssetCategoryPayload>;
  /** Deletes a single `AssetCategory` using its globally unique id. */
  deleteAssetCategoryByNodeId?: Maybe<DeleteAssetCategoryPayload>;
  /** Deletes a single `AssetType` using a unique key. */
  deleteAssetType?: Maybe<DeleteAssetTypePayload>;
  /** Deletes a single `AssetType` using a unique key. */
  deleteAssetTypeByDescription?: Maybe<DeleteAssetTypePayload>;
  /** Deletes a single `AssetType` using its globally unique id. */
  deleteAssetTypeByNodeId?: Maybe<DeleteAssetTypePayload>;
  /** Deletes a single `AssetWingUnderbody` using a unique key. */
  deleteAssetWingUnderbody?: Maybe<DeleteAssetWingUnderbodyPayload>;
  /** Deletes a single `AssetWingUnderbody` using its globally unique id. */
  deleteAssetWingUnderbodyByNodeId?: Maybe<DeleteAssetWingUnderbodyPayload>;
  /** Deletes a single `AuthSessionToken` using a unique key. */
  deleteAuthSessionToken?: Maybe<DeleteAuthSessionTokenPayload>;
  /** Deletes a single `AuthSessionToken` using its globally unique id. */
  deleteAuthSessionTokenByNodeId?: Maybe<DeleteAuthSessionTokenPayload>;
  /** Deletes a single `AuthUser` using a unique key. */
  deleteAuthUser?: Maybe<DeleteAuthUserPayload>;
  /** Deletes a single `AuthUser` using a unique key. */
  deleteAuthUserByEmail?: Maybe<DeleteAuthUserPayload>;
  /** Deletes a single `AuthUser` using a unique key. */
  deleteAuthUserByEmployeeNo?: Maybe<DeleteAuthUserPayload>;
  /** Deletes a single `AuthUser` using its globally unique id. */
  deleteAuthUserByNodeId?: Maybe<DeleteAuthUserPayload>;
  /** Deletes a single `BecBiogeoclimatic` using a unique key. */
  deleteBecBiogeoclimatic?: Maybe<DeleteBecBiogeoclimaticPayload>;
  /** Deletes a single `BecBiogeoclimatic` using its globally unique id. */
  deleteBecBiogeoclimaticByNodeId?: Maybe<DeleteBecBiogeoclimaticPayload>;
  /** Deletes a single `BiForecast` using a unique key. */
  deleteBiForecast?: Maybe<DeleteBiForecastPayload>;
  /** Deletes a single `BiForecast` using its globally unique id. */
  deleteBiForecastByNodeId?: Maybe<DeleteBiForecastPayload>;
  /** Deletes a single `BiForecastPermission` using a unique key. */
  deleteBiForecastPermission?: Maybe<DeleteBiForecastPermissionPayload>;
  /** Deletes a single `BiForecastPermission` using its globally unique id. */
  deleteBiForecastPermissionByNodeId?: Maybe<DeleteBiForecastPermissionPayload>;
  /** Deletes a single `BiForecastPhase` using a unique key. */
  deleteBiForecastPhase?: Maybe<DeleteBiForecastPhasePayload>;
  /** Deletes a single `BiForecastPhase` using its globally unique id. */
  deleteBiForecastPhaseByNodeId?: Maybe<DeleteBiForecastPhasePayload>;
  /** Deletes a single `Collision` using a unique key. */
  deleteCollision?: Maybe<DeleteCollisionPayload>;
  /** Deletes a single `Collision` using its globally unique id. */
  deleteCollisionByNodeId?: Maybe<DeleteCollisionPayload>;
  /** Deletes a single `DtcFmi` using a unique key. */
  deleteDtcFmi?: Maybe<DeleteDtcFmiPayload>;
  /** Deletes a single `DtcFmi` using a unique key. */
  deleteDtcFmiByDescription?: Maybe<DeleteDtcFmiPayload>;
  /** Deletes a single `DtcFmi` using its globally unique id. */
  deleteDtcFmiByNodeId?: Maybe<DeleteDtcFmiPayload>;
  /** Deletes a single `DtcObd` using a unique key. */
  deleteDtcObd?: Maybe<DeleteDtcObdPayload>;
  /** Deletes a single `DtcObd` using its globally unique id. */
  deleteDtcObdByNodeId?: Maybe<DeleteDtcObdPayload>;
  /** Deletes a single `DtcSpn` using a unique key. */
  deleteDtcSpn?: Maybe<DeleteDtcSpnPayload>;
  /** Deletes a single `DtcSpn` using its globally unique id. */
  deleteDtcSpnByNodeId?: Maybe<DeleteDtcSpnPayload>;
  /** Deletes a single `GisAtlasZone` using a unique key. */
  deleteGisAtlasZone?: Maybe<DeleteGisAtlasZonePayload>;
  /** Deletes a single `GisAtlasZone` using its globally unique id. */
  deleteGisAtlasZoneByNodeId?: Maybe<DeleteGisAtlasZonePayload>;
  /** Deletes a single `ImapCulvert` using a unique key. */
  deleteImapCulvert?: Maybe<DeleteImapCulvertPayload>;
  /** Deletes a single `ImapCulvert` using its globally unique id. */
  deleteImapCulvertByNodeId?: Maybe<DeleteImapCulvertPayload>;
  /** Deletes a single `ImapDrainage` using a unique key. */
  deleteImapDrainage?: Maybe<DeleteImapDrainagePayload>;
  /** Deletes a single `ImapDrainage` using its globally unique id. */
  deleteImapDrainageByNodeId?: Maybe<DeleteImapDrainagePayload>;
  /** Deletes a single `ImapGuardrail` using a unique key. */
  deleteImapGuardrail?: Maybe<DeleteImapGuardrailPayload>;
  /** Deletes a single `ImapGuardrail` using its globally unique id. */
  deleteImapGuardrailByNodeId?: Maybe<DeleteImapGuardrailPayload>;
  /** Deletes a single `ImapHighwayProfile` using a unique key. */
  deleteImapHighwayProfile?: Maybe<DeleteImapHighwayProfilePayload>;
  /** Deletes a single `ImapHighwayProfile` using its globally unique id. */
  deleteImapHighwayProfileByNodeId?: Maybe<DeleteImapHighwayProfilePayload>;
  /** Deletes a single `ImapLandmark` using a unique key. */
  deleteImapLandmark?: Maybe<DeleteImapLandmarkPayload>;
  /** Deletes a single `ImapLandmark` using its globally unique id. */
  deleteImapLandmarkByNodeId?: Maybe<DeleteImapLandmarkPayload>;
  /** Deletes a single `ImapRestarea` using a unique key. */
  deleteImapRestarea?: Maybe<DeleteImapRestareaPayload>;
  /** Deletes a single `ImapRestarea` using its globally unique id. */
  deleteImapRestareaByNodeId?: Maybe<DeleteImapRestareaPayload>;
  /** Deletes a single `ImapRetainingWall` using a unique key. */
  deleteImapRetainingWall?: Maybe<DeleteImapRetainingWallPayload>;
  /** Deletes a single `ImapRetainingWall` using its globally unique id. */
  deleteImapRetainingWallByNodeId?: Maybe<DeleteImapRetainingWallPayload>;
  /** Deletes a single `ImapRoadClass` using a unique key. */
  deleteImapRoadClass?: Maybe<DeleteImapRoadClassPayload>;
  /** Deletes a single `ImapRoadClass` using a unique key. */
  deleteImapRoadClassByHwyMaintenanceClassId?: Maybe<DeleteImapRoadClassPayload>;
  /** Deletes a single `ImapRoadClass` using its globally unique id. */
  deleteImapRoadClassByNodeId?: Maybe<DeleteImapRoadClassPayload>;
  /** Deletes a single `ImapRoadClassFeature` using a unique key. */
  deleteImapRoadClassFeature?: Maybe<DeleteImapRoadClassFeaturePayload>;
  /** Deletes a single `ImapRoadClassFeature` using its globally unique id. */
  deleteImapRoadClassFeatureByNodeId?: Maybe<DeleteImapRoadClassFeaturePayload>;
  /** Deletes a single `ImapRoadFeature` using a unique key. */
  deleteImapRoadFeature?: Maybe<DeleteImapRoadFeaturePayload>;
  /** Deletes a single `ImapRoadFeature` using its globally unique id. */
  deleteImapRoadFeatureByNodeId?: Maybe<DeleteImapRoadFeaturePayload>;
  /** Deletes a single `ImapRoadFeature` using a unique key. */
  deleteImapRoadFeatureByRfiHighwayName?: Maybe<DeleteImapRoadFeaturePayload>;
  /** Deletes a single `ImapRoadFeature` using a unique key. */
  deleteImapRoadFeatureByRoadFeatureInvntryId?: Maybe<DeleteImapRoadFeaturePayload>;
  /** Deletes a single `ImapRoadStructure` using a unique key. */
  deleteImapRoadStructure?: Maybe<DeleteImapRoadStructurePayload>;
  /** Deletes a single `ImapRoadStructure` using its globally unique id. */
  deleteImapRoadStructureByNodeId?: Maybe<DeleteImapRoadStructurePayload>;
  /** Deletes a single `ImapRoadSurface` using a unique key. */
  deleteImapRoadSurface?: Maybe<DeleteImapRoadSurfacePayload>;
  /** Deletes a single `ImapRoadSurface` using its globally unique id. */
  deleteImapRoadSurfaceByNodeId?: Maybe<DeleteImapRoadSurfacePayload>;
  /** Deletes a single `ImapRoadSurface` using a unique key. */
  deleteImapRoadSurfaceByRoadSurfaceTypeId?: Maybe<DeleteImapRoadSurfacePayload>;
  /** Deletes a single `ImapRoadSurfaceFeature` using a unique key. */
  deleteImapRoadSurfaceFeature?: Maybe<DeleteImapRoadSurfaceFeaturePayload>;
  /** Deletes a single `ImapRoadSurfaceFeature` using its globally unique id. */
  deleteImapRoadSurfaceFeatureByNodeId?: Maybe<DeleteImapRoadSurfaceFeaturePayload>;
  /** Deletes a single `ImapSafety` using a unique key. */
  deleteImapSafety?: Maybe<DeleteImapSafetyPayload>;
  /** Deletes a single `ImapSafety` using its globally unique id. */
  deleteImapSafetyByNodeId?: Maybe<DeleteImapSafetyPayload>;
  /** Deletes a single `ImapSign` using a unique key. */
  deleteImapSign?: Maybe<DeleteImapSignPayload>;
  /** Deletes a single `ImapSign` using its globally unique id. */
  deleteImapSignByNodeId?: Maybe<DeleteImapSignPayload>;
  deleteJobForecast: Scalars['Boolean'];
  /** Deletes a single `OpsActivity` using a unique key. */
  deleteOpsActivity?: Maybe<DeleteOpsActivityPayload>;
  /** Deletes a single `OpsActivity` using a unique key. */
  deleteOpsActivityByActivityCode?: Maybe<DeleteOpsActivityPayload>;
  /** Deletes a single `OpsActivity` using its globally unique id. */
  deleteOpsActivityByNodeId?: Maybe<DeleteOpsActivityPayload>;
  /** Deletes a single `OpsActivityRate` using a unique key. */
  deleteOpsActivityRate?: Maybe<DeleteOpsActivityRatePayload>;
  /** Deletes a single `OpsActivityRate` using a unique key. */
  deleteOpsActivityRateByActivityIdAndServiceAreaAndStartDate?: Maybe<DeleteOpsActivityRatePayload>;
  /** Deletes a single `OpsActivityRate` using its globally unique id. */
  deleteOpsActivityRateByNodeId?: Maybe<DeleteOpsActivityRatePayload>;
  /** Deletes a single `OpsBlock` using a unique key. */
  deleteOpsBlock?: Maybe<DeleteOpsBlockPayload>;
  /** Deletes a single `OpsBlock` using its globally unique id. */
  deleteOpsBlockByNodeId?: Maybe<DeleteOpsBlockPayload>;
  /** Deletes a single `OpsBlock` using a unique key. */
  deleteOpsBlockByServiceAreaAndBlockCode?: Maybe<DeleteOpsBlockPayload>;
  /** Deletes a single `OpsBlockRoad` using a unique key. */
  deleteOpsBlockRoad?: Maybe<DeleteOpsBlockRoadPayload>;
  /** Deletes a single `OpsBlockRoad` using its globally unique id. */
  deleteOpsBlockRoadByNodeId?: Maybe<DeleteOpsBlockRoadPayload>;
  /** Deletes a single `OpsBlockType` using a unique key. */
  deleteOpsBlockType?: Maybe<DeleteOpsBlockTypePayload>;
  /** Deletes a single `OpsBlockType` using a unique key. */
  deleteOpsBlockTypeByDescription?: Maybe<DeleteOpsBlockTypePayload>;
  /** Deletes a single `OpsBlockType` using its globally unique id. */
  deleteOpsBlockTypeByNodeId?: Maybe<DeleteOpsBlockTypePayload>;
  /** Deletes a single `OpsGroup` using a unique key. */
  deleteOpsGroup?: Maybe<DeleteOpsGroupPayload>;
  /** Deletes a single `OpsGroup` using a unique key. */
  deleteOpsGroupByDescription?: Maybe<DeleteOpsGroupPayload>;
  /** Deletes a single `OpsGroup` using its globally unique id. */
  deleteOpsGroupByNodeId?: Maybe<DeleteOpsGroupPayload>;
  /** Deletes a single `OpsGroupType` using a unique key. */
  deleteOpsGroupType?: Maybe<DeleteOpsGroupTypePayload>;
  /** Deletes a single `OpsGroupType` using a unique key. */
  deleteOpsGroupTypeByDescription?: Maybe<DeleteOpsGroupTypePayload>;
  /** Deletes a single `OpsGroupType` using its globally unique id. */
  deleteOpsGroupTypeByNodeId?: Maybe<DeleteOpsGroupTypePayload>;
  /** Deletes a single `OpsMaterial` using a unique key. */
  deleteOpsMaterial?: Maybe<DeleteOpsMaterialPayload>;
  /** Deletes a single `OpsMaterial` using a unique key. */
  deleteOpsMaterialByMaterialCode?: Maybe<DeleteOpsMaterialPayload>;
  /** Deletes a single `OpsMaterial` using its globally unique id. */
  deleteOpsMaterialByNodeId?: Maybe<DeleteOpsMaterialPayload>;
  /** Deletes a single `OpsMembershipCustom` using a unique key. */
  deleteOpsMembershipCustom?: Maybe<DeleteOpsMembershipCustomPayload>;
  /** Deletes a single `OpsMembershipCustom` using its globally unique id. */
  deleteOpsMembershipCustomByNodeId?: Maybe<DeleteOpsMembershipCustomPayload>;
  /** Deletes a single `OpsSchedule` using a unique key. */
  deleteOpsSchedule?: Maybe<DeleteOpsSchedulePayload>;
  /** Deletes a single `OpsSchedule` using its globally unique id. */
  deleteOpsScheduleByNodeId?: Maybe<DeleteOpsSchedulePayload>;
  /** Deletes a single `OpsShiftPattern` using a unique key. */
  deleteOpsShiftPattern?: Maybe<DeleteOpsShiftPatternPayload>;
  /** Deletes a single `OpsShiftPattern` using a unique key. */
  deleteOpsShiftPatternByDescription?: Maybe<DeleteOpsShiftPatternPayload>;
  /** Deletes a single `OpsShiftPattern` using its globally unique id. */
  deleteOpsShiftPatternByNodeId?: Maybe<DeleteOpsShiftPatternPayload>;
  /** Deletes a single `OpsShiftWeek` using a unique key. */
  deleteOpsShiftWeek?: Maybe<DeleteOpsShiftWeekPayload>;
  /** Deletes a single `OpsShiftWeek` using its globally unique id. */
  deleteOpsShiftWeekByNodeId?: Maybe<DeleteOpsShiftWeekPayload>;
  /** Deletes a single `OpsSupervisor` using a unique key. */
  deleteOpsSupervisor?: Maybe<DeleteOpsSupervisorPayload>;
  /** Deletes a single `OpsSupervisor` using its globally unique id. */
  deleteOpsSupervisorByNodeId?: Maybe<DeleteOpsSupervisorPayload>;
  /** Deletes a single `OpsYard` using a unique key. */
  deleteOpsYard?: Maybe<DeleteOpsYardPayload>;
  /** Deletes a single `OpsYard` using a unique key. */
  deleteOpsYardByDescription?: Maybe<DeleteOpsYardPayload>;
  /** Deletes a single `OpsYard` using its globally unique id. */
  deleteOpsYardByNodeId?: Maybe<DeleteOpsYardPayload>;
  /** Deletes a single `RwisForecast` using a unique key. */
  deleteRwisForecast?: Maybe<DeleteRwisForecastPayload>;
  /** Deletes a single `RwisForecast` using its globally unique id. */
  deleteRwisForecastByNodeId?: Maybe<DeleteRwisForecastPayload>;
  /** Deletes a single `RwisForecast` using a unique key. */
  deleteRwisForecastByStationIdAndIssueTime?: Maybe<DeleteRwisForecastPayload>;
  /** Deletes a single `RwisForecastTarget` using a unique key. */
  deleteRwisForecastTarget?: Maybe<DeleteRwisForecastTargetPayload>;
  /** Deletes a single `RwisForecastTarget` using its globally unique id. */
  deleteRwisForecastTargetByNodeId?: Maybe<DeleteRwisForecastTargetPayload>;
  /** Deletes a single `RwisReading` using a unique key. */
  deleteRwisReading?: Maybe<DeleteRwisReadingPayload>;
  /** Deletes a single `RwisReading` using its globally unique id. */
  deleteRwisReadingByNodeId?: Maybe<DeleteRwisReadingPayload>;
  /** Deletes a single `RwisStation` using a unique key. */
  deleteRwisStation?: Maybe<DeleteRwisStationPayload>;
  /** Deletes a single `RwisStation` using its globally unique id. */
  deleteRwisStationByNodeId?: Maybe<DeleteRwisStationPayload>;
  /** Deletes a single `RwisStation` using a unique key. */
  deleteRwisStationByStationName?: Maybe<DeleteRwisStationPayload>;
  deleteSchedules: Array<IdSuccessResponse>;
  /** Deletes a single `SdCompany` using a unique key. */
  deleteSdCompany?: Maybe<DeleteSdCompanyPayload>;
  /** Deletes a single `SdCompany` using a unique key. */
  deleteSdCompanyByDescription?: Maybe<DeleteSdCompanyPayload>;
  /** Deletes a single `SdCompany` using its globally unique id. */
  deleteSdCompanyByNodeId?: Maybe<DeleteSdCompanyPayload>;
  /** Deletes a single `SdForm` using a unique key. */
  deleteSdForm?: Maybe<DeleteSdFormPayload>;
  /** Deletes a single `SdForm` using its globally unique id. */
  deleteSdFormByNodeId?: Maybe<DeleteSdFormPayload>;
  /** Deletes a single `SdFormItem` using a unique key. */
  deleteSdFormItem?: Maybe<DeleteSdFormItemPayload>;
  /** Deletes a single `SdFormItem` using its globally unique id. */
  deleteSdFormItemByNodeId?: Maybe<DeleteSdFormItemPayload>;
  /** Deletes a single `SdFormType` using a unique key. */
  deleteSdFormType?: Maybe<DeleteSdFormTypePayload>;
  /** Deletes a single `SdFormType` using a unique key. */
  deleteSdFormTypeByDocumentTemplateId?: Maybe<DeleteSdFormTypePayload>;
  /** Deletes a single `SdFormType` using its globally unique id. */
  deleteSdFormTypeByNodeId?: Maybe<DeleteSdFormTypePayload>;
  /** Deletes a single `SdFormWorkerValue` using a unique key. */
  deleteSdFormWorkerValue?: Maybe<DeleteSdFormWorkerValuePayload>;
  /** Deletes a single `SdFormWorkerValue` using its globally unique id. */
  deleteSdFormWorkerValueByNodeId?: Maybe<DeleteSdFormWorkerValuePayload>;
  /** Deletes a single `SdLocation` using a unique key. */
  deleteSdLocation?: Maybe<DeleteSdLocationPayload>;
  /** Deletes a single `SdLocation` using its globally unique id. */
  deleteSdLocationByNodeId?: Maybe<DeleteSdLocationPayload>;
  /** Deletes a single `SdSignature` using a unique key. */
  deleteSdSignature?: Maybe<DeleteSdSignaturePayload>;
  /** Deletes a single `SdSignature` using its globally unique id. */
  deleteSdSignatureByNodeId?: Maybe<DeleteSdSignaturePayload>;
  /** Deletes a single `SdWorker` using a unique key. */
  deleteSdWorker?: Maybe<DeleteSdWorkerPayload>;
  /** Deletes a single `SdWorker` using its globally unique id. */
  deleteSdWorkerByNodeId?: Maybe<DeleteSdWorkerPayload>;
  deleteShiftPattern: SuccessResponse;
  /** Deletes a single `TcCollectiveAgreement` using a unique key. */
  deleteTcCollectiveAgreement?: Maybe<DeleteTcCollectiveAgreementPayload>;
  /** Deletes a single `TcCollectiveAgreement` using its globally unique id. */
  deleteTcCollectiveAgreementByNodeId?: Maybe<DeleteTcCollectiveAgreementPayload>;
  /** Deletes a single `TcCompany` using a unique key. */
  deleteTcCompany?: Maybe<DeleteTcCompanyPayload>;
  /** Deletes a single `TcCompanyAgreement` using a unique key. */
  deleteTcCompanyAgreement?: Maybe<DeleteTcCompanyAgreementPayload>;
  /** Deletes a single `TcCompanyAgreement` using its globally unique id. */
  deleteTcCompanyAgreementByNodeId?: Maybe<DeleteTcCompanyAgreementPayload>;
  /** Deletes a single `TcCompany` using its globally unique id. */
  deleteTcCompanyByNodeId?: Maybe<DeleteTcCompanyPayload>;
  /** Deletes a single `TcEarningCodeRate` using a unique key. */
  deleteTcEarningCodeRate?: Maybe<DeleteTcEarningCodeRatePayload>;
  /** Deletes a single `TcEarningCodeRate` using its globally unique id. */
  deleteTcEarningCodeRateByNodeId?: Maybe<DeleteTcEarningCodeRatePayload>;
  /** Deletes a single `TcEquipment` using a unique key. */
  deleteTcEquipment?: Maybe<DeleteTcEquipmentPayload>;
  /** Deletes a single `TcEquipment` using its globally unique id. */
  deleteTcEquipmentByNodeId?: Maybe<DeleteTcEquipmentPayload>;
  /** Deletes a single `TcHoliday` using a unique key. */
  deleteTcHoliday?: Maybe<DeleteTcHolidayPayload>;
  /** Deletes a single `TcHoliday` using its globally unique id. */
  deleteTcHolidayByNodeId?: Maybe<DeleteTcHolidayPayload>;
  /** Deletes a single `TcLeave` using a unique key. */
  deleteTcLeave?: Maybe<DeleteTcLeavePayload>;
  /** Deletes a single `TcLeave` using its globally unique id. */
  deleteTcLeaveByNodeId?: Maybe<DeleteTcLeavePayload>;
  /** Deletes a single `TcMaterial` using a unique key. */
  deleteTcMaterial?: Maybe<DeleteTcMaterialPayload>;
  /** Deletes a single `TcMaterial` using its globally unique id. */
  deleteTcMaterialByNodeId?: Maybe<DeleteTcMaterialPayload>;
  /** Deletes a single `TcRejection` using a unique key. */
  deleteTcRejection?: Maybe<DeleteTcRejectionPayload>;
  /** Deletes a single `TcRejection` using its globally unique id. */
  deleteTcRejectionByNodeId?: Maybe<DeleteTcRejectionPayload>;
  /** Deletes a single `TcTimecard` using a unique key. */
  deleteTcTimecard?: Maybe<DeleteTcTimecardPayload>;
  /** Deletes a single `TcTimecard` using its globally unique id. */
  deleteTcTimecardByNodeId?: Maybe<DeleteTcTimecardPayload>;
  /** Deletes a single `TcVacationAllotment` using a unique key. */
  deleteTcVacationAllotment?: Maybe<DeleteTcVacationAllotmentPayload>;
  /** Deletes a single `TcVacationAllotment` using its globally unique id. */
  deleteTcVacationAllotmentByNodeId?: Maybe<DeleteTcVacationAllotmentPayload>;
  /** Deletes a single `TcWork` using a unique key. */
  deleteTcWork?: Maybe<DeleteTcWorkPayload>;
  /** Deletes a single `TcWork` using its globally unique id. */
  deleteTcWorkByNodeId?: Maybe<DeleteTcWorkPayload>;
  /** Deletes a single `TcWorkLeave` using a unique key. */
  deleteTcWorkLeave?: Maybe<DeleteTcWorkLeavePayload>;
  /** Deletes a single `TcWorkLeave` using its globally unique id. */
  deleteTcWorkLeaveByNodeId?: Maybe<DeleteTcWorkLeavePayload>;
  /** Deletes a single `TcWorkTest` using a unique key. */
  deleteTcWorkTest?: Maybe<DeleteTcWorkTestPayload>;
  /** Deletes a single `TcWorkTest` using its globally unique id. */
  deleteTcWorkTestByNodeId?: Maybe<DeleteTcWorkTestPayload>;
  /** Deletes a single `TcWorkTime` using a unique key. */
  deleteTcWorkTime?: Maybe<DeleteTcWorkTimePayload>;
  /** Deletes a single `TcWorkTime` using its globally unique id. */
  deleteTcWorkTimeByNodeId?: Maybe<DeleteTcWorkTimePayload>;
  deleteTimecard: Scalars['Boolean'];
  /** Deletes a single `Uom` using a unique key. */
  deleteUom?: Maybe<DeleteUomPayload>;
  /** Deletes a single `Uom` using a unique key. */
  deleteUomByDescription?: Maybe<DeleteUomPayload>;
  /** Deletes a single `Uom` using a unique key. */
  deleteUomByMotiCode?: Maybe<DeleteUomPayload>;
  /** Deletes a single `Uom` using its globally unique id. */
  deleteUomByNodeId?: Maybe<DeleteUomPayload>;
  /** Deletes a single `Uom` using a unique key. */
  deleteUomByUomCode?: Maybe<DeleteUomPayload>;
  /** Deletes a single `VehicleDiagnostic` using a unique key. */
  deleteVehicleDiagnostic?: Maybe<DeleteVehicleDiagnosticPayload>;
  /** Deletes a single `VehicleDiagnostic` using a unique key. */
  deleteVehicleDiagnosticByAssetIdAndObdCodeAndDatetime?: Maybe<DeleteVehicleDiagnosticPayload>;
  /** Deletes a single `VehicleDiagnostic` using a unique key. */
  deleteVehicleDiagnosticByAssetIdAndSpnCodeAndFmiCodeAndDatetime?: Maybe<DeleteVehicleDiagnosticPayload>;
  /** Deletes a single `VehicleDiagnostic` using its globally unique id. */
  deleteVehicleDiagnosticByNodeId?: Maybe<DeleteVehicleDiagnosticPayload>;
  /** Deletes a single `VehicleService` using a unique key. */
  deleteVehicleService?: Maybe<DeleteVehicleServicePayload>;
  /** Deletes a single `VehicleService` using its globally unique id. */
  deleteVehicleServiceByNodeId?: Maybe<DeleteVehicleServicePayload>;
  /** Deletes a single `VehicleStatus` using a unique key. */
  deleteVehicleStatus?: Maybe<DeleteVehicleStatusPayload>;
  /** Deletes a single `VehicleStatus` using its globally unique id. */
  deleteVehicleStatusByNodeId?: Maybe<DeleteVehicleStatusPayload>;
  /** Deletes a single `VpJobOverride` using a unique key. */
  deleteVpJobOverride?: Maybe<DeleteVpJobOverridePayload>;
  /** Deletes a single `VpJobOverride` using its globally unique id. */
  deleteVpJobOverrideByNodeId?: Maybe<DeleteVpJobOverridePayload>;
  /** Deletes a single `VpPhaseOverride` using a unique key. */
  deleteVpPhaseOverride?: Maybe<DeleteVpPhaseOverridePayload>;
  /** Deletes a single `VpPhaseOverride` using its globally unique id. */
  deleteVpPhaseOverrideByNodeId?: Maybe<DeleteVpPhaseOverridePayload>;
  editTimecard?: Maybe<Scalars['ID']>;
  editUsers: Scalars['Boolean'];
  getImapRoadClass?: Maybe<GetImapRoadClassPayload>;
  getImapRoadFeature?: Maybe<GetImapRoadFeaturePayload>;
  getImapRoadSurface?: Maybe<GetImapRoadSurfacePayload>;
  getOpsYard?: Maybe<GetOpsYardPayload>;
  isDate?: Maybe<IsDatePayload>;
  rejectTimecard: Scalars['Boolean'];
  renameShiftPattern: SuccessResponse;
  requestLeave?: Maybe<Scalars['ID']>;
  resetPassword: Scalars['Boolean'];
  reviewLeave: Scalars['Boolean'];
  revokeLeave?: Maybe<Scalars['Boolean']>;
  saveJobForecast?: Maybe<Scalars['ID']>;
  saveRecord?: Maybe<RecordId>;
  setOtCompensationMethod?: Maybe<Scalars['Boolean']>;
  shareJobForecast: Scalars['Boolean'];
  tcWorkTimeCalc?: Maybe<TcWorkTimeCalcPayload>;
  /** Pre-calculate work ranges to speed up query performance. */
  tcWorkTimeRefresh?: Maybe<TcWorkTimeRefreshPayload>;
  updateAccomplishment: Array<Scalars['ID']>;
  /** Updates a single `Asset` using a unique key and a patch. */
  updateAsset?: Maybe<UpdateAssetPayload>;
  /** Updates a single `AssetBreadcrumb` using a unique key and a patch. */
  updateAssetBreadcrumb?: Maybe<UpdateAssetBreadcrumbPayload>;
  /** Updates a single `AssetBreadcrumb` using a unique key and a patch. */
  updateAssetBreadcrumbByAssetIdAndGeogAndDatetime?: Maybe<UpdateAssetBreadcrumbPayload>;
  /** Updates a single `AssetBreadcrumb` using its globally unique id and a patch. */
  updateAssetBreadcrumbByNodeId?: Maybe<UpdateAssetBreadcrumbPayload>;
  /** Updates a single `Asset` using a unique key and a patch. */
  updateAssetByAssetCode?: Maybe<UpdateAssetPayload>;
  /** Updates a single `Asset` using its globally unique id and a patch. */
  updateAssetByNodeId?: Maybe<UpdateAssetPayload>;
  /** Updates a single `AssetCategory` using a unique key and a patch. */
  updateAssetCategory?: Maybe<UpdateAssetCategoryPayload>;
  /** Updates a single `AssetCategory` using its globally unique id and a patch. */
  updateAssetCategoryByNodeId?: Maybe<UpdateAssetCategoryPayload>;
  /** Updates a single `AssetType` using a unique key and a patch. */
  updateAssetType?: Maybe<UpdateAssetTypePayload>;
  /** Updates a single `AssetType` using a unique key and a patch. */
  updateAssetTypeByDescription?: Maybe<UpdateAssetTypePayload>;
  /** Updates a single `AssetType` using its globally unique id and a patch. */
  updateAssetTypeByNodeId?: Maybe<UpdateAssetTypePayload>;
  /** Updates a single `AssetWingUnderbody` using a unique key and a patch. */
  updateAssetWingUnderbody?: Maybe<UpdateAssetWingUnderbodyPayload>;
  /** Updates a single `AssetWingUnderbody` using its globally unique id and a patch. */
  updateAssetWingUnderbodyByNodeId?: Maybe<UpdateAssetWingUnderbodyPayload>;
  /** Updates a single `AuthSessionToken` using a unique key and a patch. */
  updateAuthSessionToken?: Maybe<UpdateAuthSessionTokenPayload>;
  /** Updates a single `AuthSessionToken` using its globally unique id and a patch. */
  updateAuthSessionTokenByNodeId?: Maybe<UpdateAuthSessionTokenPayload>;
  /** Updates a single `AuthUser` using a unique key and a patch. */
  updateAuthUser?: Maybe<UpdateAuthUserPayload>;
  /** Updates a single `AuthUser` using a unique key and a patch. */
  updateAuthUserByEmail?: Maybe<UpdateAuthUserPayload>;
  /** Updates a single `AuthUser` using a unique key and a patch. */
  updateAuthUserByEmployeeNo?: Maybe<UpdateAuthUserPayload>;
  /** Updates a single `AuthUser` using its globally unique id and a patch. */
  updateAuthUserByNodeId?: Maybe<UpdateAuthUserPayload>;
  /** Updates a single `BecBiogeoclimatic` using a unique key and a patch. */
  updateBecBiogeoclimatic?: Maybe<UpdateBecBiogeoclimaticPayload>;
  /** Updates a single `BecBiogeoclimatic` using its globally unique id and a patch. */
  updateBecBiogeoclimaticByNodeId?: Maybe<UpdateBecBiogeoclimaticPayload>;
  /** Updates a single `BiForecast` using a unique key and a patch. */
  updateBiForecast?: Maybe<UpdateBiForecastPayload>;
  /** Updates a single `BiForecast` using its globally unique id and a patch. */
  updateBiForecastByNodeId?: Maybe<UpdateBiForecastPayload>;
  /** Updates a single `BiForecastPermission` using a unique key and a patch. */
  updateBiForecastPermission?: Maybe<UpdateBiForecastPermissionPayload>;
  /** Updates a single `BiForecastPermission` using its globally unique id and a patch. */
  updateBiForecastPermissionByNodeId?: Maybe<UpdateBiForecastPermissionPayload>;
  /** Updates a single `BiForecastPhase` using a unique key and a patch. */
  updateBiForecastPhase?: Maybe<UpdateBiForecastPhasePayload>;
  /** Updates a single `BiForecastPhase` using its globally unique id and a patch. */
  updateBiForecastPhaseByNodeId?: Maybe<UpdateBiForecastPhasePayload>;
  /** Updates a single `Collision` using a unique key and a patch. */
  updateCollision?: Maybe<UpdateCollisionPayload>;
  /** Updates a single `Collision` using its globally unique id and a patch. */
  updateCollisionByNodeId?: Maybe<UpdateCollisionPayload>;
  /** Updates a single `DtcFmi` using a unique key and a patch. */
  updateDtcFmi?: Maybe<UpdateDtcFmiPayload>;
  /** Updates a single `DtcFmi` using a unique key and a patch. */
  updateDtcFmiByDescription?: Maybe<UpdateDtcFmiPayload>;
  /** Updates a single `DtcFmi` using its globally unique id and a patch. */
  updateDtcFmiByNodeId?: Maybe<UpdateDtcFmiPayload>;
  /** Updates a single `DtcObd` using a unique key and a patch. */
  updateDtcObd?: Maybe<UpdateDtcObdPayload>;
  /** Updates a single `DtcObd` using its globally unique id and a patch. */
  updateDtcObdByNodeId?: Maybe<UpdateDtcObdPayload>;
  /** Updates a single `DtcSpn` using a unique key and a patch. */
  updateDtcSpn?: Maybe<UpdateDtcSpnPayload>;
  /** Updates a single `DtcSpn` using its globally unique id and a patch. */
  updateDtcSpnByNodeId?: Maybe<UpdateDtcSpnPayload>;
  /** Updates a single `GisAtlasZone` using a unique key and a patch. */
  updateGisAtlasZone?: Maybe<UpdateGisAtlasZonePayload>;
  /** Updates a single `GisAtlasZone` using its globally unique id and a patch. */
  updateGisAtlasZoneByNodeId?: Maybe<UpdateGisAtlasZonePayload>;
  /** Updates a single `ImapCulvert` using a unique key and a patch. */
  updateImapCulvert?: Maybe<UpdateImapCulvertPayload>;
  /** Updates a single `ImapCulvert` using its globally unique id and a patch. */
  updateImapCulvertByNodeId?: Maybe<UpdateImapCulvertPayload>;
  /** Updates a single `ImapDrainage` using a unique key and a patch. */
  updateImapDrainage?: Maybe<UpdateImapDrainagePayload>;
  /** Updates a single `ImapDrainage` using its globally unique id and a patch. */
  updateImapDrainageByNodeId?: Maybe<UpdateImapDrainagePayload>;
  /** Updates a single `ImapGuardrail` using a unique key and a patch. */
  updateImapGuardrail?: Maybe<UpdateImapGuardrailPayload>;
  /** Updates a single `ImapGuardrail` using its globally unique id and a patch. */
  updateImapGuardrailByNodeId?: Maybe<UpdateImapGuardrailPayload>;
  /** Updates a single `ImapHighwayProfile` using a unique key and a patch. */
  updateImapHighwayProfile?: Maybe<UpdateImapHighwayProfilePayload>;
  /** Updates a single `ImapHighwayProfile` using its globally unique id and a patch. */
  updateImapHighwayProfileByNodeId?: Maybe<UpdateImapHighwayProfilePayload>;
  /** Updates a single `ImapLandmark` using a unique key and a patch. */
  updateImapLandmark?: Maybe<UpdateImapLandmarkPayload>;
  /** Updates a single `ImapLandmark` using its globally unique id and a patch. */
  updateImapLandmarkByNodeId?: Maybe<UpdateImapLandmarkPayload>;
  /** Updates a single `ImapRestarea` using a unique key and a patch. */
  updateImapRestarea?: Maybe<UpdateImapRestareaPayload>;
  /** Updates a single `ImapRestarea` using its globally unique id and a patch. */
  updateImapRestareaByNodeId?: Maybe<UpdateImapRestareaPayload>;
  /** Updates a single `ImapRetainingWall` using a unique key and a patch. */
  updateImapRetainingWall?: Maybe<UpdateImapRetainingWallPayload>;
  /** Updates a single `ImapRetainingWall` using its globally unique id and a patch. */
  updateImapRetainingWallByNodeId?: Maybe<UpdateImapRetainingWallPayload>;
  /** Updates a single `ImapRoadClass` using a unique key and a patch. */
  updateImapRoadClass?: Maybe<UpdateImapRoadClassPayload>;
  /** Updates a single `ImapRoadClass` using a unique key and a patch. */
  updateImapRoadClassByHwyMaintenanceClassId?: Maybe<UpdateImapRoadClassPayload>;
  /** Updates a single `ImapRoadClass` using its globally unique id and a patch. */
  updateImapRoadClassByNodeId?: Maybe<UpdateImapRoadClassPayload>;
  /** Updates a single `ImapRoadClassFeature` using a unique key and a patch. */
  updateImapRoadClassFeature?: Maybe<UpdateImapRoadClassFeaturePayload>;
  /** Updates a single `ImapRoadClassFeature` using its globally unique id and a patch. */
  updateImapRoadClassFeatureByNodeId?: Maybe<UpdateImapRoadClassFeaturePayload>;
  /** Updates a single `ImapRoadFeature` using a unique key and a patch. */
  updateImapRoadFeature?: Maybe<UpdateImapRoadFeaturePayload>;
  /** Updates a single `ImapRoadFeature` using its globally unique id and a patch. */
  updateImapRoadFeatureByNodeId?: Maybe<UpdateImapRoadFeaturePayload>;
  /** Updates a single `ImapRoadFeature` using a unique key and a patch. */
  updateImapRoadFeatureByRfiHighwayName?: Maybe<UpdateImapRoadFeaturePayload>;
  /** Updates a single `ImapRoadFeature` using a unique key and a patch. */
  updateImapRoadFeatureByRoadFeatureInvntryId?: Maybe<UpdateImapRoadFeaturePayload>;
  /** Updates a single `ImapRoadStructure` using a unique key and a patch. */
  updateImapRoadStructure?: Maybe<UpdateImapRoadStructurePayload>;
  /** Updates a single `ImapRoadStructure` using its globally unique id and a patch. */
  updateImapRoadStructureByNodeId?: Maybe<UpdateImapRoadStructurePayload>;
  /** Updates a single `ImapRoadSurface` using a unique key and a patch. */
  updateImapRoadSurface?: Maybe<UpdateImapRoadSurfacePayload>;
  /** Updates a single `ImapRoadSurface` using its globally unique id and a patch. */
  updateImapRoadSurfaceByNodeId?: Maybe<UpdateImapRoadSurfacePayload>;
  /** Updates a single `ImapRoadSurface` using a unique key and a patch. */
  updateImapRoadSurfaceByRoadSurfaceTypeId?: Maybe<UpdateImapRoadSurfacePayload>;
  /** Updates a single `ImapRoadSurfaceFeature` using a unique key and a patch. */
  updateImapRoadSurfaceFeature?: Maybe<UpdateImapRoadSurfaceFeaturePayload>;
  /** Updates a single `ImapRoadSurfaceFeature` using its globally unique id and a patch. */
  updateImapRoadSurfaceFeatureByNodeId?: Maybe<UpdateImapRoadSurfaceFeaturePayload>;
  /** Updates a single `ImapSafety` using a unique key and a patch. */
  updateImapSafety?: Maybe<UpdateImapSafetyPayload>;
  /** Updates a single `ImapSafety` using its globally unique id and a patch. */
  updateImapSafetyByNodeId?: Maybe<UpdateImapSafetyPayload>;
  /** Updates a single `ImapSign` using a unique key and a patch. */
  updateImapSign?: Maybe<UpdateImapSignPayload>;
  /** Updates a single `ImapSign` using its globally unique id and a patch. */
  updateImapSignByNodeId?: Maybe<UpdateImapSignPayload>;
  /** Updates a single `OpsActivity` using a unique key and a patch. */
  updateOpsActivity?: Maybe<UpdateOpsActivityPayload>;
  /** Updates a single `OpsActivity` using a unique key and a patch. */
  updateOpsActivityByActivityCode?: Maybe<UpdateOpsActivityPayload>;
  /** Updates a single `OpsActivity` using its globally unique id and a patch. */
  updateOpsActivityByNodeId?: Maybe<UpdateOpsActivityPayload>;
  /** Updates a single `OpsActivityRate` using a unique key and a patch. */
  updateOpsActivityRate?: Maybe<UpdateOpsActivityRatePayload>;
  /** Updates a single `OpsActivityRate` using a unique key and a patch. */
  updateOpsActivityRateByActivityIdAndServiceAreaAndStartDate?: Maybe<UpdateOpsActivityRatePayload>;
  /** Updates a single `OpsActivityRate` using its globally unique id and a patch. */
  updateOpsActivityRateByNodeId?: Maybe<UpdateOpsActivityRatePayload>;
  /** Updates a single `OpsBlock` using a unique key and a patch. */
  updateOpsBlock?: Maybe<UpdateOpsBlockPayload>;
  /** Updates a single `OpsBlock` using its globally unique id and a patch. */
  updateOpsBlockByNodeId?: Maybe<UpdateOpsBlockPayload>;
  /** Updates a single `OpsBlock` using a unique key and a patch. */
  updateOpsBlockByServiceAreaAndBlockCode?: Maybe<UpdateOpsBlockPayload>;
  /** Updates a single `OpsBlockRoad` using a unique key and a patch. */
  updateOpsBlockRoad?: Maybe<UpdateOpsBlockRoadPayload>;
  /** Updates a single `OpsBlockRoad` using its globally unique id and a patch. */
  updateOpsBlockRoadByNodeId?: Maybe<UpdateOpsBlockRoadPayload>;
  /** Updates a single `OpsBlockType` using a unique key and a patch. */
  updateOpsBlockType?: Maybe<UpdateOpsBlockTypePayload>;
  /** Updates a single `OpsBlockType` using a unique key and a patch. */
  updateOpsBlockTypeByDescription?: Maybe<UpdateOpsBlockTypePayload>;
  /** Updates a single `OpsBlockType` using its globally unique id and a patch. */
  updateOpsBlockTypeByNodeId?: Maybe<UpdateOpsBlockTypePayload>;
  /** Updates a single `OpsGroup` using a unique key and a patch. */
  updateOpsGroup?: Maybe<UpdateOpsGroupPayload>;
  /** Updates a single `OpsGroup` using a unique key and a patch. */
  updateOpsGroupByDescription?: Maybe<UpdateOpsGroupPayload>;
  /** Updates a single `OpsGroup` using its globally unique id and a patch. */
  updateOpsGroupByNodeId?: Maybe<UpdateOpsGroupPayload>;
  /** Updates a single `OpsGroupType` using a unique key and a patch. */
  updateOpsGroupType?: Maybe<UpdateOpsGroupTypePayload>;
  /** Updates a single `OpsGroupType` using a unique key and a patch. */
  updateOpsGroupTypeByDescription?: Maybe<UpdateOpsGroupTypePayload>;
  /** Updates a single `OpsGroupType` using its globally unique id and a patch. */
  updateOpsGroupTypeByNodeId?: Maybe<UpdateOpsGroupTypePayload>;
  /** Updates a single `OpsMaterial` using a unique key and a patch. */
  updateOpsMaterial?: Maybe<UpdateOpsMaterialPayload>;
  /** Updates a single `OpsMaterial` using a unique key and a patch. */
  updateOpsMaterialByMaterialCode?: Maybe<UpdateOpsMaterialPayload>;
  /** Updates a single `OpsMaterial` using its globally unique id and a patch. */
  updateOpsMaterialByNodeId?: Maybe<UpdateOpsMaterialPayload>;
  /** Updates a single `OpsMembershipCustom` using a unique key and a patch. */
  updateOpsMembershipCustom?: Maybe<UpdateOpsMembershipCustomPayload>;
  /** Updates a single `OpsMembershipCustom` using its globally unique id and a patch. */
  updateOpsMembershipCustomByNodeId?: Maybe<UpdateOpsMembershipCustomPayload>;
  /** Updates a single `OpsSchedule` using a unique key and a patch. */
  updateOpsSchedule?: Maybe<UpdateOpsSchedulePayload>;
  /** Updates a single `OpsSchedule` using its globally unique id and a patch. */
  updateOpsScheduleByNodeId?: Maybe<UpdateOpsSchedulePayload>;
  /** Updates a single `OpsShiftPattern` using a unique key and a patch. */
  updateOpsShiftPattern?: Maybe<UpdateOpsShiftPatternPayload>;
  /** Updates a single `OpsShiftPattern` using a unique key and a patch. */
  updateOpsShiftPatternByDescription?: Maybe<UpdateOpsShiftPatternPayload>;
  /** Updates a single `OpsShiftPattern` using its globally unique id and a patch. */
  updateOpsShiftPatternByNodeId?: Maybe<UpdateOpsShiftPatternPayload>;
  /** Updates a single `OpsShiftWeek` using a unique key and a patch. */
  updateOpsShiftWeek?: Maybe<UpdateOpsShiftWeekPayload>;
  /** Updates a single `OpsShiftWeek` using its globally unique id and a patch. */
  updateOpsShiftWeekByNodeId?: Maybe<UpdateOpsShiftWeekPayload>;
  /** Updates a single `OpsSupervisor` using a unique key and a patch. */
  updateOpsSupervisor?: Maybe<UpdateOpsSupervisorPayload>;
  /** Updates a single `OpsSupervisor` using its globally unique id and a patch. */
  updateOpsSupervisorByNodeId?: Maybe<UpdateOpsSupervisorPayload>;
  /** Updates a single `OpsYard` using a unique key and a patch. */
  updateOpsYard?: Maybe<UpdateOpsYardPayload>;
  /** Updates a single `OpsYard` using a unique key and a patch. */
  updateOpsYardByDescription?: Maybe<UpdateOpsYardPayload>;
  /** Updates a single `OpsYard` using its globally unique id and a patch. */
  updateOpsYardByNodeId?: Maybe<UpdateOpsYardPayload>;
  /** Updates a single `RwisForecast` using a unique key and a patch. */
  updateRwisForecast?: Maybe<UpdateRwisForecastPayload>;
  /** Updates a single `RwisForecast` using its globally unique id and a patch. */
  updateRwisForecastByNodeId?: Maybe<UpdateRwisForecastPayload>;
  /** Updates a single `RwisForecast` using a unique key and a patch. */
  updateRwisForecastByStationIdAndIssueTime?: Maybe<UpdateRwisForecastPayload>;
  /** Updates a single `RwisForecastTarget` using a unique key and a patch. */
  updateRwisForecastTarget?: Maybe<UpdateRwisForecastTargetPayload>;
  /** Updates a single `RwisForecastTarget` using its globally unique id and a patch. */
  updateRwisForecastTargetByNodeId?: Maybe<UpdateRwisForecastTargetPayload>;
  /** Updates a single `RwisReading` using a unique key and a patch. */
  updateRwisReading?: Maybe<UpdateRwisReadingPayload>;
  /** Updates a single `RwisReading` using its globally unique id and a patch. */
  updateRwisReadingByNodeId?: Maybe<UpdateRwisReadingPayload>;
  /** Updates a single `RwisStation` using a unique key and a patch. */
  updateRwisStation?: Maybe<UpdateRwisStationPayload>;
  /** Updates a single `RwisStation` using its globally unique id and a patch. */
  updateRwisStationByNodeId?: Maybe<UpdateRwisStationPayload>;
  /** Updates a single `RwisStation` using a unique key and a patch. */
  updateRwisStationByStationName?: Maybe<UpdateRwisStationPayload>;
  /** Updates a single `SdCompany` using a unique key and a patch. */
  updateSdCompany?: Maybe<UpdateSdCompanyPayload>;
  /** Updates a single `SdCompany` using a unique key and a patch. */
  updateSdCompanyByDescription?: Maybe<UpdateSdCompanyPayload>;
  /** Updates a single `SdCompany` using its globally unique id and a patch. */
  updateSdCompanyByNodeId?: Maybe<UpdateSdCompanyPayload>;
  /** Updates a single `SdForm` using a unique key and a patch. */
  updateSdForm?: Maybe<UpdateSdFormPayload>;
  /** Updates a single `SdForm` using its globally unique id and a patch. */
  updateSdFormByNodeId?: Maybe<UpdateSdFormPayload>;
  /** Updates a single `SdFormItem` using a unique key and a patch. */
  updateSdFormItem?: Maybe<UpdateSdFormItemPayload>;
  /** Updates a single `SdFormItem` using its globally unique id and a patch. */
  updateSdFormItemByNodeId?: Maybe<UpdateSdFormItemPayload>;
  /** Updates a single `SdFormType` using a unique key and a patch. */
  updateSdFormType?: Maybe<UpdateSdFormTypePayload>;
  /** Updates a single `SdFormType` using a unique key and a patch. */
  updateSdFormTypeByDocumentTemplateId?: Maybe<UpdateSdFormTypePayload>;
  /** Updates a single `SdFormType` using its globally unique id and a patch. */
  updateSdFormTypeByNodeId?: Maybe<UpdateSdFormTypePayload>;
  /** Updates a single `SdFormWorkerValue` using a unique key and a patch. */
  updateSdFormWorkerValue?: Maybe<UpdateSdFormWorkerValuePayload>;
  /** Updates a single `SdFormWorkerValue` using its globally unique id and a patch. */
  updateSdFormWorkerValueByNodeId?: Maybe<UpdateSdFormWorkerValuePayload>;
  /** Updates a single `SdLocation` using a unique key and a patch. */
  updateSdLocation?: Maybe<UpdateSdLocationPayload>;
  /** Updates a single `SdLocation` using its globally unique id and a patch. */
  updateSdLocationByNodeId?: Maybe<UpdateSdLocationPayload>;
  /** Updates a single `SdSignature` using a unique key and a patch. */
  updateSdSignature?: Maybe<UpdateSdSignaturePayload>;
  /** Updates a single `SdSignature` using its globally unique id and a patch. */
  updateSdSignatureByNodeId?: Maybe<UpdateSdSignaturePayload>;
  /** Updates a single `SdWorker` using a unique key and a patch. */
  updateSdWorker?: Maybe<UpdateSdWorkerPayload>;
  /** Updates a single `SdWorker` using its globally unique id and a patch. */
  updateSdWorkerByNodeId?: Maybe<UpdateSdWorkerPayload>;
  /** Updates a single `TcCollectiveAgreement` using a unique key and a patch. */
  updateTcCollectiveAgreement?: Maybe<UpdateTcCollectiveAgreementPayload>;
  /** Updates a single `TcCollectiveAgreement` using its globally unique id and a patch. */
  updateTcCollectiveAgreementByNodeId?: Maybe<UpdateTcCollectiveAgreementPayload>;
  /** Updates a single `TcCompany` using a unique key and a patch. */
  updateTcCompany?: Maybe<UpdateTcCompanyPayload>;
  /** Updates a single `TcCompanyAgreement` using a unique key and a patch. */
  updateTcCompanyAgreement?: Maybe<UpdateTcCompanyAgreementPayload>;
  /** Updates a single `TcCompanyAgreement` using its globally unique id and a patch. */
  updateTcCompanyAgreementByNodeId?: Maybe<UpdateTcCompanyAgreementPayload>;
  /** Updates a single `TcCompany` using its globally unique id and a patch. */
  updateTcCompanyByNodeId?: Maybe<UpdateTcCompanyPayload>;
  /** Updates a single `TcEarningCodeRate` using a unique key and a patch. */
  updateTcEarningCodeRate?: Maybe<UpdateTcEarningCodeRatePayload>;
  /** Updates a single `TcEarningCodeRate` using its globally unique id and a patch. */
  updateTcEarningCodeRateByNodeId?: Maybe<UpdateTcEarningCodeRatePayload>;
  /** Updates a single `TcEquipment` using a unique key and a patch. */
  updateTcEquipment?: Maybe<UpdateTcEquipmentPayload>;
  /** Updates a single `TcEquipment` using its globally unique id and a patch. */
  updateTcEquipmentByNodeId?: Maybe<UpdateTcEquipmentPayload>;
  /** Updates a single `TcHoliday` using a unique key and a patch. */
  updateTcHoliday?: Maybe<UpdateTcHolidayPayload>;
  /** Updates a single `TcHoliday` using its globally unique id and a patch. */
  updateTcHolidayByNodeId?: Maybe<UpdateTcHolidayPayload>;
  /** Updates a single `TcLeave` using a unique key and a patch. */
  updateTcLeave?: Maybe<UpdateTcLeavePayload>;
  /** Updates a single `TcLeave` using its globally unique id and a patch. */
  updateTcLeaveByNodeId?: Maybe<UpdateTcLeavePayload>;
  /** Updates a single `TcMaterial` using a unique key and a patch. */
  updateTcMaterial?: Maybe<UpdateTcMaterialPayload>;
  /** Updates a single `TcMaterial` using its globally unique id and a patch. */
  updateTcMaterialByNodeId?: Maybe<UpdateTcMaterialPayload>;
  /** Updates a single `TcRejection` using a unique key and a patch. */
  updateTcRejection?: Maybe<UpdateTcRejectionPayload>;
  /** Updates a single `TcRejection` using its globally unique id and a patch. */
  updateTcRejectionByNodeId?: Maybe<UpdateTcRejectionPayload>;
  /** Updates a single `TcTimecard` using a unique key and a patch. */
  updateTcTimecard?: Maybe<UpdateTcTimecardPayload>;
  /** Updates a single `TcTimecard` using its globally unique id and a patch. */
  updateTcTimecardByNodeId?: Maybe<UpdateTcTimecardPayload>;
  /** Updates a single `TcVacationAllotment` using a unique key and a patch. */
  updateTcVacationAllotment?: Maybe<UpdateTcVacationAllotmentPayload>;
  /** Updates a single `TcVacationAllotment` using its globally unique id and a patch. */
  updateTcVacationAllotmentByNodeId?: Maybe<UpdateTcVacationAllotmentPayload>;
  /** Updates a single `TcWork` using a unique key and a patch. */
  updateTcWork?: Maybe<UpdateTcWorkPayload>;
  /** Updates a single `TcWork` using its globally unique id and a patch. */
  updateTcWorkByNodeId?: Maybe<UpdateTcWorkPayload>;
  /** Updates a single `TcWorkLeave` using a unique key and a patch. */
  updateTcWorkLeave?: Maybe<UpdateTcWorkLeavePayload>;
  /** Updates a single `TcWorkLeave` using its globally unique id and a patch. */
  updateTcWorkLeaveByNodeId?: Maybe<UpdateTcWorkLeavePayload>;
  /** Updates a single `TcWorkTest` using a unique key and a patch. */
  updateTcWorkTest?: Maybe<UpdateTcWorkTestPayload>;
  /** Updates a single `TcWorkTest` using its globally unique id and a patch. */
  updateTcWorkTestByNodeId?: Maybe<UpdateTcWorkTestPayload>;
  /** Updates a single `TcWorkTime` using a unique key and a patch. */
  updateTcWorkTime?: Maybe<UpdateTcWorkTimePayload>;
  /** Updates a single `TcWorkTime` using its globally unique id and a patch. */
  updateTcWorkTimeByNodeId?: Maybe<UpdateTcWorkTimePayload>;
  updateUnderbody?: Maybe<Scalars['ID']>;
  /** Updates a single `Uom` using a unique key and a patch. */
  updateUom?: Maybe<UpdateUomPayload>;
  /** Updates a single `Uom` using a unique key and a patch. */
  updateUomByDescription?: Maybe<UpdateUomPayload>;
  /** Updates a single `Uom` using a unique key and a patch. */
  updateUomByMotiCode?: Maybe<UpdateUomPayload>;
  /** Updates a single `Uom` using its globally unique id and a patch. */
  updateUomByNodeId?: Maybe<UpdateUomPayload>;
  /** Updates a single `Uom` using a unique key and a patch. */
  updateUomByUomCode?: Maybe<UpdateUomPayload>;
  /** Updates a single `VehicleDiagnostic` using a unique key and a patch. */
  updateVehicleDiagnostic?: Maybe<UpdateVehicleDiagnosticPayload>;
  /** Updates a single `VehicleDiagnostic` using a unique key and a patch. */
  updateVehicleDiagnosticByAssetIdAndObdCodeAndDatetime?: Maybe<UpdateVehicleDiagnosticPayload>;
  /** Updates a single `VehicleDiagnostic` using a unique key and a patch. */
  updateVehicleDiagnosticByAssetIdAndSpnCodeAndFmiCodeAndDatetime?: Maybe<UpdateVehicleDiagnosticPayload>;
  /** Updates a single `VehicleDiagnostic` using its globally unique id and a patch. */
  updateVehicleDiagnosticByNodeId?: Maybe<UpdateVehicleDiagnosticPayload>;
  /** Updates a single `VehicleService` using a unique key and a patch. */
  updateVehicleService?: Maybe<UpdateVehicleServicePayload>;
  /** Updates a single `VehicleService` using its globally unique id and a patch. */
  updateVehicleServiceByNodeId?: Maybe<UpdateVehicleServicePayload>;
  /** Updates a single `VehicleStatus` using a unique key and a patch. */
  updateVehicleStatus?: Maybe<UpdateVehicleStatusPayload>;
  /** Updates a single `VehicleStatus` using its globally unique id and a patch. */
  updateVehicleStatusByNodeId?: Maybe<UpdateVehicleStatusPayload>;
  /** Updates a single `VpJobOverride` using a unique key and a patch. */
  updateVpJobOverride?: Maybe<UpdateVpJobOverridePayload>;
  /** Updates a single `VpJobOverride` using its globally unique id and a patch. */
  updateVpJobOverrideByNodeId?: Maybe<UpdateVpJobOverridePayload>;
  /** Updates a single `VpPhaseOverride` using a unique key and a patch. */
  updateVpPhaseOverride?: Maybe<UpdateVpPhaseOverridePayload>;
  /** Updates a single `VpPhaseOverride` using its globally unique id and a patch. */
  updateVpPhaseOverrideByNodeId?: Maybe<UpdateVpPhaseOverridePayload>;
  upsertOpsGroup: UpsertOpsGroupResponse;
  viewpointLogin?: Maybe<Scalars['String']>;
  viewpointLogout: Scalars['Boolean'];
};


export type MutationAddTimecardArgs = {
  timecard: TimecardInput;
};


export type MutationAddVehicleServiceArgs = {
  service: VehicleServiceProps;
};


export type MutationApproveTimecardArgs = {
  id: Scalars['ID'];
  scheduleComment?: InputMaybe<Scalars['String']>;
  scheduledEnd?: InputMaybe<Scalars['DateTime']>;
  scheduledStart?: InputMaybe<Scalars['DateTime']>;
};


export type MutationClearDiagnosticArgs = {
  asset_code: Scalars['String'];
  code: DiagnosticCode;
  datetime: Scalars['DateTime'];
};


export type MutationCreateAssetArgs = {
  input: CreateAssetInput;
};


export type MutationCreateAssetBreadcrumbArgs = {
  input: CreateAssetBreadcrumbInput;
};


export type MutationCreateAssetCategoryArgs = {
  input: CreateAssetCategoryInput;
};


export type MutationCreateAssetTypeArgs = {
  input: CreateAssetTypeInput;
};


export type MutationCreateAssetWingUnderbodyArgs = {
  input: CreateAssetWingUnderbodyInput;
};


export type MutationCreateAuthSessionTokenArgs = {
  input: CreateAuthSessionTokenInput;
};


export type MutationCreateAuthUserArgs = {
  input: CreateAuthUserInput;
};


export type MutationCreateBecBiogeoclimaticArgs = {
  input: CreateBecBiogeoclimaticInput;
};


export type MutationCreateBiForecastArgs = {
  input: CreateBiForecastInput;
};


export type MutationCreateBiForecastPermissionArgs = {
  input: CreateBiForecastPermissionInput;
};


export type MutationCreateBiForecastPhaseArgs = {
  input: CreateBiForecastPhaseInput;
};


export type MutationCreateCollisionArgs = {
  input: CreateCollisionInput;
};


export type MutationCreateDtcFmiArgs = {
  input: CreateDtcFmiInput;
};


export type MutationCreateDtcObdArgs = {
  input: CreateDtcObdInput;
};


export type MutationCreateDtcSpnArgs = {
  input: CreateDtcSpnInput;
};


export type MutationCreateGisAtlasZoneArgs = {
  input: CreateGisAtlasZoneInput;
};


export type MutationCreateImapActiveStructureArgs = {
  input: CreateImapActiveStructureInput;
};


export type MutationCreateImapCulvertArgs = {
  input: CreateImapCulvertInput;
};


export type MutationCreateImapDrainageArgs = {
  input: CreateImapDrainageInput;
};


export type MutationCreateImapGuardrailArgs = {
  input: CreateImapGuardrailInput;
};


export type MutationCreateImapHighwayProfileArgs = {
  input: CreateImapHighwayProfileInput;
};


export type MutationCreateImapLandmarkArgs = {
  input: CreateImapLandmarkInput;
};


export type MutationCreateImapRestareaArgs = {
  input: CreateImapRestareaInput;
};


export type MutationCreateImapRetainingWallArgs = {
  input: CreateImapRetainingWallInput;
};


export type MutationCreateImapRoadClassArgs = {
  input: CreateImapRoadClassInput;
};


export type MutationCreateImapRoadClassFeatureArgs = {
  input: CreateImapRoadClassFeatureInput;
};


export type MutationCreateImapRoadFeatureArgs = {
  input: CreateImapRoadFeatureInput;
};


export type MutationCreateImapRoadStructureArgs = {
  input: CreateImapRoadStructureInput;
};


export type MutationCreateImapRoadSurfaceArgs = {
  input: CreateImapRoadSurfaceInput;
};


export type MutationCreateImapRoadSurfaceFeatureArgs = {
  input: CreateImapRoadSurfaceFeatureInput;
};


export type MutationCreateImapSafetyArgs = {
  input: CreateImapSafetyInput;
};


export type MutationCreateImapSignArgs = {
  input: CreateImapSignInput;
};


export type MutationCreateOpsActivityArgs = {
  input: CreateOpsActivityInput;
};


export type MutationCreateOpsActivityRateArgs = {
  input: CreateOpsActivityRateInput;
};


export type MutationCreateOpsBlockArgs = {
  input: CreateOpsBlockInput;
};


export type MutationCreateOpsBlockRoadArgs = {
  input: CreateOpsBlockRoadInput;
};


export type MutationCreateOpsBlockTypeArgs = {
  input: CreateOpsBlockTypeInput;
};


export type MutationCreateOpsGroupArgs = {
  input: CreateOpsGroupInput;
};


export type MutationCreateOpsGroupTypeArgs = {
  input: CreateOpsGroupTypeInput;
};


export type MutationCreateOpsMaterialArgs = {
  input: CreateOpsMaterialInput;
};


export type MutationCreateOpsMembershipCustomArgs = {
  input: CreateOpsMembershipCustomInput;
};


export type MutationCreateOpsScheduleArgs = {
  input: CreateOpsScheduleInput;
};


export type MutationCreateOpsShiftPatternArgs = {
  input: CreateOpsShiftPatternInput;
};


export type MutationCreateOpsShiftWeekArgs = {
  input: CreateOpsShiftWeekInput;
};


export type MutationCreateOpsSupervisorArgs = {
  input: CreateOpsSupervisorInput;
};


export type MutationCreateOpsYardArgs = {
  input: CreateOpsYardInput;
};


export type MutationCreateRwisForecastArgs = {
  input: CreateRwisForecastInput;
};


export type MutationCreateRwisForecastTargetArgs = {
  input: CreateRwisForecastTargetInput;
};


export type MutationCreateRwisReadingArgs = {
  input: CreateRwisReadingInput;
};


export type MutationCreateRwisStationArgs = {
  input: CreateRwisStationInput;
};


export type MutationCreateSchedulesArgs = {
  effective: Scalars['DateTime'];
  shiftPatternId: Scalars['Int'];
  userIds: Array<Scalars['Int']>;
  weekOffset?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateSdCompanyArgs = {
  input: CreateSdCompanyInput;
};


export type MutationCreateSdFormArgs = {
  input: CreateSdFormInput;
};


export type MutationCreateSdFormItemArgs = {
  input: CreateSdFormItemInput;
};


export type MutationCreateSdFormTypeArgs = {
  input: CreateSdFormTypeInput;
};


export type MutationCreateSdFormWorkerValueArgs = {
  input: CreateSdFormWorkerValueInput;
};


export type MutationCreateSdLocationArgs = {
  input: CreateSdLocationInput;
};


export type MutationCreateSdSignatureArgs = {
  input: CreateSdSignatureInput;
};


export type MutationCreateSdWorkerArgs = {
  input: CreateSdWorkerInput;
};


export type MutationCreateShiftPatternArgs = {
  description: Scalars['String'];
  weeks: Array<InputShiftWeek>;
};


export type MutationCreateTcCollectiveAgreementArgs = {
  input: CreateTcCollectiveAgreementInput;
};


export type MutationCreateTcCompanyArgs = {
  input: CreateTcCompanyInput;
};


export type MutationCreateTcCompanyAgreementArgs = {
  input: CreateTcCompanyAgreementInput;
};


export type MutationCreateTcEarningCodeRateArgs = {
  input: CreateTcEarningCodeRateInput;
};


export type MutationCreateTcEquipmentArgs = {
  input: CreateTcEquipmentInput;
};


export type MutationCreateTcHolidayArgs = {
  input: CreateTcHolidayInput;
};


export type MutationCreateTcHolidayAlternateArgs = {
  input: CreateTcHolidayAlternateInput;
};


export type MutationCreateTcLeaveArgs = {
  input: CreateTcLeaveInput;
};


export type MutationCreateTcMaterialArgs = {
  input: CreateTcMaterialInput;
};


export type MutationCreateTcRejectionArgs = {
  input: CreateTcRejectionInput;
};


export type MutationCreateTcTimecardArgs = {
  input: CreateTcTimecardInput;
};


export type MutationCreateTcVacationAllotmentArgs = {
  input: CreateTcVacationAllotmentInput;
};


export type MutationCreateTcWorkArgs = {
  input: CreateTcWorkInput;
};


export type MutationCreateTcWorkLeaveArgs = {
  input: CreateTcWorkLeaveInput;
};


export type MutationCreateTcWorkTestArgs = {
  input: CreateTcWorkTestInput;
};


export type MutationCreateTcWorkTimeArgs = {
  input: CreateTcWorkTimeInput;
};


export type MutationCreateUomArgs = {
  input: CreateUomInput;
};


export type MutationCreateUsersArgs = {
  users: Array<UserInput>;
};


export type MutationCreateVehicleDiagnosticArgs = {
  input: CreateVehicleDiagnosticInput;
};


export type MutationCreateVehicleServiceArgs = {
  input: CreateVehicleServiceInput;
};


export type MutationCreateVehicleStatusArgs = {
  input: CreateVehicleStatusInput;
};


export type MutationCreateVpJobOverrideArgs = {
  input: CreateVpJobOverrideInput;
};


export type MutationCreateVpPhaseOverrideArgs = {
  input: CreateVpPhaseOverrideInput;
};


export type MutationCreateWrRecordFullArgs = {
  input: CreateWrRecordFullInput;
};


export type MutationDeleteAssetArgs = {
  input: DeleteAssetInput;
};


export type MutationDeleteAssetBreadcrumbArgs = {
  input: DeleteAssetBreadcrumbInput;
};


export type MutationDeleteAssetBreadcrumbByAssetIdAndGeogAndDatetimeArgs = {
  input: DeleteAssetBreadcrumbByAssetIdAndGeogAndDatetimeInput;
};


export type MutationDeleteAssetBreadcrumbByNodeIdArgs = {
  input: DeleteAssetBreadcrumbByNodeIdInput;
};


export type MutationDeleteAssetByAssetCodeArgs = {
  input: DeleteAssetByAssetCodeInput;
};


export type MutationDeleteAssetByNodeIdArgs = {
  input: DeleteAssetByNodeIdInput;
};


export type MutationDeleteAssetCategoryArgs = {
  input: DeleteAssetCategoryInput;
};


export type MutationDeleteAssetCategoryByNodeIdArgs = {
  input: DeleteAssetCategoryByNodeIdInput;
};


export type MutationDeleteAssetTypeArgs = {
  input: DeleteAssetTypeInput;
};


export type MutationDeleteAssetTypeByDescriptionArgs = {
  input: DeleteAssetTypeByDescriptionInput;
};


export type MutationDeleteAssetTypeByNodeIdArgs = {
  input: DeleteAssetTypeByNodeIdInput;
};


export type MutationDeleteAssetWingUnderbodyArgs = {
  input: DeleteAssetWingUnderbodyInput;
};


export type MutationDeleteAssetWingUnderbodyByNodeIdArgs = {
  input: DeleteAssetWingUnderbodyByNodeIdInput;
};


export type MutationDeleteAuthSessionTokenArgs = {
  input: DeleteAuthSessionTokenInput;
};


export type MutationDeleteAuthSessionTokenByNodeIdArgs = {
  input: DeleteAuthSessionTokenByNodeIdInput;
};


export type MutationDeleteAuthUserArgs = {
  input: DeleteAuthUserInput;
};


export type MutationDeleteAuthUserByEmailArgs = {
  input: DeleteAuthUserByEmailInput;
};


export type MutationDeleteAuthUserByEmployeeNoArgs = {
  input: DeleteAuthUserByEmployeeNoInput;
};


export type MutationDeleteAuthUserByNodeIdArgs = {
  input: DeleteAuthUserByNodeIdInput;
};


export type MutationDeleteBecBiogeoclimaticArgs = {
  input: DeleteBecBiogeoclimaticInput;
};


export type MutationDeleteBecBiogeoclimaticByNodeIdArgs = {
  input: DeleteBecBiogeoclimaticByNodeIdInput;
};


export type MutationDeleteBiForecastArgs = {
  input: DeleteBiForecastInput;
};


export type MutationDeleteBiForecastByNodeIdArgs = {
  input: DeleteBiForecastByNodeIdInput;
};


export type MutationDeleteBiForecastPermissionArgs = {
  input: DeleteBiForecastPermissionInput;
};


export type MutationDeleteBiForecastPermissionByNodeIdArgs = {
  input: DeleteBiForecastPermissionByNodeIdInput;
};


export type MutationDeleteBiForecastPhaseArgs = {
  input: DeleteBiForecastPhaseInput;
};


export type MutationDeleteBiForecastPhaseByNodeIdArgs = {
  input: DeleteBiForecastPhaseByNodeIdInput;
};


export type MutationDeleteCollisionArgs = {
  input: DeleteCollisionInput;
};


export type MutationDeleteCollisionByNodeIdArgs = {
  input: DeleteCollisionByNodeIdInput;
};


export type MutationDeleteDtcFmiArgs = {
  input: DeleteDtcFmiInput;
};


export type MutationDeleteDtcFmiByDescriptionArgs = {
  input: DeleteDtcFmiByDescriptionInput;
};


export type MutationDeleteDtcFmiByNodeIdArgs = {
  input: DeleteDtcFmiByNodeIdInput;
};


export type MutationDeleteDtcObdArgs = {
  input: DeleteDtcObdInput;
};


export type MutationDeleteDtcObdByNodeIdArgs = {
  input: DeleteDtcObdByNodeIdInput;
};


export type MutationDeleteDtcSpnArgs = {
  input: DeleteDtcSpnInput;
};


export type MutationDeleteDtcSpnByNodeIdArgs = {
  input: DeleteDtcSpnByNodeIdInput;
};


export type MutationDeleteGisAtlasZoneArgs = {
  input: DeleteGisAtlasZoneInput;
};


export type MutationDeleteGisAtlasZoneByNodeIdArgs = {
  input: DeleteGisAtlasZoneByNodeIdInput;
};


export type MutationDeleteImapCulvertArgs = {
  input: DeleteImapCulvertInput;
};


export type MutationDeleteImapCulvertByNodeIdArgs = {
  input: DeleteImapCulvertByNodeIdInput;
};


export type MutationDeleteImapDrainageArgs = {
  input: DeleteImapDrainageInput;
};


export type MutationDeleteImapDrainageByNodeIdArgs = {
  input: DeleteImapDrainageByNodeIdInput;
};


export type MutationDeleteImapGuardrailArgs = {
  input: DeleteImapGuardrailInput;
};


export type MutationDeleteImapGuardrailByNodeIdArgs = {
  input: DeleteImapGuardrailByNodeIdInput;
};


export type MutationDeleteImapHighwayProfileArgs = {
  input: DeleteImapHighwayProfileInput;
};


export type MutationDeleteImapHighwayProfileByNodeIdArgs = {
  input: DeleteImapHighwayProfileByNodeIdInput;
};


export type MutationDeleteImapLandmarkArgs = {
  input: DeleteImapLandmarkInput;
};


export type MutationDeleteImapLandmarkByNodeIdArgs = {
  input: DeleteImapLandmarkByNodeIdInput;
};


export type MutationDeleteImapRestareaArgs = {
  input: DeleteImapRestareaInput;
};


export type MutationDeleteImapRestareaByNodeIdArgs = {
  input: DeleteImapRestareaByNodeIdInput;
};


export type MutationDeleteImapRetainingWallArgs = {
  input: DeleteImapRetainingWallInput;
};


export type MutationDeleteImapRetainingWallByNodeIdArgs = {
  input: DeleteImapRetainingWallByNodeIdInput;
};


export type MutationDeleteImapRoadClassArgs = {
  input: DeleteImapRoadClassInput;
};


export type MutationDeleteImapRoadClassByHwyMaintenanceClassIdArgs = {
  input: DeleteImapRoadClassByHwyMaintenanceClassIdInput;
};


export type MutationDeleteImapRoadClassByNodeIdArgs = {
  input: DeleteImapRoadClassByNodeIdInput;
};


export type MutationDeleteImapRoadClassFeatureArgs = {
  input: DeleteImapRoadClassFeatureInput;
};


export type MutationDeleteImapRoadClassFeatureByNodeIdArgs = {
  input: DeleteImapRoadClassFeatureByNodeIdInput;
};


export type MutationDeleteImapRoadFeatureArgs = {
  input: DeleteImapRoadFeatureInput;
};


export type MutationDeleteImapRoadFeatureByNodeIdArgs = {
  input: DeleteImapRoadFeatureByNodeIdInput;
};


export type MutationDeleteImapRoadFeatureByRfiHighwayNameArgs = {
  input: DeleteImapRoadFeatureByRfiHighwayNameInput;
};


export type MutationDeleteImapRoadFeatureByRoadFeatureInvntryIdArgs = {
  input: DeleteImapRoadFeatureByRoadFeatureInvntryIdInput;
};


export type MutationDeleteImapRoadStructureArgs = {
  input: DeleteImapRoadStructureInput;
};


export type MutationDeleteImapRoadStructureByNodeIdArgs = {
  input: DeleteImapRoadStructureByNodeIdInput;
};


export type MutationDeleteImapRoadSurfaceArgs = {
  input: DeleteImapRoadSurfaceInput;
};


export type MutationDeleteImapRoadSurfaceByNodeIdArgs = {
  input: DeleteImapRoadSurfaceByNodeIdInput;
};


export type MutationDeleteImapRoadSurfaceByRoadSurfaceTypeIdArgs = {
  input: DeleteImapRoadSurfaceByRoadSurfaceTypeIdInput;
};


export type MutationDeleteImapRoadSurfaceFeatureArgs = {
  input: DeleteImapRoadSurfaceFeatureInput;
};


export type MutationDeleteImapRoadSurfaceFeatureByNodeIdArgs = {
  input: DeleteImapRoadSurfaceFeatureByNodeIdInput;
};


export type MutationDeleteImapSafetyArgs = {
  input: DeleteImapSafetyInput;
};


export type MutationDeleteImapSafetyByNodeIdArgs = {
  input: DeleteImapSafetyByNodeIdInput;
};


export type MutationDeleteImapSignArgs = {
  input: DeleteImapSignInput;
};


export type MutationDeleteImapSignByNodeIdArgs = {
  input: DeleteImapSignByNodeIdInput;
};


export type MutationDeleteJobForecastArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOpsActivityArgs = {
  input: DeleteOpsActivityInput;
};


export type MutationDeleteOpsActivityByActivityCodeArgs = {
  input: DeleteOpsActivityByActivityCodeInput;
};


export type MutationDeleteOpsActivityByNodeIdArgs = {
  input: DeleteOpsActivityByNodeIdInput;
};


export type MutationDeleteOpsActivityRateArgs = {
  input: DeleteOpsActivityRateInput;
};


export type MutationDeleteOpsActivityRateByActivityIdAndServiceAreaAndStartDateArgs = {
  input: DeleteOpsActivityRateByActivityIdAndServiceAreaAndStartDateInput;
};


export type MutationDeleteOpsActivityRateByNodeIdArgs = {
  input: DeleteOpsActivityRateByNodeIdInput;
};


export type MutationDeleteOpsBlockArgs = {
  input: DeleteOpsBlockInput;
};


export type MutationDeleteOpsBlockByNodeIdArgs = {
  input: DeleteOpsBlockByNodeIdInput;
};


export type MutationDeleteOpsBlockByServiceAreaAndBlockCodeArgs = {
  input: DeleteOpsBlockByServiceAreaAndBlockCodeInput;
};


export type MutationDeleteOpsBlockRoadArgs = {
  input: DeleteOpsBlockRoadInput;
};


export type MutationDeleteOpsBlockRoadByNodeIdArgs = {
  input: DeleteOpsBlockRoadByNodeIdInput;
};


export type MutationDeleteOpsBlockTypeArgs = {
  input: DeleteOpsBlockTypeInput;
};


export type MutationDeleteOpsBlockTypeByDescriptionArgs = {
  input: DeleteOpsBlockTypeByDescriptionInput;
};


export type MutationDeleteOpsBlockTypeByNodeIdArgs = {
  input: DeleteOpsBlockTypeByNodeIdInput;
};


export type MutationDeleteOpsGroupArgs = {
  input: DeleteOpsGroupInput;
};


export type MutationDeleteOpsGroupByDescriptionArgs = {
  input: DeleteOpsGroupByDescriptionInput;
};


export type MutationDeleteOpsGroupByNodeIdArgs = {
  input: DeleteOpsGroupByNodeIdInput;
};


export type MutationDeleteOpsGroupTypeArgs = {
  input: DeleteOpsGroupTypeInput;
};


export type MutationDeleteOpsGroupTypeByDescriptionArgs = {
  input: DeleteOpsGroupTypeByDescriptionInput;
};


export type MutationDeleteOpsGroupTypeByNodeIdArgs = {
  input: DeleteOpsGroupTypeByNodeIdInput;
};


export type MutationDeleteOpsMaterialArgs = {
  input: DeleteOpsMaterialInput;
};


export type MutationDeleteOpsMaterialByMaterialCodeArgs = {
  input: DeleteOpsMaterialByMaterialCodeInput;
};


export type MutationDeleteOpsMaterialByNodeIdArgs = {
  input: DeleteOpsMaterialByNodeIdInput;
};


export type MutationDeleteOpsMembershipCustomArgs = {
  input: DeleteOpsMembershipCustomInput;
};


export type MutationDeleteOpsMembershipCustomByNodeIdArgs = {
  input: DeleteOpsMembershipCustomByNodeIdInput;
};


export type MutationDeleteOpsScheduleArgs = {
  input: DeleteOpsScheduleInput;
};


export type MutationDeleteOpsScheduleByNodeIdArgs = {
  input: DeleteOpsScheduleByNodeIdInput;
};


export type MutationDeleteOpsShiftPatternArgs = {
  input: DeleteOpsShiftPatternInput;
};


export type MutationDeleteOpsShiftPatternByDescriptionArgs = {
  input: DeleteOpsShiftPatternByDescriptionInput;
};


export type MutationDeleteOpsShiftPatternByNodeIdArgs = {
  input: DeleteOpsShiftPatternByNodeIdInput;
};


export type MutationDeleteOpsShiftWeekArgs = {
  input: DeleteOpsShiftWeekInput;
};


export type MutationDeleteOpsShiftWeekByNodeIdArgs = {
  input: DeleteOpsShiftWeekByNodeIdInput;
};


export type MutationDeleteOpsSupervisorArgs = {
  input: DeleteOpsSupervisorInput;
};


export type MutationDeleteOpsSupervisorByNodeIdArgs = {
  input: DeleteOpsSupervisorByNodeIdInput;
};


export type MutationDeleteOpsYardArgs = {
  input: DeleteOpsYardInput;
};


export type MutationDeleteOpsYardByDescriptionArgs = {
  input: DeleteOpsYardByDescriptionInput;
};


export type MutationDeleteOpsYardByNodeIdArgs = {
  input: DeleteOpsYardByNodeIdInput;
};


export type MutationDeleteRwisForecastArgs = {
  input: DeleteRwisForecastInput;
};


export type MutationDeleteRwisForecastByNodeIdArgs = {
  input: DeleteRwisForecastByNodeIdInput;
};


export type MutationDeleteRwisForecastByStationIdAndIssueTimeArgs = {
  input: DeleteRwisForecastByStationIdAndIssueTimeInput;
};


export type MutationDeleteRwisForecastTargetArgs = {
  input: DeleteRwisForecastTargetInput;
};


export type MutationDeleteRwisForecastTargetByNodeIdArgs = {
  input: DeleteRwisForecastTargetByNodeIdInput;
};


export type MutationDeleteRwisReadingArgs = {
  input: DeleteRwisReadingInput;
};


export type MutationDeleteRwisReadingByNodeIdArgs = {
  input: DeleteRwisReadingByNodeIdInput;
};


export type MutationDeleteRwisStationArgs = {
  input: DeleteRwisStationInput;
};


export type MutationDeleteRwisStationByNodeIdArgs = {
  input: DeleteRwisStationByNodeIdInput;
};


export type MutationDeleteRwisStationByStationNameArgs = {
  input: DeleteRwisStationByStationNameInput;
};


export type MutationDeleteSchedulesArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationDeleteSdCompanyArgs = {
  input: DeleteSdCompanyInput;
};


export type MutationDeleteSdCompanyByDescriptionArgs = {
  input: DeleteSdCompanyByDescriptionInput;
};


export type MutationDeleteSdCompanyByNodeIdArgs = {
  input: DeleteSdCompanyByNodeIdInput;
};


export type MutationDeleteSdFormArgs = {
  input: DeleteSdFormInput;
};


export type MutationDeleteSdFormByNodeIdArgs = {
  input: DeleteSdFormByNodeIdInput;
};


export type MutationDeleteSdFormItemArgs = {
  input: DeleteSdFormItemInput;
};


export type MutationDeleteSdFormItemByNodeIdArgs = {
  input: DeleteSdFormItemByNodeIdInput;
};


export type MutationDeleteSdFormTypeArgs = {
  input: DeleteSdFormTypeInput;
};


export type MutationDeleteSdFormTypeByDocumentTemplateIdArgs = {
  input: DeleteSdFormTypeByDocumentTemplateIdInput;
};


export type MutationDeleteSdFormTypeByNodeIdArgs = {
  input: DeleteSdFormTypeByNodeIdInput;
};


export type MutationDeleteSdFormWorkerValueArgs = {
  input: DeleteSdFormWorkerValueInput;
};


export type MutationDeleteSdFormWorkerValueByNodeIdArgs = {
  input: DeleteSdFormWorkerValueByNodeIdInput;
};


export type MutationDeleteSdLocationArgs = {
  input: DeleteSdLocationInput;
};


export type MutationDeleteSdLocationByNodeIdArgs = {
  input: DeleteSdLocationByNodeIdInput;
};


export type MutationDeleteSdSignatureArgs = {
  input: DeleteSdSignatureInput;
};


export type MutationDeleteSdSignatureByNodeIdArgs = {
  input: DeleteSdSignatureByNodeIdInput;
};


export type MutationDeleteSdWorkerArgs = {
  input: DeleteSdWorkerInput;
};


export type MutationDeleteSdWorkerByNodeIdArgs = {
  input: DeleteSdWorkerByNodeIdInput;
};


export type MutationDeleteShiftPatternArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTcCollectiveAgreementArgs = {
  input: DeleteTcCollectiveAgreementInput;
};


export type MutationDeleteTcCollectiveAgreementByNodeIdArgs = {
  input: DeleteTcCollectiveAgreementByNodeIdInput;
};


export type MutationDeleteTcCompanyArgs = {
  input: DeleteTcCompanyInput;
};


export type MutationDeleteTcCompanyAgreementArgs = {
  input: DeleteTcCompanyAgreementInput;
};


export type MutationDeleteTcCompanyAgreementByNodeIdArgs = {
  input: DeleteTcCompanyAgreementByNodeIdInput;
};


export type MutationDeleteTcCompanyByNodeIdArgs = {
  input: DeleteTcCompanyByNodeIdInput;
};


export type MutationDeleteTcEarningCodeRateArgs = {
  input: DeleteTcEarningCodeRateInput;
};


export type MutationDeleteTcEarningCodeRateByNodeIdArgs = {
  input: DeleteTcEarningCodeRateByNodeIdInput;
};


export type MutationDeleteTcEquipmentArgs = {
  input: DeleteTcEquipmentInput;
};


export type MutationDeleteTcEquipmentByNodeIdArgs = {
  input: DeleteTcEquipmentByNodeIdInput;
};


export type MutationDeleteTcHolidayArgs = {
  input: DeleteTcHolidayInput;
};


export type MutationDeleteTcHolidayByNodeIdArgs = {
  input: DeleteTcHolidayByNodeIdInput;
};


export type MutationDeleteTcLeaveArgs = {
  input: DeleteTcLeaveInput;
};


export type MutationDeleteTcLeaveByNodeIdArgs = {
  input: DeleteTcLeaveByNodeIdInput;
};


export type MutationDeleteTcMaterialArgs = {
  input: DeleteTcMaterialInput;
};


export type MutationDeleteTcMaterialByNodeIdArgs = {
  input: DeleteTcMaterialByNodeIdInput;
};


export type MutationDeleteTcRejectionArgs = {
  input: DeleteTcRejectionInput;
};


export type MutationDeleteTcRejectionByNodeIdArgs = {
  input: DeleteTcRejectionByNodeIdInput;
};


export type MutationDeleteTcTimecardArgs = {
  input: DeleteTcTimecardInput;
};


export type MutationDeleteTcTimecardByNodeIdArgs = {
  input: DeleteTcTimecardByNodeIdInput;
};


export type MutationDeleteTcVacationAllotmentArgs = {
  input: DeleteTcVacationAllotmentInput;
};


export type MutationDeleteTcVacationAllotmentByNodeIdArgs = {
  input: DeleteTcVacationAllotmentByNodeIdInput;
};


export type MutationDeleteTcWorkArgs = {
  input: DeleteTcWorkInput;
};


export type MutationDeleteTcWorkByNodeIdArgs = {
  input: DeleteTcWorkByNodeIdInput;
};


export type MutationDeleteTcWorkLeaveArgs = {
  input: DeleteTcWorkLeaveInput;
};


export type MutationDeleteTcWorkLeaveByNodeIdArgs = {
  input: DeleteTcWorkLeaveByNodeIdInput;
};


export type MutationDeleteTcWorkTestArgs = {
  input: DeleteTcWorkTestInput;
};


export type MutationDeleteTcWorkTestByNodeIdArgs = {
  input: DeleteTcWorkTestByNodeIdInput;
};


export type MutationDeleteTcWorkTimeArgs = {
  input: DeleteTcWorkTimeInput;
};


export type MutationDeleteTcWorkTimeByNodeIdArgs = {
  input: DeleteTcWorkTimeByNodeIdInput;
};


export type MutationDeleteTimecardArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUomArgs = {
  input: DeleteUomInput;
};


export type MutationDeleteUomByDescriptionArgs = {
  input: DeleteUomByDescriptionInput;
};


export type MutationDeleteUomByMotiCodeArgs = {
  input: DeleteUomByMotiCodeInput;
};


export type MutationDeleteUomByNodeIdArgs = {
  input: DeleteUomByNodeIdInput;
};


export type MutationDeleteUomByUomCodeArgs = {
  input: DeleteUomByUomCodeInput;
};


export type MutationDeleteVehicleDiagnosticArgs = {
  input: DeleteVehicleDiagnosticInput;
};


export type MutationDeleteVehicleDiagnosticByAssetIdAndObdCodeAndDatetimeArgs = {
  input: DeleteVehicleDiagnosticByAssetIdAndObdCodeAndDatetimeInput;
};


export type MutationDeleteVehicleDiagnosticByAssetIdAndSpnCodeAndFmiCodeAndDatetimeArgs = {
  input: DeleteVehicleDiagnosticByAssetIdAndSpnCodeAndFmiCodeAndDatetimeInput;
};


export type MutationDeleteVehicleDiagnosticByNodeIdArgs = {
  input: DeleteVehicleDiagnosticByNodeIdInput;
};


export type MutationDeleteVehicleServiceArgs = {
  input: DeleteVehicleServiceInput;
};


export type MutationDeleteVehicleServiceByNodeIdArgs = {
  input: DeleteVehicleServiceByNodeIdInput;
};


export type MutationDeleteVehicleStatusArgs = {
  input: DeleteVehicleStatusInput;
};


export type MutationDeleteVehicleStatusByNodeIdArgs = {
  input: DeleteVehicleStatusByNodeIdInput;
};


export type MutationDeleteVpJobOverrideArgs = {
  input: DeleteVpJobOverrideInput;
};


export type MutationDeleteVpJobOverrideByNodeIdArgs = {
  input: DeleteVpJobOverrideByNodeIdInput;
};


export type MutationDeleteVpPhaseOverrideArgs = {
  input: DeleteVpPhaseOverrideInput;
};


export type MutationDeleteVpPhaseOverrideByNodeIdArgs = {
  input: DeleteVpPhaseOverrideByNodeIdInput;
};


export type MutationEditTimecardArgs = {
  id: Scalars['ID'];
  timecard: TimecardInput;
};


export type MutationEditUsersArgs = {
  data: EditUserInput;
  ids: Array<Scalars['Int']>;
};


export type MutationGetImapRoadClassArgs = {
  input: GetImapRoadClassInput;
};


export type MutationGetImapRoadFeatureArgs = {
  input: GetImapRoadFeatureInput;
};


export type MutationGetImapRoadSurfaceArgs = {
  input: GetImapRoadSurfaceInput;
};


export type MutationGetOpsYardArgs = {
  input: GetOpsYardInput;
};


export type MutationIsDateArgs = {
  input: IsDateInput;
};


export type MutationRejectTimecardArgs = {
  id: Scalars['ID'];
  reason: Scalars['String'];
};


export type MutationRenameShiftPatternArgs = {
  description: Scalars['String'];
  id: Scalars['Int'];
};


export type MutationRequestLeaveArgs = {
  request: LeaveInput;
};


export type MutationResetPasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationReviewLeaveArgs = {
  approve: Scalars['Boolean'];
  id: Scalars['ID'];
};


export type MutationRevokeLeaveArgs = {
  id: Scalars['ID'];
};


export type MutationSaveJobForecastArgs = {
  companyCode: Scalars['ID'];
  description: Scalars['String'];
  endDate: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  jobCode: Scalars['ID'];
  phases: Array<JobForecastPhaseInput>;
  startDate: Scalars['String'];
};


export type MutationSaveRecordArgs = {
  record: WorkReportSubmission;
};


export type MutationSetOtCompensationMethodArgs = {
  method: CompensationMethod;
};


export type MutationShareJobForecastArgs = {
  id: Scalars['ID'];
  username: Scalars['String'];
};


export type MutationTcWorkTimeCalcArgs = {
  input: TcWorkTimeCalcInput;
};


export type MutationTcWorkTimeRefreshArgs = {
  input: TcWorkTimeRefreshInput;
};


export type MutationUpdateAccomplishmentArgs = {
  updates: Array<AccomplishmentUpdate>;
};


export type MutationUpdateAssetArgs = {
  input: UpdateAssetInput;
};


export type MutationUpdateAssetBreadcrumbArgs = {
  input: UpdateAssetBreadcrumbInput;
};


export type MutationUpdateAssetBreadcrumbByAssetIdAndGeogAndDatetimeArgs = {
  input: UpdateAssetBreadcrumbByAssetIdAndGeogAndDatetimeInput;
};


export type MutationUpdateAssetBreadcrumbByNodeIdArgs = {
  input: UpdateAssetBreadcrumbByNodeIdInput;
};


export type MutationUpdateAssetByAssetCodeArgs = {
  input: UpdateAssetByAssetCodeInput;
};


export type MutationUpdateAssetByNodeIdArgs = {
  input: UpdateAssetByNodeIdInput;
};


export type MutationUpdateAssetCategoryArgs = {
  input: UpdateAssetCategoryInput;
};


export type MutationUpdateAssetCategoryByNodeIdArgs = {
  input: UpdateAssetCategoryByNodeIdInput;
};


export type MutationUpdateAssetTypeArgs = {
  input: UpdateAssetTypeInput;
};


export type MutationUpdateAssetTypeByDescriptionArgs = {
  input: UpdateAssetTypeByDescriptionInput;
};


export type MutationUpdateAssetTypeByNodeIdArgs = {
  input: UpdateAssetTypeByNodeIdInput;
};


export type MutationUpdateAssetWingUnderbodyArgs = {
  input: UpdateAssetWingUnderbodyInput;
};


export type MutationUpdateAssetWingUnderbodyByNodeIdArgs = {
  input: UpdateAssetWingUnderbodyByNodeIdInput;
};


export type MutationUpdateAuthSessionTokenArgs = {
  input: UpdateAuthSessionTokenInput;
};


export type MutationUpdateAuthSessionTokenByNodeIdArgs = {
  input: UpdateAuthSessionTokenByNodeIdInput;
};


export type MutationUpdateAuthUserArgs = {
  input: UpdateAuthUserInput;
};


export type MutationUpdateAuthUserByEmailArgs = {
  input: UpdateAuthUserByEmailInput;
};


export type MutationUpdateAuthUserByEmployeeNoArgs = {
  input: UpdateAuthUserByEmployeeNoInput;
};


export type MutationUpdateAuthUserByNodeIdArgs = {
  input: UpdateAuthUserByNodeIdInput;
};


export type MutationUpdateBecBiogeoclimaticArgs = {
  input: UpdateBecBiogeoclimaticInput;
};


export type MutationUpdateBecBiogeoclimaticByNodeIdArgs = {
  input: UpdateBecBiogeoclimaticByNodeIdInput;
};


export type MutationUpdateBiForecastArgs = {
  input: UpdateBiForecastInput;
};


export type MutationUpdateBiForecastByNodeIdArgs = {
  input: UpdateBiForecastByNodeIdInput;
};


export type MutationUpdateBiForecastPermissionArgs = {
  input: UpdateBiForecastPermissionInput;
};


export type MutationUpdateBiForecastPermissionByNodeIdArgs = {
  input: UpdateBiForecastPermissionByNodeIdInput;
};


export type MutationUpdateBiForecastPhaseArgs = {
  input: UpdateBiForecastPhaseInput;
};


export type MutationUpdateBiForecastPhaseByNodeIdArgs = {
  input: UpdateBiForecastPhaseByNodeIdInput;
};


export type MutationUpdateCollisionArgs = {
  input: UpdateCollisionInput;
};


export type MutationUpdateCollisionByNodeIdArgs = {
  input: UpdateCollisionByNodeIdInput;
};


export type MutationUpdateDtcFmiArgs = {
  input: UpdateDtcFmiInput;
};


export type MutationUpdateDtcFmiByDescriptionArgs = {
  input: UpdateDtcFmiByDescriptionInput;
};


export type MutationUpdateDtcFmiByNodeIdArgs = {
  input: UpdateDtcFmiByNodeIdInput;
};


export type MutationUpdateDtcObdArgs = {
  input: UpdateDtcObdInput;
};


export type MutationUpdateDtcObdByNodeIdArgs = {
  input: UpdateDtcObdByNodeIdInput;
};


export type MutationUpdateDtcSpnArgs = {
  input: UpdateDtcSpnInput;
};


export type MutationUpdateDtcSpnByNodeIdArgs = {
  input: UpdateDtcSpnByNodeIdInput;
};


export type MutationUpdateGisAtlasZoneArgs = {
  input: UpdateGisAtlasZoneInput;
};


export type MutationUpdateGisAtlasZoneByNodeIdArgs = {
  input: UpdateGisAtlasZoneByNodeIdInput;
};


export type MutationUpdateImapCulvertArgs = {
  input: UpdateImapCulvertInput;
};


export type MutationUpdateImapCulvertByNodeIdArgs = {
  input: UpdateImapCulvertByNodeIdInput;
};


export type MutationUpdateImapDrainageArgs = {
  input: UpdateImapDrainageInput;
};


export type MutationUpdateImapDrainageByNodeIdArgs = {
  input: UpdateImapDrainageByNodeIdInput;
};


export type MutationUpdateImapGuardrailArgs = {
  input: UpdateImapGuardrailInput;
};


export type MutationUpdateImapGuardrailByNodeIdArgs = {
  input: UpdateImapGuardrailByNodeIdInput;
};


export type MutationUpdateImapHighwayProfileArgs = {
  input: UpdateImapHighwayProfileInput;
};


export type MutationUpdateImapHighwayProfileByNodeIdArgs = {
  input: UpdateImapHighwayProfileByNodeIdInput;
};


export type MutationUpdateImapLandmarkArgs = {
  input: UpdateImapLandmarkInput;
};


export type MutationUpdateImapLandmarkByNodeIdArgs = {
  input: UpdateImapLandmarkByNodeIdInput;
};


export type MutationUpdateImapRestareaArgs = {
  input: UpdateImapRestareaInput;
};


export type MutationUpdateImapRestareaByNodeIdArgs = {
  input: UpdateImapRestareaByNodeIdInput;
};


export type MutationUpdateImapRetainingWallArgs = {
  input: UpdateImapRetainingWallInput;
};


export type MutationUpdateImapRetainingWallByNodeIdArgs = {
  input: UpdateImapRetainingWallByNodeIdInput;
};


export type MutationUpdateImapRoadClassArgs = {
  input: UpdateImapRoadClassInput;
};


export type MutationUpdateImapRoadClassByHwyMaintenanceClassIdArgs = {
  input: UpdateImapRoadClassByHwyMaintenanceClassIdInput;
};


export type MutationUpdateImapRoadClassByNodeIdArgs = {
  input: UpdateImapRoadClassByNodeIdInput;
};


export type MutationUpdateImapRoadClassFeatureArgs = {
  input: UpdateImapRoadClassFeatureInput;
};


export type MutationUpdateImapRoadClassFeatureByNodeIdArgs = {
  input: UpdateImapRoadClassFeatureByNodeIdInput;
};


export type MutationUpdateImapRoadFeatureArgs = {
  input: UpdateImapRoadFeatureInput;
};


export type MutationUpdateImapRoadFeatureByNodeIdArgs = {
  input: UpdateImapRoadFeatureByNodeIdInput;
};


export type MutationUpdateImapRoadFeatureByRfiHighwayNameArgs = {
  input: UpdateImapRoadFeatureByRfiHighwayNameInput;
};


export type MutationUpdateImapRoadFeatureByRoadFeatureInvntryIdArgs = {
  input: UpdateImapRoadFeatureByRoadFeatureInvntryIdInput;
};


export type MutationUpdateImapRoadStructureArgs = {
  input: UpdateImapRoadStructureInput;
};


export type MutationUpdateImapRoadStructureByNodeIdArgs = {
  input: UpdateImapRoadStructureByNodeIdInput;
};


export type MutationUpdateImapRoadSurfaceArgs = {
  input: UpdateImapRoadSurfaceInput;
};


export type MutationUpdateImapRoadSurfaceByNodeIdArgs = {
  input: UpdateImapRoadSurfaceByNodeIdInput;
};


export type MutationUpdateImapRoadSurfaceByRoadSurfaceTypeIdArgs = {
  input: UpdateImapRoadSurfaceByRoadSurfaceTypeIdInput;
};


export type MutationUpdateImapRoadSurfaceFeatureArgs = {
  input: UpdateImapRoadSurfaceFeatureInput;
};


export type MutationUpdateImapRoadSurfaceFeatureByNodeIdArgs = {
  input: UpdateImapRoadSurfaceFeatureByNodeIdInput;
};


export type MutationUpdateImapSafetyArgs = {
  input: UpdateImapSafetyInput;
};


export type MutationUpdateImapSafetyByNodeIdArgs = {
  input: UpdateImapSafetyByNodeIdInput;
};


export type MutationUpdateImapSignArgs = {
  input: UpdateImapSignInput;
};


export type MutationUpdateImapSignByNodeIdArgs = {
  input: UpdateImapSignByNodeIdInput;
};


export type MutationUpdateOpsActivityArgs = {
  input: UpdateOpsActivityInput;
};


export type MutationUpdateOpsActivityByActivityCodeArgs = {
  input: UpdateOpsActivityByActivityCodeInput;
};


export type MutationUpdateOpsActivityByNodeIdArgs = {
  input: UpdateOpsActivityByNodeIdInput;
};


export type MutationUpdateOpsActivityRateArgs = {
  input: UpdateOpsActivityRateInput;
};


export type MutationUpdateOpsActivityRateByActivityIdAndServiceAreaAndStartDateArgs = {
  input: UpdateOpsActivityRateByActivityIdAndServiceAreaAndStartDateInput;
};


export type MutationUpdateOpsActivityRateByNodeIdArgs = {
  input: UpdateOpsActivityRateByNodeIdInput;
};


export type MutationUpdateOpsBlockArgs = {
  input: UpdateOpsBlockInput;
};


export type MutationUpdateOpsBlockByNodeIdArgs = {
  input: UpdateOpsBlockByNodeIdInput;
};


export type MutationUpdateOpsBlockByServiceAreaAndBlockCodeArgs = {
  input: UpdateOpsBlockByServiceAreaAndBlockCodeInput;
};


export type MutationUpdateOpsBlockRoadArgs = {
  input: UpdateOpsBlockRoadInput;
};


export type MutationUpdateOpsBlockRoadByNodeIdArgs = {
  input: UpdateOpsBlockRoadByNodeIdInput;
};


export type MutationUpdateOpsBlockTypeArgs = {
  input: UpdateOpsBlockTypeInput;
};


export type MutationUpdateOpsBlockTypeByDescriptionArgs = {
  input: UpdateOpsBlockTypeByDescriptionInput;
};


export type MutationUpdateOpsBlockTypeByNodeIdArgs = {
  input: UpdateOpsBlockTypeByNodeIdInput;
};


export type MutationUpdateOpsGroupArgs = {
  input: UpdateOpsGroupInput;
};


export type MutationUpdateOpsGroupByDescriptionArgs = {
  input: UpdateOpsGroupByDescriptionInput;
};


export type MutationUpdateOpsGroupByNodeIdArgs = {
  input: UpdateOpsGroupByNodeIdInput;
};


export type MutationUpdateOpsGroupTypeArgs = {
  input: UpdateOpsGroupTypeInput;
};


export type MutationUpdateOpsGroupTypeByDescriptionArgs = {
  input: UpdateOpsGroupTypeByDescriptionInput;
};


export type MutationUpdateOpsGroupTypeByNodeIdArgs = {
  input: UpdateOpsGroupTypeByNodeIdInput;
};


export type MutationUpdateOpsMaterialArgs = {
  input: UpdateOpsMaterialInput;
};


export type MutationUpdateOpsMaterialByMaterialCodeArgs = {
  input: UpdateOpsMaterialByMaterialCodeInput;
};


export type MutationUpdateOpsMaterialByNodeIdArgs = {
  input: UpdateOpsMaterialByNodeIdInput;
};


export type MutationUpdateOpsMembershipCustomArgs = {
  input: UpdateOpsMembershipCustomInput;
};


export type MutationUpdateOpsMembershipCustomByNodeIdArgs = {
  input: UpdateOpsMembershipCustomByNodeIdInput;
};


export type MutationUpdateOpsScheduleArgs = {
  input: UpdateOpsScheduleInput;
};


export type MutationUpdateOpsScheduleByNodeIdArgs = {
  input: UpdateOpsScheduleByNodeIdInput;
};


export type MutationUpdateOpsShiftPatternArgs = {
  input: UpdateOpsShiftPatternInput;
};


export type MutationUpdateOpsShiftPatternByDescriptionArgs = {
  input: UpdateOpsShiftPatternByDescriptionInput;
};


export type MutationUpdateOpsShiftPatternByNodeIdArgs = {
  input: UpdateOpsShiftPatternByNodeIdInput;
};


export type MutationUpdateOpsShiftWeekArgs = {
  input: UpdateOpsShiftWeekInput;
};


export type MutationUpdateOpsShiftWeekByNodeIdArgs = {
  input: UpdateOpsShiftWeekByNodeIdInput;
};


export type MutationUpdateOpsSupervisorArgs = {
  input: UpdateOpsSupervisorInput;
};


export type MutationUpdateOpsSupervisorByNodeIdArgs = {
  input: UpdateOpsSupervisorByNodeIdInput;
};


export type MutationUpdateOpsYardArgs = {
  input: UpdateOpsYardInput;
};


export type MutationUpdateOpsYardByDescriptionArgs = {
  input: UpdateOpsYardByDescriptionInput;
};


export type MutationUpdateOpsYardByNodeIdArgs = {
  input: UpdateOpsYardByNodeIdInput;
};


export type MutationUpdateRwisForecastArgs = {
  input: UpdateRwisForecastInput;
};


export type MutationUpdateRwisForecastByNodeIdArgs = {
  input: UpdateRwisForecastByNodeIdInput;
};


export type MutationUpdateRwisForecastByStationIdAndIssueTimeArgs = {
  input: UpdateRwisForecastByStationIdAndIssueTimeInput;
};


export type MutationUpdateRwisForecastTargetArgs = {
  input: UpdateRwisForecastTargetInput;
};


export type MutationUpdateRwisForecastTargetByNodeIdArgs = {
  input: UpdateRwisForecastTargetByNodeIdInput;
};


export type MutationUpdateRwisReadingArgs = {
  input: UpdateRwisReadingInput;
};


export type MutationUpdateRwisReadingByNodeIdArgs = {
  input: UpdateRwisReadingByNodeIdInput;
};


export type MutationUpdateRwisStationArgs = {
  input: UpdateRwisStationInput;
};


export type MutationUpdateRwisStationByNodeIdArgs = {
  input: UpdateRwisStationByNodeIdInput;
};


export type MutationUpdateRwisStationByStationNameArgs = {
  input: UpdateRwisStationByStationNameInput;
};


export type MutationUpdateSdCompanyArgs = {
  input: UpdateSdCompanyInput;
};


export type MutationUpdateSdCompanyByDescriptionArgs = {
  input: UpdateSdCompanyByDescriptionInput;
};


export type MutationUpdateSdCompanyByNodeIdArgs = {
  input: UpdateSdCompanyByNodeIdInput;
};


export type MutationUpdateSdFormArgs = {
  input: UpdateSdFormInput;
};


export type MutationUpdateSdFormByNodeIdArgs = {
  input: UpdateSdFormByNodeIdInput;
};


export type MutationUpdateSdFormItemArgs = {
  input: UpdateSdFormItemInput;
};


export type MutationUpdateSdFormItemByNodeIdArgs = {
  input: UpdateSdFormItemByNodeIdInput;
};


export type MutationUpdateSdFormTypeArgs = {
  input: UpdateSdFormTypeInput;
};


export type MutationUpdateSdFormTypeByDocumentTemplateIdArgs = {
  input: UpdateSdFormTypeByDocumentTemplateIdInput;
};


export type MutationUpdateSdFormTypeByNodeIdArgs = {
  input: UpdateSdFormTypeByNodeIdInput;
};


export type MutationUpdateSdFormWorkerValueArgs = {
  input: UpdateSdFormWorkerValueInput;
};


export type MutationUpdateSdFormWorkerValueByNodeIdArgs = {
  input: UpdateSdFormWorkerValueByNodeIdInput;
};


export type MutationUpdateSdLocationArgs = {
  input: UpdateSdLocationInput;
};


export type MutationUpdateSdLocationByNodeIdArgs = {
  input: UpdateSdLocationByNodeIdInput;
};


export type MutationUpdateSdSignatureArgs = {
  input: UpdateSdSignatureInput;
};


export type MutationUpdateSdSignatureByNodeIdArgs = {
  input: UpdateSdSignatureByNodeIdInput;
};


export type MutationUpdateSdWorkerArgs = {
  input: UpdateSdWorkerInput;
};


export type MutationUpdateSdWorkerByNodeIdArgs = {
  input: UpdateSdWorkerByNodeIdInput;
};


export type MutationUpdateTcCollectiveAgreementArgs = {
  input: UpdateTcCollectiveAgreementInput;
};


export type MutationUpdateTcCollectiveAgreementByNodeIdArgs = {
  input: UpdateTcCollectiveAgreementByNodeIdInput;
};


export type MutationUpdateTcCompanyArgs = {
  input: UpdateTcCompanyInput;
};


export type MutationUpdateTcCompanyAgreementArgs = {
  input: UpdateTcCompanyAgreementInput;
};


export type MutationUpdateTcCompanyAgreementByNodeIdArgs = {
  input: UpdateTcCompanyAgreementByNodeIdInput;
};


export type MutationUpdateTcCompanyByNodeIdArgs = {
  input: UpdateTcCompanyByNodeIdInput;
};


export type MutationUpdateTcEarningCodeRateArgs = {
  input: UpdateTcEarningCodeRateInput;
};


export type MutationUpdateTcEarningCodeRateByNodeIdArgs = {
  input: UpdateTcEarningCodeRateByNodeIdInput;
};


export type MutationUpdateTcEquipmentArgs = {
  input: UpdateTcEquipmentInput;
};


export type MutationUpdateTcEquipmentByNodeIdArgs = {
  input: UpdateTcEquipmentByNodeIdInput;
};


export type MutationUpdateTcHolidayArgs = {
  input: UpdateTcHolidayInput;
};


export type MutationUpdateTcHolidayByNodeIdArgs = {
  input: UpdateTcHolidayByNodeIdInput;
};


export type MutationUpdateTcLeaveArgs = {
  input: UpdateTcLeaveInput;
};


export type MutationUpdateTcLeaveByNodeIdArgs = {
  input: UpdateTcLeaveByNodeIdInput;
};


export type MutationUpdateTcMaterialArgs = {
  input: UpdateTcMaterialInput;
};


export type MutationUpdateTcMaterialByNodeIdArgs = {
  input: UpdateTcMaterialByNodeIdInput;
};


export type MutationUpdateTcRejectionArgs = {
  input: UpdateTcRejectionInput;
};


export type MutationUpdateTcRejectionByNodeIdArgs = {
  input: UpdateTcRejectionByNodeIdInput;
};


export type MutationUpdateTcTimecardArgs = {
  input: UpdateTcTimecardInput;
};


export type MutationUpdateTcTimecardByNodeIdArgs = {
  input: UpdateTcTimecardByNodeIdInput;
};


export type MutationUpdateTcVacationAllotmentArgs = {
  input: UpdateTcVacationAllotmentInput;
};


export type MutationUpdateTcVacationAllotmentByNodeIdArgs = {
  input: UpdateTcVacationAllotmentByNodeIdInput;
};


export type MutationUpdateTcWorkArgs = {
  input: UpdateTcWorkInput;
};


export type MutationUpdateTcWorkByNodeIdArgs = {
  input: UpdateTcWorkByNodeIdInput;
};


export type MutationUpdateTcWorkLeaveArgs = {
  input: UpdateTcWorkLeaveInput;
};


export type MutationUpdateTcWorkLeaveByNodeIdArgs = {
  input: UpdateTcWorkLeaveByNodeIdInput;
};


export type MutationUpdateTcWorkTestArgs = {
  input: UpdateTcWorkTestInput;
};


export type MutationUpdateTcWorkTestByNodeIdArgs = {
  input: UpdateTcWorkTestByNodeIdInput;
};


export type MutationUpdateTcWorkTimeArgs = {
  input: UpdateTcWorkTimeInput;
};


export type MutationUpdateTcWorkTimeByNodeIdArgs = {
  input: UpdateTcWorkTimeByNodeIdInput;
};


export type MutationUpdateUnderbodyArgs = {
  active: Scalars['Boolean'];
  asset: Scalars['ID'];
  effective: Scalars['DateTime'];
};


export type MutationUpdateUomArgs = {
  input: UpdateUomInput;
};


export type MutationUpdateUomByDescriptionArgs = {
  input: UpdateUomByDescriptionInput;
};


export type MutationUpdateUomByMotiCodeArgs = {
  input: UpdateUomByMotiCodeInput;
};


export type MutationUpdateUomByNodeIdArgs = {
  input: UpdateUomByNodeIdInput;
};


export type MutationUpdateUomByUomCodeArgs = {
  input: UpdateUomByUomCodeInput;
};


export type MutationUpdateVehicleDiagnosticArgs = {
  input: UpdateVehicleDiagnosticInput;
};


export type MutationUpdateVehicleDiagnosticByAssetIdAndObdCodeAndDatetimeArgs = {
  input: UpdateVehicleDiagnosticByAssetIdAndObdCodeAndDatetimeInput;
};


export type MutationUpdateVehicleDiagnosticByAssetIdAndSpnCodeAndFmiCodeAndDatetimeArgs = {
  input: UpdateVehicleDiagnosticByAssetIdAndSpnCodeAndFmiCodeAndDatetimeInput;
};


export type MutationUpdateVehicleDiagnosticByNodeIdArgs = {
  input: UpdateVehicleDiagnosticByNodeIdInput;
};


export type MutationUpdateVehicleServiceArgs = {
  input: UpdateVehicleServiceInput;
};


export type MutationUpdateVehicleServiceByNodeIdArgs = {
  input: UpdateVehicleServiceByNodeIdInput;
};


export type MutationUpdateVehicleStatusArgs = {
  input: UpdateVehicleStatusInput;
};


export type MutationUpdateVehicleStatusByNodeIdArgs = {
  input: UpdateVehicleStatusByNodeIdInput;
};


export type MutationUpdateVpJobOverrideArgs = {
  input: UpdateVpJobOverrideInput;
};


export type MutationUpdateVpJobOverrideByNodeIdArgs = {
  input: UpdateVpJobOverrideByNodeIdInput;
};


export type MutationUpdateVpPhaseOverrideArgs = {
  input: UpdateVpPhaseOverrideInput;
};


export type MutationUpdateVpPhaseOverrideByNodeIdArgs = {
  input: UpdateVpPhaseOverrideByNodeIdInput;
};


export type MutationUpsertOpsGroupArgs = {
  description: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  members: Array<Scalars['Int']>;
};


export type MutationViewpointLoginArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationViewpointLogoutArgs = {
  token: Scalars['String'];
};

export type NearbyEquipment = {
  __typename?: 'NearbyEquipment';
  assetCode: Scalars['String'];
  assetId: Scalars['ID'];
  closestDistance: Scalars['Float'];
  mostRecentlyInRange: Scalars['DateTime'];
};

export type NearbyInput = {
  limit?: InputMaybe<Scalars['Int']>;
  position: InputPoint;
  range: Scalars['Float'];
  since: Scalars['DateTime'];
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

export type Obd = {
  __typename?: 'OBD';
  obd_code: Scalars['ID'];
  obd_description?: Maybe<Scalars['String']>;
};

export type OpenCommitment = {
  __typename?: 'OpenCommitment';
  amount: Scalars['Float'];
  costType: Scalars['Int'];
  originalPhaseCode: Scalars['ID'];
  phaseCode: Scalars['String'];
};

/** Methods to use when ordering `OpsActivity`. */
export enum OpsActivitiesOrderBy {
  ActivityCodeAsc = 'ACTIVITY_CODE_ASC',
  ActivityCodeDesc = 'ACTIVITY_CODE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UomIdAsc = 'UOM_ID_ASC',
  UomIdDesc = 'UOM_ID_DESC'
}

export type OpsActivity = Node & {
  __typename?: 'OpsActivity';
  activityCode: Scalars['String'];
  description: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  locationReq?: Maybe<Scalars['String']>;
  lumpSum: Scalars['Boolean'];
  maintenanceType: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `OpsActivityRate`. */
  opsActivityRatesByActivityId: Array<OpsActivityRate>;
  reportingType: Scalars['String'];
  /** Reads a single `Uom` that is related to this `OpsActivity`. */
  uom?: Maybe<Uom>;
  uomId: Scalars['Int'];
};


export type OpsActivityOpsActivityRatesByActivityIdArgs = {
  condition?: InputMaybe<OpsActivityRateCondition>;
  filter?: InputMaybe<OpsActivityRateFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsActivityRatesOrderBy>>;
};

/**
 * A condition to be used against `OpsActivity` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type OpsActivityCondition = {
  /** Checks for equality with the object’s `activityCode` field. */
  activityCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `uomId` field. */
  uomId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `OpsActivity` object types. All fields are combined with a logical ‘and.’ */
export type OpsActivityFilter = {
  /** Filter by the object’s `activityCode` field. */
  activityCode?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OpsActivityFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OpsActivityFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OpsActivityFilter>>;
  /** Filter by the object’s `uomId` field. */
  uomId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `OpsActivity` */
export type OpsActivityInput = {
  activityCode: Scalars['String'];
  description: Scalars['String'];
  enabled?: InputMaybe<Scalars['Boolean']>;
  locationReq?: InputMaybe<Scalars['String']>;
  lumpSum?: InputMaybe<Scalars['Boolean']>;
  maintenanceType: Scalars['String'];
  reportingType: Scalars['String'];
  uomId: Scalars['Int'];
};

/** Represents an update to a `OpsActivity`. Fields that are set will be updated. */
export type OpsActivityPatch = {
  activityCode?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  locationReq?: InputMaybe<Scalars['String']>;
  lumpSum?: InputMaybe<Scalars['Boolean']>;
  maintenanceType?: InputMaybe<Scalars['String']>;
  reportingType?: InputMaybe<Scalars['String']>;
  uomId?: InputMaybe<Scalars['Int']>;
};

export type OpsActivityRate = Node & {
  __typename?: 'OpsActivityRate';
  /** Reads a single `OpsActivity` that is related to this `OpsActivityRate`. */
  activity?: Maybe<OpsActivity>;
  activityId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  rate: Scalars['BigFloat'];
  serviceArea: Scalars['String'];
  startDate: Scalars['Date'];
};

/**
 * A condition to be used against `OpsActivityRate` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type OpsActivityRateCondition = {
  /** Checks for equality with the object’s `activityId` field. */
  activityId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `OpsActivityRate` object types. All fields are combined with a logical ‘and.’ */
export type OpsActivityRateFilter = {
  /** Filter by the object’s `activityId` field. */
  activityId?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OpsActivityRateFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OpsActivityRateFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OpsActivityRateFilter>>;
};

/** An input for mutations affecting `OpsActivityRate` */
export type OpsActivityRateInput = {
  activityId?: InputMaybe<Scalars['Int']>;
  rate: Scalars['BigFloat'];
  serviceArea: Scalars['String'];
  startDate: Scalars['Date'];
};

/** Represents an update to a `OpsActivityRate`. Fields that are set will be updated. */
export type OpsActivityRatePatch = {
  activityId?: InputMaybe<Scalars['Int']>;
  rate?: InputMaybe<Scalars['BigFloat']>;
  serviceArea?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

/** Methods to use when ordering `OpsActivityRate`. */
export enum OpsActivityRatesOrderBy {
  ActivityIdAsc = 'ACTIVITY_ID_ASC',
  ActivityIdDesc = 'ACTIVITY_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type OpsBlock = Node & {
  __typename?: 'OpsBlock';
  blockCode: Scalars['String'];
  /** Reads a single `OpsBlockType` that is related to this `OpsBlock`. */
  blockType?: Maybe<OpsBlockType>;
  blockTypeId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `OpsBlockRoad`. */
  opsBlockRoadsByBlockId: Array<OpsBlockRoad>;
  serviceArea: Scalars['String'];
  /** Reads and enables pagination through a set of `TcWork`. */
  tcWorksByBlockId: Array<TcWork>;
};


export type OpsBlockOpsBlockRoadsByBlockIdArgs = {
  condition?: InputMaybe<OpsBlockRoadCondition>;
  filter?: InputMaybe<OpsBlockRoadFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsBlockRoadsOrderBy>>;
};


export type OpsBlockTcWorksByBlockIdArgs = {
  condition?: InputMaybe<TcWorkCondition>;
  filter?: InputMaybe<TcWorkFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcWorksOrderBy>>;
};

/**
 * A condition to be used against `OpsBlock` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type OpsBlockCondition = {
  /** Checks for equality with the object’s `blockTypeId` field. */
  blockTypeId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `serviceArea` field. */
  serviceArea?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `OpsBlock` object types. All fields are combined with a logical ‘and.’ */
export type OpsBlockFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OpsBlockFilter>>;
  /** Filter by the object’s `blockTypeId` field. */
  blockTypeId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OpsBlockFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OpsBlockFilter>>;
  /** Filter by the object’s `serviceArea` field. */
  serviceArea?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `OpsBlock` */
export type OpsBlockInput = {
  blockCode: Scalars['String'];
  blockTypeId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  serviceArea: Scalars['String'];
};

/** Represents an update to a `OpsBlock`. Fields that are set will be updated. */
export type OpsBlockPatch = {
  blockCode?: InputMaybe<Scalars['String']>;
  blockTypeId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  serviceArea?: InputMaybe<Scalars['String']>;
};

export type OpsBlockRfi = {
  __typename?: 'OpsBlockRfi';
  areaManagerArea?: Maybe<Scalars['String']>;
  blockCode?: Maybe<Scalars['String']>;
  blockDescription?: Maybe<Scalars['String']>;
  blockType?: Maybe<Scalars['String']>;
  featureId?: Maybe<Scalars['Int']>;
  geom?: Maybe<GeometryMultiLineStringM>;
  id?: Maybe<Scalars['BigInt']>;
  rfiCode?: Maybe<Scalars['String']>;
  rfiDescription?: Maybe<Scalars['String']>;
  rfiHighwayType?: Maybe<Scalars['String']>;
  serviceArea?: Maybe<Scalars['String']>;
  subArea?: Maybe<Scalars['String']>;
};

/** Methods to use when ordering `OpsBlockRfi`. */
export enum OpsBlockRfisOrderBy {
  Natural = 'NATURAL'
}

export type OpsBlockRoad = Node & {
  __typename?: 'OpsBlockRoad';
  /** Reads a single `OpsBlock` that is related to this `OpsBlockRoad`. */
  block?: Maybe<OpsBlock>;
  blockId: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `ImapRoadFeature` that is related to this `OpsBlockRoad`. */
  roadFeature?: Maybe<ImapRoadFeature>;
  roadFeatureId: Scalars['Int'];
};

/**
 * A condition to be used against `OpsBlockRoad` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type OpsBlockRoadCondition = {
  /** Checks for equality with the object’s `blockId` field. */
  blockId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `roadFeatureId` field. */
  roadFeatureId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `OpsBlockRoad` object types. All fields are combined with a logical ‘and.’ */
export type OpsBlockRoadFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OpsBlockRoadFilter>>;
  /** Filter by the object’s `blockId` field. */
  blockId?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OpsBlockRoadFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OpsBlockRoadFilter>>;
  /** Filter by the object’s `roadFeatureId` field. */
  roadFeatureId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `OpsBlockRoad` */
export type OpsBlockRoadInput = {
  blockId: Scalars['Int'];
  roadFeatureId: Scalars['Int'];
};

/** Represents an update to a `OpsBlockRoad`. Fields that are set will be updated. */
export type OpsBlockRoadPatch = {
  blockId?: InputMaybe<Scalars['Int']>;
  roadFeatureId?: InputMaybe<Scalars['Int']>;
};

/** Methods to use when ordering `OpsBlockRoad`. */
export enum OpsBlockRoadsOrderBy {
  BlockIdAsc = 'BLOCK_ID_ASC',
  BlockIdDesc = 'BLOCK_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RoadFeatureIdAsc = 'ROAD_FEATURE_ID_ASC',
  RoadFeatureIdDesc = 'ROAD_FEATURE_ID_DESC'
}

export type OpsBlockType = Node & {
  __typename?: 'OpsBlockType';
  description: Scalars['String'];
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `OpsBlock`. */
  opsBlocksByBlockTypeId: Array<OpsBlock>;
};


export type OpsBlockTypeOpsBlocksByBlockTypeIdArgs = {
  condition?: InputMaybe<OpsBlockCondition>;
  filter?: InputMaybe<OpsBlockFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsBlocksOrderBy>>;
};

/**
 * A condition to be used against `OpsBlockType` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type OpsBlockTypeCondition = {
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `OpsBlockType` object types. All fields are combined with a logical ‘and.’ */
export type OpsBlockTypeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OpsBlockTypeFilter>>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OpsBlockTypeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OpsBlockTypeFilter>>;
};

/** An input for mutations affecting `OpsBlockType` */
export type OpsBlockTypeInput = {
  description: Scalars['String'];
};

/** Represents an update to a `OpsBlockType`. Fields that are set will be updated. */
export type OpsBlockTypePatch = {
  description?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `OpsBlockType`. */
export enum OpsBlockTypesOrderBy {
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** Methods to use when ordering `OpsBlock`. */
export enum OpsBlocksOrderBy {
  BlockTypeIdAsc = 'BLOCK_TYPE_ID_ASC',
  BlockTypeIdDesc = 'BLOCK_TYPE_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ServiceAreaAsc = 'SERVICE_AREA_ASC',
  ServiceAreaDesc = 'SERVICE_AREA_DESC'
}

export type OpsGroup = Node & {
  __typename?: 'OpsGroup';
  description: Scalars['String'];
  /** Reads a single `OpsGroupType` that is related to this `OpsGroup`. */
  groupType?: Maybe<OpsGroupType>;
  groupTypeId: Scalars['Int'];
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `OpsMembershipCustom`. */
  opsMembershipCustomsByGroupId: Array<OpsMembershipCustom>;
};


export type OpsGroupOpsMembershipCustomsByGroupIdArgs = {
  condition?: InputMaybe<OpsMembershipCustomCondition>;
  filter?: InputMaybe<OpsMembershipCustomFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsMembershipCustomsOrderBy>>;
};

/**
 * A condition to be used against `OpsGroup` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type OpsGroupCondition = {
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `groupTypeId` field. */
  groupTypeId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `OpsGroup` object types. All fields are combined with a logical ‘and.’ */
export type OpsGroupFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OpsGroupFilter>>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `groupTypeId` field. */
  groupTypeId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OpsGroupFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OpsGroupFilter>>;
};

/** An input for mutations affecting `OpsGroup` */
export type OpsGroupInput = {
  description: Scalars['String'];
  groupTypeId?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `OpsGroup`. Fields that are set will be updated. */
export type OpsGroupPatch = {
  description?: InputMaybe<Scalars['String']>;
  groupTypeId?: InputMaybe<Scalars['Int']>;
};

export type OpsGroupType = Node & {
  __typename?: 'OpsGroupType';
  description: Scalars['String'];
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `OpsGroup`. */
  opsGroupsByGroupTypeId: Array<OpsGroup>;
};


export type OpsGroupTypeOpsGroupsByGroupTypeIdArgs = {
  condition?: InputMaybe<OpsGroupCondition>;
  filter?: InputMaybe<OpsGroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsGroupsOrderBy>>;
};

/**
 * A condition to be used against `OpsGroupType` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type OpsGroupTypeCondition = {
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `OpsGroupType` object types. All fields are combined with a logical ‘and.’ */
export type OpsGroupTypeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OpsGroupTypeFilter>>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OpsGroupTypeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OpsGroupTypeFilter>>;
};

/** An input for mutations affecting `OpsGroupType` */
export type OpsGroupTypeInput = {
  description: Scalars['String'];
};

/** Represents an update to a `OpsGroupType`. Fields that are set will be updated. */
export type OpsGroupTypePatch = {
  description?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `OpsGroupType`. */
export enum OpsGroupTypesOrderBy {
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** Methods to use when ordering `OpsGroup`. */
export enum OpsGroupsOrderBy {
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  GroupTypeIdAsc = 'GROUP_TYPE_ID_ASC',
  GroupTypeIdDesc = 'GROUP_TYPE_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type OpsMaterial = Node & {
  __typename?: 'OpsMaterial';
  description: Scalars['String'];
  id: Scalars['Int'];
  materialCode: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `TcMaterial`. */
  tcMaterialsByMaterialCode: Array<TcMaterial>;
  /** Reads a single `Uom` that is related to this `OpsMaterial`. */
  uom?: Maybe<Uom>;
  uomId: Scalars['Int'];
};


export type OpsMaterialTcMaterialsByMaterialCodeArgs = {
  condition?: InputMaybe<TcMaterialCondition>;
  filter?: InputMaybe<TcMaterialFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcMaterialsOrderBy>>;
};

/**
 * A condition to be used against `OpsMaterial` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type OpsMaterialCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `materialCode` field. */
  materialCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `uomId` field. */
  uomId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `OpsMaterial` object types. All fields are combined with a logical ‘and.’ */
export type OpsMaterialFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OpsMaterialFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `materialCode` field. */
  materialCode?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OpsMaterialFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OpsMaterialFilter>>;
  /** Filter by the object’s `uomId` field. */
  uomId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `OpsMaterial` */
export type OpsMaterialInput = {
  description: Scalars['String'];
  materialCode: Scalars['String'];
  uomId: Scalars['Int'];
};

/** Represents an update to a `OpsMaterial`. Fields that are set will be updated. */
export type OpsMaterialPatch = {
  description?: InputMaybe<Scalars['String']>;
  materialCode?: InputMaybe<Scalars['String']>;
  uomId?: InputMaybe<Scalars['Int']>;
};

/** As requested by Wanda in relation to material tracking needs from Brock.  Note that in SA7, materials are immediately expensed on purchase without being put in inventory.  SA26 is opposite.  SA20 is undecided. */
export type OpsMaterialTransfer = {
  __typename?: 'OpsMaterialTransfer';
  companyNo?: Maybe<Scalars['Int']>;
  employeeName?: Maybe<Scalars['String']>;
  employeeNo?: Maybe<Scalars['Int']>;
  jobCode?: Maybe<Scalars['String']>;
  locationCode?: Maybe<Scalars['String']>;
  locationDescription?: Maybe<Scalars['String']>;
  materialCode?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phaseCode?: Maybe<Scalars['String']>;
  phaseDescription?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['BigFloat']>;
  timecardId?: Maybe<Scalars['Int']>;
  workDate?: Maybe<Scalars['Date']>;
};

/** Methods to use when ordering `OpsMaterialTransfer`. */
export enum OpsMaterialTransfersOrderBy {
  Natural = 'NATURAL'
}

/** Methods to use when ordering `OpsMaterial`. */
export enum OpsMaterialsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MaterialCodeAsc = 'MATERIAL_CODE_ASC',
  MaterialCodeDesc = 'MATERIAL_CODE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UomIdAsc = 'UOM_ID_ASC',
  UomIdDesc = 'UOM_ID_DESC'
}

export type OpsMembership = {
  __typename?: 'OpsMembership';
  groupId?: Maybe<Scalars['Int']>;
  groupType?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
};

export type OpsMembershipCustom = Node & {
  __typename?: 'OpsMembershipCustom';
  /** Reads a single `OpsGroup` that is related to this `OpsMembershipCustom`. */
  group?: Maybe<OpsGroup>;
  groupId: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `AuthUser` that is related to this `OpsMembershipCustom`. */
  user?: Maybe<AuthUser>;
  userId: Scalars['Int'];
};

/**
 * A condition to be used against `OpsMembershipCustom` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type OpsMembershipCustomCondition = {
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `OpsMembershipCustom` object types. All fields are combined with a logical ‘and.’ */
export type OpsMembershipCustomFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OpsMembershipCustomFilter>>;
  /** Filter by the object’s `groupId` field. */
  groupId?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OpsMembershipCustomFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OpsMembershipCustomFilter>>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `OpsMembershipCustom` */
export type OpsMembershipCustomInput = {
  groupId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** Represents an update to a `OpsMembershipCustom`. Fields that are set will be updated. */
export type OpsMembershipCustomPatch = {
  groupId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** Methods to use when ordering `OpsMembershipCustom`. */
export enum OpsMembershipCustomsOrderBy {
  GroupIdAsc = 'GROUP_ID_ASC',
  GroupIdDesc = 'GROUP_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type OpsMembershipHomeYard = {
  __typename?: 'OpsMembershipHomeYard';
  groupId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** Methods to use when ordering `OpsMembershipHomeYard`. */
export enum OpsMembershipHomeYardsOrderBy {
  Natural = 'NATURAL'
}

export type OpsMembershipSupervisor = {
  __typename?: 'OpsMembershipSupervisor';
  groupId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** Methods to use when ordering `OpsMembershipSupervisor`. */
export enum OpsMembershipSupervisorsOrderBy {
  Natural = 'NATURAL'
}

/** Methods to use when ordering `OpsMembership`. */
export enum OpsMembershipsOrderBy {
  Natural = 'NATURAL'
}

export type OpsPatrol = {
  __typename?: 'OpsPatrol';
  bus?: Maybe<Scalars['Boolean']>;
  geom?: Maybe<GeometryLineStringM>;
  graderAge?: Maybe<Scalars['Float']>;
  graderTime?: Maybe<Scalars['Datetime']>;
  highwayNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  patrolAge?: Maybe<Scalars['Float']>;
  patrolTime?: Maybe<Scalars['Datetime']>;
  plowAge?: Maybe<Scalars['Float']>;
  plowTime?: Maybe<Scalars['Datetime']>;
  rfiHighwayDescription?: Maybe<Scalars['String']>;
  rfiHighwayName?: Maybe<Scalars['String']>;
  serviceArea?: Maybe<Scalars['String']>;
  summerClass?: Maybe<Scalars['String']>;
  visitAge?: Maybe<Scalars['Float']>;
  visitTime?: Maybe<Scalars['Datetime']>;
  winterClass?: Maybe<Scalars['String']>;
};

/** Methods to use when ordering `OpsPatrol`. */
export enum OpsPatrolsOrderBy {
  Natural = 'NATURAL'
}

export type OpsSchedule = Node & {
  __typename?: 'OpsSchedule';
  effective: Scalars['Datetime'];
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `OpsShiftPattern` that is related to this `OpsSchedule`. */
  shiftPattern?: Maybe<OpsShiftPattern>;
  shiftPatternId?: Maybe<Scalars['Int']>;
  /** Reads a single `AuthUser` that is related to this `OpsSchedule`. */
  user?: Maybe<AuthUser>;
  userId: Scalars['Int'];
  weekOffset: Scalars['Int'];
};

/**
 * A condition to be used against `OpsSchedule` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type OpsScheduleCondition = {
  /** Checks for equality with the object’s `effective` field. */
  effective?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `shiftPatternId` field. */
  shiftPatternId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

export type OpsScheduleCurrent = {
  __typename?: 'OpsScheduleCurrent';
  effective?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  shiftPatternId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  weekOffset?: Maybe<Scalars['Int']>;
};

/** Methods to use when ordering `OpsScheduleCurrent`. */
export enum OpsScheduleCurrentsOrderBy {
  Natural = 'NATURAL'
}

/** A filter to be used against `OpsSchedule` object types. All fields are combined with a logical ‘and.’ */
export type OpsScheduleFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OpsScheduleFilter>>;
  /** Filter by the object’s `effective` field. */
  effective?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OpsScheduleFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OpsScheduleFilter>>;
  /** Filter by the object’s `shiftPatternId` field. */
  shiftPatternId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `OpsSchedule` */
export type OpsScheduleInput = {
  effective?: InputMaybe<Scalars['Datetime']>;
  shiftPatternId?: InputMaybe<Scalars['Int']>;
  userId: Scalars['Int'];
  weekOffset?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `OpsSchedule`. Fields that are set will be updated. */
export type OpsSchedulePatch = {
  effective?: InputMaybe<Scalars['Datetime']>;
  shiftPatternId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
  weekOffset?: InputMaybe<Scalars['Int']>;
};

/** Methods to use when ordering `OpsSchedule`. */
export enum OpsSchedulesOrderBy {
  EffectiveAsc = 'EFFECTIVE_ASC',
  EffectiveDesc = 'EFFECTIVE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ShiftPatternIdAsc = 'SHIFT_PATTERN_ID_ASC',
  ShiftPatternIdDesc = 'SHIFT_PATTERN_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type OpsShiftPattern = Node & {
  __typename?: 'OpsShiftPattern';
  description: Scalars['String'];
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `OpsSchedule`. */
  opsSchedulesByShiftPatternId: Array<OpsSchedule>;
  /** Reads and enables pagination through a set of `OpsShiftWeek`. */
  opsShiftWeeksByShiftPatternId: Array<OpsShiftWeek>;
};


export type OpsShiftPatternOpsSchedulesByShiftPatternIdArgs = {
  condition?: InputMaybe<OpsScheduleCondition>;
  filter?: InputMaybe<OpsScheduleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsSchedulesOrderBy>>;
};


export type OpsShiftPatternOpsShiftWeeksByShiftPatternIdArgs = {
  condition?: InputMaybe<OpsShiftWeekCondition>;
  filter?: InputMaybe<OpsShiftWeekFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsShiftWeeksOrderBy>>;
};

/**
 * A condition to be used against `OpsShiftPattern` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type OpsShiftPatternCondition = {
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `OpsShiftPattern` object types. All fields are combined with a logical ‘and.’ */
export type OpsShiftPatternFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OpsShiftPatternFilter>>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OpsShiftPatternFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OpsShiftPatternFilter>>;
};

/** An input for mutations affecting `OpsShiftPattern` */
export type OpsShiftPatternInput = {
  description: Scalars['String'];
};

/** Represents an update to a `OpsShiftPattern`. Fields that are set will be updated. */
export type OpsShiftPatternPatch = {
  description?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `OpsShiftPattern`. */
export enum OpsShiftPatternsOrderBy {
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type OpsShiftWeek = Node & {
  __typename?: 'OpsShiftWeek';
  endTime: Scalars['Time'];
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `OpsShiftPattern` that is related to this `OpsShiftWeek`. */
  shiftPattern?: Maybe<OpsShiftPattern>;
  shiftPatternId: Scalars['Int'];
  startTime: Scalars['Time'];
  weekInterval: Scalars['Int'];
  weekOffset: Scalars['Int'];
  weekdays?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
};

/**
 * A condition to be used against `OpsShiftWeek` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type OpsShiftWeekCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `shiftPatternId` field. */
  shiftPatternId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `OpsShiftWeek` object types. All fields are combined with a logical ‘and.’ */
export type OpsShiftWeekFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OpsShiftWeekFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OpsShiftWeekFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OpsShiftWeekFilter>>;
  /** Filter by the object’s `shiftPatternId` field. */
  shiftPatternId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `OpsShiftWeek` */
export type OpsShiftWeekInput = {
  endTime: Scalars['Time'];
  shiftPatternId: Scalars['Int'];
  startTime: Scalars['Time'];
  weekInterval?: InputMaybe<Scalars['Int']>;
  weekOffset?: InputMaybe<Scalars['Int']>;
  weekdays?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
};

/** Represents an update to a `OpsShiftWeek`. Fields that are set will be updated. */
export type OpsShiftWeekPatch = {
  endTime?: InputMaybe<Scalars['Time']>;
  shiftPatternId?: InputMaybe<Scalars['Int']>;
  startTime?: InputMaybe<Scalars['Time']>;
  weekInterval?: InputMaybe<Scalars['Int']>;
  weekOffset?: InputMaybe<Scalars['Int']>;
  weekdays?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
};

/** Methods to use when ordering `OpsShiftWeek`. */
export enum OpsShiftWeeksOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ShiftPatternIdAsc = 'SHIFT_PATTERN_ID_ASC',
  ShiftPatternIdDesc = 'SHIFT_PATTERN_ID_DESC'
}

export type OpsSupervisor = Node & {
  __typename?: 'OpsSupervisor';
  level: SupervisorLevel;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `AuthUser` that is related to this `OpsSupervisor`. */
  supervisor?: Maybe<AuthUser>;
  supervisorId: Scalars['Int'];
  /** Reads a single `AuthUser` that is related to this `OpsSupervisor`. */
  user?: Maybe<AuthUser>;
  userId: Scalars['Int'];
};

/**
 * A condition to be used against `OpsSupervisor` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type OpsSupervisorCondition = {
  /** Checks for equality with the object’s `supervisorId` field. */
  supervisorId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `OpsSupervisor` object types. All fields are combined with a logical ‘and.’ */
export type OpsSupervisorFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OpsSupervisorFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<OpsSupervisorFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OpsSupervisorFilter>>;
  /** Filter by the object’s `supervisorId` field. */
  supervisorId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `OpsSupervisor` */
export type OpsSupervisorInput = {
  level?: InputMaybe<SupervisorLevel>;
  supervisorId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** Represents an update to a `OpsSupervisor`. Fields that are set will be updated. */
export type OpsSupervisorPatch = {
  level?: InputMaybe<SupervisorLevel>;
  supervisorId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** Methods to use when ordering `OpsSupervisor`. */
export enum OpsSupervisorsOrderBy {
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SupervisorIdAsc = 'SUPERVISOR_ID_ASC',
  SupervisorIdDesc = 'SUPERVISOR_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type OpsWorkreport = {
  __typename?: 'OpsWorkreport';
  accomplishment?: Maybe<Scalars['BigFloat']>;
  activityNumber?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  endLatitude?: Maybe<Scalars['String']>;
  endLongitude?: Maybe<Scalars['String']>;
  highwayUnique?: Maybe<Scalars['String']>;
  postedDate?: Maybe<Scalars['String']>;
  recordNumber?: Maybe<Scalars['String']>;
  recordType?: Maybe<Scalars['String']>;
  serviceArea?: Maybe<Scalars['String']>;
  siteNumber?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  startLatitude?: Maybe<Scalars['String']>;
  startLongitude?: Maybe<Scalars['String']>;
  structureNumber?: Maybe<Scalars['String']>;
  taskNumber?: Maybe<Scalars['String']>;
  unitOfMeasure?: Maybe<Scalars['String']>;
  valueOfWork?: Maybe<Scalars['String']>;
};

/** Methods to use when ordering `OpsWorkreport`. */
export enum OpsWorkreportsOrderBy {
  Natural = 'NATURAL'
}

export type OpsYard = Node & {
  __typename?: 'OpsYard';
  /** Reads and enables pagination through a set of `AssetBreadcrumb`. */
  assetBreadcrumbsByYardId: Array<AssetBreadcrumb>;
  /** Reads and enables pagination through a set of `AuthUser`. */
  authUsersByHomeYardId: Array<AuthUser>;
  description: Scalars['String'];
  geog: GeographyPoint;
  geom?: Maybe<GeometryPoint>;
  id: Scalars['Int'];
  isolationPay?: Maybe<Scalars['Float']>;
  jobPrefix: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  serviceArea: Scalars['String'];
  /** Reads and enables pagination through a set of `TcTimecard`. */
  tcTimecardsByWorksiteId: Array<TcTimecard>;
};


export type OpsYardAssetBreadcrumbsByYardIdArgs = {
  condition?: InputMaybe<AssetBreadcrumbCondition>;
  filter?: InputMaybe<AssetBreadcrumbFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetBreadcrumbsOrderBy>>;
};


export type OpsYardAuthUsersByHomeYardIdArgs = {
  condition?: InputMaybe<AuthUserCondition>;
  filter?: InputMaybe<AuthUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthUsersOrderBy>>;
};


export type OpsYardTcTimecardsByWorksiteIdArgs = {
  condition?: InputMaybe<TcTimecardCondition>;
  filter?: InputMaybe<TcTimecardFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcTimecardsOrderBy>>;
};

/** A condition to be used against `OpsYard` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type OpsYardCondition = {
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `OpsYard` object types. All fields are combined with a logical ‘and.’ */
export type OpsYardFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OpsYardFilter>>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OpsYardFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OpsYardFilter>>;
};

/** An input for mutations affecting `OpsYard` */
export type OpsYardInput = {
  description: Scalars['String'];
  geog: Scalars['GeoJSON'];
  geom?: InputMaybe<Scalars['GeoJSON']>;
  isolationPay?: InputMaybe<Scalars['Float']>;
  jobPrefix: Scalars['String'];
  serviceArea: Scalars['String'];
};

/** Represents an update to a `OpsYard`. Fields that are set will be updated. */
export type OpsYardPatch = {
  description?: InputMaybe<Scalars['String']>;
  geog?: InputMaybe<Scalars['GeoJSON']>;
  geom?: InputMaybe<Scalars['GeoJSON']>;
  isolationPay?: InputMaybe<Scalars['Float']>;
  jobPrefix?: InputMaybe<Scalars['String']>;
  serviceArea?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `OpsYard`. */
export enum OpsYardsOrderBy {
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type Overtime = {
  __typename?: 'Overtime';
  approved: Scalars['Boolean'];
  day: Scalars['String'];
  scheduledMinutes?: Maybe<Scalars['Int']>;
  workedMinutes: Scalars['Int'];
  workerId: Scalars['ID'];
  worksiteId: Scalars['ID'];
};

export type PatrolAge = {
  __typename?: 'PatrolAge';
  grader_age?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  patrol_age?: Maybe<Scalars['Int']>;
  plow_age?: Maybe<Scalars['Int']>;
  visit_age?: Maybe<Scalars['Int']>;
};

/** Methods to use when ordering `PatrolHistory`. */
export enum PatrolHistoriesOrderBy {
  Natural = 'NATURAL'
}

export type PatrolHistory = {
  __typename?: 'PatrolHistory';
  assetCode?: Maybe<Scalars['String']>;
  assetId?: Maybe<Scalars['Int']>;
  categoryPrefix?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['Datetime']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  rfi?: Maybe<Scalars['String']>;
  roadFeatureId?: Maybe<Scalars['Int']>;
};

export type PatrolRecord = {
  __typename?: 'PatrolRecord';
  assetCode: Scalars['ID'];
  assetId: Scalars['Int'];
  categoryPrefix: Scalars['String'];
  datetime: Scalars['DateTime'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  rfi: Scalars['String'];
  roadFeatureId: Scalars['Int'];
};

export type PatrolRoad = {
  __typename?: 'PatrolRoad';
  lengthM?: Maybe<Scalars['Float']>;
  rfi?: Maybe<Scalars['String']>;
  roadFeatureId?: Maybe<Scalars['Int']>;
  roadName?: Maybe<Scalars['String']>;
  serviceArea?: Maybe<Scalars['String']>;
  summerClass?: Maybe<Scalars['String']>;
  winterClass?: Maybe<Scalars['String']>;
};

/** Methods to use when ordering `PatrolRoad`. */
export enum PatrolRoadsOrderBy {
  Natural = 'NATURAL'
}

export type PayrollEntry = {
  __typename?: 'PayrollEntry';
  amount?: Maybe<Scalars['Float']>;
  asset_code?: Maybe<Scalars['String']>;
  class_code: Scalars['String'];
  company_no: Scalars['Int'];
  craft_code: Scalars['String'];
  earning_code: Scalars['String'];
  employee_no: Scalars['Int'];
  entry_date: Scalars['String'];
  equipment_co?: Maybe<Scalars['Int']>;
  equipment_usage?: Maybe<Scalars['Float']>;
  explanation?: Maybe<Scalars['String']>;
  hours: Scalars['Float'];
  job_code: Scalars['String'];
  phase_code: Scalars['String'];
};

export type Phase = {
  __typename?: 'Phase';
  ContractCode: Scalars['ID'];
  ItemCode: Scalars['ID'];
  ItemUM: Scalars['Boolean'];
  OriginalContractCode: Scalars['ID'];
  OriginalItemCode: Scalars['ID'];
  UM?: Maybe<Scalars['ID']>;
  description: Scalars['String'];
  originalPhaseCode: Scalars['ID'];
  phaseCode: Scalars['String'];
};

export type Point = {
  __typename?: 'Point';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export enum Premium {
  Afternoon = 'AFTERNOON',
  Night = 'NIGHT',
  Unsociable = 'UNSOCIABLE',
  Weekend = 'WEEKEND'
}

export type Pressure = {
  __typename?: 'Pressure';
  strength: Scalars['Float'];
  tendency: Tendency;
};

export type ProbationStatus = {
  __typename?: 'ProbationStatus';
  daysWorked: Scalars['Int'];
  employeeNo: Scalars['ID'];
  fullname: Scalars['String'];
  latestEntry?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  asset?: Maybe<Asset>;
  assetBreadcrumb?: Maybe<AssetBreadcrumb>;
  assetBreadcrumbByAssetIdAndGeogAndDatetime?: Maybe<AssetBreadcrumb>;
  /** Reads a single `AssetBreadcrumb` using its globally unique `ID`. */
  assetBreadcrumbByNodeId?: Maybe<AssetBreadcrumb>;
  /** Reads a set of `AssetBreadcrumb`. */
  assetBreadcrumbs?: Maybe<Array<AssetBreadcrumb>>;
  assetByAssetCode?: Maybe<Asset>;
  /** Reads a single `Asset` using its globally unique `ID`. */
  assetByNodeId?: Maybe<Asset>;
  /** Reads a set of `AssetCategory`. */
  assetCategories?: Maybe<Array<AssetCategory>>;
  assetCategory?: Maybe<AssetCategory>;
  /** Reads a single `AssetCategory` using its globally unique `ID`. */
  assetCategoryByNodeId?: Maybe<AssetCategory>;
  /** Reads a set of `AssetCurrentLocation`. */
  assetCurrentLocations?: Maybe<Array<AssetCurrentLocation>>;
  /** Reads a set of `AssetLatestLocation`. */
  assetLatestLocations?: Maybe<Array<AssetLatestLocation>>;
  assetType?: Maybe<AssetType>;
  assetTypeByDescription?: Maybe<AssetType>;
  /** Reads a single `AssetType` using its globally unique `ID`. */
  assetTypeByNodeId?: Maybe<AssetType>;
  /** Reads a set of `AssetType`. */
  assetTypes?: Maybe<Array<AssetType>>;
  /** Reads a set of `AssetWingUnderbody`. */
  assetWingUnderbodies?: Maybe<Array<AssetWingUnderbody>>;
  assetWingUnderbody?: Maybe<AssetWingUnderbody>;
  /** Reads a single `AssetWingUnderbody` using its globally unique `ID`. */
  assetWingUnderbodyByNodeId?: Maybe<AssetWingUnderbody>;
  /** Reads a set of `AssetWingUnderbodyCurrent`. */
  assetWingUnderbodyCurrents?: Maybe<Array<AssetWingUnderbodyCurrent>>;
  /** Reads a set of `AssetWingUnderbodyPeriod`. */
  assetWingUnderbodyPeriods?: Maybe<Array<AssetWingUnderbodyPeriod>>;
  /** Reads a set of `Asset`. */
  assets?: Maybe<Array<Asset>>;
  authSessionToken?: Maybe<AuthSessionToken>;
  /** Reads a single `AuthSessionToken` using its globally unique `ID`. */
  authSessionTokenByNodeId?: Maybe<AuthSessionToken>;
  /** Reads a set of `AuthSessionToken`. */
  authSessionTokens?: Maybe<Array<AuthSessionToken>>;
  authUser?: Maybe<AuthUser>;
  authUserByEmail?: Maybe<AuthUser>;
  authUserByEmployeeNo?: Maybe<AuthUser>;
  /** Reads a single `AuthUser` using its globally unique `ID`. */
  authUserByNodeId?: Maybe<AuthUser>;
  /** Reads a set of `AuthUser`. */
  authUsers?: Maybe<Array<AuthUser>>;
  becBiogeoclimatic?: Maybe<BecBiogeoclimatic>;
  /** Reads a single `BecBiogeoclimatic` using its globally unique `ID`. */
  becBiogeoclimaticByNodeId?: Maybe<BecBiogeoclimatic>;
  /** Reads a set of `BecBiogeoclimatic`. */
  becBiogeoclimatics?: Maybe<Array<BecBiogeoclimatic>>;
  biForecast?: Maybe<BiForecast>;
  /** Reads a single `BiForecast` using its globally unique `ID`. */
  biForecastByNodeId?: Maybe<BiForecast>;
  biForecastPermission?: Maybe<BiForecastPermission>;
  /** Reads a single `BiForecastPermission` using its globally unique `ID`. */
  biForecastPermissionByNodeId?: Maybe<BiForecastPermission>;
  /** Reads a set of `BiForecastPermission`. */
  biForecastPermissions?: Maybe<Array<BiForecastPermission>>;
  biForecastPhase?: Maybe<BiForecastPhase>;
  /** Reads a single `BiForecastPhase` using its globally unique `ID`. */
  biForecastPhaseByNodeId?: Maybe<BiForecastPhase>;
  /** Reads a set of `BiForecastPhase`. */
  biForecastPhases?: Maybe<Array<BiForecastPhase>>;
  /** Reads a set of `BiForecast`. */
  biForecasts?: Maybe<Array<BiForecast>>;
  /** Reads a set of `BreadcrumbYard`. */
  breadcrumbYards?: Maybe<Array<BreadcrumbYard>>;
  breadcrumbs: Scalars['CSV'];
  breadcrumbsSlice: Array<Breadcrumb>;
  collision?: Maybe<Collision>;
  /** Reads a single `Collision` using its globally unique `ID`. */
  collisionByNodeId?: Maybe<Collision>;
  /** Reads a set of `CollisionGeom`. */
  collisionGeoms?: Maybe<Array<CollisionGeom>>;
  /** Reads a set of `Collision`. */
  collisions?: Maybe<Array<Collision>>;
  companies: Array<Scalars['ID']>;
  companyJobs?: Maybe<Array<Job>>;
  contracts?: Maybe<Array<Contract>>;
  dashcams: Array<DashcamRecord>;
  drivebcEvents: Array<DriveBcEvent>;
  dtcFmi?: Maybe<DtcFmi>;
  dtcFmiByDescription?: Maybe<DtcFmi>;
  /** Reads a single `DtcFmi` using its globally unique `ID`. */
  dtcFmiByNodeId?: Maybe<DtcFmi>;
  /** Reads a set of `DtcFmi`. */
  dtcFmis?: Maybe<Array<DtcFmi>>;
  dtcObd?: Maybe<DtcObd>;
  /** Reads a single `DtcObd` using its globally unique `ID`. */
  dtcObdByNodeId?: Maybe<DtcObd>;
  /** Reads a set of `DtcObd`. */
  dtcObds?: Maybe<Array<DtcObd>>;
  dtcSpn?: Maybe<DtcSpn>;
  /** Reads a single `DtcSpn` using its globally unique `ID`. */
  dtcSpnByNodeId?: Maybe<DtcSpn>;
  /** Reads a set of `DtcSpn`. */
  dtcSpns?: Maybe<Array<DtcSpn>>;
  equipmentRoadSummary: Array<RoadVisitSummary>;
  esaExport: Array<PayrollEntry>;
  esaExportCsv: Scalars['String'];
  /** Reads a set of `EsaHolidayCompensation`. */
  esaHolidayCompensations?: Maybe<Array<EsaHolidayCompensation>>;
  esaHolidayCsv: Scalars['String'];
  findRejectedTimecards: Array<Scalars['ID']>;
  findRelatedTimecards: Array<RelatedTimecard>;
  findTimecards: Array<Scalars['ID']>;
  /** Reads a set of `FleetShopDay`. */
  fleetShopDays?: Maybe<Array<FleetShopDay>>;
  /** Reads a set of `FleetShopMonth`. */
  fleetShopMonths?: Maybe<Array<FleetShopMonth>>;
  /** Reads a set of `FleetShop`. */
  fleetShops?: Maybe<Array<FleetShop>>;
  getCompanyProbation: Array<ProbationStatus>;
  getNearestRoad?: Maybe<RoadInventoryRelationship>;
  getRecords: Array<WorkReport>;
  getRoadRelationship?: Maybe<LocationRelationship>;
  getSupervisedLeaves: Array<Scalars['ID']>;
  geuHolidays: Array<GeuHoliday>;
  gisAtlasZone?: Maybe<GisAtlasZone>;
  /** Reads a single `GisAtlasZone` using its globally unique `ID`. */
  gisAtlasZoneByNodeId?: Maybe<GisAtlasZone>;
  /** Reads a set of `GisAtlasZone`. */
  gisAtlasZones?: Maybe<Array<GisAtlasZone>>;
  /** Reads a set of `ImapActiveStructure`. */
  imapActiveStructures?: Maybe<Array<ImapActiveStructure>>;
  /** Reads a set of `ImapContinuousRoad`. */
  imapContinuousRoads?: Maybe<Array<ImapContinuousRoad>>;
  imapCulvert?: Maybe<ImapCulvert>;
  /** Reads a single `ImapCulvert` using its globally unique `ID`. */
  imapCulvertByNodeId?: Maybe<ImapCulvert>;
  /** Reads a set of `ImapCulvert`. */
  imapCulverts?: Maybe<Array<ImapCulvert>>;
  imapDrainage?: Maybe<ImapDrainage>;
  /** Reads a single `ImapDrainage` using its globally unique `ID`. */
  imapDrainageByNodeId?: Maybe<ImapDrainage>;
  /** Reads a set of `ImapDrainage`. */
  imapDrainages?: Maybe<Array<ImapDrainage>>;
  imapGuardrail?: Maybe<ImapGuardrail>;
  /** Reads a single `ImapGuardrail` using its globally unique `ID`. */
  imapGuardrailByNodeId?: Maybe<ImapGuardrail>;
  /** Reads a set of `ImapGuardrail`. */
  imapGuardrails?: Maybe<Array<ImapGuardrail>>;
  imapHighwayProfile?: Maybe<ImapHighwayProfile>;
  /** Reads a single `ImapHighwayProfile` using its globally unique `ID`. */
  imapHighwayProfileByNodeId?: Maybe<ImapHighwayProfile>;
  /** Reads a set of `ImapHighwayProfile`. */
  imapHighwayProfiles?: Maybe<Array<ImapHighwayProfile>>;
  imapLandmark?: Maybe<ImapLandmark>;
  /** Reads a single `ImapLandmark` using its globally unique `ID`. */
  imapLandmarkByNodeId?: Maybe<ImapLandmark>;
  /** Reads a set of `ImapLandmark`. */
  imapLandmarks?: Maybe<Array<ImapLandmark>>;
  imapRestarea?: Maybe<ImapRestarea>;
  /** Reads a single `ImapRestarea` using its globally unique `ID`. */
  imapRestareaByNodeId?: Maybe<ImapRestarea>;
  /** Reads a set of `ImapRestarea`. */
  imapRestareas?: Maybe<Array<ImapRestarea>>;
  imapRetainingWall?: Maybe<ImapRetainingWall>;
  /** Reads a single `ImapRetainingWall` using its globally unique `ID`. */
  imapRetainingWallByNodeId?: Maybe<ImapRetainingWall>;
  /** Reads a set of `ImapRetainingWall`. */
  imapRetainingWalls?: Maybe<Array<ImapRetainingWall>>;
  imapRoadClass?: Maybe<ImapRoadClass>;
  imapRoadClassByHwyMaintenanceClassId?: Maybe<ImapRoadClass>;
  /** Reads a single `ImapRoadClass` using its globally unique `ID`. */
  imapRoadClassByNodeId?: Maybe<ImapRoadClass>;
  imapRoadClassFeature?: Maybe<ImapRoadClassFeature>;
  /** Reads a single `ImapRoadClassFeature` using its globally unique `ID`. */
  imapRoadClassFeatureByNodeId?: Maybe<ImapRoadClassFeature>;
  /** Reads a set of `ImapRoadClassFeatureExpanded`. */
  imapRoadClassFeatureExpandeds?: Maybe<Array<ImapRoadClassFeatureExpanded>>;
  /** Reads a set of `ImapRoadClassFeature`. */
  imapRoadClassFeatures?: Maybe<Array<ImapRoadClassFeature>>;
  /** Reads a set of `ImapRoadClass`. */
  imapRoadClasses?: Maybe<Array<ImapRoadClass>>;
  imapRoadFeature?: Maybe<ImapRoadFeature>;
  /** Reads a single `ImapRoadFeature` using its globally unique `ID`. */
  imapRoadFeatureByNodeId?: Maybe<ImapRoadFeature>;
  imapRoadFeatureByRfiHighwayName?: Maybe<ImapRoadFeature>;
  imapRoadFeatureByRoadFeatureInvntryId?: Maybe<ImapRoadFeature>;
  /** Reads a set of `ImapRoadFeature`. */
  imapRoadFeatures?: Maybe<Array<ImapRoadFeature>>;
  imapRoadStructure?: Maybe<ImapRoadStructure>;
  /** Reads a single `ImapRoadStructure` using its globally unique `ID`. */
  imapRoadStructureByNodeId?: Maybe<ImapRoadStructure>;
  /** Reads a set of `ImapRoadStructure`. */
  imapRoadStructures?: Maybe<Array<ImapRoadStructure>>;
  imapRoadSurface?: Maybe<ImapRoadSurface>;
  /** Reads a single `ImapRoadSurface` using its globally unique `ID`. */
  imapRoadSurfaceByNodeId?: Maybe<ImapRoadSurface>;
  imapRoadSurfaceByRoadSurfaceTypeId?: Maybe<ImapRoadSurface>;
  imapRoadSurfaceFeature?: Maybe<ImapRoadSurfaceFeature>;
  /** Reads a single `ImapRoadSurfaceFeature` using its globally unique `ID`. */
  imapRoadSurfaceFeatureByNodeId?: Maybe<ImapRoadSurfaceFeature>;
  /** Reads a set of `ImapRoadSurfaceFeature`. */
  imapRoadSurfaceFeatures?: Maybe<Array<ImapRoadSurfaceFeature>>;
  /** Reads a set of `ImapRoadSurface`. */
  imapRoadSurfaces?: Maybe<Array<ImapRoadSurface>>;
  /** Reads a set of `ImapSafety`. */
  imapSafeties?: Maybe<Array<ImapSafety>>;
  imapSafety?: Maybe<ImapSafety>;
  /** Reads a single `ImapSafety` using its globally unique `ID`. */
  imapSafetyByNodeId?: Maybe<ImapSafety>;
  imapSign?: Maybe<ImapSign>;
  /** Reads a single `ImapSign` using its globally unique `ID`. */
  imapSignByNodeId?: Maybe<ImapSign>;
  /** Reads a set of `ImapSign`. */
  imapSigns?: Maybe<Array<ImapSign>>;
  inventory: Array<InventoryItem>;
  latestPositions: Scalars['CSV'];
  mins?: Maybe<Scalars['Int']>;
  nearbyEquipment: Array<NearbyEquipment>;
  /** Reads a set of `OpsActivity`. */
  opsActivities?: Maybe<Array<OpsActivity>>;
  opsActivity?: Maybe<OpsActivity>;
  opsActivityByActivityCode?: Maybe<OpsActivity>;
  /** Reads a single `OpsActivity` using its globally unique `ID`. */
  opsActivityByNodeId?: Maybe<OpsActivity>;
  opsActivityRate?: Maybe<OpsActivityRate>;
  opsActivityRateByActivityIdAndServiceAreaAndStartDate?: Maybe<OpsActivityRate>;
  /** Reads a single `OpsActivityRate` using its globally unique `ID`. */
  opsActivityRateByNodeId?: Maybe<OpsActivityRate>;
  /** Reads a set of `OpsActivityRate`. */
  opsActivityRates?: Maybe<Array<OpsActivityRate>>;
  opsBlock?: Maybe<OpsBlock>;
  /** Reads a single `OpsBlock` using its globally unique `ID`. */
  opsBlockByNodeId?: Maybe<OpsBlock>;
  opsBlockByServiceAreaAndBlockCode?: Maybe<OpsBlock>;
  /** Reads a set of `OpsBlockRfi`. */
  opsBlockRfis?: Maybe<Array<OpsBlockRfi>>;
  opsBlockRoad?: Maybe<OpsBlockRoad>;
  /** Reads a single `OpsBlockRoad` using its globally unique `ID`. */
  opsBlockRoadByNodeId?: Maybe<OpsBlockRoad>;
  /** Reads a set of `OpsBlockRoad`. */
  opsBlockRoads?: Maybe<Array<OpsBlockRoad>>;
  opsBlockType?: Maybe<OpsBlockType>;
  opsBlockTypeByDescription?: Maybe<OpsBlockType>;
  /** Reads a single `OpsBlockType` using its globally unique `ID`. */
  opsBlockTypeByNodeId?: Maybe<OpsBlockType>;
  /** Reads a set of `OpsBlockType`. */
  opsBlockTypes?: Maybe<Array<OpsBlockType>>;
  /** Reads a set of `OpsBlock`. */
  opsBlocks?: Maybe<Array<OpsBlock>>;
  opsGroup?: Maybe<OpsGroup>;
  opsGroupByDescription?: Maybe<OpsGroup>;
  /** Reads a single `OpsGroup` using its globally unique `ID`. */
  opsGroupByNodeId?: Maybe<OpsGroup>;
  opsGroupType?: Maybe<OpsGroupType>;
  opsGroupTypeByDescription?: Maybe<OpsGroupType>;
  /** Reads a single `OpsGroupType` using its globally unique `ID`. */
  opsGroupTypeByNodeId?: Maybe<OpsGroupType>;
  /** Reads a set of `OpsGroupType`. */
  opsGroupTypes?: Maybe<Array<OpsGroupType>>;
  /** Reads a set of `OpsGroup`. */
  opsGroups?: Maybe<Array<OpsGroup>>;
  opsMaterial?: Maybe<OpsMaterial>;
  opsMaterialByMaterialCode?: Maybe<OpsMaterial>;
  /** Reads a single `OpsMaterial` using its globally unique `ID`. */
  opsMaterialByNodeId?: Maybe<OpsMaterial>;
  /** Reads a set of `OpsMaterialTransfer`. */
  opsMaterialTransfers?: Maybe<Array<OpsMaterialTransfer>>;
  /** Reads a set of `OpsMaterial`. */
  opsMaterials?: Maybe<Array<OpsMaterial>>;
  opsMembershipCustom?: Maybe<OpsMembershipCustom>;
  /** Reads a single `OpsMembershipCustom` using its globally unique `ID`. */
  opsMembershipCustomByNodeId?: Maybe<OpsMembershipCustom>;
  /** Reads a set of `OpsMembershipCustom`. */
  opsMembershipCustoms?: Maybe<Array<OpsMembershipCustom>>;
  /** Reads a set of `OpsMembershipHomeYard`. */
  opsMembershipHomeYards?: Maybe<Array<OpsMembershipHomeYard>>;
  /** Reads a set of `OpsMembershipSupervisor`. */
  opsMembershipSupervisors?: Maybe<Array<OpsMembershipSupervisor>>;
  /** Reads a set of `OpsMembership`. */
  opsMemberships?: Maybe<Array<OpsMembership>>;
  /** Reads a set of `OpsPatrol`. */
  opsPatrols?: Maybe<Array<OpsPatrol>>;
  opsSchedule?: Maybe<OpsSchedule>;
  /** Reads a single `OpsSchedule` using its globally unique `ID`. */
  opsScheduleByNodeId?: Maybe<OpsSchedule>;
  /** Reads a set of `OpsScheduleCurrent`. */
  opsScheduleCurrents?: Maybe<Array<OpsScheduleCurrent>>;
  /** Reads a set of `OpsSchedule`. */
  opsSchedules?: Maybe<Array<OpsSchedule>>;
  opsShiftPattern?: Maybe<OpsShiftPattern>;
  opsShiftPatternByDescription?: Maybe<OpsShiftPattern>;
  /** Reads a single `OpsShiftPattern` using its globally unique `ID`. */
  opsShiftPatternByNodeId?: Maybe<OpsShiftPattern>;
  /** Reads a set of `OpsShiftPattern`. */
  opsShiftPatterns?: Maybe<Array<OpsShiftPattern>>;
  opsShiftWeek?: Maybe<OpsShiftWeek>;
  /** Reads a single `OpsShiftWeek` using its globally unique `ID`. */
  opsShiftWeekByNodeId?: Maybe<OpsShiftWeek>;
  /** Reads a set of `OpsShiftWeek`. */
  opsShiftWeeks?: Maybe<Array<OpsShiftWeek>>;
  opsSupervisor?: Maybe<OpsSupervisor>;
  /** Reads a single `OpsSupervisor` using its globally unique `ID`. */
  opsSupervisorByNodeId?: Maybe<OpsSupervisor>;
  /** Reads a set of `OpsSupervisor`. */
  opsSupervisors?: Maybe<Array<OpsSupervisor>>;
  /** Reads a set of `OpsWorkreport`. */
  opsWorkreports?: Maybe<Array<OpsWorkreport>>;
  opsYard?: Maybe<OpsYard>;
  opsYardByDescription?: Maybe<OpsYard>;
  /** Reads a single `OpsYard` using its globally unique `ID`. */
  opsYardByNodeId?: Maybe<OpsYard>;
  /** Reads a set of `OpsYard`. */
  opsYards?: Maybe<Array<OpsYard>>;
  overtime: Array<Overtime>;
  patrolAges: Array<PatrolAge>;
  /** Reads a set of `PatrolHistory`. */
  patrolHistories?: Maybe<Array<PatrolHistory>>;
  patrolRecords: Array<PatrolRecord>;
  /** Reads a set of `PatrolRoad`. */
  patrolRoads?: Maybe<Array<PatrolRoad>>;
  recentVisits: Array<Visit>;
  roads: Array<Road>;
  rwisForecast?: Maybe<RwisForecast>;
  /** Reads a single `RwisForecast` using its globally unique `ID`. */
  rwisForecastByNodeId?: Maybe<RwisForecast>;
  rwisForecastByStationIdAndIssueTime?: Maybe<RwisForecast>;
  rwisForecastTarget?: Maybe<RwisForecastTarget>;
  /** Reads a single `RwisForecastTarget` using its globally unique `ID`. */
  rwisForecastTargetByNodeId?: Maybe<RwisForecastTarget>;
  /** Reads a set of `RwisForecastTarget`. */
  rwisForecastTargets?: Maybe<Array<RwisForecastTarget>>;
  /** Reads a set of `RwisForecast`. */
  rwisForecasts?: Maybe<Array<RwisForecast>>;
  rwisReading?: Maybe<RwisReading>;
  /** Reads a single `RwisReading` using its globally unique `ID`. */
  rwisReadingByNodeId?: Maybe<RwisReading>;
  /** Reads a set of `RwisReading`. */
  rwisReadings?: Maybe<Array<RwisReading>>;
  /** Reads a set of `RwisSimpleForecast`. */
  rwisSimpleForecasts?: Maybe<Array<RwisSimpleForecast>>;
  /** Reads a set of `RwisSimpleReading`. */
  rwisSimpleReadings?: Maybe<Array<RwisSimpleReading>>;
  rwisStation?: Maybe<RwisStation>;
  /** Reads a single `RwisStation` using its globally unique `ID`. */
  rwisStationByNodeId?: Maybe<RwisStation>;
  rwisStationByStationName?: Maybe<RwisStation>;
  /** Reads a set of `RwisStation`. */
  rwisStations?: Maybe<Array<RwisStation>>;
  rwis_stations: Array<Station>;
  /** Reads a set of `Sa7Abnormal`. */
  sa7Abnormals?: Maybe<Array<Sa7Abnormal>>;
  /** Reads a set of `Sa7ShiftPremium`. */
  sa7ShiftPremiums?: Maybe<Array<Sa7ShiftPremium>>;
  /** Reads a set of `Sa7Unsociable`. */
  sa7Unsociables?: Maybe<Array<Sa7Unsociable>>;
  /** Reads a set of `Sa7Weekend`. */
  sa7Weekends?: Maybe<Array<Sa7Weekend>>;
  /** Reads a set of `Sa20ShiftPremiumCalc`. */
  sa20ShiftPremiumCalcs?: Maybe<Array<Sa20ShiftPremiumCalc>>;
  /** Reads a set of `Sa20ShiftPremium`. */
  sa20ShiftPremiums?: Maybe<Array<Sa20ShiftPremium>>;
  /** Reads a set of `Sa26ShiftPremiumCalc`. */
  sa26ShiftPremiumCalcs?: Maybe<Array<Sa26ShiftPremiumCalc>>;
  /** Reads a set of `Sa26ShiftPremium`. */
  sa26ShiftPremiums?: Maybe<Array<Sa26ShiftPremium>>;
  /** Reads a set of `Sa26WingUnderbody`. */
  sa26WingUnderbodies?: Maybe<Array<Sa26WingUnderbody>>;
  scrs: Array<Scr>;
  /** Reads a set of `SdCompany`. */
  sdCompanies?: Maybe<Array<SdCompany>>;
  sdCompany?: Maybe<SdCompany>;
  sdCompanyByDescription?: Maybe<SdCompany>;
  /** Reads a single `SdCompany` using its globally unique `ID`. */
  sdCompanyByNodeId?: Maybe<SdCompany>;
  sdForm?: Maybe<SdForm>;
  /** Reads a single `SdForm` using its globally unique `ID`. */
  sdFormByNodeId?: Maybe<SdForm>;
  sdFormItem?: Maybe<SdFormItem>;
  /** Reads a single `SdFormItem` using its globally unique `ID`. */
  sdFormItemByNodeId?: Maybe<SdFormItem>;
  /** Reads a set of `SdFormItem`. */
  sdFormItems?: Maybe<Array<SdFormItem>>;
  sdFormType?: Maybe<SdFormType>;
  sdFormTypeByDocumentTemplateId?: Maybe<SdFormType>;
  /** Reads a single `SdFormType` using its globally unique `ID`. */
  sdFormTypeByNodeId?: Maybe<SdFormType>;
  /** Reads a set of `SdFormType`. */
  sdFormTypes?: Maybe<Array<SdFormType>>;
  sdFormWorkerValue?: Maybe<SdFormWorkerValue>;
  /** Reads a single `SdFormWorkerValue` using its globally unique `ID`. */
  sdFormWorkerValueByNodeId?: Maybe<SdFormWorkerValue>;
  /** Reads a set of `SdFormWorkerValue`. */
  sdFormWorkerValues?: Maybe<Array<SdFormWorkerValue>>;
  /** Reads a set of `SdForm`. */
  sdForms?: Maybe<Array<SdForm>>;
  sdLocation?: Maybe<SdLocation>;
  /** Reads a single `SdLocation` using its globally unique `ID`. */
  sdLocationByNodeId?: Maybe<SdLocation>;
  /** Reads a set of `SdLocation`. */
  sdLocations?: Maybe<Array<SdLocation>>;
  sdSignature?: Maybe<SdSignature>;
  /** Reads a single `SdSignature` using its globally unique `ID`. */
  sdSignatureByNodeId?: Maybe<SdSignature>;
  /** Reads a set of `SdSignature`. */
  sdSignatures?: Maybe<Array<SdSignature>>;
  sdWorker?: Maybe<SdWorker>;
  /** Reads a single `SdWorker` using its globally unique `ID`. */
  sdWorkerByNodeId?: Maybe<SdWorker>;
  /** Reads a set of `SdWorker`. */
  sdWorkers?: Maybe<Array<SdWorker>>;
  serverTime: Scalars['DateTime'];
  structures: Array<Structure>;
  /** Reads a set of `TcActivityRate`. */
  tcActivityRates?: Maybe<Array<TcActivityRate>>;
  /** Reads a set of `TcAllM`. */
  tcAllMs?: Maybe<Array<TcAllM>>;
  /** Reads a set of `TcAssetUsage`. */
  tcAssetUsages?: Maybe<Array<TcAssetUsage>>;
  /** Reads a set of `TcAuxHolidayEligibility`. */
  tcAuxHolidayEligibilities?: Maybe<Array<TcAuxHolidayEligibility>>;
  /** Reads a set of `TcClassPlurality`. */
  tcClassPluralities?: Maybe<Array<TcClassPlurality>>;
  tcCollectiveAgreement?: Maybe<TcCollectiveAgreement>;
  /** Reads a single `TcCollectiveAgreement` using its globally unique `ID`. */
  tcCollectiveAgreementByNodeId?: Maybe<TcCollectiveAgreement>;
  /** Reads a set of `TcCollectiveAgreement`. */
  tcCollectiveAgreements?: Maybe<Array<TcCollectiveAgreement>>;
  /** Reads a set of `TcCompany`. */
  tcCompanies?: Maybe<Array<TcCompany>>;
  tcCompany?: Maybe<TcCompany>;
  tcCompanyAgreement?: Maybe<TcCompanyAgreement>;
  /** Reads a single `TcCompanyAgreement` using its globally unique `ID`. */
  tcCompanyAgreementByNodeId?: Maybe<TcCompanyAgreement>;
  /** Reads a set of `TcCompanyAgreement`. */
  tcCompanyAgreements?: Maybe<Array<TcCompanyAgreement>>;
  /** Reads a single `TcCompany` using its globally unique `ID`. */
  tcCompanyByNodeId?: Maybe<TcCompany>;
  /** Reads a set of `TcDominantClass`. */
  tcDominantClasses?: Maybe<Array<TcDominantClass>>;
  tcEarningCodeRate?: Maybe<TcEarningCodeRate>;
  /** Reads a single `TcEarningCodeRate` using its globally unique `ID`. */
  tcEarningCodeRateByNodeId?: Maybe<TcEarningCodeRate>;
  /** Reads a set of `TcEarningCodeRate`. */
  tcEarningCodeRates?: Maybe<Array<TcEarningCodeRate>>;
  tcEquipment?: Maybe<TcEquipment>;
  /** Reads a single `TcEquipment` using its globally unique `ID`. */
  tcEquipmentByNodeId?: Maybe<TcEquipment>;
  /** Reads a set of `TcEquipmentExtra`. */
  tcEquipmentExtras?: Maybe<Array<TcEquipmentExtra>>;
  /** Reads a set of `TcEquipment`. */
  tcEquipments?: Maybe<Array<TcEquipment>>;
  /** Reads a set of `TcEsaEc`. */
  tcEsaEcs?: Maybe<Array<TcEsaEc>>;
  /** Reads a set of `TcEsaHolidayCompensation`. */
  tcEsaHolidayCompensations?: Maybe<Array<TcEsaHolidayCompensation>>;
  /** Reads a set of `TcEsaHolidayEc`. */
  tcEsaHolidayEcs?: Maybe<Array<TcEsaHolidayEc>>;
  /** Reads a set of `TcEsaHolidayWorker`. */
  tcEsaHolidayWorkers?: Maybe<Array<TcEsaHolidayWorker>>;
  /** Reads a set of `TcEsaHoliday`. */
  tcEsaHolidays?: Maybe<Array<TcEsaHoliday>>;
  /** Reads a set of `TcEsaOt`. */
  tcEsaOts?: Maybe<Array<TcEsaOt>>;
  /** Reads a set of `TcEsaVacationCompensation`. */
  tcEsaVacationCompensations?: Maybe<Array<TcEsaVacationCompensation>>;
  /** Reads a set of `TcEsaWorkEc`. */
  tcEsaWorkEcs?: Maybe<Array<TcEsaWorkEc>>;
  /** Reads a set of `TcEsa`. */
  tcEsas?: Maybe<Array<TcEsa>>;
  /** Reads a set of `TcGeuBenefit`. */
  tcGeuBenefits?: Maybe<Array<TcGeuBenefit>>;
  /** Reads a set of `TcGeuCalloutCalc`. */
  tcGeuCalloutCalcs?: Maybe<Array<TcGeuCalloutCalc>>;
  /** Reads a set of `TcGeuCalloutWorkEc`. */
  tcGeuCalloutWorkEcs?: Maybe<Array<TcGeuCalloutWorkEc>>;
  /** Reads a set of `TcGeuCallout`. */
  tcGeuCallouts?: Maybe<Array<TcGeuCallout>>;
  /** Reads a set of `TcGeuHolidaySchedule`. */
  tcGeuHolidaySchedules?: Maybe<Array<TcGeuHolidaySchedule>>;
  /** Reads a set of `TcGeuHolidayWorkerView`. */
  tcGeuHolidayWorkerViews?: Maybe<Array<TcGeuHolidayWorkerView>>;
  /** Reads a set of `TcGeuHolidayWorker`. */
  tcGeuHolidayWorkers?: Maybe<Array<TcGeuHolidayWorker>>;
  /** Reads a set of `TcGeuHoliday`. */
  tcGeuHolidays?: Maybe<Array<TcGeuHoliday>>;
  /** Reads a set of `TcGeuMissedMeal`. */
  tcGeuMissedMeals?: Maybe<Array<TcGeuMissedMeal>>;
  /** Reads a set of `TcGeuOmitShiftEc`. */
  tcGeuOmitShiftEcs?: Maybe<Array<TcGeuOmitShiftEc>>;
  /** Reads a set of `TcGeuOmitShift`. */
  tcGeuOmitShifts?: Maybe<Array<TcGeuOmitShift>>;
  /** Reads a set of `TcGeuOtMeal`. */
  tcGeuOtMeals?: Maybe<Array<TcGeuOtMeal>>;
  /** Reads a set of `TcGeuOt`. */
  tcGeuOts?: Maybe<Array<TcGeuOt>>;
  /** Reads a set of `TcGeuShiftBreakdown`. */
  tcGeuShiftBreakdowns?: Maybe<Array<TcGeuShiftBreakdown>>;
  /** Reads a set of `TcGeuShiftCalc`. */
  tcGeuShiftCalcs?: Maybe<Array<TcGeuShiftCalc>>;
  /** Reads a set of `TcGeuShiftEc`. */
  tcGeuShiftEcs?: Maybe<Array<TcGeuShiftEc>>;
  /** Reads a set of `TcGeuShiftOt`. */
  tcGeuShiftOts?: Maybe<Array<TcGeuShiftOt>>;
  /** Reads a set of `TcGeuShiftWorkEc`. */
  tcGeuShiftWorkEcs?: Maybe<Array<TcGeuShiftWorkEc>>;
  /** Reads a set of `TcGeuShift`. */
  tcGeuShifts?: Maybe<Array<TcGeuShift>>;
  /** Reads a set of `TcGeuShortfall`. */
  tcGeuShortfalls?: Maybe<Array<TcGeuShortfall>>;
  /** Reads a set of `TcGeuSickConsecutive`. */
  tcGeuSickConsecutives?: Maybe<Array<TcGeuSickConsecutive>>;
  /** Reads a set of `TcGeuUnclearCallout`. */
  tcGeuUnclearCallouts?: Maybe<Array<TcGeuUnclearCallout>>;
  /** Reads a set of `TcGeuVp`. */
  tcGeuVps?: Maybe<Array<TcGeuVp>>;
  /** Reads a set of `TcGeu`. */
  tcGeus?: Maybe<Array<TcGeu>>;
  /** Reads a set of `TcHighlight`. */
  tcHighlights?: Maybe<Array<TcHighlight>>;
  /** Reads a set of `TcHistoricalClassMaterialized`. */
  tcHistoricalClassMaterializeds?: Maybe<Array<TcHistoricalClassMaterialized>>;
  /** Reads a set of `TcHistoricalClass`. */
  tcHistoricalClasses?: Maybe<Array<TcHistoricalClass>>;
  tcHoliday?: Maybe<TcHoliday>;
  /** Reads a set of `TcHolidayAlternate`. */
  tcHolidayAlternates?: Maybe<Array<TcHolidayAlternate>>;
  /** Reads a single `TcHoliday` using its globally unique `ID`. */
  tcHolidayByNodeId?: Maybe<TcHoliday>;
  /** Reads a set of `TcHolidayUser`. */
  tcHolidayUsers?: Maybe<Array<TcHolidayUser>>;
  /** Reads a set of `TcHoliday`. */
  tcHolidays?: Maybe<Array<TcHoliday>>;
  tcLeave?: Maybe<TcLeave>;
  /** Reads a single `TcLeave` using its globally unique `ID`. */
  tcLeaveByNodeId?: Maybe<TcLeave>;
  /** Reads a set of `TcLeaveDaily`. */
  tcLeaveDailies?: Maybe<Array<TcLeaveDaily>>;
  /** Reads a set of `TcLeaveDailyScheduled`. */
  tcLeaveDailyScheduleds?: Maybe<Array<TcLeaveDailyScheduled>>;
  /** Reads a set of `TcLeaveGeuSickCompensation`. */
  tcLeaveGeuSickCompensations?: Maybe<Array<TcLeaveGeuSickCompensation>>;
  /** Reads a set of `TcLeave`. */
  tcLeaves?: Maybe<Array<TcLeave>>;
  tcMaterial?: Maybe<TcMaterial>;
  /** Reads a single `TcMaterial` using its globally unique `ID`. */
  tcMaterialByNodeId?: Maybe<TcMaterial>;
  /** Reads a set of `TcMaterial`. */
  tcMaterials?: Maybe<Array<TcMaterial>>;
  /** Reads a set of `TcMeal`. */
  tcMeals?: Maybe<Array<TcMeal>>;
  /** Reads a set of `TcOtSummary`. */
  tcOtSummaries?: Maybe<Array<TcOtSummary>>;
  /** Reads a set of `TcPayrollPrimary`. */
  tcPayrollPrimaries?: Maybe<Array<TcPayrollPrimary>>;
  tcRejection?: Maybe<TcRejection>;
  /** Reads a single `TcRejection` using its globally unique `ID`. */
  tcRejectionByNodeId?: Maybe<TcRejection>;
  /** Reads a set of `TcRejection`. */
  tcRejections?: Maybe<Array<TcRejection>>;
  /** Reads a set of `TcSickApproved`. */
  tcSickApproveds?: Maybe<Array<TcSickApproved>>;
  tcTimecard?: Maybe<TcTimecard>;
  /** Reads a single `TcTimecard` using its globally unique `ID`. */
  tcTimecardByNodeId?: Maybe<TcTimecard>;
  /** Reads a set of `TcTimecard`. */
  tcTimecards?: Maybe<Array<TcTimecard>>;
  /** Reads a set of `TcUserAgreement`. */
  tcUserAgreements?: Maybe<Array<TcUserAgreement>>;
  tcVacationAllotment?: Maybe<TcVacationAllotment>;
  /** Reads a single `TcVacationAllotment` using its globally unique `ID`. */
  tcVacationAllotmentByNodeId?: Maybe<TcVacationAllotment>;
  /** Reads a set of `TcVacationAllotment`. */
  tcVacationAllotments?: Maybe<Array<TcVacationAllotment>>;
  /** Reads a set of `TcVpUnion`. */
  tcVpUnions?: Maybe<Array<TcVpUnion>>;
  tcWork?: Maybe<TcWork>;
  /** Reads a single `TcWork` using its globally unique `ID`. */
  tcWorkByNodeId?: Maybe<TcWork>;
  /** Reads a set of `TcWorkExtended`. */
  tcWorkExtendeds?: Maybe<Array<TcWorkExtended>>;
  tcWorkLeave?: Maybe<TcWorkLeave>;
  /** Reads a single `TcWorkLeave` using its globally unique `ID`. */
  tcWorkLeaveByNodeId?: Maybe<TcWorkLeave>;
  /** Reads a set of `TcWorkLeaveEcMaterialized`. */
  tcWorkLeaveEcMaterializeds?: Maybe<Array<TcWorkLeaveEcMaterialized>>;
  /** Reads a set of `TcWorkLeaveEc`. */
  tcWorkLeaveEcs?: Maybe<Array<TcWorkLeaveEc>>;
  /** Reads a set of `TcWorkLeaveGeuSickCompensation`. */
  tcWorkLeaveGeuSickCompensations?: Maybe<Array<TcWorkLeaveGeuSickCompensation>>;
  /** Reads a set of `TcWorkLeave`. */
  tcWorkLeaves?: Maybe<Array<TcWorkLeave>>;
  /** Reads a set of `TcWorkSummary`. */
  tcWorkSummaries?: Maybe<Array<TcWorkSummary>>;
  /** Reads a set of `TcWorkSummaryText`. */
  tcWorkSummaryTexts?: Maybe<Array<TcWorkSummaryText>>;
  tcWorkTest?: Maybe<TcWorkTest>;
  /** Reads a single `TcWorkTest` using its globally unique `ID`. */
  tcWorkTestByNodeId?: Maybe<TcWorkTest>;
  /** Reads a set of `TcWorkTest`. */
  tcWorkTests?: Maybe<Array<TcWorkTest>>;
  tcWorkTime?: Maybe<TcWorkTime>;
  /** Reads a single `TcWorkTime` using its globally unique `ID`. */
  tcWorkTimeByNodeId?: Maybe<TcWorkTime>;
  /** Reads a set of `TcWorkTime`. */
  tcWorkTimes?: Maybe<Array<TcWorkTime>>;
  /** Reads a set of `TcWorkVp`. */
  tcWorkVps?: Maybe<Array<TcWorkVp>>;
  /** Reads a set of `TcWork`. */
  tcWorks?: Maybe<Array<TcWork>>;
  timecardHighlights: Array<TimecardHighlights>;
  uom?: Maybe<Uom>;
  uomByDescription?: Maybe<Uom>;
  uomByMotiCode?: Maybe<Uom>;
  /** Reads a single `Uom` using its globally unique `ID`. */
  uomByNodeId?: Maybe<Uom>;
  uomByUomCode?: Maybe<Uom>;
  /** Reads a set of `Uom`. */
  uoms?: Maybe<Array<Uom>>;
  vehicleDiagnostic?: Maybe<VehicleDiagnostic>;
  vehicleDiagnosticByAssetIdAndObdCodeAndDatetime?: Maybe<VehicleDiagnostic>;
  vehicleDiagnosticByAssetIdAndSpnCodeAndFmiCodeAndDatetime?: Maybe<VehicleDiagnostic>;
  /** Reads a single `VehicleDiagnostic` using its globally unique `ID`. */
  vehicleDiagnosticByNodeId?: Maybe<VehicleDiagnostic>;
  vehicleDiagnosticSummaries: Array<VehicleDiagnosticSummary>;
  /** Reads a set of `VehicleDiagnostic`. */
  vehicleDiagnostics?: Maybe<Array<VehicleDiagnostic>>;
  vehicleLatestServices: Array<VehicleServiceSummary>;
  vehicleService?: Maybe<VehicleService>;
  /** Reads a single `VehicleService` using its globally unique `ID`. */
  vehicleServiceByNodeId?: Maybe<VehicleService>;
  /** Reads a set of `VehicleService`. */
  vehicleServices?: Maybe<Array<VehicleService>>;
  vehicleStatus?: Maybe<VehicleStatus>;
  /** Reads a single `VehicleStatus` using its globally unique `ID`. */
  vehicleStatusByNodeId?: Maybe<VehicleStatus>;
  vehicleStatusSummaries: Array<VehicleStatusSummary>;
  /** Reads a set of `VehicleStatus`. */
  vehicleStatuses?: Maybe<Array<VehicleStatus>>;
  vehicleSummaries: Array<VehicleSummary>;
  viewpointExport: Array<PayrollEntry>;
  viewpointExportCsv: Scalars['String'];
  /** Reads a set of `VpBallinaProbation`. */
  vpBallinaProbations?: Maybe<Array<VpBallinaProbation>>;
  /** Reads a set of `VpCraftClass`. */
  vpCraftClasses?: Maybe<Array<VpCraftClass>>;
  /** Reads a set of `VpCraft`. */
  vpCrafts?: Maybe<Array<VpCraft>>;
  /** Reads a set of `VpEmployee`. */
  vpEmployees?: Maybe<Array<VpEmployee>>;
  /** Reads a set of `VpEquipment`. */
  vpEquipments?: Maybe<Array<VpEquipment>>;
  vpJobOverride?: Maybe<VpJobOverride>;
  /** Reads a single `VpJobOverride` using its globally unique `ID`. */
  vpJobOverrideByNodeId?: Maybe<VpJobOverride>;
  /** Reads a set of `VpJobOverride`. */
  vpJobOverrides?: Maybe<Array<VpJobOverride>>;
  /** Reads a set of `VpJob`. */
  vpJobs?: Maybe<Array<VpJob>>;
  /** Reads a set of `VpLocation`. */
  vpLocations?: Maybe<Array<VpLocation>>;
  vpPhaseOverride?: Maybe<VpPhaseOverride>;
  /** Reads a single `VpPhaseOverride` using its globally unique `ID`. */
  vpPhaseOverrideByNodeId?: Maybe<VpPhaseOverride>;
  /** Reads a set of `VpPhaseOverride`. */
  vpPhaseOverrides?: Maybe<Array<VpPhaseOverride>>;
  /** Reads a set of `VpPhase`. */
  vpPhases?: Maybe<Array<VpPhase>>;
  weather: Array<WeatherStation>;
  workDetails: Array<WorkDetail>;
  workSummaries: Array<YardWorkSummary>;
  workids: Array<WorkId>;
  workreport: Scalars['String'];
  /** Reads a set of `WrRecordFull`. */
  wrRecordFulls?: Maybe<Array<WrRecordFull>>;
  yardReports: Array<YardReport>;
};


export type QueryAssetArgs = {
  id: Scalars['Int'];
};


export type QueryAssetBreadcrumbArgs = {
  id: Scalars['Int'];
};


export type QueryAssetBreadcrumbByAssetIdAndGeogAndDatetimeArgs = {
  assetId: Scalars['Int'];
  datetime: Scalars['Datetime'];
  geog: Scalars['GeoJSON'];
};


export type QueryAssetBreadcrumbByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryAssetBreadcrumbsArgs = {
  condition?: InputMaybe<AssetBreadcrumbCondition>;
  filter?: InputMaybe<AssetBreadcrumbFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetBreadcrumbsOrderBy>>;
};


export type QueryAssetByAssetCodeArgs = {
  assetCode: Scalars['String'];
};


export type QueryAssetByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryAssetCategoriesArgs = {
  condition?: InputMaybe<AssetCategoryCondition>;
  filter?: InputMaybe<AssetCategoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetCategoriesOrderBy>>;
};


export type QueryAssetCategoryArgs = {
  prefix: Scalars['String'];
};


export type QueryAssetCategoryByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryAssetCurrentLocationsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetCurrentLocationsOrderBy>>;
};


export type QueryAssetLatestLocationsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetLatestLocationsOrderBy>>;
};


export type QueryAssetTypeArgs = {
  id: Scalars['Int'];
};


export type QueryAssetTypeByDescriptionArgs = {
  description: Scalars['String'];
};


export type QueryAssetTypeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryAssetTypesArgs = {
  condition?: InputMaybe<AssetTypeCondition>;
  filter?: InputMaybe<AssetTypeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetTypesOrderBy>>;
};


export type QueryAssetWingUnderbodiesArgs = {
  condition?: InputMaybe<AssetWingUnderbodyCondition>;
  filter?: InputMaybe<AssetWingUnderbodyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetWingUnderbodiesOrderBy>>;
};


export type QueryAssetWingUnderbodyArgs = {
  assetId: Scalars['Int'];
  effective: Scalars['Datetime'];
};


export type QueryAssetWingUnderbodyByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryAssetWingUnderbodyCurrentsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetWingUnderbodyCurrentsOrderBy>>;
};


export type QueryAssetWingUnderbodyPeriodsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetWingUnderbodyPeriodsOrderBy>>;
};


export type QueryAssetsArgs = {
  condition?: InputMaybe<AssetCondition>;
  filter?: InputMaybe<AssetFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssetsOrderBy>>;
};


export type QueryAuthSessionTokenArgs = {
  sessionId: Scalars['Int'];
  userId: Scalars['Int'];
};


export type QueryAuthSessionTokenByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryAuthSessionTokensArgs = {
  condition?: InputMaybe<AuthSessionTokenCondition>;
  filter?: InputMaybe<AuthSessionTokenFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthSessionTokensOrderBy>>;
};


export type QueryAuthUserArgs = {
  id: Scalars['Int'];
};


export type QueryAuthUserByEmailArgs = {
  email: Scalars['String'];
};


export type QueryAuthUserByEmployeeNoArgs = {
  employeeNo: Scalars['Int'];
};


export type QueryAuthUserByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryAuthUsersArgs = {
  condition?: InputMaybe<AuthUserCondition>;
  filter?: InputMaybe<AuthUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthUsersOrderBy>>;
};


export type QueryBecBiogeoclimaticArgs = {
  id: Scalars['Int'];
};


export type QueryBecBiogeoclimaticByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryBecBiogeoclimaticsArgs = {
  condition?: InputMaybe<BecBiogeoclimaticCondition>;
  filter?: InputMaybe<BecBiogeoclimaticFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BecBiogeoclimaticsOrderBy>>;
};


export type QueryBiForecastArgs = {
  id: Scalars['Int'];
};


export type QueryBiForecastByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryBiForecastPermissionArgs = {
  id: Scalars['Int'];
};


export type QueryBiForecastPermissionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryBiForecastPermissionsArgs = {
  condition?: InputMaybe<BiForecastPermissionCondition>;
  filter?: InputMaybe<BiForecastPermissionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BiForecastPermissionsOrderBy>>;
};


export type QueryBiForecastPhaseArgs = {
  id: Scalars['Int'];
};


export type QueryBiForecastPhaseByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryBiForecastPhasesArgs = {
  condition?: InputMaybe<BiForecastPhaseCondition>;
  filter?: InputMaybe<BiForecastPhaseFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BiForecastPhasesOrderBy>>;
};


export type QueryBiForecastsArgs = {
  condition?: InputMaybe<BiForecastCondition>;
  filter?: InputMaybe<BiForecastFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BiForecastsOrderBy>>;
};


export type QueryBreadcrumbYardsArgs = {
  condition?: InputMaybe<BreadcrumbYardCondition>;
  filter?: InputMaybe<BreadcrumbYardFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BreadcrumbYardsOrderBy>>;
};


export type QueryBreadcrumbsArgs = {
  assetCode?: InputMaybe<Scalars['String']>;
  since: Scalars['String'];
};


export type QueryBreadcrumbsSliceArgs = {
  assetCode?: InputMaybe<Scalars['String']>;
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};


export type QueryCollisionArgs = {
  caseNo: Scalars['String'];
};


export type QueryCollisionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryCollisionGeomsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CollisionGeomsOrderBy>>;
};


export type QueryCollisionsArgs = {
  condition?: InputMaybe<CollisionCondition>;
  filter?: InputMaybe<CollisionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CollisionsOrderBy>>;
};


export type QueryCompanyJobsArgs = {
  JCCo: Scalars['Int'];
  jobCode?: InputMaybe<Scalars['ID']>;
};


export type QueryContractsArgs = {
  JCCo: Scalars['Int'];
};


export type QueryDrivebcEventsArgs = {
  areas: Array<Scalars['String']>;
};


export type QueryDtcFmiArgs = {
  code: Scalars['Int'];
};


export type QueryDtcFmiByDescriptionArgs = {
  description: Scalars['String'];
};


export type QueryDtcFmiByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryDtcFmisArgs = {
  condition?: InputMaybe<DtcFmiCondition>;
  filter?: InputMaybe<DtcFmiFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DtcFmisOrderBy>>;
};


export type QueryDtcObdArgs = {
  code: Scalars['String'];
};


export type QueryDtcObdByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryDtcObdsArgs = {
  condition?: InputMaybe<DtcObdCondition>;
  filter?: InputMaybe<DtcObdFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DtcObdsOrderBy>>;
};


export type QueryDtcSpnArgs = {
  code: Scalars['Int'];
};


export type QueryDtcSpnByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryDtcSpnsArgs = {
  condition?: InputMaybe<DtcSpnCondition>;
  filter?: InputMaybe<DtcSpnFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DtcSpnsOrderBy>>;
};


export type QueryEquipmentRoadSummaryArgs = {
  assetCodes: Array<Scalars['String']>;
  since?: InputMaybe<Scalars['DateTime']>;
};


export type QueryEsaExportArgs = {
  co: Scalars['ID'];
  end?: InputMaybe<Scalars['String']>;
  start: Scalars['String'];
};


export type QueryEsaExportCsvArgs = {
  co: Scalars['ID'];
  end?: InputMaybe<Scalars['String']>;
  start: Scalars['String'];
};


export type QueryEsaHolidayCompensationsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EsaHolidayCompensationsOrderBy>>;
};


export type QueryEsaHolidayCsvArgs = {
  co: Scalars['ID'];
  holiday: Scalars['String'];
};


export type QueryFindRejectedTimecardsArgs = {
  worker: Scalars['ID'];
};


export type QueryFindRelatedTimecardsArgs = {
  end?: InputMaybe<Scalars['DateTime']>;
  phase: Scalars['ID'];
  road?: InputMaybe<Scalars['ID']>;
  serviceArea: Scalars['String'];
  start: Scalars['DateTime'];
};


export type QueryFindTimecardsArgs = {
  end?: InputMaybe<Scalars['DateTime']>;
  equipment?: InputMaybe<Array<Scalars['ID']>>;
  phases?: InputMaybe<Array<Scalars['ID']>>;
  start?: InputMaybe<Scalars['DateTime']>;
  workers?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryFleetShopDaysArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FleetShopDaysOrderBy>>;
};


export type QueryFleetShopMonthsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FleetShopMonthsOrderBy>>;
};


export type QueryFleetShopsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FleetShopsOrderBy>>;
};


export type QueryGetCompanyProbationArgs = {
  co: Scalars['ID'];
};


export type QueryGetNearestRoadArgs = {
  point: InputPoint;
};


export type QueryGetRecordsArgs = {
  userid?: InputMaybe<Scalars['Int']>;
};


export type QueryGetRoadRelationshipArgs = {
  point: InputPoint;
  rfi: Scalars['String'];
};


export type QueryGetSupervisedLeavesArgs = {
  pendingOnly: Scalars['Boolean'];
};


export type QueryGeuHolidaysArgs = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
  users: Array<Scalars['ID']>;
};


export type QueryGisAtlasZoneArgs = {
  id: Scalars['Int'];
};


export type QueryGisAtlasZoneByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryGisAtlasZonesArgs = {
  condition?: InputMaybe<GisAtlasZoneCondition>;
  filter?: InputMaybe<GisAtlasZoneFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GisAtlasZonesOrderBy>>;
};


export type QueryImapActiveStructuresArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImapActiveStructuresOrderBy>>;
};


export type QueryImapContinuousRoadsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImapContinuousRoadsOrderBy>>;
};


export type QueryImapCulvertArgs = {
  id: Scalars['Int'];
};


export type QueryImapCulvertByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryImapCulvertsArgs = {
  condition?: InputMaybe<ImapCulvertCondition>;
  filter?: InputMaybe<ImapCulvertFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImapCulvertsOrderBy>>;
};


export type QueryImapDrainageArgs = {
  id: Scalars['Int'];
};


export type QueryImapDrainageByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryImapDrainagesArgs = {
  condition?: InputMaybe<ImapDrainageCondition>;
  filter?: InputMaybe<ImapDrainageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImapDrainagesOrderBy>>;
};


export type QueryImapGuardrailArgs = {
  id: Scalars['Int'];
};


export type QueryImapGuardrailByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryImapGuardrailsArgs = {
  condition?: InputMaybe<ImapGuardrailCondition>;
  filter?: InputMaybe<ImapGuardrailFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImapGuardrailsOrderBy>>;
};


export type QueryImapHighwayProfileArgs = {
  id: Scalars['Int'];
};


export type QueryImapHighwayProfileByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryImapHighwayProfilesArgs = {
  condition?: InputMaybe<ImapHighwayProfileCondition>;
  filter?: InputMaybe<ImapHighwayProfileFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImapHighwayProfilesOrderBy>>;
};


export type QueryImapLandmarkArgs = {
  id: Scalars['Int'];
};


export type QueryImapLandmarkByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryImapLandmarksArgs = {
  condition?: InputMaybe<ImapLandmarkCondition>;
  filter?: InputMaybe<ImapLandmarkFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImapLandmarksOrderBy>>;
};


export type QueryImapRestareaArgs = {
  id: Scalars['Int'];
};


export type QueryImapRestareaByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryImapRestareasArgs = {
  condition?: InputMaybe<ImapRestareaCondition>;
  filter?: InputMaybe<ImapRestareaFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImapRestareasOrderBy>>;
};


export type QueryImapRetainingWallArgs = {
  id: Scalars['Int'];
};


export type QueryImapRetainingWallByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryImapRetainingWallsArgs = {
  condition?: InputMaybe<ImapRetainingWallCondition>;
  filter?: InputMaybe<ImapRetainingWallFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImapRetainingWallsOrderBy>>;
};


export type QueryImapRoadClassArgs = {
  id: Scalars['Int'];
};


export type QueryImapRoadClassByHwyMaintenanceClassIdArgs = {
  hwyMaintenanceClassId: Scalars['Int'];
};


export type QueryImapRoadClassByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryImapRoadClassFeatureArgs = {
  roadClassId: Scalars['Int'];
};


export type QueryImapRoadClassFeatureByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryImapRoadClassFeatureExpandedsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImapRoadClassFeatureExpandedsOrderBy>>;
};


export type QueryImapRoadClassFeaturesArgs = {
  condition?: InputMaybe<ImapRoadClassFeatureCondition>;
  filter?: InputMaybe<ImapRoadClassFeatureFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImapRoadClassFeaturesOrderBy>>;
};


export type QueryImapRoadClassesArgs = {
  condition?: InputMaybe<ImapRoadClassCondition>;
  filter?: InputMaybe<ImapRoadClassFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImapRoadClassesOrderBy>>;
};


export type QueryImapRoadFeatureArgs = {
  id: Scalars['Int'];
};


export type QueryImapRoadFeatureByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryImapRoadFeatureByRfiHighwayNameArgs = {
  rfiHighwayName: Scalars['String'];
};


export type QueryImapRoadFeatureByRoadFeatureInvntryIdArgs = {
  roadFeatureInvntryId: Scalars['Int'];
};


export type QueryImapRoadFeaturesArgs = {
  condition?: InputMaybe<ImapRoadFeatureCondition>;
  filter?: InputMaybe<ImapRoadFeatureFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImapRoadFeaturesOrderBy>>;
};


export type QueryImapRoadStructureArgs = {
  id: Scalars['Int'];
};


export type QueryImapRoadStructureByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryImapRoadStructuresArgs = {
  condition?: InputMaybe<ImapRoadStructureCondition>;
  filter?: InputMaybe<ImapRoadStructureFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImapRoadStructuresOrderBy>>;
};


export type QueryImapRoadSurfaceArgs = {
  id: Scalars['Int'];
};


export type QueryImapRoadSurfaceByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryImapRoadSurfaceByRoadSurfaceTypeIdArgs = {
  roadSurfaceTypeId: Scalars['Int'];
};


export type QueryImapRoadSurfaceFeatureArgs = {
  roadSurfaceId: Scalars['Int'];
};


export type QueryImapRoadSurfaceFeatureByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryImapRoadSurfaceFeaturesArgs = {
  condition?: InputMaybe<ImapRoadSurfaceFeatureCondition>;
  filter?: InputMaybe<ImapRoadSurfaceFeatureFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImapRoadSurfaceFeaturesOrderBy>>;
};


export type QueryImapRoadSurfacesArgs = {
  condition?: InputMaybe<ImapRoadSurfaceCondition>;
  filter?: InputMaybe<ImapRoadSurfaceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImapRoadSurfacesOrderBy>>;
};


export type QueryImapSafetiesArgs = {
  condition?: InputMaybe<ImapSafetyCondition>;
  filter?: InputMaybe<ImapSafetyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImapSafetiesOrderBy>>;
};


export type QueryImapSafetyArgs = {
  id: Scalars['Int'];
};


export type QueryImapSafetyByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryImapSignArgs = {
  id: Scalars['Int'];
};


export type QueryImapSignByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryImapSignsArgs = {
  condition?: InputMaybe<ImapSignCondition>;
  filter?: InputMaybe<ImapSignFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImapSignsOrderBy>>;
};


export type QueryMinsArgs = {
  arg0?: InputMaybe<IntervalInput>;
};


export type QueryNearbyEquipmentArgs = {
  filter: NearbyInput;
};


export type QueryOpsActivitiesArgs = {
  condition?: InputMaybe<OpsActivityCondition>;
  filter?: InputMaybe<OpsActivityFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsActivitiesOrderBy>>;
};


export type QueryOpsActivityArgs = {
  id: Scalars['Int'];
};


export type QueryOpsActivityByActivityCodeArgs = {
  activityCode: Scalars['String'];
};


export type QueryOpsActivityByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryOpsActivityRateArgs = {
  id: Scalars['Int'];
};


export type QueryOpsActivityRateByActivityIdAndServiceAreaAndStartDateArgs = {
  activityId: Scalars['Int'];
  serviceArea: Scalars['String'];
  startDate: Scalars['Date'];
};


export type QueryOpsActivityRateByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryOpsActivityRatesArgs = {
  condition?: InputMaybe<OpsActivityRateCondition>;
  filter?: InputMaybe<OpsActivityRateFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsActivityRatesOrderBy>>;
};


export type QueryOpsBlockArgs = {
  id: Scalars['Int'];
};


export type QueryOpsBlockByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryOpsBlockByServiceAreaAndBlockCodeArgs = {
  blockCode: Scalars['String'];
  serviceArea: Scalars['String'];
};


export type QueryOpsBlockRfisArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsBlockRfisOrderBy>>;
};


export type QueryOpsBlockRoadArgs = {
  blockId: Scalars['Int'];
  roadFeatureId: Scalars['Int'];
};


export type QueryOpsBlockRoadByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryOpsBlockRoadsArgs = {
  condition?: InputMaybe<OpsBlockRoadCondition>;
  filter?: InputMaybe<OpsBlockRoadFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsBlockRoadsOrderBy>>;
};


export type QueryOpsBlockTypeArgs = {
  id: Scalars['Int'];
};


export type QueryOpsBlockTypeByDescriptionArgs = {
  description: Scalars['String'];
};


export type QueryOpsBlockTypeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryOpsBlockTypesArgs = {
  condition?: InputMaybe<OpsBlockTypeCondition>;
  filter?: InputMaybe<OpsBlockTypeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsBlockTypesOrderBy>>;
};


export type QueryOpsBlocksArgs = {
  condition?: InputMaybe<OpsBlockCondition>;
  filter?: InputMaybe<OpsBlockFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsBlocksOrderBy>>;
};


export type QueryOpsGroupArgs = {
  id: Scalars['Int'];
};


export type QueryOpsGroupByDescriptionArgs = {
  description: Scalars['String'];
};


export type QueryOpsGroupByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryOpsGroupTypeArgs = {
  id: Scalars['Int'];
};


export type QueryOpsGroupTypeByDescriptionArgs = {
  description: Scalars['String'];
};


export type QueryOpsGroupTypeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryOpsGroupTypesArgs = {
  condition?: InputMaybe<OpsGroupTypeCondition>;
  filter?: InputMaybe<OpsGroupTypeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsGroupTypesOrderBy>>;
};


export type QueryOpsGroupsArgs = {
  condition?: InputMaybe<OpsGroupCondition>;
  filter?: InputMaybe<OpsGroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsGroupsOrderBy>>;
};


export type QueryOpsMaterialArgs = {
  id: Scalars['Int'];
};


export type QueryOpsMaterialByMaterialCodeArgs = {
  materialCode: Scalars['String'];
};


export type QueryOpsMaterialByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryOpsMaterialTransfersArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsMaterialTransfersOrderBy>>;
};


export type QueryOpsMaterialsArgs = {
  condition?: InputMaybe<OpsMaterialCondition>;
  filter?: InputMaybe<OpsMaterialFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsMaterialsOrderBy>>;
};


export type QueryOpsMembershipCustomArgs = {
  groupId: Scalars['Int'];
  userId: Scalars['Int'];
};


export type QueryOpsMembershipCustomByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryOpsMembershipCustomsArgs = {
  condition?: InputMaybe<OpsMembershipCustomCondition>;
  filter?: InputMaybe<OpsMembershipCustomFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsMembershipCustomsOrderBy>>;
};


export type QueryOpsMembershipHomeYardsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsMembershipHomeYardsOrderBy>>;
};


export type QueryOpsMembershipSupervisorsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsMembershipSupervisorsOrderBy>>;
};


export type QueryOpsMembershipsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsMembershipsOrderBy>>;
};


export type QueryOpsPatrolsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsPatrolsOrderBy>>;
};


export type QueryOpsScheduleArgs = {
  id: Scalars['Int'];
};


export type QueryOpsScheduleByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryOpsScheduleCurrentsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsScheduleCurrentsOrderBy>>;
};


export type QueryOpsSchedulesArgs = {
  condition?: InputMaybe<OpsScheduleCondition>;
  filter?: InputMaybe<OpsScheduleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsSchedulesOrderBy>>;
};


export type QueryOpsShiftPatternArgs = {
  id: Scalars['Int'];
};


export type QueryOpsShiftPatternByDescriptionArgs = {
  description: Scalars['String'];
};


export type QueryOpsShiftPatternByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryOpsShiftPatternsArgs = {
  condition?: InputMaybe<OpsShiftPatternCondition>;
  filter?: InputMaybe<OpsShiftPatternFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsShiftPatternsOrderBy>>;
};


export type QueryOpsShiftWeekArgs = {
  id: Scalars['Int'];
};


export type QueryOpsShiftWeekByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryOpsShiftWeeksArgs = {
  condition?: InputMaybe<OpsShiftWeekCondition>;
  filter?: InputMaybe<OpsShiftWeekFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsShiftWeeksOrderBy>>;
};


export type QueryOpsSupervisorArgs = {
  supervisorId: Scalars['Int'];
  userId: Scalars['Int'];
};


export type QueryOpsSupervisorByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryOpsSupervisorsArgs = {
  condition?: InputMaybe<OpsSupervisorCondition>;
  filter?: InputMaybe<OpsSupervisorFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsSupervisorsOrderBy>>;
};


export type QueryOpsWorkreportsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsWorkreportsOrderBy>>;
};


export type QueryOpsYardArgs = {
  id: Scalars['Int'];
};


export type QueryOpsYardByDescriptionArgs = {
  description: Scalars['String'];
};


export type QueryOpsYardByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryOpsYardsArgs = {
  condition?: InputMaybe<OpsYardCondition>;
  filter?: InputMaybe<OpsYardFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsYardsOrderBy>>;
};


export type QueryOvertimeArgs = {
  end?: InputMaybe<Scalars['DateTime']>;
  start?: InputMaybe<Scalars['DateTime']>;
  unapproved?: InputMaybe<Scalars['Boolean']>;
  workers: Array<Scalars['ID']>;
};


export type QueryPatrolHistoriesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatrolHistoriesOrderBy>>;
};


export type QueryPatrolRecordsArgs = {
  categories?: InputMaybe<Array<Scalars['String']>>;
  end?: InputMaybe<Scalars['DateTime']>;
  rfis: Array<Scalars['String']>;
  start: Scalars['DateTime'];
};


export type QueryPatrolRoadsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatrolRoadsOrderBy>>;
};


export type QueryRecentVisitsArgs = {
  timeSpace: TimeSpace;
};


export type QueryRoadsArgs = {
  serviceAreas: Array<Scalars['String']>;
};


export type QueryRwisForecastArgs = {
  id: Scalars['Int'];
};


export type QueryRwisForecastByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryRwisForecastByStationIdAndIssueTimeArgs = {
  issueTime: Scalars['Datetime'];
  stationId: Scalars['Int'];
};


export type QueryRwisForecastTargetArgs = {
  forecastId: Scalars['Int'];
  targetTime: Scalars['Datetime'];
};


export type QueryRwisForecastTargetByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryRwisForecastTargetsArgs = {
  condition?: InputMaybe<RwisForecastTargetCondition>;
  filter?: InputMaybe<RwisForecastTargetFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RwisForecastTargetsOrderBy>>;
};


export type QueryRwisForecastsArgs = {
  condition?: InputMaybe<RwisForecastCondition>;
  filter?: InputMaybe<RwisForecastFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RwisForecastsOrderBy>>;
};


export type QueryRwisReadingArgs = {
  readingTime: Scalars['Datetime'];
  stationId: Scalars['Int'];
};


export type QueryRwisReadingByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryRwisReadingsArgs = {
  condition?: InputMaybe<RwisReadingCondition>;
  filter?: InputMaybe<RwisReadingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RwisReadingsOrderBy>>;
};


export type QueryRwisSimpleForecastsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RwisSimpleForecastsOrderBy>>;
};


export type QueryRwisSimpleReadingsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RwisSimpleReadingsOrderBy>>;
};


export type QueryRwisStationArgs = {
  id: Scalars['Int'];
};


export type QueryRwisStationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryRwisStationByStationNameArgs = {
  stationName: Scalars['String'];
};


export type QueryRwisStationsArgs = {
  condition?: InputMaybe<RwisStationCondition>;
  filter?: InputMaybe<RwisStationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RwisStationsOrderBy>>;
};


export type QueryRwis_StationsArgs = {
  ids: Array<Scalars['Int']>;
};


export type QuerySa7AbnormalsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Sa7AbnormalsOrderBy>>;
};


export type QuerySa7ShiftPremiumsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Sa7ShiftPremiumsOrderBy>>;
};


export type QuerySa7UnsociablesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Sa7UnsociablesOrderBy>>;
};


export type QuerySa7WeekendsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Sa7WeekendsOrderBy>>;
};


export type QuerySa20ShiftPremiumCalcsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Sa20ShiftPremiumCalcsOrderBy>>;
};


export type QuerySa20ShiftPremiumsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Sa20ShiftPremiumsOrderBy>>;
};


export type QuerySa26ShiftPremiumCalcsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Sa26ShiftPremiumCalcsOrderBy>>;
};


export type QuerySa26ShiftPremiumsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Sa26ShiftPremiumsOrderBy>>;
};


export type QuerySa26WingUnderbodiesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Sa26WingUnderbodiesOrderBy>>;
};


export type QueryScrsArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type QuerySdCompaniesArgs = {
  condition?: InputMaybe<SdCompanyCondition>;
  filter?: InputMaybe<SdCompanyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SdCompaniesOrderBy>>;
};


export type QuerySdCompanyArgs = {
  id: Scalars['UUID'];
};


export type QuerySdCompanyByDescriptionArgs = {
  description: Scalars['String'];
};


export type QuerySdCompanyByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QuerySdFormArgs = {
  id: Scalars['UUID'];
};


export type QuerySdFormByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QuerySdFormItemArgs = {
  itemId: Scalars['UUID'];
};


export type QuerySdFormItemByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QuerySdFormItemsArgs = {
  condition?: InputMaybe<SdFormItemCondition>;
  filter?: InputMaybe<SdFormItemFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SdFormItemsOrderBy>>;
};


export type QuerySdFormTypeArgs = {
  id: Scalars['UUID'];
};


export type QuerySdFormTypeByDocumentTemplateIdArgs = {
  documentTemplateId: Scalars['UUID'];
};


export type QuerySdFormTypeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QuerySdFormTypesArgs = {
  condition?: InputMaybe<SdFormTypeCondition>;
  filter?: InputMaybe<SdFormTypeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SdFormTypesOrderBy>>;
};


export type QuerySdFormWorkerValueArgs = {
  employeeId: Scalars['UUID'];
  formId: Scalars['UUID'];
  itemId: Scalars['UUID'];
};


export type QuerySdFormWorkerValueByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QuerySdFormWorkerValuesArgs = {
  condition?: InputMaybe<SdFormWorkerValueCondition>;
  filter?: InputMaybe<SdFormWorkerValueFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SdFormWorkerValuesOrderBy>>;
};


export type QuerySdFormsArgs = {
  condition?: InputMaybe<SdFormCondition>;
  filter?: InputMaybe<SdFormFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SdFormsOrderBy>>;
};


export type QuerySdLocationArgs = {
  id: Scalars['UUID'];
};


export type QuerySdLocationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QuerySdLocationsArgs = {
  condition?: InputMaybe<SdLocationCondition>;
  filter?: InputMaybe<SdLocationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SdLocationsOrderBy>>;
};


export type QuerySdSignatureArgs = {
  id: Scalars['UUID'];
};


export type QuerySdSignatureByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QuerySdSignaturesArgs = {
  condition?: InputMaybe<SdSignatureCondition>;
  filter?: InputMaybe<SdSignatureFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SdSignaturesOrderBy>>;
};


export type QuerySdWorkerArgs = {
  id: Scalars['UUID'];
};


export type QuerySdWorkerByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QuerySdWorkersArgs = {
  condition?: InputMaybe<SdWorkerCondition>;
  filter?: InputMaybe<SdWorkerFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SdWorkersOrderBy>>;
};


export type QueryStructuresArgs = {
  serviceAreas: Array<Scalars['String']>;
};


export type QueryTcActivityRatesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcActivityRatesOrderBy>>;
};


export type QueryTcAllMsArgs = {
  condition?: InputMaybe<TcAllMCondition>;
  filter?: InputMaybe<TcAllMFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcAllMsOrderBy>>;
};


export type QueryTcAssetUsagesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcAssetUsagesOrderBy>>;
};


export type QueryTcAuxHolidayEligibilitiesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcAuxHolidayEligibilitiesOrderBy>>;
};


export type QueryTcClassPluralitiesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcClassPluralitiesOrderBy>>;
};


export type QueryTcCollectiveAgreementArgs = {
  id: Scalars['String'];
};


export type QueryTcCollectiveAgreementByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryTcCollectiveAgreementsArgs = {
  condition?: InputMaybe<TcCollectiveAgreementCondition>;
  filter?: InputMaybe<TcCollectiveAgreementFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcCollectiveAgreementsOrderBy>>;
};


export type QueryTcCompaniesArgs = {
  condition?: InputMaybe<TcCompanyCondition>;
  filter?: InputMaybe<TcCompanyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcCompaniesOrderBy>>;
};


export type QueryTcCompanyArgs = {
  companyNo: Scalars['Int'];
};


export type QueryTcCompanyAgreementArgs = {
  companyNo: Scalars['Int'];
};


export type QueryTcCompanyAgreementByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryTcCompanyAgreementsArgs = {
  condition?: InputMaybe<TcCompanyAgreementCondition>;
  filter?: InputMaybe<TcCompanyAgreementFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcCompanyAgreementsOrderBy>>;
};


export type QueryTcCompanyByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryTcDominantClassesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcDominantClassesOrderBy>>;
};


export type QueryTcEarningCodeRateArgs = {
  collectiveAgreement: Scalars['String'];
  earningCode: Scalars['String'];
};


export type QueryTcEarningCodeRateByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryTcEarningCodeRatesArgs = {
  condition?: InputMaybe<TcEarningCodeRateCondition>;
  filter?: InputMaybe<TcEarningCodeRateFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcEarningCodeRatesOrderBy>>;
};


export type QueryTcEquipmentArgs = {
  id: Scalars['Int'];
};


export type QueryTcEquipmentByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryTcEquipmentExtrasArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcEquipmentExtrasOrderBy>>;
};


export type QueryTcEquipmentsArgs = {
  condition?: InputMaybe<TcEquipmentCondition>;
  filter?: InputMaybe<TcEquipmentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcEquipmentOrderBy>>;
};


export type QueryTcEsaEcsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcEsaEcsOrderBy>>;
};


export type QueryTcEsaHolidayCompensationsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcEsaHolidayCompensationsOrderBy>>;
};


export type QueryTcEsaHolidayEcsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcEsaHolidayEcsOrderBy>>;
};


export type QueryTcEsaHolidayWorkersArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcEsaHolidayWorkersOrderBy>>;
};


export type QueryTcEsaHolidaysArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcEsaHolidaysOrderBy>>;
};


export type QueryTcEsaOtsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcEsaOtsOrderBy>>;
};


export type QueryTcEsaVacationCompensationsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcEsaVacationCompensationsOrderBy>>;
};


export type QueryTcEsaWorkEcsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcEsaWorkEcsOrderBy>>;
};


export type QueryTcEsasArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcEsasOrderBy>>;
};


export type QueryTcGeuBenefitsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcGeuBenefitsOrderBy>>;
};


export type QueryTcGeuCalloutCalcsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcGeuCalloutCalcsOrderBy>>;
};


export type QueryTcGeuCalloutWorkEcsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcGeuCalloutWorkEcsOrderBy>>;
};


export type QueryTcGeuCalloutsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcGeuCalloutsOrderBy>>;
};


export type QueryTcGeuHolidaySchedulesArgs = {
  condition?: InputMaybe<TcGeuHolidayScheduleCondition>;
  filter?: InputMaybe<TcGeuHolidayScheduleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcGeuHolidaySchedulesOrderBy>>;
};


export type QueryTcGeuHolidayWorkerViewsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcGeuHolidayWorkerViewsOrderBy>>;
};


export type QueryTcGeuHolidayWorkersArgs = {
  condition?: InputMaybe<TcGeuHolidayWorkerCondition>;
  filter?: InputMaybe<TcGeuHolidayWorkerFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcGeuHolidayWorkersOrderBy>>;
};


export type QueryTcGeuHolidaysArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcGeuHolidaysOrderBy>>;
};


export type QueryTcGeuMissedMealsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcGeuMissedMealsOrderBy>>;
};


export type QueryTcGeuOmitShiftEcsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcGeuOmitShiftEcsOrderBy>>;
};


export type QueryTcGeuOmitShiftsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcGeuOmitShiftsOrderBy>>;
};


export type QueryTcGeuOtMealsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcGeuOtMealsOrderBy>>;
};


export type QueryTcGeuOtsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcGeuOtsOrderBy>>;
};


export type QueryTcGeuShiftBreakdownsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcGeuShiftBreakdownsOrderBy>>;
};


export type QueryTcGeuShiftCalcsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcGeuShiftCalcsOrderBy>>;
};


export type QueryTcGeuShiftEcsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcGeuShiftEcsOrderBy>>;
};


export type QueryTcGeuShiftOtsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcGeuShiftOtsOrderBy>>;
};


export type QueryTcGeuShiftWorkEcsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcGeuShiftWorkEcsOrderBy>>;
};


export type QueryTcGeuShiftsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcGeuShiftsOrderBy>>;
};


export type QueryTcGeuShortfallsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcGeuShortfallsOrderBy>>;
};


export type QueryTcGeuSickConsecutivesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcGeuSickConsecutivesOrderBy>>;
};


export type QueryTcGeuUnclearCalloutsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcGeuUnclearCalloutsOrderBy>>;
};


export type QueryTcGeuVpsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcGeuVpsOrderBy>>;
};


export type QueryTcGeusArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcGeusOrderBy>>;
};


export type QueryTcHighlightsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcHighlightsOrderBy>>;
};


export type QueryTcHistoricalClassMaterializedsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcHistoricalClassMaterializedsOrderBy>>;
};


export type QueryTcHistoricalClassesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcHistoricalClassesOrderBy>>;
};


export type QueryTcHolidayArgs = {
  id: Scalars['Int'];
};


export type QueryTcHolidayAlternatesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcHolidayAlternatesOrderBy>>;
};


export type QueryTcHolidayByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryTcHolidayUsersArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcHolidayUsersOrderBy>>;
};


export type QueryTcHolidaysArgs = {
  condition?: InputMaybe<TcHolidayCondition>;
  filter?: InputMaybe<TcHolidayFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcHolidaysOrderBy>>;
};


export type QueryTcLeaveArgs = {
  id: Scalars['Int'];
};


export type QueryTcLeaveByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryTcLeaveDailiesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcLeaveDailiesOrderBy>>;
};


export type QueryTcLeaveDailyScheduledsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcLeaveDailyScheduledsOrderBy>>;
};


export type QueryTcLeaveGeuSickCompensationsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcLeaveGeuSickCompensationsOrderBy>>;
};


export type QueryTcLeavesArgs = {
  condition?: InputMaybe<TcLeaveCondition>;
  filter?: InputMaybe<TcLeaveFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcLeavesOrderBy>>;
};


export type QueryTcMaterialArgs = {
  id: Scalars['Int'];
};


export type QueryTcMaterialByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryTcMaterialsArgs = {
  condition?: InputMaybe<TcMaterialCondition>;
  filter?: InputMaybe<TcMaterialFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcMaterialsOrderBy>>;
};


export type QueryTcMealsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcMealsOrderBy>>;
};


export type QueryTcOtSummariesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcOtSummariesOrderBy>>;
};


export type QueryTcPayrollPrimariesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcPayrollPrimariesOrderBy>>;
};


export type QueryTcRejectionArgs = {
  id: Scalars['Int'];
};


export type QueryTcRejectionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryTcRejectionsArgs = {
  condition?: InputMaybe<TcRejectionCondition>;
  filter?: InputMaybe<TcRejectionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcRejectionsOrderBy>>;
};


export type QueryTcSickApprovedsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcSickApprovedsOrderBy>>;
};


export type QueryTcTimecardArgs = {
  id: Scalars['Int'];
};


export type QueryTcTimecardByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryTcTimecardsArgs = {
  condition?: InputMaybe<TcTimecardCondition>;
  filter?: InputMaybe<TcTimecardFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcTimecardsOrderBy>>;
};


export type QueryTcUserAgreementsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcUserAgreementsOrderBy>>;
};


export type QueryTcVacationAllotmentArgs = {
  collectiveAgreementCode: Scalars['String'];
  workDuration: IntervalInput;
  workdays: Scalars['Int'];
};


export type QueryTcVacationAllotmentByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryTcVacationAllotmentsArgs = {
  condition?: InputMaybe<TcVacationAllotmentCondition>;
  filter?: InputMaybe<TcVacationAllotmentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcVacationAllotmentsOrderBy>>;
};


export type QueryTcVpUnionsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcVpUnionsOrderBy>>;
};


export type QueryTcWorkArgs = {
  id: Scalars['Int'];
};


export type QueryTcWorkByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryTcWorkExtendedsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcWorkExtendedsOrderBy>>;
};


export type QueryTcWorkLeaveArgs = {
  id: Scalars['Int'];
};


export type QueryTcWorkLeaveByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryTcWorkLeaveEcMaterializedsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcWorkLeaveEcMaterializedsOrderBy>>;
};


export type QueryTcWorkLeaveEcsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcWorkLeaveEcsOrderBy>>;
};


export type QueryTcWorkLeaveGeuSickCompensationsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcWorkLeaveGeuSickCompensationsOrderBy>>;
};


export type QueryTcWorkLeavesArgs = {
  condition?: InputMaybe<TcWorkLeaveCondition>;
  filter?: InputMaybe<TcWorkLeaveFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcWorkLeavesOrderBy>>;
};


export type QueryTcWorkSummariesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcWorkSummariesOrderBy>>;
};


export type QueryTcWorkSummaryTextsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcWorkSummaryTextsOrderBy>>;
};


export type QueryTcWorkTestArgs = {
  timecardId: Scalars['Int'];
};


export type QueryTcWorkTestByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryTcWorkTestsArgs = {
  condition?: InputMaybe<TcWorkTestCondition>;
  filter?: InputMaybe<TcWorkTestFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcWorkTestsOrderBy>>;
};


export type QueryTcWorkTimeArgs = {
  sequenceNo: Scalars['Int'];
  timecardId: Scalars['Int'];
  workRange: DatetimeRangeInput;
};


export type QueryTcWorkTimeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryTcWorkTimesArgs = {
  condition?: InputMaybe<TcWorkTimeCondition>;
  filter?: InputMaybe<TcWorkTimeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcWorkTimesOrderBy>>;
};


export type QueryTcWorkVpsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcWorkVpsOrderBy>>;
};


export type QueryTcWorksArgs = {
  condition?: InputMaybe<TcWorkCondition>;
  filter?: InputMaybe<TcWorkFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcWorksOrderBy>>;
};


export type QueryTimecardHighlightsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryUomArgs = {
  id: Scalars['Int'];
};


export type QueryUomByDescriptionArgs = {
  description: Scalars['String'];
};


export type QueryUomByMotiCodeArgs = {
  motiCode: Scalars['String'];
};


export type QueryUomByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryUomByUomCodeArgs = {
  uomCode: Scalars['String'];
};


export type QueryUomsArgs = {
  condition?: InputMaybe<UomCondition>;
  filter?: InputMaybe<UomFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UomsOrderBy>>;
};


export type QueryVehicleDiagnosticArgs = {
  id: Scalars['Int'];
};


export type QueryVehicleDiagnosticByAssetIdAndObdCodeAndDatetimeArgs = {
  assetId: Scalars['Int'];
  datetime: Scalars['Datetime'];
  obdCode: Scalars['String'];
};


export type QueryVehicleDiagnosticByAssetIdAndSpnCodeAndFmiCodeAndDatetimeArgs = {
  assetId: Scalars['Int'];
  datetime: Scalars['Datetime'];
  fmiCode: Scalars['Int'];
  spnCode: Scalars['Int'];
};


export type QueryVehicleDiagnosticByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryVehicleDiagnosticsArgs = {
  condition?: InputMaybe<VehicleDiagnosticCondition>;
  filter?: InputMaybe<VehicleDiagnosticFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VehicleDiagnosticsOrderBy>>;
};


export type QueryVehicleServiceArgs = {
  id: Scalars['Int'];
};


export type QueryVehicleServiceByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryVehicleServicesArgs = {
  condition?: InputMaybe<VehicleServiceCondition>;
  filter?: InputMaybe<VehicleServiceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VehicleServicesOrderBy>>;
};


export type QueryVehicleStatusArgs = {
  statusId: Scalars['Int'];
};


export type QueryVehicleStatusByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryVehicleStatusesArgs = {
  condition?: InputMaybe<VehicleStatusCondition>;
  filter?: InputMaybe<VehicleStatusFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VehicleStatusesOrderBy>>;
};


export type QueryViewpointExportArgs = {
  co: Scalars['ID'];
  end?: InputMaybe<Scalars['String']>;
  start: Scalars['String'];
};


export type QueryViewpointExportCsvArgs = {
  co: Scalars['ID'];
  end?: InputMaybe<Scalars['String']>;
  start: Scalars['String'];
};


export type QueryVpBallinaProbationsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VpBallinaProbationsOrderBy>>;
};


export type QueryVpCraftClassesArgs = {
  condition?: InputMaybe<VpCraftClassCondition>;
  filter?: InputMaybe<VpCraftClassFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VpCraftClassesOrderBy>>;
};


export type QueryVpCraftsArgs = {
  condition?: InputMaybe<VpCraftCondition>;
  filter?: InputMaybe<VpCraftFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VpCraftsOrderBy>>;
};


export type QueryVpEmployeesArgs = {
  condition?: InputMaybe<VpEmployeeCondition>;
  filter?: InputMaybe<VpEmployeeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VpEmployeesOrderBy>>;
};


export type QueryVpEquipmentsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VpEquipmentOrderBy>>;
};


export type QueryVpJobOverrideArgs = {
  companyNo: Scalars['Int'];
  jobCode: Scalars['String'];
};


export type QueryVpJobOverrideByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryVpJobOverridesArgs = {
  condition?: InputMaybe<VpJobOverrideCondition>;
  filter?: InputMaybe<VpJobOverrideFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VpJobOverridesOrderBy>>;
};


export type QueryVpJobsArgs = {
  condition?: InputMaybe<VpJobCondition>;
  filter?: InputMaybe<VpJobFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VpJobsOrderBy>>;
};


export type QueryVpLocationsArgs = {
  condition?: InputMaybe<VpLocationCondition>;
  filter?: InputMaybe<VpLocationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VpLocationsOrderBy>>;
};


export type QueryVpPhaseOverrideArgs = {
  companyNo: Scalars['Int'];
  jobCode: Scalars['String'];
  phaseCode: Scalars['String'];
};


export type QueryVpPhaseOverrideByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


export type QueryVpPhaseOverridesArgs = {
  condition?: InputMaybe<VpPhaseOverrideCondition>;
  filter?: InputMaybe<VpPhaseOverrideFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VpPhaseOverridesOrderBy>>;
};


export type QueryVpPhasesArgs = {
  condition?: InputMaybe<VpPhaseCondition>;
  filter?: InputMaybe<VpPhaseFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VpPhasesOrderBy>>;
};


export type QueryWeatherArgs = {
  stations: Array<Scalars['String']>;
};


export type QueryWorkDetailsArgs = {
  end?: InputMaybe<Scalars['DateTime']>;
  start: Scalars['DateTime'];
  yards: Array<Scalars['ID']>;
};


export type QueryWorkSummariesArgs = {
  end?: InputMaybe<Scalars['DateTime']>;
  start: Scalars['DateTime'];
  yards: Array<Scalars['ID']>;
};


export type QueryWorkidsArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type QueryWorkreportArgs = {
  end?: InputMaybe<Scalars['DateTime']>;
  serviceArea: Scalars['String'];
  start?: InputMaybe<Scalars['DateTime']>;
  unapproved?: InputMaybe<Scalars['Boolean']>;
};


export type QueryWrRecordFullsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WrRecordFullsOrderBy>>;
};


export type QueryYardReportsArgs = {
  end?: InputMaybe<Scalars['DateTime']>;
  start: Scalars['DateTime'];
  yards: Array<Scalars['ID']>;
};

export type Reading = {
  __typename?: 'Reading';
  air_temp?: Maybe<Scalars['Float']>;
  dew_point?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  precip_hourly?: Maybe<Scalars['Float']>;
  precip_new?: Maybe<Scalars['Float']>;
  pressure?: Maybe<Scalars['Float']>;
  reading_time: Scalars['DateTime'];
  snow_hourly?: Maybe<Scalars['Float']>;
  snow_new?: Maybe<Scalars['Float']>;
  station: Station;
  surface_temp?: Maybe<Scalars['Float']>;
  wind_dir?: Maybe<Scalars['Float']>;
  wind_max?: Maybe<Scalars['Float']>;
  wind_mean?: Maybe<Scalars['Float']>;
};

export type RecordId = {
  __typename?: 'RecordID';
  recordid: Scalars['Int'];
  sessionid: Scalars['Int'];
  userid: Scalars['Int'];
};

export type RecordSubmissionId = {
  recordid: Scalars['Int'];
  sessionid: Scalars['Int'];
  userid: Scalars['Int'];
};

export type RelatedTimecard = {
  __typename?: 'RelatedTimecard';
  id: Scalars['ID'];
  workerId: Scalars['ID'];
};

export type Road = {
  __typename?: 'Road';
  geometry: Scalars['String'];
  id: Scalars['ID'];
  rfiHighwayDescription: Scalars['String'];
  rfiHighwayLength: Scalars['Float'];
  rfiHighwayName: Scalars['String'];
  rfiHighwayType: Scalars['String'];
  serviceArea: Scalars['String'];
  srid3005: Scalars['String'];
};

export type RoadInventoryRelationship = {
  __typename?: 'RoadInventoryRelationship';
  distance: Scalars['Float'];
  imapRoadFeature: ImapRoadFeature;
  point: Point;
};

export type RoadVisitSummary = {
  __typename?: 'RoadVisitSummary';
  breadcrumbsPresent: Scalars['Int'];
  mostRecentVisit: Scalars['DateTime'];
  rfiHighwayName: Scalars['String'];
  roadFeatureId: Scalars['Int'];
};

export type RwisForecast = Node & {
  __typename?: 'RwisForecast';
  discussion: Scalars['String'];
  id: Scalars['Int'];
  issueTime: Scalars['Datetime'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `RwisForecastTarget`. */
  rwisForecastTargetsByForecastId: Array<RwisForecastTarget>;
  /** Reads a single `RwisStation` that is related to this `RwisForecast`. */
  station?: Maybe<RwisStation>;
  stationId?: Maybe<Scalars['Int']>;
};


export type RwisForecastRwisForecastTargetsByForecastIdArgs = {
  condition?: InputMaybe<RwisForecastTargetCondition>;
  filter?: InputMaybe<RwisForecastTargetFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RwisForecastTargetsOrderBy>>;
};

/**
 * A condition to be used against `RwisForecast` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type RwisForecastCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `issueTime` field. */
  issueTime?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `stationId` field. */
  stationId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `RwisForecast` object types. All fields are combined with a logical ‘and.’ */
export type RwisForecastFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<RwisForecastFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `issueTime` field. */
  issueTime?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<RwisForecastFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<RwisForecastFilter>>;
  /** Filter by the object’s `stationId` field. */
  stationId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `RwisForecast` */
export type RwisForecastInput = {
  discussion: Scalars['String'];
  issueTime: Scalars['Datetime'];
  stationId?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `RwisForecast`. Fields that are set will be updated. */
export type RwisForecastPatch = {
  discussion?: InputMaybe<Scalars['String']>;
  issueTime?: InputMaybe<Scalars['Datetime']>;
  stationId?: InputMaybe<Scalars['Int']>;
};

export type RwisForecastTarget = Node & {
  __typename?: 'RwisForecastTarget';
  /** Reads a single `RwisForecast` that is related to this `RwisForecastTarget`. */
  forecast?: Maybe<RwisForecast>;
  forecastId: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  surfaceStatus?: Maybe<Scalars['Int']>;
  surfaceTemp?: Maybe<Scalars['Float']>;
  targetTime: Scalars['Datetime'];
};

/**
 * A condition to be used against `RwisForecastTarget` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type RwisForecastTargetCondition = {
  /** Checks for equality with the object’s `forecastId` field. */
  forecastId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `RwisForecastTarget` object types. All fields are combined with a logical ‘and.’ */
export type RwisForecastTargetFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<RwisForecastTargetFilter>>;
  /** Filter by the object’s `forecastId` field. */
  forecastId?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<RwisForecastTargetFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<RwisForecastTargetFilter>>;
};

/** An input for mutations affecting `RwisForecastTarget` */
export type RwisForecastTargetInput = {
  forecastId: Scalars['Int'];
  surfaceStatus?: InputMaybe<Scalars['Int']>;
  surfaceTemp?: InputMaybe<Scalars['Float']>;
  targetTime: Scalars['Datetime'];
};

/** Represents an update to a `RwisForecastTarget`. Fields that are set will be updated. */
export type RwisForecastTargetPatch = {
  forecastId?: InputMaybe<Scalars['Int']>;
  surfaceStatus?: InputMaybe<Scalars['Int']>;
  surfaceTemp?: InputMaybe<Scalars['Float']>;
  targetTime?: InputMaybe<Scalars['Datetime']>;
};

/** Methods to use when ordering `RwisForecastTarget`. */
export enum RwisForecastTargetsOrderBy {
  ForecastIdAsc = 'FORECAST_ID_ASC',
  ForecastIdDesc = 'FORECAST_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** Methods to use when ordering `RwisForecast`. */
export enum RwisForecastsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IssueTimeAsc = 'ISSUE_TIME_ASC',
  IssueTimeDesc = 'ISSUE_TIME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StationIdAsc = 'STATION_ID_ASC',
  StationIdDesc = 'STATION_ID_DESC'
}

export type RwisReading = Node & {
  __typename?: 'RwisReading';
  airAirTemp?: Maybe<Scalars['Float']>;
  airAirTemp2?: Maybe<Scalars['Float']>;
  airAtmosPressure?: Maybe<Scalars['Float']>;
  airDewPoint?: Maybe<Scalars['Float']>;
  airHumidity?: Maybe<Scalars['Float']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  optAirTemp?: Maybe<Scalars['Float']>;
  optBedTemp?: Maybe<Scalars['Float']>;
  optDewPoint?: Maybe<Scalars['Float']>;
  optGripLevel?: Maybe<Scalars['Float']>;
  optHumidity?: Maybe<Scalars['Float']>;
  optSurfaceStatus?: Maybe<Scalars['Float']>;
  optSurfaceTemp?: Maybe<Scalars['Float']>;
  optThicknessIce?: Maybe<Scalars['Float']>;
  optThicknessSnow?: Maybe<Scalars['Float']>;
  optThicknessWater?: Maybe<Scalars['Float']>;
  optVisibility?: Maybe<Scalars['Float']>;
  paveAltSurfaceTemp?: Maybe<Scalars['Float']>;
  paveBaseTemp?: Maybe<Scalars['Float']>;
  paveCoverThickness?: Maybe<Scalars['Float']>;
  paveFreezePoint?: Maybe<Scalars['Float']>;
  paveSurfaceStatus?: Maybe<Scalars['Float']>;
  paveSurfaceTemp?: Maybe<Scalars['Float']>;
  precipPrecipHourly?: Maybe<Scalars['Float']>;
  precipPrecipNew?: Maybe<Scalars['Float']>;
  precipPrecipRatio?: Maybe<Scalars['Float']>;
  precipPrecipTotal?: Maybe<Scalars['Float']>;
  pwd12MaxIntensity?: Maybe<Scalars['Float']>;
  pwd12MinVisibility10Min?: Maybe<Scalars['Float']>;
  pwd12PrecipHourly?: Maybe<Scalars['Float']>;
  pwd12PrecipNew?: Maybe<Scalars['Float']>;
  pwd12SnowHourly?: Maybe<Scalars['Float']>;
  pwd12SnowNew?: Maybe<Scalars['Float']>;
  pwd12Visibility10Min?: Maybe<Scalars['Float']>;
  pwd12WxCode15Min?: Maybe<Scalars['Float']>;
  readingTime: Scalars['Datetime'];
  snowSnowHourly?: Maybe<Scalars['Float']>;
  snowSnowNew?: Maybe<Scalars['Float']>;
  snowSnowTotal?: Maybe<Scalars['Float']>;
  /** Reads a single `RwisStation` that is related to this `RwisReading`. */
  station?: Maybe<RwisStation>;
  stationId: Scalars['Int'];
  windWindDirection?: Maybe<Scalars['Float']>;
  windWindSpeedMax?: Maybe<Scalars['Float']>;
  windWindSpeedMean?: Maybe<Scalars['Float']>;
};

/**
 * A condition to be used against `RwisReading` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type RwisReadingCondition = {
  /** Checks for equality with the object’s `readingTime` field. */
  readingTime?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `stationId` field. */
  stationId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `RwisReading` object types. All fields are combined with a logical ‘and.’ */
export type RwisReadingFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<RwisReadingFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<RwisReadingFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<RwisReadingFilter>>;
  /** Filter by the object’s `readingTime` field. */
  readingTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `stationId` field. */
  stationId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `RwisReading` */
export type RwisReadingInput = {
  airAirTemp?: InputMaybe<Scalars['Float']>;
  airAirTemp2?: InputMaybe<Scalars['Float']>;
  airAtmosPressure?: InputMaybe<Scalars['Float']>;
  airDewPoint?: InputMaybe<Scalars['Float']>;
  airHumidity?: InputMaybe<Scalars['Float']>;
  optAirTemp?: InputMaybe<Scalars['Float']>;
  optBedTemp?: InputMaybe<Scalars['Float']>;
  optDewPoint?: InputMaybe<Scalars['Float']>;
  optGripLevel?: InputMaybe<Scalars['Float']>;
  optHumidity?: InputMaybe<Scalars['Float']>;
  optSurfaceStatus?: InputMaybe<Scalars['Float']>;
  optSurfaceTemp?: InputMaybe<Scalars['Float']>;
  optThicknessIce?: InputMaybe<Scalars['Float']>;
  optThicknessSnow?: InputMaybe<Scalars['Float']>;
  optThicknessWater?: InputMaybe<Scalars['Float']>;
  optVisibility?: InputMaybe<Scalars['Float']>;
  paveAltSurfaceTemp?: InputMaybe<Scalars['Float']>;
  paveBaseTemp?: InputMaybe<Scalars['Float']>;
  paveCoverThickness?: InputMaybe<Scalars['Float']>;
  paveFreezePoint?: InputMaybe<Scalars['Float']>;
  paveSurfaceStatus?: InputMaybe<Scalars['Float']>;
  paveSurfaceTemp?: InputMaybe<Scalars['Float']>;
  precipPrecipHourly?: InputMaybe<Scalars['Float']>;
  precipPrecipNew?: InputMaybe<Scalars['Float']>;
  precipPrecipRatio?: InputMaybe<Scalars['Float']>;
  precipPrecipTotal?: InputMaybe<Scalars['Float']>;
  pwd12MaxIntensity?: InputMaybe<Scalars['Float']>;
  pwd12MinVisibility10Min?: InputMaybe<Scalars['Float']>;
  pwd12PrecipHourly?: InputMaybe<Scalars['Float']>;
  pwd12PrecipNew?: InputMaybe<Scalars['Float']>;
  pwd12SnowHourly?: InputMaybe<Scalars['Float']>;
  pwd12SnowNew?: InputMaybe<Scalars['Float']>;
  pwd12Visibility10Min?: InputMaybe<Scalars['Float']>;
  pwd12WxCode15Min?: InputMaybe<Scalars['Float']>;
  readingTime: Scalars['Datetime'];
  snowSnowHourly?: InputMaybe<Scalars['Float']>;
  snowSnowNew?: InputMaybe<Scalars['Float']>;
  snowSnowTotal?: InputMaybe<Scalars['Float']>;
  stationId: Scalars['Int'];
  windWindDirection?: InputMaybe<Scalars['Float']>;
  windWindSpeedMax?: InputMaybe<Scalars['Float']>;
  windWindSpeedMean?: InputMaybe<Scalars['Float']>;
};

/** Represents an update to a `RwisReading`. Fields that are set will be updated. */
export type RwisReadingPatch = {
  airAirTemp?: InputMaybe<Scalars['Float']>;
  airAirTemp2?: InputMaybe<Scalars['Float']>;
  airAtmosPressure?: InputMaybe<Scalars['Float']>;
  airDewPoint?: InputMaybe<Scalars['Float']>;
  airHumidity?: InputMaybe<Scalars['Float']>;
  optAirTemp?: InputMaybe<Scalars['Float']>;
  optBedTemp?: InputMaybe<Scalars['Float']>;
  optDewPoint?: InputMaybe<Scalars['Float']>;
  optGripLevel?: InputMaybe<Scalars['Float']>;
  optHumidity?: InputMaybe<Scalars['Float']>;
  optSurfaceStatus?: InputMaybe<Scalars['Float']>;
  optSurfaceTemp?: InputMaybe<Scalars['Float']>;
  optThicknessIce?: InputMaybe<Scalars['Float']>;
  optThicknessSnow?: InputMaybe<Scalars['Float']>;
  optThicknessWater?: InputMaybe<Scalars['Float']>;
  optVisibility?: InputMaybe<Scalars['Float']>;
  paveAltSurfaceTemp?: InputMaybe<Scalars['Float']>;
  paveBaseTemp?: InputMaybe<Scalars['Float']>;
  paveCoverThickness?: InputMaybe<Scalars['Float']>;
  paveFreezePoint?: InputMaybe<Scalars['Float']>;
  paveSurfaceStatus?: InputMaybe<Scalars['Float']>;
  paveSurfaceTemp?: InputMaybe<Scalars['Float']>;
  precipPrecipHourly?: InputMaybe<Scalars['Float']>;
  precipPrecipNew?: InputMaybe<Scalars['Float']>;
  precipPrecipRatio?: InputMaybe<Scalars['Float']>;
  precipPrecipTotal?: InputMaybe<Scalars['Float']>;
  pwd12MaxIntensity?: InputMaybe<Scalars['Float']>;
  pwd12MinVisibility10Min?: InputMaybe<Scalars['Float']>;
  pwd12PrecipHourly?: InputMaybe<Scalars['Float']>;
  pwd12PrecipNew?: InputMaybe<Scalars['Float']>;
  pwd12SnowHourly?: InputMaybe<Scalars['Float']>;
  pwd12SnowNew?: InputMaybe<Scalars['Float']>;
  pwd12Visibility10Min?: InputMaybe<Scalars['Float']>;
  pwd12WxCode15Min?: InputMaybe<Scalars['Float']>;
  readingTime?: InputMaybe<Scalars['Datetime']>;
  snowSnowHourly?: InputMaybe<Scalars['Float']>;
  snowSnowNew?: InputMaybe<Scalars['Float']>;
  snowSnowTotal?: InputMaybe<Scalars['Float']>;
  stationId?: InputMaybe<Scalars['Int']>;
  windWindDirection?: InputMaybe<Scalars['Float']>;
  windWindSpeedMax?: InputMaybe<Scalars['Float']>;
  windWindSpeedMean?: InputMaybe<Scalars['Float']>;
};

/** Methods to use when ordering `RwisReading`. */
export enum RwisReadingsOrderBy {
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReadingTimeAsc = 'READING_TIME_ASC',
  ReadingTimeDesc = 'READING_TIME_DESC',
  StationIdAsc = 'STATION_ID_ASC',
  StationIdDesc = 'STATION_ID_DESC'
}

export type RwisSimpleForecast = {
  __typename?: 'RwisSimpleForecast';
  discussion?: Maybe<Scalars['String']>;
  issueTime?: Maybe<Scalars['Datetime']>;
  stationId?: Maybe<Scalars['Int']>;
  stationName?: Maybe<Scalars['String']>;
};

/** Methods to use when ordering `RwisSimpleForecast`. */
export enum RwisSimpleForecastsOrderBy {
  Natural = 'NATURAL'
}

export type RwisSimpleReading = {
  __typename?: 'RwisSimpleReading';
  airTemp?: Maybe<Scalars['Float']>;
  dewPoint?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  precipHourly?: Maybe<Scalars['Float']>;
  precipNew?: Maybe<Scalars['Float']>;
  pressure?: Maybe<Scalars['Float']>;
  readingTime?: Maybe<Scalars['Datetime']>;
  snowHourly?: Maybe<Scalars['Float']>;
  snowNew?: Maybe<Scalars['Float']>;
  stationId?: Maybe<Scalars['Int']>;
  stationName?: Maybe<Scalars['String']>;
  surfaceTemp?: Maybe<Scalars['Float']>;
  windDir?: Maybe<Scalars['Float']>;
  windMax?: Maybe<Scalars['Float']>;
  windMean?: Maybe<Scalars['Float']>;
};

/** Methods to use when ordering `RwisSimpleReading`. */
export enum RwisSimpleReadingsOrderBy {
  Natural = 'NATURAL'
}

export type RwisStation = Node & {
  __typename?: 'RwisStation';
  geog: GeographyPoint;
  id: Scalars['Int'];
  installDate: Scalars['Date'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `RwisForecast`. */
  rwisForecastsByStationId: Array<RwisForecast>;
  /** Reads and enables pagination through a set of `RwisReading`. */
  rwisReadingsByStationId: Array<RwisReading>;
  serviceAreaId: Scalars['String'];
  stationName: Scalars['String'];
};


export type RwisStationRwisForecastsByStationIdArgs = {
  condition?: InputMaybe<RwisForecastCondition>;
  filter?: InputMaybe<RwisForecastFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RwisForecastsOrderBy>>;
};


export type RwisStationRwisReadingsByStationIdArgs = {
  condition?: InputMaybe<RwisReadingCondition>;
  filter?: InputMaybe<RwisReadingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RwisReadingsOrderBy>>;
};

/**
 * A condition to be used against `RwisStation` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type RwisStationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `serviceAreaId` field. */
  serviceAreaId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `stationName` field. */
  stationName?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `RwisStation` object types. All fields are combined with a logical ‘and.’ */
export type RwisStationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<RwisStationFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<RwisStationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<RwisStationFilter>>;
  /** Filter by the object’s `serviceAreaId` field. */
  serviceAreaId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `stationName` field. */
  stationName?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `RwisStation` */
export type RwisStationInput = {
  geog: Scalars['GeoJSON'];
  id: Scalars['Int'];
  installDate: Scalars['Date'];
  serviceAreaId: Scalars['String'];
  stationName: Scalars['String'];
};

/** Represents an update to a `RwisStation`. Fields that are set will be updated. */
export type RwisStationPatch = {
  geog?: InputMaybe<Scalars['GeoJSON']>;
  id?: InputMaybe<Scalars['Int']>;
  installDate?: InputMaybe<Scalars['Date']>;
  serviceAreaId?: InputMaybe<Scalars['String']>;
  stationName?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `RwisStation`. */
export enum RwisStationsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ServiceAreaIdAsc = 'SERVICE_AREA_ID_ASC',
  ServiceAreaIdDesc = 'SERVICE_AREA_ID_DESC',
  StationNameAsc = 'STATION_NAME_ASC',
  StationNameDesc = 'STATION_NAME_DESC'
}

export type Scr = {
  __typename?: 'SCR';
  ActionItemId: Scalars['ID'];
  CallbackRequested?: Maybe<Scalars['Boolean']>;
  CompletedAction?: Maybe<Scalars['String']>;
  CurrentStateName: Scalars['String'];
  IssueConfirmedBy?: Maybe<Scalars['String']>;
  IssueDescription: Scalars['String'];
  IssueType: Scalars['String'];
  LastModifiedAt: Scalars['DateTime'];
  LastModifiedBy: Scalars['String'];
  PlannedAction?: Maybe<Scalars['String']>;
  PlannedDate?: Maybe<Scalars['String']>;
  RecordedBy?: Maybe<Scalars['String']>;
  Road?: Maybe<Scalars['String']>;
  RoadNumber?: Maybe<Scalars['String']>;
  Road_display?: Maybe<Scalars['String']>;
  StakeholderAddress?: Maybe<Scalars['String']>;
  StakeholderGroup: Scalars['String'];
  StakeholderName: Scalars['String'];
  StakeholderPhone?: Maybe<Scalars['String']>;
  Subject: Scalars['String'];
  VerbalNotificationTo?: Maybe<Scalars['String']>;
};

export type Spn = {
  __typename?: 'SPN';
  fmi?: Maybe<Fmi>;
  spn_code: Scalars['ID'];
  spn_description?: Maybe<Scalars['String']>;
  spn_name?: Maybe<Scalars['String']>;
};

/**
 * Earning Code 13 - 27.11 Abnormal working conditions
 * including falling trees > 6" diameter, working inside culverts, tanks, raw sewage, welding hazardous materials
 * Paid in one hour increments
 */
export type Sa7Abnormal = {
  __typename?: 'Sa7Abnormal';
  classCode?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  jobCode?: Maybe<Scalars['String']>;
  phaseCode?: Maybe<Scalars['String']>;
};

/** Methods to use when ordering `Sa7Abnormal`. */
export enum Sa7AbnormalsOrderBy {
  Natural = 'NATURAL'
}

export type Sa7ShiftPremium = {
  __typename?: 'Sa7ShiftPremium';
  classCode?: Maybe<Scalars['String']>;
  earningCode?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  jobCode?: Maybe<Scalars['String']>;
  phaseCode?: Maybe<Scalars['String']>;
};

/** Methods to use when ordering `Sa7ShiftPremium`. */
export enum Sa7ShiftPremiumsOrderBy {
  Natural = 'NATURAL'
}

/**
 * Earning Code 14 - Unsociable premium - 15.1a2, 15.2b
 * 15.1a2: all hours worked on a shift which starts between 13:00 and 04:59
 * 15.2b: a full shift which begins between 11:00 and 12:59 shall receive the unsociable hours premium for all hours worked after 13:00
 */
export type Sa7Unsociable = {
  __typename?: 'Sa7Unsociable';
  id?: Maybe<Scalars['Int']>;
  unsociableTime?: Maybe<DatetimeRange>;
};

/** Methods to use when ordering `Sa7Unsociable`. */
export enum Sa7UnsociablesOrderBy {
  Natural = 'NATURAL'
}

/**
 * Earning Code 12 - Weekend premium - collective agreement 15.1c
 * Paid for all hours worked on shift between 06:00 Saturday and 06:00 Monday
 * But only in the absence of other premiums (eg. weekend premium)
 */
export type Sa7Weekend = {
  __typename?: 'Sa7Weekend';
  id?: Maybe<Scalars['Int']>;
  unsociableTime?: Maybe<DatetimeRange>;
  weekendExclusive?: Maybe<DatetimeRange>;
  weekendTime?: Maybe<DatetimeRange>;
};

/** Methods to use when ordering `Sa7Weekend`. */
export enum Sa7WeekendsOrderBy {
  Natural = 'NATURAL'
}

export type Sa20ShiftPremium = {
  __typename?: 'Sa20ShiftPremium';
  classCode?: Maybe<Scalars['String']>;
  earningCode?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  jobCode?: Maybe<Scalars['String']>;
  phaseCode?: Maybe<Scalars['String']>;
};

/**
 * 15.1 Premiums based on start time - Day 05:00, Afternoon 13:00, Night: 21:00
 * Earning Code 5 - Afternoon Shift
 * Earning Code 6 - Night Shift
 * Paid on overtime too
 * 15.2b:
 * Premiums apply to callout portion of a shift
 * See sa26_shift_premiem_calc comment for additional discussion
 */
export type Sa20ShiftPremiumCalc = {
  __typename?: 'Sa20ShiftPremiumCalc';
  id?: Maybe<Scalars['Int']>;
  shiftType?: Maybe<Scalars['String']>;
};

/** Methods to use when ordering `Sa20ShiftPremiumCalc`. */
export enum Sa20ShiftPremiumCalcsOrderBy {
  Natural = 'NATURAL'
}

/** Methods to use when ordering `Sa20ShiftPremium`. */
export enum Sa20ShiftPremiumsOrderBy {
  Natural = 'NATURAL'
}

export type Sa26ShiftPremium = {
  __typename?: 'Sa26ShiftPremium';
  classCode?: Maybe<Scalars['String']>;
  earningCode?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  jobCode?: Maybe<Scalars['String']>;
  phaseCode?: Maybe<Scalars['String']>;
};

/**
 * 15.1 Premiums based on SHIFT worked start time - Day 04:30, Afternoon 12:30, Night: 20:30
 * Earning Code 5 - Afternoon Shift (12:30+)
 * Earning Code 6 - Night Shift (20:30+)
 * Paid on overtime adjoining shift too
 * 15.2b:
 * PTE working < normal shift length receives afternoon premium for all hours on any shift a majority of which is regularly scheduled between 18:00 and 06:00,
 * except if it starts between 22:00 and 02:00, night shift premium
 *
 * "All hours worked on any shift" in 15.1a is critical.  Article 1 definitions says a shift is the scheduled hours.  And this is hours worked on a scheduled shift, which would normally exclude OT.
 * Wording around overtime adjoining shift is clarified, but badly, in area 20.  Ambigious.
 * Does overtime impact start time and thus which premium to apply?  A shift starting at 05:00 with an adjoining 04:00 callout would make the whole shift night eligible, but a 21:00 night shift with a 20:00 callout would be downgraded to an afternoon shift.  Irregular earnings, not better for anyone.
 * Does overtime get premium for hours in target range independent of when the scheduled hours work?  Will get confusion, could earn 3 different premium rates on one shift.
 * Does the scheduled, worked hours determine the premium, but the premium applies to overtime hours too?  We assume this.
 */
export type Sa26ShiftPremiumCalc = {
  __typename?: 'Sa26ShiftPremiumCalc';
  eveningPortion?: Maybe<Scalars['Float']>;
  fulltimeShiftType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  partTime?: Maybe<Scalars['Boolean']>;
  pteNight?: Maybe<Scalars['Boolean']>;
  shiftType?: Maybe<Scalars['String']>;
};

/** Methods to use when ordering `Sa26ShiftPremiumCalc`. */
export enum Sa26ShiftPremiumCalcsOrderBy {
  Natural = 'NATURAL'
}

/** Methods to use when ordering `Sa26ShiftPremium`. */
export enum Sa26ShiftPremiumsOrderBy {
  Natural = 'NATURAL'
}

/** Methods to use when ordering `Sa26WingUnderbody`. */
export enum Sa26WingUnderbodiesOrderBy {
  Natural = 'NATURAL'
}

/** SA26 Appendix 2 Wing Truck/Extended underbody: thirty-five cents (35¢) per hour premium will be paid to equipment operators while operating a truck equipped with a wing plow or extended underbody */
export type Sa26WingUnderbody = {
  __typename?: 'Sa26WingUnderbody';
  classCode?: Maybe<Scalars['String']>;
  earningCode?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  jobCode?: Maybe<Scalars['String']>;
  phaseCode?: Maybe<Scalars['String']>;
  workId?: Maybe<Scalars['Int']>;
};

export type ScheduleInput = {
  effective?: InputMaybe<Scalars['DateTime']>;
  overrideOthers?: InputMaybe<Scalars['Boolean']>;
  shiftPatternId: Scalars['Int'];
  weekOffset?: InputMaybe<Scalars['Int']>;
};

export enum Scope {
  Admin = 'ADMIN',
  Supervisor = 'SUPERVISOR'
}

/** Methods to use when ordering `SdCompany`. */
export enum SdCompaniesOrderBy {
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type SdCompany = Node & {
  __typename?: 'SdCompany';
  description: Scalars['String'];
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `SdFormType`. */
  sdFormTypesByCreatingCompanyId: Array<SdFormType>;
  /** Reads and enables pagination through a set of `SdForm`. */
  sdFormsByCreatingCompanyId: Array<SdForm>;
  /** Reads and enables pagination through a set of `SdLocation`. */
  sdLocationsByCreatingCompanyId: Array<SdLocation>;
  /** Reads and enables pagination through a set of `SdSignature`. */
  sdSignaturesByCreatingCompanyId: Array<SdSignature>;
  /** Reads and enables pagination through a set of `SdWorker`. */
  sdWorkersByEmployerId: Array<SdWorker>;
};


export type SdCompanySdFormTypesByCreatingCompanyIdArgs = {
  condition?: InputMaybe<SdFormTypeCondition>;
  filter?: InputMaybe<SdFormTypeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SdFormTypesOrderBy>>;
};


export type SdCompanySdFormsByCreatingCompanyIdArgs = {
  condition?: InputMaybe<SdFormCondition>;
  filter?: InputMaybe<SdFormFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SdFormsOrderBy>>;
};


export type SdCompanySdLocationsByCreatingCompanyIdArgs = {
  condition?: InputMaybe<SdLocationCondition>;
  filter?: InputMaybe<SdLocationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SdLocationsOrderBy>>;
};


export type SdCompanySdSignaturesByCreatingCompanyIdArgs = {
  condition?: InputMaybe<SdSignatureCondition>;
  filter?: InputMaybe<SdSignatureFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SdSignaturesOrderBy>>;
};


export type SdCompanySdWorkersByEmployerIdArgs = {
  condition?: InputMaybe<SdWorkerCondition>;
  filter?: InputMaybe<SdWorkerFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SdWorkersOrderBy>>;
};

/**
 * A condition to be used against `SdCompany` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type SdCompanyCondition = {
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `SdCompany` object types. All fields are combined with a logical ‘and.’ */
export type SdCompanyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SdCompanyFilter>>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SdCompanyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SdCompanyFilter>>;
};

/** An input for mutations affecting `SdCompany` */
export type SdCompanyInput = {
  description: Scalars['String'];
  id: Scalars['UUID'];
};

/** Represents an update to a `SdCompany`. Fields that are set will be updated. */
export type SdCompanyPatch = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
};

export type SdForm = Node & {
  __typename?: 'SdForm';
  createdBy: Scalars['UUID'];
  createdOn: Scalars['Datetime'];
  /** Reads a single `SdCompany` that is related to this `SdForm`. */
  creatingCompany?: Maybe<SdCompany>;
  creatingCompanyId: Scalars['UUID'];
  documentId: Scalars['UUID'];
  documentTemplateId: Scalars['UUID'];
  documentTemplateName: Scalars['String'];
  documentTemplateVersionId: Scalars['UUID'];
  due?: Maybe<Scalars['Datetime']>;
  hasGoodData: Scalars['Boolean'];
  id: Scalars['UUID'];
  isDeleted: Scalars['Boolean'];
  isPrivate: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
  lastModifiedBy: Scalars['UUID'];
  lastModifiedOn: Scalars['Datetime'];
  /** Reads a single `SdLocation` that is related to this `SdForm`. */
  location?: Maybe<SdLocation>;
  locationId: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  precedingVersionId?: Maybe<Scalars['UUID']>;
  type?: Maybe<Scalars['String']>;
};

/** A condition to be used against `SdForm` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type SdFormCondition = {
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `creatingCompanyId` field. */
  creatingCompanyId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `documentTemplateId` field. */
  documentTemplateId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `lastModifiedBy` field. */
  lastModifiedBy?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `locationId` field. */
  locationId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `precedingVersionId` field. */
  precedingVersionId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `SdForm` object types. All fields are combined with a logical ‘and.’ */
export type SdFormFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SdFormFilter>>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `creatingCompanyId` field. */
  creatingCompanyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `documentTemplateId` field. */
  documentTemplateId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `lastModifiedBy` field. */
  lastModifiedBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `locationId` field. */
  locationId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SdFormFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SdFormFilter>>;
  /** Filter by the object’s `precedingVersionId` field. */
  precedingVersionId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `SdForm` */
export type SdFormInput = {
  createdBy: Scalars['UUID'];
  createdOn: Scalars['Datetime'];
  creatingCompanyId: Scalars['UUID'];
  documentId: Scalars['UUID'];
  documentTemplateId: Scalars['UUID'];
  documentTemplateName: Scalars['String'];
  documentTemplateVersionId: Scalars['UUID'];
  due?: InputMaybe<Scalars['Datetime']>;
  hasGoodData?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  lastModifiedBy: Scalars['UUID'];
  lastModifiedOn: Scalars['Datetime'];
  locationId: Scalars['UUID'];
  precedingVersionId?: InputMaybe<Scalars['UUID']>;
  type?: InputMaybe<Scalars['String']>;
};

export type SdFormItem = Node & {
  __typename?: 'SdFormItem';
  description: Scalars['String'];
  /** Reads a single `SdFormType` that is related to this `SdFormItem`. */
  documentTemplate?: Maybe<SdFormType>;
  documentTemplateId: Scalars['UUID'];
  itemId: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `SdFormWorkerValue`. */
  sdFormWorkerValuesByItemId: Array<SdFormWorkerValue>;
};


export type SdFormItemSdFormWorkerValuesByItemIdArgs = {
  condition?: InputMaybe<SdFormWorkerValueCondition>;
  filter?: InputMaybe<SdFormWorkerValueFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SdFormWorkerValuesOrderBy>>;
};

/**
 * A condition to be used against `SdFormItem` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type SdFormItemCondition = {
  /** Checks for equality with the object’s `documentTemplateId` field. */
  documentTemplateId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `itemId` field. */
  itemId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `SdFormItem` object types. All fields are combined with a logical ‘and.’ */
export type SdFormItemFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SdFormItemFilter>>;
  /** Filter by the object’s `documentTemplateId` field. */
  documentTemplateId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `itemId` field. */
  itemId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SdFormItemFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SdFormItemFilter>>;
};

/** An input for mutations affecting `SdFormItem` */
export type SdFormItemInput = {
  description: Scalars['String'];
  documentTemplateId: Scalars['UUID'];
  itemId: Scalars['UUID'];
};

/** Represents an update to a `SdFormItem`. Fields that are set will be updated. */
export type SdFormItemPatch = {
  description?: InputMaybe<Scalars['String']>;
  documentTemplateId?: InputMaybe<Scalars['UUID']>;
  itemId?: InputMaybe<Scalars['UUID']>;
};

/** Methods to use when ordering `SdFormItem`. */
export enum SdFormItemsOrderBy {
  DocumentTemplateIdAsc = 'DOCUMENT_TEMPLATE_ID_ASC',
  DocumentTemplateIdDesc = 'DOCUMENT_TEMPLATE_ID_DESC',
  ItemIdAsc = 'ITEM_ID_ASC',
  ItemIdDesc = 'ITEM_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** Represents an update to a `SdForm`. Fields that are set will be updated. */
export type SdFormPatch = {
  createdBy?: InputMaybe<Scalars['UUID']>;
  createdOn?: InputMaybe<Scalars['Datetime']>;
  creatingCompanyId?: InputMaybe<Scalars['UUID']>;
  documentId?: InputMaybe<Scalars['UUID']>;
  documentTemplateId?: InputMaybe<Scalars['UUID']>;
  documentTemplateName?: InputMaybe<Scalars['String']>;
  documentTemplateVersionId?: InputMaybe<Scalars['UUID']>;
  due?: InputMaybe<Scalars['Datetime']>;
  hasGoodData?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  lastModifiedBy?: InputMaybe<Scalars['UUID']>;
  lastModifiedOn?: InputMaybe<Scalars['Datetime']>;
  locationId?: InputMaybe<Scalars['UUID']>;
  precedingVersionId?: InputMaybe<Scalars['UUID']>;
  type?: InputMaybe<Scalars['String']>;
};

export type SdFormType = Node & {
  __typename?: 'SdFormType';
  canDuplicate: Scalars['Boolean'];
  createdBy: Scalars['UUID'];
  createdOn: Scalars['Datetime'];
  /** Reads a single `SdCompany` that is related to this `SdFormType`. */
  creatingCompany?: Maybe<SdCompany>;
  creatingCompanyId: Scalars['UUID'];
  documentTemplateId: Scalars['UUID'];
  hidden: Scalars['Boolean'];
  id: Scalars['UUID'];
  isDeleted: Scalars['Boolean'];
  isFollowup: Scalars['Boolean'];
  isPrivate: Scalars['Boolean'];
  isResource: Scalars['Boolean'];
  lastModifiedBy: Scalars['UUID'];
  lastModifiedOn: Scalars['Datetime'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `SdFormItem`. */
  sdFormItemsByDocumentTemplateId: Array<SdFormItem>;
  type: Scalars['String'];
};


export type SdFormTypeSdFormItemsByDocumentTemplateIdArgs = {
  condition?: InputMaybe<SdFormItemCondition>;
  filter?: InputMaybe<SdFormItemFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SdFormItemsOrderBy>>;
};

/**
 * A condition to be used against `SdFormType` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type SdFormTypeCondition = {
  /** Checks for equality with the object’s `creatingCompanyId` field. */
  creatingCompanyId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `documentTemplateId` field. */
  documentTemplateId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `SdFormType` object types. All fields are combined with a logical ‘and.’ */
export type SdFormTypeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SdFormTypeFilter>>;
  /** Filter by the object’s `creatingCompanyId` field. */
  creatingCompanyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `documentTemplateId` field. */
  documentTemplateId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SdFormTypeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SdFormTypeFilter>>;
};

/** An input for mutations affecting `SdFormType` */
export type SdFormTypeInput = {
  canDuplicate?: InputMaybe<Scalars['Boolean']>;
  createdBy: Scalars['UUID'];
  createdOn: Scalars['Datetime'];
  creatingCompanyId: Scalars['UUID'];
  documentTemplateId: Scalars['UUID'];
  hidden?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isFollowup?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  isResource: Scalars['Boolean'];
  lastModifiedBy: Scalars['UUID'];
  lastModifiedOn: Scalars['Datetime'];
  name: Scalars['String'];
  type: Scalars['String'];
};

/** Represents an update to a `SdFormType`. Fields that are set will be updated. */
export type SdFormTypePatch = {
  canDuplicate?: InputMaybe<Scalars['Boolean']>;
  createdBy?: InputMaybe<Scalars['UUID']>;
  createdOn?: InputMaybe<Scalars['Datetime']>;
  creatingCompanyId?: InputMaybe<Scalars['UUID']>;
  documentTemplateId?: InputMaybe<Scalars['UUID']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isFollowup?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  isResource?: InputMaybe<Scalars['Boolean']>;
  lastModifiedBy?: InputMaybe<Scalars['UUID']>;
  lastModifiedOn?: InputMaybe<Scalars['Datetime']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `SdFormType`. */
export enum SdFormTypesOrderBy {
  CreatingCompanyIdAsc = 'CREATING_COMPANY_ID_ASC',
  CreatingCompanyIdDesc = 'CREATING_COMPANY_ID_DESC',
  DocumentTemplateIdAsc = 'DOCUMENT_TEMPLATE_ID_ASC',
  DocumentTemplateIdDesc = 'DOCUMENT_TEMPLATE_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type SdFormWorkerValue = Node & {
  __typename?: 'SdFormWorkerValue';
  employeeId: Scalars['UUID'];
  formId: Scalars['UUID'];
  /** Reads a single `SdFormItem` that is related to this `SdFormWorkerValue`. */
  item?: Maybe<SdFormItem>;
  itemId: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/**
 * A condition to be used against `SdFormWorkerValue` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SdFormWorkerValueCondition = {
  /** Checks for equality with the object’s `formId` field. */
  formId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `itemId` field. */
  itemId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `SdFormWorkerValue` object types. All fields are combined with a logical ‘and.’ */
export type SdFormWorkerValueFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SdFormWorkerValueFilter>>;
  /** Filter by the object’s `formId` field. */
  formId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `itemId` field. */
  itemId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SdFormWorkerValueFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SdFormWorkerValueFilter>>;
};

/** An input for mutations affecting `SdFormWorkerValue` */
export type SdFormWorkerValueInput = {
  employeeId: Scalars['UUID'];
  formId: Scalars['UUID'];
  itemId: Scalars['UUID'];
};

/** Represents an update to a `SdFormWorkerValue`. Fields that are set will be updated. */
export type SdFormWorkerValuePatch = {
  employeeId?: InputMaybe<Scalars['UUID']>;
  formId?: InputMaybe<Scalars['UUID']>;
  itemId?: InputMaybe<Scalars['UUID']>;
};

/** Methods to use when ordering `SdFormWorkerValue`. */
export enum SdFormWorkerValuesOrderBy {
  FormIdAsc = 'FORM_ID_ASC',
  FormIdDesc = 'FORM_ID_DESC',
  ItemIdAsc = 'ITEM_ID_ASC',
  ItemIdDesc = 'ITEM_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** Methods to use when ordering `SdForm`. */
export enum SdFormsOrderBy {
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  CreatingCompanyIdAsc = 'CREATING_COMPANY_ID_ASC',
  CreatingCompanyIdDesc = 'CREATING_COMPANY_ID_DESC',
  DocumentTemplateIdAsc = 'DOCUMENT_TEMPLATE_ID_ASC',
  DocumentTemplateIdDesc = 'DOCUMENT_TEMPLATE_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsDeletedAsc = 'IS_DELETED_ASC',
  IsDeletedDesc = 'IS_DELETED_DESC',
  LastModifiedByAsc = 'LAST_MODIFIED_BY_ASC',
  LastModifiedByDesc = 'LAST_MODIFIED_BY_DESC',
  LocationIdAsc = 'LOCATION_ID_ASC',
  LocationIdDesc = 'LOCATION_ID_DESC',
  Natural = 'NATURAL',
  PrecedingVersionIdAsc = 'PRECEDING_VERSION_ID_ASC',
  PrecedingVersionIdDesc = 'PRECEDING_VERSION_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type SdLocation = Node & {
  __typename?: 'SdLocation';
  address: Scalars['String'];
  createdOn: Scalars['Datetime'];
  /** Reads a single `SdCompany` that is related to this `SdLocation`. */
  creatingCompany?: Maybe<SdCompany>;
  creatingCompanyId: Scalars['UUID'];
  description: Scalars['String'];
  endDate?: Maybe<Scalars['Datetime']>;
  id: Scalars['UUID'];
  isArchived: Scalars['Boolean'];
  lastModifiedOn: Scalars['Datetime'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `SdForm`. */
  sdFormsByLocationId: Array<SdForm>;
  startDate?: Maybe<Scalars['Datetime']>;
};


export type SdLocationSdFormsByLocationIdArgs = {
  condition?: InputMaybe<SdFormCondition>;
  filter?: InputMaybe<SdFormFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SdFormsOrderBy>>;
};

/**
 * A condition to be used against `SdLocation` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type SdLocationCondition = {
  /** Checks for equality with the object’s `creatingCompanyId` field. */
  creatingCompanyId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `SdLocation` object types. All fields are combined with a logical ‘and.’ */
export type SdLocationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SdLocationFilter>>;
  /** Filter by the object’s `creatingCompanyId` field. */
  creatingCompanyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SdLocationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SdLocationFilter>>;
};

/** An input for mutations affecting `SdLocation` */
export type SdLocationInput = {
  address: Scalars['String'];
  createdOn: Scalars['Datetime'];
  creatingCompanyId: Scalars['UUID'];
  description: Scalars['String'];
  endDate?: InputMaybe<Scalars['Datetime']>;
  id: Scalars['UUID'];
  isArchived: Scalars['Boolean'];
  lastModifiedOn: Scalars['Datetime'];
  name: Scalars['String'];
  startDate?: InputMaybe<Scalars['Datetime']>;
};

/** Represents an update to a `SdLocation`. Fields that are set will be updated. */
export type SdLocationPatch = {
  address?: InputMaybe<Scalars['String']>;
  createdOn?: InputMaybe<Scalars['Datetime']>;
  creatingCompanyId?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  lastModifiedOn?: InputMaybe<Scalars['Datetime']>;
  name?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Datetime']>;
};

/** Methods to use when ordering `SdLocation`. */
export enum SdLocationsOrderBy {
  CreatingCompanyIdAsc = 'CREATING_COMPANY_ID_ASC',
  CreatingCompanyIdDesc = 'CREATING_COMPANY_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type SdSignature = Node & {
  __typename?: 'SdSignature';
  createdBy: Scalars['UUID'];
  createdOn: Scalars['Datetime'];
  /** Reads a single `SdCompany` that is related to this `SdSignature`. */
  creatingCompany?: Maybe<SdCompany>;
  creatingCompanyId: Scalars['UUID'];
  documentVersionId: Scalars['UUID'];
  /** Reads a single `SdWorker` that is related to this `SdSignature`. */
  employee?: Maybe<SdWorker>;
  employeeId: Scalars['UUID'];
  id: Scalars['UUID'];
  imageId: Scalars['UUID'];
  isDeleted: Scalars['Boolean'];
  json?: Maybe<Scalars['String']>;
  lastModifiedBy: Scalars['UUID'];
  lastModifiedOn: Scalars['Datetime'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `SdWorker` that is related to this `SdSignature`. */
  sdWorkerByCreatedBy?: Maybe<SdWorker>;
  /** Reads a single `SdWorker` that is related to this `SdSignature`. */
  sdWorkerByLastModifiedBy?: Maybe<SdWorker>;
  serverReceivedOn: Scalars['Datetime'];
};

/**
 * A condition to be used against `SdSignature` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type SdSignatureCondition = {
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `creatingCompanyId` field. */
  creatingCompanyId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `employeeId` field. */
  employeeId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `lastModifiedBy` field. */
  lastModifiedBy?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `SdSignature` object types. All fields are combined with a logical ‘and.’ */
export type SdSignatureFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SdSignatureFilter>>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `creatingCompanyId` field. */
  creatingCompanyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `employeeId` field. */
  employeeId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lastModifiedBy` field. */
  lastModifiedBy?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SdSignatureFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SdSignatureFilter>>;
};

/** An input for mutations affecting `SdSignature` */
export type SdSignatureInput = {
  createdBy: Scalars['UUID'];
  createdOn: Scalars['Datetime'];
  creatingCompanyId: Scalars['UUID'];
  documentVersionId: Scalars['UUID'];
  employeeId: Scalars['UUID'];
  id: Scalars['UUID'];
  imageId: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  json?: InputMaybe<Scalars['String']>;
  lastModifiedBy: Scalars['UUID'];
  lastModifiedOn: Scalars['Datetime'];
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  serverReceivedOn: Scalars['Datetime'];
};

/** Represents an update to a `SdSignature`. Fields that are set will be updated. */
export type SdSignaturePatch = {
  createdBy?: InputMaybe<Scalars['UUID']>;
  createdOn?: InputMaybe<Scalars['Datetime']>;
  creatingCompanyId?: InputMaybe<Scalars['UUID']>;
  documentVersionId?: InputMaybe<Scalars['UUID']>;
  employeeId?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  imageId?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  json?: InputMaybe<Scalars['String']>;
  lastModifiedBy?: InputMaybe<Scalars['UUID']>;
  lastModifiedOn?: InputMaybe<Scalars['Datetime']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  serverReceivedOn?: InputMaybe<Scalars['Datetime']>;
};

/** Methods to use when ordering `SdSignature`. */
export enum SdSignaturesOrderBy {
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  CreatingCompanyIdAsc = 'CREATING_COMPANY_ID_ASC',
  CreatingCompanyIdDesc = 'CREATING_COMPANY_ID_DESC',
  EmployeeIdAsc = 'EMPLOYEE_ID_ASC',
  EmployeeIdDesc = 'EMPLOYEE_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LastModifiedByAsc = 'LAST_MODIFIED_BY_ASC',
  LastModifiedByDesc = 'LAST_MODIFIED_BY_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type SdWorker = Node & {
  __typename?: 'SdWorker';
  city?: Maybe<Scalars['String']>;
  createdOn: Scalars['Datetime'];
  dateHired?: Maybe<Scalars['Date']>;
  emergencyContact1?: Maybe<Scalars['String']>;
  emergencyContact2?: Maybe<Scalars['String']>;
  emergencyNotes?: Maybe<Scalars['String']>;
  employeeNumber?: Maybe<Scalars['String']>;
  /** Reads a single `SdCompany` that is related to this `SdWorker`. */
  employer?: Maybe<SdCompany>;
  employerId: Scalars['UUID'];
  firstName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  jobTitle?: Maybe<Scalars['String']>;
  lastModifiedOn: Scalars['Datetime'];
  lastName?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  phoneNumber?: Maybe<Scalars['String']>;
  pictureId?: Maybe<Scalars['UUID']>;
  postalCode?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `SdSignature`. */
  sdSignaturesByCreatedBy: Array<SdSignature>;
  /** Reads and enables pagination through a set of `SdSignature`. */
  sdSignaturesByEmployeeId: Array<SdSignature>;
  /** Reads and enables pagination through a set of `SdSignature`. */
  sdSignaturesByLastModifiedBy: Array<SdSignature>;
  streetAddress?: Maybe<Scalars['String']>;
};


export type SdWorkerSdSignaturesByCreatedByArgs = {
  condition?: InputMaybe<SdSignatureCondition>;
  filter?: InputMaybe<SdSignatureFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SdSignaturesOrderBy>>;
};


export type SdWorkerSdSignaturesByEmployeeIdArgs = {
  condition?: InputMaybe<SdSignatureCondition>;
  filter?: InputMaybe<SdSignatureFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SdSignaturesOrderBy>>;
};


export type SdWorkerSdSignaturesByLastModifiedByArgs = {
  condition?: InputMaybe<SdSignatureCondition>;
  filter?: InputMaybe<SdSignatureFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SdSignaturesOrderBy>>;
};

/**
 * A condition to be used against `SdWorker` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type SdWorkerCondition = {
  /** Checks for equality with the object’s `employeeNumber` field. */
  employeeNumber?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `employerId` field. */
  employerId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `SdWorker` object types. All fields are combined with a logical ‘and.’ */
export type SdWorkerFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SdWorkerFilter>>;
  /** Filter by the object’s `employeeNumber` field. */
  employeeNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `employerId` field. */
  employerId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SdWorkerFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SdWorkerFilter>>;
};

/** An input for mutations affecting `SdWorker` */
export type SdWorkerInput = {
  city?: InputMaybe<Scalars['String']>;
  createdOn: Scalars['Datetime'];
  dateHired?: InputMaybe<Scalars['Date']>;
  emergencyContact1?: InputMaybe<Scalars['String']>;
  emergencyContact2?: InputMaybe<Scalars['String']>;
  emergencyNotes?: InputMaybe<Scalars['String']>;
  employeeNumber?: InputMaybe<Scalars['String']>;
  employerId: Scalars['UUID'];
  firstName: Scalars['String'];
  gender?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  jobTitle?: InputMaybe<Scalars['String']>;
  lastModifiedOn: Scalars['Datetime'];
  lastName?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  pictureId?: InputMaybe<Scalars['UUID']>;
  postalCode?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
};

/** Represents an update to a `SdWorker`. Fields that are set will be updated. */
export type SdWorkerPatch = {
  city?: InputMaybe<Scalars['String']>;
  createdOn?: InputMaybe<Scalars['Datetime']>;
  dateHired?: InputMaybe<Scalars['Date']>;
  emergencyContact1?: InputMaybe<Scalars['String']>;
  emergencyContact2?: InputMaybe<Scalars['String']>;
  emergencyNotes?: InputMaybe<Scalars['String']>;
  employeeNumber?: InputMaybe<Scalars['String']>;
  employerId?: InputMaybe<Scalars['UUID']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  lastModifiedOn?: InputMaybe<Scalars['Datetime']>;
  lastName?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  pictureId?: InputMaybe<Scalars['UUID']>;
  postalCode?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `SdWorker`. */
export enum SdWorkersOrderBy {
  EmployeeNumberAsc = 'EMPLOYEE_NUMBER_ASC',
  EmployeeNumberDesc = 'EMPLOYEE_NUMBER_DESC',
  EmployerIdAsc = 'EMPLOYER_ID_ASC',
  EmployerIdDesc = 'EMPLOYER_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type SmallBreadcrumb = {
  __typename?: 'SmallBreadcrumb';
  assetId: Scalars['Int'];
  datetime: Scalars['DateTime'];
  position: Array<Scalars['Float']>;
  speed?: Maybe<Scalars['Float']>;
};

export type Station = {
  __typename?: 'Station';
  id: Scalars['Int'];
  install_date: Scalars['DateTime'];
  latestForecast?: Maybe<Forecast>;
  location: Point;
  readings: Array<Reading>;
  service_area_id: Scalars['String'];
  station_name: Scalars['String'];
};


export type StationReadingsArgs = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: InputMaybe<Scalars['String']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: InputMaybe<Scalars['String']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: InputMaybe<Scalars['String']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['String']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: InputMaybe<Scalars['String']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['String']>>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: InputMaybe<Array<Scalars['String']>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars['String']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars['String']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['String']>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: InputMaybe<Scalars['String']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: InputMaybe<Scalars['String']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: InputMaybe<Scalars['String']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['String']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: InputMaybe<Array<Scalars['String']>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: InputMaybe<Scalars['String']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: InputMaybe<Scalars['String']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: InputMaybe<Scalars['String']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: InputMaybe<Scalars['String']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: InputMaybe<Scalars['String']>;
};

export type Structure = {
  __typename?: 'Structure';
  geometry: Scalars['String'];
  id: Scalars['ID'];
  rfiHighwayName: Scalars['String'];
  serviceArea: Scalars['String'];
  srid3005: Scalars['String'];
  structureName: Scalars['String'];
  structureNumber: Scalars['String'];
  structureType: Scalars['String'];
};

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  reason?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export enum SupervisorLevel {
  Reviewer = 'REVIEWER',
  Supervisor = 'SUPERVISOR'
}

export type TcActivityRate = {
  __typename?: 'TcActivityRate';
  rate?: Maybe<Scalars['BigFloat']>;
  tcWorkId?: Maybe<Scalars['Int']>;
};

/** Methods to use when ordering `TcActivityRate`. */
export enum TcActivityRatesOrderBy {
  Natural = 'NATURAL'
}

export type TcAllM = {
  __typename?: 'TcAllM';
  actualLocalStart?: Maybe<Scalars['Datetime']>;
  actualLocalTime?: Maybe<DatetimeRange>;
  approved?: Maybe<Scalars['Boolean']>;
  asWhen?: Maybe<Scalars['Boolean']>;
  auxiliary?: Maybe<Scalars['Boolean']>;
  collectiveAgreement?: Maybe<Scalars['String']>;
  companyNo?: Maybe<Scalars['Int']>;
  cwu?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  shiftTime?: Maybe<DatetimeRange>;
  timezone?: Maybe<Scalars['String']>;
  vacationId?: Maybe<Scalars['Int']>;
  workerId?: Maybe<Scalars['Int']>;
};

/** A condition to be used against `TcAllM` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type TcAllMCondition = {
  /** Checks for equality with the object’s `actualLocalTime` field. */
  actualLocalTime?: InputMaybe<DatetimeRangeInput>;
  /** Checks for equality with the object’s `approved` field. */
  approved?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `asWhen` field. */
  asWhen?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `auxiliary` field. */
  auxiliary?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `collectiveAgreement` field. */
  collectiveAgreement?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `companyNo` field. */
  companyNo?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `cwu` field. */
  cwu?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `workerId` field. */
  workerId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `TcAllM` object types. All fields are combined with a logical ‘and.’ */
export type TcAllMFilter = {
  /** Filter by the object’s `actualLocalTime` field. */
  actualLocalTime?: InputMaybe<DatetimeRangeFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TcAllMFilter>>;
  /** Filter by the object’s `approved` field. */
  approved?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `asWhen` field. */
  asWhen?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `auxiliary` field. */
  auxiliary?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `collectiveAgreement` field. */
  collectiveAgreement?: InputMaybe<StringFilter>;
  /** Filter by the object’s `companyNo` field. */
  companyNo?: InputMaybe<IntFilter>;
  /** Filter by the object’s `cwu` field. */
  cwu?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TcAllMFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TcAllMFilter>>;
  /** Filter by the object’s `workerId` field. */
  workerId?: InputMaybe<IntFilter>;
};

/** Methods to use when ordering `TcAllM`. */
export enum TcAllMsOrderBy {
  ActualLocalTimeAsc = 'ACTUAL_LOCAL_TIME_ASC',
  ActualLocalTimeDesc = 'ACTUAL_LOCAL_TIME_DESC',
  ApprovedAsc = 'APPROVED_ASC',
  ApprovedDesc = 'APPROVED_DESC',
  AsWhenAsc = 'AS_WHEN_ASC',
  AsWhenDesc = 'AS_WHEN_DESC',
  AuxiliaryAsc = 'AUXILIARY_ASC',
  AuxiliaryDesc = 'AUXILIARY_DESC',
  CollectiveAgreementAsc = 'COLLECTIVE_AGREEMENT_ASC',
  CollectiveAgreementDesc = 'COLLECTIVE_AGREEMENT_DESC',
  CompanyNoAsc = 'COMPANY_NO_ASC',
  CompanyNoDesc = 'COMPANY_NO_DESC',
  CwuAsc = 'CWU_ASC',
  CwuDesc = 'CWU_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  WorkerIdAsc = 'WORKER_ID_ASC',
  WorkerIdDesc = 'WORKER_ID_DESC'
}

export type TcAssetUsage = {
  __typename?: 'TcAssetUsage';
  assetCode?: Maybe<Scalars['String']>;
  collectiveAgreement?: Maybe<Scalars['String']>;
  hoursUsed?: Maybe<Scalars['BigFloat']>;
  month?: Maybe<Scalars['String']>;
};

/** Methods to use when ordering `TcAssetUsage`. */
export enum TcAssetUsagesOrderBy {
  Natural = 'NATURAL'
}

/** Methods to use when ordering `TcAuxHolidayEligibility`. */
export enum TcAuxHolidayEligibilitiesOrderBy {
  Natural = 'NATURAL'
}

/**
 * 17.8 Paid Holidays for Auxiliary employees:
 * SA7, SA20 auxiliaries (not SA26 as/when): eligible for all GEU holidays if any of:
 * - worked 15 of last 30 days
 * - worked 105 ST hours in last 30 days
 * - worked the day before and after
 */
export type TcAuxHolidayEligibility = {
  __typename?: 'TcAuxHolidayEligibility';
  dayAfter?: Maybe<Scalars['BigInt']>;
  dayBefore?: Maybe<Scalars['BigInt']>;
  daysEligible?: Maybe<Scalars['Boolean']>;
  eligible?: Maybe<Scalars['Boolean']>;
  employeeNo?: Maybe<Scalars['Int']>;
  holiday?: Maybe<Scalars['Date']>;
  previousDays?: Maybe<Scalars['BigInt']>;
  previousSt?: Maybe<Scalars['BigFloat']>;
  proximateEligible?: Maybe<Scalars['Boolean']>;
  stEligible?: Maybe<Scalars['Boolean']>;
};

/** Methods to use when ordering `TcClassPlurality`. */
export enum TcClassPluralitiesOrderBy {
  Natural = 'NATURAL'
}

/** The most common class for a given timecard, used to determine the rate of pay for meal breaks */
export type TcClassPlurality = {
  __typename?: 'TcClassPlurality';
  classCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

/** Requires additional logic to identify auxilaries - company 5, AUX StdCraft, etc. */
export type TcCollectiveAgreement = Node & {
  __typename?: 'TcCollectiveAgreement';
  /** GEU Article 15.2b Afternoon Shift (area 7) */
  afternoonPartialStart?: Maybe<Scalars['Time']>;
  /** GEU Article 15.1a Afternoon Shift */
  afternoonStart?: Maybe<Scalars['Time']>;
  /** GEU Article 32.10b Vacation Entitlement (7) or 31.8 Annual Vacations (26), vacation rate from regular earnings */
  baseVacationRate?: Maybe<Scalars['Float']>;
  /** GEU Article 32.10a Vacation Entitlement (7) or 31.8 Annual Vacations (26), vacation rate from regular earnings for grandfathered employees */
  baseVacationRateAlt?: Maybe<Scalars['Float']>;
  calloutSacredHolidayCitation?: Maybe<Scalars['String']>;
  /** GEU Article 16.9c2 The allowable duration of callout work inside the clear period before a buffer must exist before starting the next shift */
  clearAllowance?: Maybe<Interval>;
  /** GEU Article 16.9c2 The window in which the clear_allowance applies */
  clearAllowanceWindow?: Maybe<Interval>;
  /** GEU Article 16.9c1/2 The time that must be clear from the end of the last shift to the start of the next */
  clearDuration?: Maybe<Interval>;
  /** GEU Article 16.9c3 length of shift remaining after clear period from last shift, after which the shift can be omitted and still paid */
  clearShiftMin?: Maybe<Interval>;
  /** GEU Article 15.1a Day Shift */
  dayStart?: Maybe<Scalars['Time']>;
  /** GEU Article 17.5 the historical number of DAYS WORKED to use to establish the majority base rate of pay for holiday compensation */
  historicPayWorkdays?: Maybe<Scalars['Int']>;
  /** GEU Article 17.5 the historical number of working HOURS to use to establish the majority base rate of pay for holiday compensation */
  historicPayWorktime?: Maybe<Interval>;
  /** GEU Article 18.4 the historical number of DAYS WORKED where REGULARLY SCHEDULED HOURS are used to use to establish the majority base rate of pay for vacation compensation */
  historicVacationWorkdays?: Maybe<Scalars['Int']>;
  /** GEU Article 17.3b /17.2b lieu pay rate */
  holidayEc?: Maybe<Scalars['Int']>;
  /** Each collective agreement is associated to a service area, but potentially also limited by StdCraft */
  id: Scalars['String'];
  /** GEU Article 17.4 / 17.3 holiday pay rate */
  lieuEc?: Maybe<Scalars['Int']>;
  lieuHolidayCitation?: Maybe<Scalars['String']>;
  /** GEU Article 17.3a/17.2a3 time wherein lieu day must be used or booked before converting to vacation */
  lieuRequestWindow?: Maybe<Interval>;
  /** GEU Article 16.9b (16.8b2 in area 20) maximum duration of a callout before an abutting shift may be cut short to compensate */
  maxCalloutDuration?: Maybe<Interval>;
  /** GEU Article 14.6a meal period duration */
  mealDuration?: Maybe<Interval>;
  /** GEU Article 15.1a Night Shift (area 26) */
  nightStart?: Maybe<Scalars['Time']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** GEU Article 16.2 increment of overtime precision (30m in all examined areas) */
  otIncrement?: Maybe<Interval>;
  /** GEU Article 16.5a continuous overtime first meal */
  otMeal1?: Maybe<Interval>;
  /** GEU Article 16.5b continuous overtime second meal */
  otMeal2?: Maybe<Interval>;
  /** GEU Article 16.5b continuous overtime nth meal beyond 2nd */
  otMealN?: Maybe<Interval>;
  /** GEU Article 16.2 minimum time before overtime is incremented */
  otMin?: Maybe<Interval>;
  scheduledSacredHolidayCitation?: Maybe<Scalars['String']>;
  /** GEU Article 16.4a1 overtime duration at ST rates (area 20) */
  stDuration?: Maybe<Interval>;
  /** GEU Article 16.8 (area 7), 16.5 (area 20), 16.2 (area 26) overtime pay is based on standard scheduled daily hours */
  standardShiftDuration?: Maybe<Interval>;
  /** GEU Article 16.4a1 overtime duration at TH rates (conditions apply in area 26) */
  thDuration?: Maybe<Interval>;
  /** GEU Article 14.3b duration of a day's vacation credits */
  vacationDay?: Maybe<Interval>;
  /** GEU Article 32.10b Vacation Entitlement (7) or 31.8 Annual Vacations (26), vacation rate from regular earnings for grandfathered employees */
  vacationGrandfatherCutoff?: Maybe<Scalars['Date']>;
  /** GEU Article 18.2a/c monthly minimum days of activity to acrue vacation that month */
  vacationMonthlySeniority?: Maybe<Scalars['Int']>;
  /** GEU Article 15.1c Weekend Shift Premiums (area 7) */
  weekendEnd?: Maybe<Scalars['Time']>;
  /** GEU Article 15.1c Weekend Shift Premiums (area 7) */
  weekendStart?: Maybe<Scalars['Time']>;
  /** GEU Article 14.3 (also 14.1, 14.7) maximum workday duration where prorated against shift schedule */
  workdayMaxDuration?: Maybe<Interval>;
};

/**
 * A condition to be used against `TcCollectiveAgreement` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type TcCollectiveAgreementCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `TcCollectiveAgreement` object types. All fields are combined with a logical ‘and.’ */
export type TcCollectiveAgreementFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TcCollectiveAgreementFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TcCollectiveAgreementFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TcCollectiveAgreementFilter>>;
};

/** An input for mutations affecting `TcCollectiveAgreement` */
export type TcCollectiveAgreementInput = {
  /** GEU Article 15.2b Afternoon Shift (area 7) */
  afternoonPartialStart?: InputMaybe<Scalars['Time']>;
  /** GEU Article 15.1a Afternoon Shift */
  afternoonStart?: InputMaybe<Scalars['Time']>;
  /** GEU Article 32.10b Vacation Entitlement (7) or 31.8 Annual Vacations (26), vacation rate from regular earnings */
  baseVacationRate?: InputMaybe<Scalars['Float']>;
  /** GEU Article 32.10a Vacation Entitlement (7) or 31.8 Annual Vacations (26), vacation rate from regular earnings for grandfathered employees */
  baseVacationRateAlt?: InputMaybe<Scalars['Float']>;
  calloutSacredHolidayCitation?: InputMaybe<Scalars['String']>;
  /** GEU Article 16.9c2 The allowable duration of callout work inside the clear period before a buffer must exist before starting the next shift */
  clearAllowance?: InputMaybe<IntervalInput>;
  /** GEU Article 16.9c2 The window in which the clear_allowance applies */
  clearAllowanceWindow?: InputMaybe<IntervalInput>;
  /** GEU Article 16.9c1/2 The time that must be clear from the end of the last shift to the start of the next */
  clearDuration?: InputMaybe<IntervalInput>;
  /** GEU Article 16.9c3 length of shift remaining after clear period from last shift, after which the shift can be omitted and still paid */
  clearShiftMin?: InputMaybe<IntervalInput>;
  /** GEU Article 15.1a Day Shift */
  dayStart?: InputMaybe<Scalars['Time']>;
  /** GEU Article 17.5 the historical number of DAYS WORKED to use to establish the majority base rate of pay for holiday compensation */
  historicPayWorkdays?: InputMaybe<Scalars['Int']>;
  /** GEU Article 17.5 the historical number of working HOURS to use to establish the majority base rate of pay for holiday compensation */
  historicPayWorktime?: InputMaybe<IntervalInput>;
  /** GEU Article 18.4 the historical number of DAYS WORKED where REGULARLY SCHEDULED HOURS are used to use to establish the majority base rate of pay for vacation compensation */
  historicVacationWorkdays?: InputMaybe<Scalars['Int']>;
  /** GEU Article 17.3b /17.2b lieu pay rate */
  holidayEc?: InputMaybe<Scalars['Int']>;
  /** Each collective agreement is associated to a service area, but potentially also limited by StdCraft */
  id: Scalars['String'];
  /** GEU Article 17.4 / 17.3 holiday pay rate */
  lieuEc?: InputMaybe<Scalars['Int']>;
  lieuHolidayCitation?: InputMaybe<Scalars['String']>;
  /** GEU Article 17.3a/17.2a3 time wherein lieu day must be used or booked before converting to vacation */
  lieuRequestWindow?: InputMaybe<IntervalInput>;
  /** GEU Article 16.9b (16.8b2 in area 20) maximum duration of a callout before an abutting shift may be cut short to compensate */
  maxCalloutDuration?: InputMaybe<IntervalInput>;
  /** GEU Article 14.6a meal period duration */
  mealDuration?: InputMaybe<IntervalInput>;
  /** GEU Article 15.1a Night Shift (area 26) */
  nightStart?: InputMaybe<Scalars['Time']>;
  /** GEU Article 16.2 increment of overtime precision (30m in all examined areas) */
  otIncrement?: InputMaybe<IntervalInput>;
  /** GEU Article 16.5a continuous overtime first meal */
  otMeal1?: InputMaybe<IntervalInput>;
  /** GEU Article 16.5b continuous overtime second meal */
  otMeal2?: InputMaybe<IntervalInput>;
  /** GEU Article 16.5b continuous overtime nth meal beyond 2nd */
  otMealN?: InputMaybe<IntervalInput>;
  /** GEU Article 16.2 minimum time before overtime is incremented */
  otMin?: InputMaybe<IntervalInput>;
  scheduledSacredHolidayCitation?: InputMaybe<Scalars['String']>;
  /** GEU Article 16.4a1 overtime duration at ST rates (area 20) */
  stDuration?: InputMaybe<IntervalInput>;
  /** GEU Article 16.8 (area 7), 16.5 (area 20), 16.2 (area 26) overtime pay is based on standard scheduled daily hours */
  standardShiftDuration?: InputMaybe<IntervalInput>;
  /** GEU Article 16.4a1 overtime duration at TH rates (conditions apply in area 26) */
  thDuration?: InputMaybe<IntervalInput>;
  /** GEU Article 14.3b duration of a day's vacation credits */
  vacationDay?: InputMaybe<IntervalInput>;
  /** GEU Article 32.10b Vacation Entitlement (7) or 31.8 Annual Vacations (26), vacation rate from regular earnings for grandfathered employees */
  vacationGrandfatherCutoff?: InputMaybe<Scalars['Date']>;
  /** GEU Article 18.2a/c monthly minimum days of activity to acrue vacation that month */
  vacationMonthlySeniority?: InputMaybe<Scalars['Int']>;
  /** GEU Article 15.1c Weekend Shift Premiums (area 7) */
  weekendEnd?: InputMaybe<Scalars['Time']>;
  /** GEU Article 15.1c Weekend Shift Premiums (area 7) */
  weekendStart?: InputMaybe<Scalars['Time']>;
  /** GEU Article 14.3 (also 14.1, 14.7) maximum workday duration where prorated against shift schedule */
  workdayMaxDuration?: InputMaybe<IntervalInput>;
};

/** Represents an update to a `TcCollectiveAgreement`. Fields that are set will be updated. */
export type TcCollectiveAgreementPatch = {
  /** GEU Article 15.2b Afternoon Shift (area 7) */
  afternoonPartialStart?: InputMaybe<Scalars['Time']>;
  /** GEU Article 15.1a Afternoon Shift */
  afternoonStart?: InputMaybe<Scalars['Time']>;
  /** GEU Article 32.10b Vacation Entitlement (7) or 31.8 Annual Vacations (26), vacation rate from regular earnings */
  baseVacationRate?: InputMaybe<Scalars['Float']>;
  /** GEU Article 32.10a Vacation Entitlement (7) or 31.8 Annual Vacations (26), vacation rate from regular earnings for grandfathered employees */
  baseVacationRateAlt?: InputMaybe<Scalars['Float']>;
  calloutSacredHolidayCitation?: InputMaybe<Scalars['String']>;
  /** GEU Article 16.9c2 The allowable duration of callout work inside the clear period before a buffer must exist before starting the next shift */
  clearAllowance?: InputMaybe<IntervalInput>;
  /** GEU Article 16.9c2 The window in which the clear_allowance applies */
  clearAllowanceWindow?: InputMaybe<IntervalInput>;
  /** GEU Article 16.9c1/2 The time that must be clear from the end of the last shift to the start of the next */
  clearDuration?: InputMaybe<IntervalInput>;
  /** GEU Article 16.9c3 length of shift remaining after clear period from last shift, after which the shift can be omitted and still paid */
  clearShiftMin?: InputMaybe<IntervalInput>;
  /** GEU Article 15.1a Day Shift */
  dayStart?: InputMaybe<Scalars['Time']>;
  /** GEU Article 17.5 the historical number of DAYS WORKED to use to establish the majority base rate of pay for holiday compensation */
  historicPayWorkdays?: InputMaybe<Scalars['Int']>;
  /** GEU Article 17.5 the historical number of working HOURS to use to establish the majority base rate of pay for holiday compensation */
  historicPayWorktime?: InputMaybe<IntervalInput>;
  /** GEU Article 18.4 the historical number of DAYS WORKED where REGULARLY SCHEDULED HOURS are used to use to establish the majority base rate of pay for vacation compensation */
  historicVacationWorkdays?: InputMaybe<Scalars['Int']>;
  /** GEU Article 17.3b /17.2b lieu pay rate */
  holidayEc?: InputMaybe<Scalars['Int']>;
  /** Each collective agreement is associated to a service area, but potentially also limited by StdCraft */
  id?: InputMaybe<Scalars['String']>;
  /** GEU Article 17.4 / 17.3 holiday pay rate */
  lieuEc?: InputMaybe<Scalars['Int']>;
  lieuHolidayCitation?: InputMaybe<Scalars['String']>;
  /** GEU Article 17.3a/17.2a3 time wherein lieu day must be used or booked before converting to vacation */
  lieuRequestWindow?: InputMaybe<IntervalInput>;
  /** GEU Article 16.9b (16.8b2 in area 20) maximum duration of a callout before an abutting shift may be cut short to compensate */
  maxCalloutDuration?: InputMaybe<IntervalInput>;
  /** GEU Article 14.6a meal period duration */
  mealDuration?: InputMaybe<IntervalInput>;
  /** GEU Article 15.1a Night Shift (area 26) */
  nightStart?: InputMaybe<Scalars['Time']>;
  /** GEU Article 16.2 increment of overtime precision (30m in all examined areas) */
  otIncrement?: InputMaybe<IntervalInput>;
  /** GEU Article 16.5a continuous overtime first meal */
  otMeal1?: InputMaybe<IntervalInput>;
  /** GEU Article 16.5b continuous overtime second meal */
  otMeal2?: InputMaybe<IntervalInput>;
  /** GEU Article 16.5b continuous overtime nth meal beyond 2nd */
  otMealN?: InputMaybe<IntervalInput>;
  /** GEU Article 16.2 minimum time before overtime is incremented */
  otMin?: InputMaybe<IntervalInput>;
  scheduledSacredHolidayCitation?: InputMaybe<Scalars['String']>;
  /** GEU Article 16.4a1 overtime duration at ST rates (area 20) */
  stDuration?: InputMaybe<IntervalInput>;
  /** GEU Article 16.8 (area 7), 16.5 (area 20), 16.2 (area 26) overtime pay is based on standard scheduled daily hours */
  standardShiftDuration?: InputMaybe<IntervalInput>;
  /** GEU Article 16.4a1 overtime duration at TH rates (conditions apply in area 26) */
  thDuration?: InputMaybe<IntervalInput>;
  /** GEU Article 14.3b duration of a day's vacation credits */
  vacationDay?: InputMaybe<IntervalInput>;
  /** GEU Article 32.10b Vacation Entitlement (7) or 31.8 Annual Vacations (26), vacation rate from regular earnings for grandfathered employees */
  vacationGrandfatherCutoff?: InputMaybe<Scalars['Date']>;
  /** GEU Article 18.2a/c monthly minimum days of activity to acrue vacation that month */
  vacationMonthlySeniority?: InputMaybe<Scalars['Int']>;
  /** GEU Article 15.1c Weekend Shift Premiums (area 7) */
  weekendEnd?: InputMaybe<Scalars['Time']>;
  /** GEU Article 15.1c Weekend Shift Premiums (area 7) */
  weekendStart?: InputMaybe<Scalars['Time']>;
  /** GEU Article 14.3 (also 14.1, 14.7) maximum workday duration where prorated against shift schedule */
  workdayMaxDuration?: InputMaybe<IntervalInput>;
};

/** Methods to use when ordering `TcCollectiveAgreement`. */
export enum TcCollectiveAgreementsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** Methods to use when ordering `TcCompany`. */
export enum TcCompaniesOrderBy {
  CompanyNoAsc = 'COMPANY_NO_ASC',
  CompanyNoDesc = 'COMPANY_NO_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type TcCompany = Node & {
  __typename?: 'TcCompany';
  companyNo: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  overheadJob?: Maybe<Scalars['String']>;
  timezone: Scalars['String'];
};

export type TcCompanyAgreement = Node & {
  __typename?: 'TcCompanyAgreement';
  collectiveAgreement: Scalars['String'];
  companyNo: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/**
 * A condition to be used against `TcCompanyAgreement` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TcCompanyAgreementCondition = {
  /** Checks for equality with the object’s `collectiveAgreement` field. */
  collectiveAgreement?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `companyNo` field. */
  companyNo?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `TcCompanyAgreement` object types. All fields are combined with a logical ‘and.’ */
export type TcCompanyAgreementFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TcCompanyAgreementFilter>>;
  /** Filter by the object’s `collectiveAgreement` field. */
  collectiveAgreement?: InputMaybe<StringFilter>;
  /** Filter by the object’s `companyNo` field. */
  companyNo?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TcCompanyAgreementFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TcCompanyAgreementFilter>>;
};

/** An input for mutations affecting `TcCompanyAgreement` */
export type TcCompanyAgreementInput = {
  collectiveAgreement: Scalars['String'];
  companyNo: Scalars['Int'];
};

/** Represents an update to a `TcCompanyAgreement`. Fields that are set will be updated. */
export type TcCompanyAgreementPatch = {
  collectiveAgreement?: InputMaybe<Scalars['String']>;
  companyNo?: InputMaybe<Scalars['Int']>;
};

/** Methods to use when ordering `TcCompanyAgreement`. */
export enum TcCompanyAgreementsOrderBy {
  CollectiveAgreementAsc = 'COLLECTIVE_AGREEMENT_ASC',
  CollectiveAgreementDesc = 'COLLECTIVE_AGREEMENT_DESC',
  CompanyNoAsc = 'COMPANY_NO_ASC',
  CompanyNoDesc = 'COMPANY_NO_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `TcCompany` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TcCompanyCondition = {
  /** Checks for equality with the object’s `companyNo` field. */
  companyNo?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `TcCompany` object types. All fields are combined with a logical ‘and.’ */
export type TcCompanyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TcCompanyFilter>>;
  /** Filter by the object’s `companyNo` field. */
  companyNo?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TcCompanyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TcCompanyFilter>>;
};

/** An input for mutations affecting `TcCompany` */
export type TcCompanyInput = {
  companyNo: Scalars['Int'];
  overheadJob?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};

/** Represents an update to a `TcCompany`. Fields that are set will be updated. */
export type TcCompanyPatch = {
  companyNo?: InputMaybe<Scalars['Int']>;
  overheadJob?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};

/** The dominant class for a given timecard, used to determine the rate of pay for meal breaks */
export type TcDominantClass = {
  __typename?: 'TcDominantClass';
  classCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

/** Methods to use when ordering `TcDominantClass`. */
export enum TcDominantClassesOrderBy {
  Natural = 'NATURAL'
}

export type TcEarningCodeRate = Node & {
  __typename?: 'TcEarningCodeRate';
  collectiveAgreement: Scalars['String'];
  earningCode: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  rate: Scalars['BigFloat'];
};

/**
 * A condition to be used against `TcEarningCodeRate` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TcEarningCodeRateCondition = {
  /** Checks for equality with the object’s `collectiveAgreement` field. */
  collectiveAgreement?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `TcEarningCodeRate` object types. All fields are combined with a logical ‘and.’ */
export type TcEarningCodeRateFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TcEarningCodeRateFilter>>;
  /** Filter by the object’s `collectiveAgreement` field. */
  collectiveAgreement?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TcEarningCodeRateFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TcEarningCodeRateFilter>>;
};

/** An input for mutations affecting `TcEarningCodeRate` */
export type TcEarningCodeRateInput = {
  collectiveAgreement: Scalars['String'];
  earningCode: Scalars['String'];
  rate: Scalars['BigFloat'];
};

/** Represents an update to a `TcEarningCodeRate`. Fields that are set will be updated. */
export type TcEarningCodeRatePatch = {
  collectiveAgreement?: InputMaybe<Scalars['String']>;
  earningCode?: InputMaybe<Scalars['String']>;
  rate?: InputMaybe<Scalars['BigFloat']>;
};

/** Methods to use when ordering `TcEarningCodeRate`. */
export enum TcEarningCodeRatesOrderBy {
  CollectiveAgreementAsc = 'COLLECTIVE_AGREEMENT_ASC',
  CollectiveAgreementDesc = 'COLLECTIVE_AGREEMENT_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type TcEquipment = Node & {
  __typename?: 'TcEquipment';
  /** Reads a single `Asset` that is related to this `TcEquipment`. */
  asset?: Maybe<Asset>;
  assetId: Scalars['Int'];
  duration: Scalars['Int'];
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `TcWork` that is related to this `TcEquipment`. */
  tcWork?: Maybe<TcWork>;
  tcWorkId: Scalars['Int'];
};

/**
 * A condition to be used against `TcEquipment` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TcEquipmentCondition = {
  /** Checks for equality with the object’s `assetId` field. */
  assetId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `tcWorkId` field. */
  tcWorkId?: InputMaybe<Scalars['Int']>;
};

export type TcEquipmentExtra = {
  __typename?: 'TcEquipmentExtra';
  assetCode?: Maybe<Scalars['String']>;
  classCode?: Maybe<Scalars['String']>;
  earningCode?: Maybe<Scalars['String']>;
  equipmentUsage?: Maybe<Scalars['Int']>;
  hours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  jobCode?: Maybe<Scalars['String']>;
  phaseCode?: Maybe<Scalars['String']>;
};

/** Methods to use when ordering `TcEquipmentExtra`. */
export enum TcEquipmentExtrasOrderBy {
  Natural = 'NATURAL'
}

/** A filter to be used against `TcEquipment` object types. All fields are combined with a logical ‘and.’ */
export type TcEquipmentFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TcEquipmentFilter>>;
  /** Filter by the object’s `assetId` field. */
  assetId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TcEquipmentFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TcEquipmentFilter>>;
  /** Filter by the object’s `tcWorkId` field. */
  tcWorkId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `TcEquipment` */
export type TcEquipmentInput = {
  assetId: Scalars['Int'];
  duration: Scalars['Int'];
  tcWorkId: Scalars['Int'];
};

/** Methods to use when ordering `TcEquipment`. */
export enum TcEquipmentOrderBy {
  AssetIdAsc = 'ASSET_ID_ASC',
  AssetIdDesc = 'ASSET_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TcWorkIdAsc = 'TC_WORK_ID_ASC',
  TcWorkIdDesc = 'TC_WORK_ID_DESC'
}

/** Represents an update to a `TcEquipment`. Fields that are set will be updated. */
export type TcEquipmentPatch = {
  assetId?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['Int']>;
  tcWorkId?: InputMaybe<Scalars['Int']>;
};

export type TcEsa = {
  __typename?: 'TcEsa';
  holidayId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  workDurationDay?: Maybe<Interval>;
  workDurationWeek?: Maybe<Interval>;
};

export type TcEsaEc = {
  __typename?: 'TcEsaEc';
  earningCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  period?: Maybe<DatetimeRange>;
};

/** Methods to use when ordering `TcEsaEc`. */
export enum TcEsaEcsOrderBy {
  Natural = 'NATURAL'
}

/** Compensation in hours to match eligible earnings for ESA employees (just Ballina) */
export type TcEsaHoliday = {
  __typename?: 'TcEsaHoliday';
  classCode?: Maybe<Scalars['String']>;
  companyNo?: Maybe<Scalars['Int']>;
  craftCode?: Maybe<Scalars['String']>;
  employeeNo?: Maybe<Scalars['Int']>;
  holiday?: Maybe<Scalars['Date']>;
  holidayCompensation?: Maybe<Scalars['BigFloat']>;
  hours?: Maybe<Scalars['BigFloat']>;
  userId?: Maybe<Scalars['Int']>;
};

/**
 * ESA 44: eligible if worked 15 of the past 30 days
 * ESA 45: total compensation for last 30 days / days active in that period
 */
export type TcEsaHolidayCompensation = {
  __typename?: 'TcEsaHolidayCompensation';
  eligible?: Maybe<Scalars['Boolean']>;
  holiday?: Maybe<Scalars['Date']>;
  holidayCompensation?: Maybe<Scalars['BigFloat']>;
  previousCompensation?: Maybe<Scalars['BigFloat']>;
  previousDays?: Maybe<Scalars['BigInt']>;
  userId?: Maybe<Scalars['Int']>;
};

/** Methods to use when ordering `TcEsaHolidayCompensation`. */
export enum TcEsaHolidayCompensationsOrderBy {
  Natural = 'NATURAL'
}

export type TcEsaHolidayEc = {
  __typename?: 'TcEsaHolidayEc';
  classCode?: Maybe<Scalars['String']>;
  companyNo?: Maybe<Scalars['Int']>;
  craftCode?: Maybe<Scalars['String']>;
  earningCode?: Maybe<Scalars['String']>;
  employeeNo?: Maybe<Scalars['Int']>;
  entryDate?: Maybe<Scalars['Date']>;
  hours?: Maybe<Scalars['BigFloat']>;
  jobCode?: Maybe<Scalars['String']>;
  phaseCode?: Maybe<Scalars['String']>;
};

/** Methods to use when ordering `TcEsaHolidayEc`. */
export enum TcEsaHolidayEcsOrderBy {
  Natural = 'NATURAL'
}

export type TcEsaHolidayWorker = {
  __typename?: 'TcEsaHolidayWorker';
  description?: Maybe<Scalars['String']>;
  holiday?: Maybe<Scalars['Date']>;
  holidayEnd?: Maybe<Scalars['Datetime']>;
  holidayId?: Maybe<Scalars['Int']>;
  holidayStart?: Maybe<Scalars['Datetime']>;
  userId?: Maybe<Scalars['Int']>;
};

/** Methods to use when ordering `TcEsaHolidayWorker`. */
export enum TcEsaHolidayWorkersOrderBy {
  Natural = 'NATURAL'
}

/** Methods to use when ordering `TcEsaHoliday`. */
export enum TcEsaHolidaysOrderBy {
  Natural = 'NATURAL'
}

/**
 * ESA 35, 40: OT after 8h/day or 40h/week starting Sunday (see Definitions > Week)
 * 36: 32 consecutive hours clear at some point each week or TH (we can ignore this, since unscheduled hours are already paid TH and we won't schedule such shifts)
 */
export type TcEsaOt = {
  __typename?: 'TcEsaOt';
  dt?: Maybe<Interval>;
  id?: Maybe<Scalars['Int']>;
  st?: Maybe<Interval>;
  th?: Maybe<Interval>;
};

/** Methods to use when ordering `TcEsaOt`. */
export enum TcEsaOtsOrderBy {
  Natural = 'NATURAL'
}

/** ESA 58: minimum 4% or 6% of wages depending on duration of employment */
export type TcEsaVacationCompensation = {
  __typename?: 'TcEsaVacationCompensation';
  earnings?: Maybe<Scalars['BigFloat']>;
  st?: Maybe<Scalars['BigFloat']>;
  userId?: Maybe<Scalars['Int']>;
  wages?: Maybe<Scalars['BigFloat']>;
  weekStart?: Maybe<Scalars['Date']>;
};

/** Methods to use when ordering `TcEsaVacationCompensation`. */
export enum TcEsaVacationCompensationsOrderBy {
  Natural = 'NATURAL'
}

export type TcEsaWorkEc = {
  __typename?: 'TcEsaWorkEc';
  earningCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  period?: Maybe<DatetimeRange>;
  proportion?: Maybe<Scalars['Float']>;
  workId?: Maybe<Scalars['Int']>;
  workPeriod?: Maybe<DatetimeRange>;
  workRange?: Maybe<DatetimeRange>;
};

/** Methods to use when ordering `TcEsaWorkEc`. */
export enum TcEsaWorkEcsOrderBy {
  Natural = 'NATURAL'
}

/** Methods to use when ordering `TcEsa`. */
export enum TcEsasOrderBy {
  Natural = 'NATURAL'
}

export type TcGeu = {
  __typename?: 'TcGeu';
  callout?: Maybe<Scalars['Boolean']>;
  dtHoliday?: Maybe<Scalars['Boolean']>;
  holidayId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  lieuId?: Maybe<Scalars['Int']>;
  otEnd?: Maybe<Interval>;
  otStart?: Maybe<Interval>;
  previousId?: Maybe<Scalars['Int']>;
  previousTime?: Maybe<DatetimeRange>;
  shiftClearStart?: Maybe<Scalars['Datetime']>;
};

export type TcGeuBenefit = {
  __typename?: 'TcGeuBenefit';
  duration?: Maybe<Interval>;
  earningCode?: Maybe<Scalars['String']>;
  explanation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

/** Methods to use when ordering `TcGeuBenefit`. */
export enum TcGeuBenefitsOrderBy {
  Natural = 'NATURAL'
}

export type TcGeuCallout = {
  __typename?: 'TcGeuCallout';
  earningCode?: Maybe<Scalars['String']>;
  explanation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  period?: Maybe<DatetimeRange>;
};

/**
 * Calculations for work that is not linked to a scheduled shift are simpler.
 * All unscheduled work (except for SA26 as-and-when auxiliaries which are not calculated here) is OT by default,
 * so we only need to check for things that could elevate it further, eg. lieu pay, sacred holiday pay.
 * From there, that base OT rate on all hours up to a standard workday and DT thereafter.
 */
export type TcGeuCalloutCalc = {
  __typename?: 'TcGeuCalloutCalc';
  dayType?: Maybe<Scalars['String']>;
  dt?: Maybe<Interval>;
  holidayDescription?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  th?: Maybe<Interval>;
};

/** Methods to use when ordering `TcGeuCalloutCalc`. */
export enum TcGeuCalloutCalcsOrderBy {
  Natural = 'NATURAL'
}

export type TcGeuCalloutWorkEc = {
  __typename?: 'TcGeuCalloutWorkEc';
  earningCode?: Maybe<Scalars['String']>;
  explanation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  period?: Maybe<DatetimeRange>;
  proportion?: Maybe<Scalars['Float']>;
  workId?: Maybe<Scalars['Int']>;
  workPeriod?: Maybe<DatetimeRange>;
  workRange?: Maybe<DatetimeRange>;
};

/** Methods to use when ordering `TcGeuCalloutWorkEc`. */
export enum TcGeuCalloutWorkEcsOrderBy {
  Natural = 'NATURAL'
}

/** Methods to use when ordering `TcGeuCallout`. */
export enum TcGeuCalloutsOrderBy {
  Natural = 'NATURAL'
}

/**
 * Holiday results in either getting holiday pay (49) or a lieu credit (52).
 * Lieu days are either utilized (42) or paid out (54)
 * 49 holiday unscheduled, get paid at historic rate
 * 52 holiday worked as scheduled, get lieu day credit at historic rate
 * 42 lieu day utilized, paid at rate earned
 * 54 lieu day never booked, paid at rate earned
 */
export type TcGeuHoliday = {
  __typename?: 'TcGeuHoliday';
  classCode?: Maybe<Scalars['String']>;
  credit?: Maybe<Interval>;
  earningCode?: Maybe<Scalars['String']>;
  entryDate?: Maybe<Scalars['Date']>;
  explanation?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
};

/** Every holiday matched with every week of every schedule, including auto-bumps for combined holidays */
export type TcGeuHolidaySchedule = {
  __typename?: 'TcGeuHolidaySchedule';
  alternateDay?: Maybe<Scalars['Date']>;
  collectiveAgreement?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  holiday?: Maybe<Scalars['Date']>;
  holidayId?: Maybe<Scalars['Int']>;
  holidayTime?: Maybe<DatetimeRange>;
  shiftPatternId?: Maybe<Scalars['Int']>;
  weekOffset?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `TcGeuHolidaySchedule` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type TcGeuHolidayScheduleCondition = {
  /** Checks for equality with the object’s `alternateDay` field. */
  alternateDay?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `holidayId` field. */
  holidayId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `holidayTime` field. */
  holidayTime?: InputMaybe<DatetimeRangeInput>;
};

/** A filter to be used against `TcGeuHolidaySchedule` object types. All fields are combined with a logical ‘and.’ */
export type TcGeuHolidayScheduleFilter = {
  /** Filter by the object’s `alternateDay` field. */
  alternateDay?: InputMaybe<DateFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TcGeuHolidayScheduleFilter>>;
  /** Filter by the object’s `holidayId` field. */
  holidayId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `holidayTime` field. */
  holidayTime?: InputMaybe<DatetimeRangeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TcGeuHolidayScheduleFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TcGeuHolidayScheduleFilter>>;
};

/** Methods to use when ordering `TcGeuHolidaySchedule`. */
export enum TcGeuHolidaySchedulesOrderBy {
  AlternateDayAsc = 'ALTERNATE_DAY_ASC',
  AlternateDayDesc = 'ALTERNATE_DAY_DESC',
  HolidayIdAsc = 'HOLIDAY_ID_ASC',
  HolidayIdDesc = 'HOLIDAY_ID_DESC',
  HolidayTimeAsc = 'HOLIDAY_TIME_ASC',
  HolidayTimeDesc = 'HOLIDAY_TIME_DESC',
  Natural = 'NATURAL'
}

/** Every holiday matched with every GEU worker with an active shift pattern when it occurs, auto-bumped to next scheduled workday where applicable, including lieu days where applicable */
export type TcGeuHolidayWorker = {
  __typename?: 'TcGeuHolidayWorker';
  alternateDay?: Maybe<Scalars['Date']>;
  collectiveAgreement?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  holiday?: Maybe<Scalars['Date']>;
  holidayId?: Maybe<Scalars['Int']>;
  holidayTime?: Maybe<DatetimeRange>;
  lieuInAdvance?: Maybe<Scalars['Boolean']>;
  lieuLimit?: Maybe<Scalars['Date']>;
  lieuTime?: Maybe<DatetimeRange>;
  userId?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `TcGeuHolidayWorker` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TcGeuHolidayWorkerCondition = {
  /** Checks for equality with the object’s `alternateDay` field. */
  alternateDay?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `lieuTime` field. */
  lieuTime?: InputMaybe<DatetimeRangeInput>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `TcGeuHolidayWorker` object types. All fields are combined with a logical ‘and.’ */
export type TcGeuHolidayWorkerFilter = {
  /** Filter by the object’s `alternateDay` field. */
  alternateDay?: InputMaybe<DateFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TcGeuHolidayWorkerFilter>>;
  /** Filter by the object’s `lieuTime` field. */
  lieuTime?: InputMaybe<DatetimeRangeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TcGeuHolidayWorkerFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TcGeuHolidayWorkerFilter>>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

export type TcGeuHolidayWorkerView = {
  __typename?: 'TcGeuHolidayWorkerView';
  alternateDay?: Maybe<Scalars['Date']>;
  collectiveAgreement?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  holiday?: Maybe<Scalars['Date']>;
  holidayId?: Maybe<Scalars['Int']>;
  holidayTime?: Maybe<DatetimeRange>;
  lieuInAdvance?: Maybe<Scalars['Boolean']>;
  lieuLimit?: Maybe<Scalars['Date']>;
  lieuTime?: Maybe<DatetimeRange>;
  userId?: Maybe<Scalars['Int']>;
};

/** Methods to use when ordering `TcGeuHolidayWorkerView`. */
export enum TcGeuHolidayWorkerViewsOrderBy {
  Natural = 'NATURAL'
}

/** Methods to use when ordering `TcGeuHolidayWorker`. */
export enum TcGeuHolidayWorkersOrderBy {
  AlternateDayAsc = 'ALTERNATE_DAY_ASC',
  AlternateDayDesc = 'ALTERNATE_DAY_DESC',
  LieuTimeAsc = 'LIEU_TIME_ASC',
  LieuTimeDesc = 'LIEU_TIME_DESC',
  Natural = 'NATURAL',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** Methods to use when ordering `TcGeuHoliday`. */
export enum TcGeuHolidaysOrderBy {
  Natural = 'NATURAL'
}

/** 14.6a (except area 20) / EC60 - Employees who work through lunch are paid TH for that period */
export type TcGeuMissedMeal = {
  __typename?: 'TcGeuMissedMeal';
  id?: Maybe<Scalars['Int']>;
  missingMeal?: Maybe<Interval>;
};

/** Methods to use when ordering `TcGeuMissedMeal`. */
export enum TcGeuMissedMealsOrderBy {
  Natural = 'NATURAL'
}

/**
 * If "without shortfall" clause 16.9c3 results in a scheduled shift < 2 hours, user can skip the entire shift and still be paid.
 * Other shortfall checks look to add hours to a timecard, but here no timecard will be present.
 */
export type TcGeuOmitShift = {
  __typename?: 'TcGeuOmitShift';
  id?: Maybe<Scalars['Int']>;
  shift?: Maybe<DatetimeRange>;
  shortfall?: Maybe<Interval>;
};

export type TcGeuOmitShiftEc = {
  __typename?: 'TcGeuOmitShiftEc';
  classCode?: Maybe<Scalars['String']>;
  earningCode?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  jobCode?: Maybe<Scalars['String']>;
  phaseCode?: Maybe<Scalars['String']>;
};

/** Methods to use when ordering `TcGeuOmitShiftEc`. */
export enum TcGeuOmitShiftEcsOrderBy {
  Natural = 'NATURAL'
}

/** Methods to use when ordering `TcGeuOmitShift`. */
export enum TcGeuOmitShiftsOrderBy {
  Natural = 'NATURAL'
}

/**
 * 16.2 - Overtime is rounded differently in each service area.
 * Believe that other earning codes attach to this extended shift too?
 * Overtime shall be compensated in <ot_increment>;
 * however, employees shall not be entitled to any compensation for periods of overtime of less than <ot_ment_min> per day.
 *
 * 16.2 prescribes set increments for overtime
 * 16.4a3 indicates OT for all hours worked on a day of rest up to and including the normal workday length and double-time thereafter
 * what if the normal workday does not exactly fit the prescribed OT increments?
 */
export type TcGeuOt = {
  __typename?: 'TcGeuOt';
  id?: Maybe<Scalars['Int']>;
  ot?: Maybe<Interval>;
  otDiff?: Maybe<Interval>;
  otIncremented?: Maybe<Interval>;
};

/** 16.5 overtime meal breaks (EC48) of meal_duration minutes given after first ot_meal_1 overtime, then the next ot_meal_2, then every ot_meal_n thereafter */
export type TcGeuOtMeal = {
  __typename?: 'TcGeuOtMeal';
  id?: Maybe<Scalars['Int']>;
  otMealEntitlement?: Maybe<Interval>;
  otMeals?: Maybe<Scalars['Float']>;
};

/** Methods to use when ordering `TcGeuOtMeal`. */
export enum TcGeuOtMealsOrderBy {
  Natural = 'NATURAL'
}

/** Methods to use when ordering `TcGeuOt`. */
export enum TcGeuOtsOrderBy {
  Natural = 'NATURAL'
}

/**
 * Overtime breakdown for shifts
 * - shifts have a scheduled/start-end attached, meaning they do not include standalone callouts, holidays or lieu days
 * - shifts may include Christmas/New Years which are paid DT regardless (this is not entirely clear in the collective agreements)
 * Article 16.9 Callout Provisions:
 * - "without shortfall" clause may result in extra ST credits before or after shift
 * - unscheduled hours are OT, as are hours worked in the callout clear period before a shift, or hours at the end of a shift that could have stopped early to credit for excessive early callouts
 * Note: Olga indicates that periods of rest for as and when auxilaries in area 26 are governed by the Employment Standards Act, not Article 16.9
 */
export type TcGeuShift = {
  __typename?: 'TcGeuShift';
  base?: Maybe<Interval>;
  dtHoliday?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  otEnd?: Maybe<Interval>;
  otStart?: Maybe<Interval>;
  shortfall?: Maybe<Interval>;
};

export type TcGeuShiftBreakdown = {
  __typename?: 'TcGeuShiftBreakdown';
  actualEnd?: Maybe<Scalars['Datetime']>;
  actualStart?: Maybe<Scalars['Datetime']>;
  approvedBy?: Maybe<Scalars['Int']>;
  baseEc?: Maybe<Scalars['Int']>;
  baseEnd?: Maybe<Scalars['Datetime']>;
  baseStart?: Maybe<Scalars['Datetime']>;
  companyNo?: Maybe<Scalars['Int']>;
  explanation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  shortfall?: Maybe<Interval>;
  workerId?: Maybe<Scalars['Int']>;
};

/** Methods to use when ordering `TcGeuShiftBreakdown`. */
export enum TcGeuShiftBreakdownsOrderBy {
  Natural = 'NATURAL'
}

export type TcGeuShiftCalc = {
  __typename?: 'TcGeuShiftCalc';
  calloutCreditWorked?: Maybe<Interval>;
  clearStart?: Maybe<Scalars['Datetime']>;
  clearWorkedStart?: Maybe<Interval>;
  earlyDeparture?: Maybe<Interval>;
  id?: Maybe<Scalars['Int']>;
  shortfallEnd?: Maybe<Interval>;
  shortfallEndCredit?: Maybe<Interval>;
  shortfallStart?: Maybe<Interval>;
};

/** Methods to use when ordering `TcGeuShiftCalc`. */
export enum TcGeuShiftCalcsOrderBy {
  Natural = 'NATURAL'
}

export type TcGeuShiftEc = {
  __typename?: 'TcGeuShiftEc';
  earningCode?: Maybe<Scalars['String']>;
  explanation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  period?: Maybe<DatetimeRange>;
};

/** Methods to use when ordering `TcGeuShiftEc`. */
export enum TcGeuShiftEcsOrderBy {
  Natural = 'NATURAL'
}

export type TcGeuShiftOt = {
  __typename?: 'TcGeuShiftOt';
  baseEc?: Maybe<Scalars['Int']>;
  dt?: Maybe<Interval>;
  duration?: Maybe<Interval>;
  endTime?: Maybe<Scalars['Datetime']>;
  explanation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  ot?: Maybe<Scalars['Boolean']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  st?: Maybe<Interval>;
  startTime?: Maybe<Scalars['Datetime']>;
  th?: Maybe<Interval>;
  totalOt?: Maybe<Interval>;
};

/** Methods to use when ordering `TcGeuShiftOt`. */
export enum TcGeuShiftOtsOrderBy {
  Natural = 'NATURAL'
}

export type TcGeuShiftWorkEc = {
  __typename?: 'TcGeuShiftWorkEc';
  earningCode?: Maybe<Scalars['String']>;
  explanation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  period?: Maybe<DatetimeRange>;
  proportion?: Maybe<Scalars['Float']>;
  workId?: Maybe<Scalars['Int']>;
  workPeriod?: Maybe<DatetimeRange>;
  workRange?: Maybe<DatetimeRange>;
};

/** Methods to use when ordering `TcGeuShiftWorkEc`. */
export enum TcGeuShiftWorkEcsOrderBy {
  Natural = 'NATURAL'
}

/** Methods to use when ordering `TcGeuShift`. */
export enum TcGeuShiftsOrderBy {
  Natural = 'NATURAL'
}

/**
 * Shortfall is credited to the closest phase associated with the shortfall
 * - for shortfall at the end of a shift, the first phase of the day
 * - for shortfall at the start of a shift, the last phase of the previous timecard
 */
export type TcGeuShortfall = {
  __typename?: 'TcGeuShortfall';
  classCode?: Maybe<Scalars['String']>;
  earningCode?: Maybe<Scalars['String']>;
  explanation?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  jobCode?: Maybe<Scalars['String']>;
  phaseCode?: Maybe<Scalars['String']>;
};

/** Methods to use when ordering `TcGeuShortfall`. */
export enum TcGeuShortfallsOrderBy {
  Natural = 'NATURAL'
}

/** Need to identify consecutive sick days for GEU employees who will receive 75% sick pay from their employer on days after day 1 */
export type TcGeuSickConsecutive = {
  __typename?: 'TcGeuSickConsecutive';
  leave?: Maybe<DatetimeRange>;
  tcLeaveId?: Maybe<Scalars['Int']>;
  tcWorkLeaveId?: Maybe<Scalars['Int']>;
};

/** Methods to use when ordering `TcGeuSickConsecutive`. */
export enum TcGeuSickConsecutivesOrderBy {
  Natural = 'NATURAL'
}

/** 16.9c2 - if X hours are worked on a callout in the Y hours before the shift, require clearance til starting shift, without shortfall */
export type TcGeuUnclearCallout = {
  __typename?: 'TcGeuUnclearCallout';
  calloutClearStart?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  previousEnd?: Maybe<Scalars['Datetime']>;
  previousInClear?: Maybe<Interval>;
};

/** Methods to use when ordering `TcGeuUnclearCallout`. */
export enum TcGeuUnclearCalloutsOrderBy {
  Natural = 'NATURAL'
}

export type TcGeuVp = {
  __typename?: 'TcGeuVp';
  amount?: Maybe<Scalars['BigFloat']>;
  classCode?: Maybe<Scalars['String']>;
  earningCode?: Maybe<Scalars['String']>;
  explanation?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  jobCode?: Maybe<Scalars['String']>;
  phaseCode?: Maybe<Scalars['String']>;
};

/** Methods to use when ordering `TcGeuVp`. */
export enum TcGeuVpsOrderBy {
  Natural = 'NATURAL'
}

/** Methods to use when ordering `TcGeu`. */
export enum TcGeusOrderBy {
  Natural = 'NATURAL'
}

export type TcHighlight = {
  __typename?: 'TcHighlight';
  dt?: Maybe<Interval>;
  dtHoliday?: Maybe<Scalars['Boolean']>;
  holiday?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lieuId?: Maybe<Scalars['Int']>;
  missingMeal?: Maybe<Interval>;
  otMealEntitlement?: Maybe<Interval>;
  previousEnd?: Maybe<Scalars['Datetime']>;
  s7Unsociable?: Maybe<Scalars['Boolean']>;
  s7Weekend?: Maybe<Scalars['Boolean']>;
  s20ShiftType?: Maybe<Scalars['String']>;
  s26ShiftType?: Maybe<Scalars['String']>;
  shortfall?: Maybe<Interval>;
  st?: Maybe<Interval>;
  th?: Maybe<Interval>;
};

/** Methods to use when ordering `TcHighlight`. */
export enum TcHighlightsOrderBy {
  Natural = 'NATURAL'
}

/**
 * Rate classes appropriate for various forms of earnings, based on past hours worked, days worked etc.
 * 17.5 Paid the best rate used for the majority of the preceding 60 workdays or 450 hours
 * 18.4 Paid the best rate they met or exceeded for the regularly scheduled hours for the last 60 workdays
 */
export type TcHistoricalClass = {
  __typename?: 'TcHistoricalClass';
  classBest?: Maybe<Scalars['String']>;
  classByHours?: Maybe<Scalars['String']>;
  classByWorkdays?: Maybe<Scalars['String']>;
  classDefault?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

export type TcHistoricalClassMaterialized = {
  __typename?: 'TcHistoricalClassMaterialized';
  classBest?: Maybe<Scalars['String']>;
  classByHours?: Maybe<Scalars['String']>;
  classByWorkdays?: Maybe<Scalars['String']>;
  classDefault?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

/** Methods to use when ordering `TcHistoricalClassMaterialized`. */
export enum TcHistoricalClassMaterializedsOrderBy {
  Natural = 'NATURAL'
}

/** Methods to use when ordering `TcHistoricalClass`. */
export enum TcHistoricalClassesOrderBy {
  Natural = 'NATURAL'
}

export type TcHoliday = Node & {
  __typename?: 'TcHoliday';
  collectiveAgreement: Scalars['String'];
  description: Scalars['String'];
  holiday: Scalars['Date'];
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `TcLeave`. */
  tcLeavesByHolidayId: Array<TcLeave>;
};


export type TcHolidayTcLeavesByHolidayIdArgs = {
  condition?: InputMaybe<TcLeaveCondition>;
  filter?: InputMaybe<TcLeaveFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcLeavesOrderBy>>;
};

export type TcHolidayAlternate = {
  __typename?: 'TcHolidayAlternate';
  alternateHoliday?: Maybe<Scalars['Datetime']>;
  collectiveAgreement?: Maybe<Scalars['String']>;
  holiday?: Maybe<Scalars['Date']>;
};

/** An input for mutations affecting `TcHolidayAlternate` */
export type TcHolidayAlternateInput = {
  alternateHoliday?: InputMaybe<Scalars['Datetime']>;
  collectiveAgreement?: InputMaybe<Scalars['String']>;
  holiday?: InputMaybe<Scalars['Date']>;
};

/** Methods to use when ordering `TcHolidayAlternate`. */
export enum TcHolidayAlternatesOrderBy {
  Natural = 'NATURAL'
}

/**
 * A condition to be used against `TcHoliday` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TcHolidayCondition = {
  /** Checks for equality with the object’s `collectiveAgreement` field. */
  collectiveAgreement?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `holiday` field. */
  holiday?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `TcHoliday` object types. All fields are combined with a logical ‘and.’ */
export type TcHolidayFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TcHolidayFilter>>;
  /** Filter by the object’s `collectiveAgreement` field. */
  collectiveAgreement?: InputMaybe<StringFilter>;
  /** Filter by the object’s `holiday` field. */
  holiday?: InputMaybe<DateFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TcHolidayFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TcHolidayFilter>>;
};

/** An input for mutations affecting `TcHoliday` */
export type TcHolidayInput = {
  collectiveAgreement: Scalars['String'];
  description: Scalars['String'];
  holiday: Scalars['Date'];
};

/** Represents an update to a `TcHoliday`. Fields that are set will be updated. */
export type TcHolidayPatch = {
  collectiveAgreement?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  holiday?: InputMaybe<Scalars['Date']>;
};

/** Every holiday matched with every user with an active shift pattern when it occurs, noting auto-bumps in schedule and approved lieu days */
export type TcHolidayUser = {
  __typename?: 'TcHolidayUser';
  alternateDay?: Maybe<Scalars['Date']>;
  collectiveAgreement?: Maybe<Scalars['String']>;
  holiday?: Maybe<Scalars['Date']>;
  holidayEnd?: Maybe<Scalars['Datetime']>;
  holidayId?: Maybe<Scalars['Int']>;
  holidayStart?: Maybe<Scalars['Datetime']>;
  lieuDate?: Maybe<Scalars['Datetime']>;
  lieuLimit?: Maybe<Scalars['Datetime']>;
  userId?: Maybe<Scalars['Int']>;
};

/** Methods to use when ordering `TcHolidayUser`. */
export enum TcHolidayUsersOrderBy {
  Natural = 'NATURAL'
}

/** Methods to use when ordering `TcHoliday`. */
export enum TcHolidaysOrderBy {
  CollectiveAgreementAsc = 'COLLECTIVE_AGREEMENT_ASC',
  CollectiveAgreementDesc = 'COLLECTIVE_AGREEMENT_DESC',
  HolidayAsc = 'HOLIDAY_ASC',
  HolidayDesc = 'HOLIDAY_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type TcLeave = Node & {
  __typename?: 'TcLeave';
  approved?: Maybe<Scalars['Boolean']>;
  /** Reads a single `AuthUser` that is related to this `TcLeave`. */
  authUserByReviewedBy?: Maybe<AuthUser>;
  earningCode?: Maybe<Scalars['String']>;
  endTime: Scalars['Datetime'];
  /** Reads a single `TcHoliday` that is related to this `TcLeave`. */
  holiday?: Maybe<TcHoliday>;
  holidayId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  leave: DatetimeRange;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  reviewTime?: Maybe<Scalars['Datetime']>;
  reviewedBy?: Maybe<Scalars['Int']>;
  sick: Scalars['Boolean'];
  startTime: Scalars['Datetime'];
  /** Reads a single `AuthUser` that is related to this `TcLeave`. */
  user?: Maybe<AuthUser>;
  userId: Scalars['Int'];
};

/** A condition to be used against `TcLeave` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type TcLeaveCondition = {
  /** Checks for equality with the object’s `approved` field. */
  approved?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `endTime` field. */
  endTime?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `holidayId` field. */
  holidayId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `leave` field. */
  leave?: InputMaybe<DatetimeRangeInput>;
  /** Checks for equality with the object’s `reviewedBy` field. */
  reviewedBy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `sick` field. */
  sick?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `startTime` field. */
  startTime?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** Methods to use when ordering `TcLeaveDaily`. */
export enum TcLeaveDailiesOrderBy {
  Natural = 'NATURAL'
}

/** Approved vacation or CTO leave (not lieu days) broken down daily for proper credit distribution and analysis */
export type TcLeaveDaily = {
  __typename?: 'TcLeaveDaily';
  classCode?: Maybe<Scalars['String']>;
  credit?: Maybe<Interval>;
  earningCode?: Maybe<Scalars['String']>;
  leave?: Maybe<DatetimeRange>;
  leaveId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** Only credit leave on unworked, scheduled days */
export type TcLeaveDailyScheduled = {
  __typename?: 'TcLeaveDailyScheduled';
  classCode?: Maybe<Scalars['String']>;
  credit?: Maybe<Interval>;
  earningCode?: Maybe<Scalars['String']>;
  leave?: Maybe<DatetimeRange>;
  leaveId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** Methods to use when ordering `TcLeaveDailyScheduled`. */
export enum TcLeaveDailyScheduledsOrderBy {
  Natural = 'NATURAL'
}

/** A filter to be used against `TcLeave` object types. All fields are combined with a logical ‘and.’ */
export type TcLeaveFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TcLeaveFilter>>;
  /** Filter by the object’s `approved` field. */
  approved?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `endTime` field. */
  endTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `holidayId` field. */
  holidayId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `leave` field. */
  leave?: InputMaybe<DatetimeRangeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TcLeaveFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TcLeaveFilter>>;
  /** Filter by the object’s `reviewedBy` field. */
  reviewedBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `sick` field. */
  sick?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `startTime` field. */
  startTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

export type TcLeaveGeuSickCompensation = {
  __typename?: 'TcLeaveGeuSickCompensation';
  classCode?: Maybe<Scalars['String']>;
  credit?: Maybe<Interval>;
  earningCode?: Maybe<Scalars['String']>;
  explanation?: Maybe<Scalars['String']>;
  leave?: Maybe<DatetimeRange>;
  leaveId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** Methods to use when ordering `TcLeaveGeuSickCompensation`. */
export enum TcLeaveGeuSickCompensationsOrderBy {
  Natural = 'NATURAL'
}

/** An input for mutations affecting `TcLeave` */
export type TcLeaveInput = {
  approved?: InputMaybe<Scalars['Boolean']>;
  earningCode?: InputMaybe<Scalars['String']>;
  endTime: Scalars['Datetime'];
  holidayId?: InputMaybe<Scalars['Int']>;
  leave?: InputMaybe<DatetimeRangeInput>;
  notes?: InputMaybe<Scalars['String']>;
  reviewTime?: InputMaybe<Scalars['Datetime']>;
  reviewedBy?: InputMaybe<Scalars['Int']>;
  sick?: InputMaybe<Scalars['Boolean']>;
  startTime: Scalars['Datetime'];
  userId: Scalars['Int'];
};

/** Represents an update to a `TcLeave`. Fields that are set will be updated. */
export type TcLeavePatch = {
  approved?: InputMaybe<Scalars['Boolean']>;
  earningCode?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['Datetime']>;
  holidayId?: InputMaybe<Scalars['Int']>;
  leave?: InputMaybe<DatetimeRangeInput>;
  notes?: InputMaybe<Scalars['String']>;
  reviewTime?: InputMaybe<Scalars['Datetime']>;
  reviewedBy?: InputMaybe<Scalars['Int']>;
  sick?: InputMaybe<Scalars['Boolean']>;
  startTime?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** Methods to use when ordering `TcLeave`. */
export enum TcLeavesOrderBy {
  ApprovedAsc = 'APPROVED_ASC',
  ApprovedDesc = 'APPROVED_DESC',
  EndTimeAsc = 'END_TIME_ASC',
  EndTimeDesc = 'END_TIME_DESC',
  HolidayIdAsc = 'HOLIDAY_ID_ASC',
  HolidayIdDesc = 'HOLIDAY_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LeaveAsc = 'LEAVE_ASC',
  LeaveDesc = 'LEAVE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReviewedByAsc = 'REVIEWED_BY_ASC',
  ReviewedByDesc = 'REVIEWED_BY_DESC',
  SickAsc = 'SICK_ASC',
  SickDesc = 'SICK_DESC',
  StartTimeAsc = 'START_TIME_ASC',
  StartTimeDesc = 'START_TIME_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type TcMaterial = Node & {
  __typename?: 'TcMaterial';
  id: Scalars['Int'];
  materialCode: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `OpsMaterial` that is related to this `TcMaterial`. */
  opsMaterialByMaterialCode?: Maybe<OpsMaterial>;
  qty: Scalars['BigFloat'];
  sourceId: Scalars['Int'];
  /** Reads a single `TcWork` that is related to this `TcMaterial`. */
  tcWork?: Maybe<TcWork>;
  tcWorkId: Scalars['Int'];
};

/**
 * A condition to be used against `TcMaterial` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TcMaterialCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `materialCode` field. */
  materialCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `sourceId` field. */
  sourceId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `tcWorkId` field. */
  tcWorkId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `TcMaterial` object types. All fields are combined with a logical ‘and.’ */
export type TcMaterialFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TcMaterialFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `materialCode` field. */
  materialCode?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TcMaterialFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TcMaterialFilter>>;
  /** Filter by the object’s `sourceId` field. */
  sourceId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `tcWorkId` field. */
  tcWorkId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `TcMaterial` */
export type TcMaterialInput = {
  materialCode: Scalars['String'];
  qty: Scalars['BigFloat'];
  sourceId: Scalars['Int'];
  tcWorkId: Scalars['Int'];
};

/** Represents an update to a `TcMaterial`. Fields that are set will be updated. */
export type TcMaterialPatch = {
  materialCode?: InputMaybe<Scalars['String']>;
  qty?: InputMaybe<Scalars['BigFloat']>;
  sourceId?: InputMaybe<Scalars['Int']>;
  tcWorkId?: InputMaybe<Scalars['Int']>;
};

/** Methods to use when ordering `TcMaterial`. */
export enum TcMaterialsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MaterialCodeAsc = 'MATERIAL_CODE_ASC',
  MaterialCodeDesc = 'MATERIAL_CODE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SourceIdAsc = 'SOURCE_ID_ASC',
  SourceIdDesc = 'SOURCE_ID_DESC',
  TcWorkIdAsc = 'TC_WORK_ID_ASC',
  TcWorkIdDesc = 'TC_WORK_ID_DESC'
}

export type TcMeal = {
  __typename?: 'TcMeal';
  id?: Maybe<Scalars['Int']>;
  mealBreak?: Maybe<DatetimeRange>;
};

/** Methods to use when ordering `TcMeal`. */
export enum TcMealsOrderBy {
  Natural = 'NATURAL'
}

/** Methods to use when ordering `TcOtSummary`. */
export enum TcOtSummariesOrderBy {
  Natural = 'NATURAL'
}

export type TcOtSummary = {
  __typename?: 'TcOtSummary';
  dt?: Maybe<Interval>;
  id?: Maybe<Scalars['Int']>;
  shortfall?: Maybe<Interval>;
  st?: Maybe<Interval>;
  th?: Maybe<Interval>;
};

/** Methods to use when ordering `TcPayrollPrimary`. */
export enum TcPayrollPrimariesOrderBy {
  Natural = 'NATURAL'
}

export type TcPayrollPrimary = {
  __typename?: 'TcPayrollPrimary';
  amount?: Maybe<Scalars['BigFloat']>;
  assetCode?: Maybe<Scalars['String']>;
  classCode?: Maybe<Scalars['String']>;
  companyNo?: Maybe<Scalars['Int']>;
  craftCode?: Maybe<Scalars['String']>;
  earningCode?: Maybe<Scalars['String']>;
  employeeNo?: Maybe<Scalars['Int']>;
  entryDate?: Maybe<Scalars['Date']>;
  equipmentCo?: Maybe<Scalars['Int']>;
  equipmentUsage?: Maybe<Scalars['Float']>;
  explanation?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Float']>;
  jobCode?: Maybe<Scalars['String']>;
  phaseCode?: Maybe<Scalars['String']>;
  timecardId?: Maybe<Scalars['Int']>;
};

export type TcRejection = Node & {
  __typename?: 'TcRejection';
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  reason: Scalars['String'];
  rejectionTime: Scalars['Datetime'];
  /** Reads a single `AuthUser` that is related to this `TcRejection`. */
  reviewer?: Maybe<AuthUser>;
  reviewerId: Scalars['Int'];
  /** Reads a single `TcTimecard` that is related to this `TcRejection`. */
  timecard?: Maybe<TcTimecard>;
  timecardId: Scalars['Int'];
};

/**
 * A condition to be used against `TcRejection` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TcRejectionCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `reviewerId` field. */
  reviewerId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `timecardId` field. */
  timecardId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `TcRejection` object types. All fields are combined with a logical ‘and.’ */
export type TcRejectionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TcRejectionFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TcRejectionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TcRejectionFilter>>;
  /** Filter by the object’s `reviewerId` field. */
  reviewerId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `timecardId` field. */
  timecardId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `TcRejection` */
export type TcRejectionInput = {
  reason: Scalars['String'];
  rejectionTime?: InputMaybe<Scalars['Datetime']>;
  reviewerId: Scalars['Int'];
  timecardId: Scalars['Int'];
};

/** Represents an update to a `TcRejection`. Fields that are set will be updated. */
export type TcRejectionPatch = {
  reason?: InputMaybe<Scalars['String']>;
  rejectionTime?: InputMaybe<Scalars['Datetime']>;
  reviewerId?: InputMaybe<Scalars['Int']>;
  timecardId?: InputMaybe<Scalars['Int']>;
};

/** Methods to use when ordering `TcRejection`. */
export enum TcRejectionsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReviewerIdAsc = 'REVIEWER_ID_ASC',
  ReviewerIdDesc = 'REVIEWER_ID_DESC',
  TimecardIdAsc = 'TIMECARD_ID_ASC',
  TimecardIdDesc = 'TIMECARD_ID_DESC'
}

export type TcSickApproved = {
  __typename?: 'TcSickApproved';
  leave?: Maybe<DatetimeRange>;
  leaveId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** Methods to use when ordering `TcSickApproved`. */
export enum TcSickApprovedsOrderBy {
  Natural = 'NATURAL'
}

export type TcTimecard = Node & {
  __typename?: 'TcTimecard';
  actualDate: Scalars['Date'];
  actualEnd: Scalars['Datetime'];
  actualLocalStart?: Maybe<Scalars['Datetime']>;
  actualLocalTime: DatetimeRange;
  actualStart: Scalars['Datetime'];
  actualTime: DatetimeRange;
  actualWeek: Scalars['Date'];
  approved: Scalars['Boolean'];
  approvedBy?: Maybe<Scalars['Int']>;
  approvedTime?: Maybe<Scalars['Datetime']>;
  asWhen: Scalars['Boolean'];
  /** Reads a single `AuthUser` that is related to this `TcTimecard`. */
  authUserByApprovedBy?: Maybe<AuthUser>;
  /** Reads a single `AuthUser` that is related to this `TcTimecard`. */
  authUserByReviewedBy?: Maybe<AuthUser>;
  /** Reads a single `AuthUser` that is related to this `TcTimecard`. */
  authUserBySubmissionBy?: Maybe<AuthUser>;
  auxiliary: Scalars['Boolean'];
  collectiveAgreement: Scalars['String'];
  companyNo: Scalars['Int'];
  craftCode: Scalars['String'];
  cwu: Scalars['Boolean'];
  id: Scalars['Int'];
  injured: Scalars['Boolean'];
  mealCount: Scalars['Int'];
  mealDuration?: Maybe<Interval>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  otCompensationMethod: CompensationMethod;
  reviewTime?: Maybe<Scalars['Datetime']>;
  reviewedBy?: Maybe<Scalars['Int']>;
  scheduleComment?: Maybe<Scalars['String']>;
  scheduledEnd?: Maybe<Scalars['Datetime']>;
  scheduledStart?: Maybe<Scalars['Datetime']>;
  scheduledTime?: Maybe<DatetimeRange>;
  shiftTime?: Maybe<DatetimeRange>;
  submissionBy: Scalars['Int'];
  submissionTime: Scalars['Datetime'];
  /** Reads and enables pagination through a set of `TcRejection`. */
  tcRejectionsByTimecardId: Array<TcRejection>;
  /** Reads and enables pagination through a set of `TcWorkLeave`. */
  tcWorkLeavesByTimecardId: Array<TcWorkLeave>;
  /** Reads and enables pagination through a set of `TcWorkTime`. */
  tcWorkTimesByTimecardId: Array<TcWorkTime>;
  /** Reads and enables pagination through a set of `TcWork`. */
  tcWorksByTimecardId: Array<TcWork>;
  timezone: Scalars['String'];
  workDuration?: Maybe<Interval>;
  /** Reads a single `AuthUser` that is related to this `TcTimecard`. */
  worker?: Maybe<AuthUser>;
  workerId: Scalars['Int'];
  /** Reads a single `OpsYard` that is related to this `TcTimecard`. */
  worksite?: Maybe<OpsYard>;
  worksiteId: Scalars['Int'];
};


export type TcTimecardTcRejectionsByTimecardIdArgs = {
  condition?: InputMaybe<TcRejectionCondition>;
  filter?: InputMaybe<TcRejectionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcRejectionsOrderBy>>;
};


export type TcTimecardTcWorkLeavesByTimecardIdArgs = {
  condition?: InputMaybe<TcWorkLeaveCondition>;
  filter?: InputMaybe<TcWorkLeaveFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcWorkLeavesOrderBy>>;
};


export type TcTimecardTcWorkTimesByTimecardIdArgs = {
  condition?: InputMaybe<TcWorkTimeCondition>;
  filter?: InputMaybe<TcWorkTimeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcWorkTimesOrderBy>>;
};


export type TcTimecardTcWorksByTimecardIdArgs = {
  condition?: InputMaybe<TcWorkCondition>;
  filter?: InputMaybe<TcWorkFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcWorksOrderBy>>;
};

/**
 * A condition to be used against `TcTimecard` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TcTimecardCondition = {
  /** Checks for equality with the object’s `actualDate` field. */
  actualDate?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `actualEnd` field. */
  actualEnd?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `actualLocalStart` field. */
  actualLocalStart?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `actualLocalTime` field. */
  actualLocalTime?: InputMaybe<DatetimeRangeInput>;
  /** Checks for equality with the object’s `actualStart` field. */
  actualStart?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `actualTime` field. */
  actualTime?: InputMaybe<DatetimeRangeInput>;
  /** Checks for equality with the object’s `actualWeek` field. */
  actualWeek?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `approved` field. */
  approved?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `approvedBy` field. */
  approvedBy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `asWhen` field. */
  asWhen?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `auxiliary` field. */
  auxiliary?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `collectiveAgreement` field. */
  collectiveAgreement?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `companyNo` field. */
  companyNo?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `craftCode` field. */
  craftCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `cwu` field. */
  cwu?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `injured` field. */
  injured?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `reviewedBy` field. */
  reviewedBy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `scheduledTime` field. */
  scheduledTime?: InputMaybe<DatetimeRangeInput>;
  /** Checks for equality with the object’s `shiftTime` field. */
  shiftTime?: InputMaybe<DatetimeRangeInput>;
  /** Checks for equality with the object’s `submissionBy` field. */
  submissionBy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `workerId` field. */
  workerId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `worksiteId` field. */
  worksiteId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `TcTimecard` object types. All fields are combined with a logical ‘and.’ */
export type TcTimecardFilter = {
  /** Filter by the object’s `actualDate` field. */
  actualDate?: InputMaybe<DateFilter>;
  /** Filter by the object’s `actualEnd` field. */
  actualEnd?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `actualLocalStart` field. */
  actualLocalStart?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `actualLocalTime` field. */
  actualLocalTime?: InputMaybe<DatetimeRangeFilter>;
  /** Filter by the object’s `actualStart` field. */
  actualStart?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `actualTime` field. */
  actualTime?: InputMaybe<DatetimeRangeFilter>;
  /** Filter by the object’s `actualWeek` field. */
  actualWeek?: InputMaybe<DateFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TcTimecardFilter>>;
  /** Filter by the object’s `approved` field. */
  approved?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `approvedBy` field. */
  approvedBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `asWhen` field. */
  asWhen?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `auxiliary` field. */
  auxiliary?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `collectiveAgreement` field. */
  collectiveAgreement?: InputMaybe<StringFilter>;
  /** Filter by the object’s `companyNo` field. */
  companyNo?: InputMaybe<IntFilter>;
  /** Filter by the object’s `craftCode` field. */
  craftCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cwu` field. */
  cwu?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `injured` field. */
  injured?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TcTimecardFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TcTimecardFilter>>;
  /** Filter by the object’s `reviewedBy` field. */
  reviewedBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `scheduledTime` field. */
  scheduledTime?: InputMaybe<DatetimeRangeFilter>;
  /** Filter by the object’s `shiftTime` field. */
  shiftTime?: InputMaybe<DatetimeRangeFilter>;
  /** Filter by the object’s `submissionBy` field. */
  submissionBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `workerId` field. */
  workerId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `worksiteId` field. */
  worksiteId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `TcTimecard` */
export type TcTimecardInput = {
  actualDate: Scalars['Date'];
  actualEnd: Scalars['Datetime'];
  actualLocalStart?: InputMaybe<Scalars['Datetime']>;
  actualLocalTime?: InputMaybe<DatetimeRangeInput>;
  actualStart: Scalars['Datetime'];
  actualTime?: InputMaybe<DatetimeRangeInput>;
  actualWeek: Scalars['Date'];
  approved?: InputMaybe<Scalars['Boolean']>;
  approvedBy?: InputMaybe<Scalars['Int']>;
  approvedTime?: InputMaybe<Scalars['Datetime']>;
  asWhen?: InputMaybe<Scalars['Boolean']>;
  auxiliary?: InputMaybe<Scalars['Boolean']>;
  collectiveAgreement: Scalars['String'];
  companyNo: Scalars['Int'];
  craftCode: Scalars['String'];
  cwu?: InputMaybe<Scalars['Boolean']>;
  injured: Scalars['Boolean'];
  mealCount: Scalars['Int'];
  mealDuration?: InputMaybe<IntervalInput>;
  notes?: InputMaybe<Scalars['String']>;
  otCompensationMethod?: InputMaybe<CompensationMethod>;
  reviewTime?: InputMaybe<Scalars['Datetime']>;
  reviewedBy?: InputMaybe<Scalars['Int']>;
  scheduleComment?: InputMaybe<Scalars['String']>;
  scheduledEnd?: InputMaybe<Scalars['Datetime']>;
  scheduledStart?: InputMaybe<Scalars['Datetime']>;
  scheduledTime?: InputMaybe<DatetimeRangeInput>;
  shiftTime?: InputMaybe<DatetimeRangeInput>;
  submissionBy: Scalars['Int'];
  submissionTime: Scalars['Datetime'];
  timezone?: InputMaybe<Scalars['String']>;
  workDuration?: InputMaybe<IntervalInput>;
  workerId: Scalars['Int'];
  worksiteId: Scalars['Int'];
};

/** Represents an update to a `TcTimecard`. Fields that are set will be updated. */
export type TcTimecardPatch = {
  actualDate?: InputMaybe<Scalars['Date']>;
  actualEnd?: InputMaybe<Scalars['Datetime']>;
  actualLocalStart?: InputMaybe<Scalars['Datetime']>;
  actualLocalTime?: InputMaybe<DatetimeRangeInput>;
  actualStart?: InputMaybe<Scalars['Datetime']>;
  actualTime?: InputMaybe<DatetimeRangeInput>;
  actualWeek?: InputMaybe<Scalars['Date']>;
  approved?: InputMaybe<Scalars['Boolean']>;
  approvedBy?: InputMaybe<Scalars['Int']>;
  approvedTime?: InputMaybe<Scalars['Datetime']>;
  asWhen?: InputMaybe<Scalars['Boolean']>;
  auxiliary?: InputMaybe<Scalars['Boolean']>;
  collectiveAgreement?: InputMaybe<Scalars['String']>;
  companyNo?: InputMaybe<Scalars['Int']>;
  craftCode?: InputMaybe<Scalars['String']>;
  cwu?: InputMaybe<Scalars['Boolean']>;
  injured?: InputMaybe<Scalars['Boolean']>;
  mealCount?: InputMaybe<Scalars['Int']>;
  mealDuration?: InputMaybe<IntervalInput>;
  notes?: InputMaybe<Scalars['String']>;
  otCompensationMethod?: InputMaybe<CompensationMethod>;
  reviewTime?: InputMaybe<Scalars['Datetime']>;
  reviewedBy?: InputMaybe<Scalars['Int']>;
  scheduleComment?: InputMaybe<Scalars['String']>;
  scheduledEnd?: InputMaybe<Scalars['Datetime']>;
  scheduledStart?: InputMaybe<Scalars['Datetime']>;
  scheduledTime?: InputMaybe<DatetimeRangeInput>;
  shiftTime?: InputMaybe<DatetimeRangeInput>;
  submissionBy?: InputMaybe<Scalars['Int']>;
  submissionTime?: InputMaybe<Scalars['Datetime']>;
  timezone?: InputMaybe<Scalars['String']>;
  workDuration?: InputMaybe<IntervalInput>;
  workerId?: InputMaybe<Scalars['Int']>;
  worksiteId?: InputMaybe<Scalars['Int']>;
};

/** Methods to use when ordering `TcTimecard`. */
export enum TcTimecardsOrderBy {
  ActualDateAsc = 'ACTUAL_DATE_ASC',
  ActualDateDesc = 'ACTUAL_DATE_DESC',
  ActualEndAsc = 'ACTUAL_END_ASC',
  ActualEndDesc = 'ACTUAL_END_DESC',
  ActualLocalStartAsc = 'ACTUAL_LOCAL_START_ASC',
  ActualLocalStartDesc = 'ACTUAL_LOCAL_START_DESC',
  ActualLocalTimeAsc = 'ACTUAL_LOCAL_TIME_ASC',
  ActualLocalTimeDesc = 'ACTUAL_LOCAL_TIME_DESC',
  ActualStartAsc = 'ACTUAL_START_ASC',
  ActualStartDesc = 'ACTUAL_START_DESC',
  ActualTimeAsc = 'ACTUAL_TIME_ASC',
  ActualTimeDesc = 'ACTUAL_TIME_DESC',
  ActualWeekAsc = 'ACTUAL_WEEK_ASC',
  ActualWeekDesc = 'ACTUAL_WEEK_DESC',
  ApprovedAsc = 'APPROVED_ASC',
  ApprovedByAsc = 'APPROVED_BY_ASC',
  ApprovedByDesc = 'APPROVED_BY_DESC',
  ApprovedDesc = 'APPROVED_DESC',
  AsWhenAsc = 'AS_WHEN_ASC',
  AsWhenDesc = 'AS_WHEN_DESC',
  AuxiliaryAsc = 'AUXILIARY_ASC',
  AuxiliaryDesc = 'AUXILIARY_DESC',
  CollectiveAgreementAsc = 'COLLECTIVE_AGREEMENT_ASC',
  CollectiveAgreementDesc = 'COLLECTIVE_AGREEMENT_DESC',
  CompanyNoAsc = 'COMPANY_NO_ASC',
  CompanyNoDesc = 'COMPANY_NO_DESC',
  CraftCodeAsc = 'CRAFT_CODE_ASC',
  CraftCodeDesc = 'CRAFT_CODE_DESC',
  CwuAsc = 'CWU_ASC',
  CwuDesc = 'CWU_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InjuredAsc = 'INJURED_ASC',
  InjuredDesc = 'INJURED_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReviewedByAsc = 'REVIEWED_BY_ASC',
  ReviewedByDesc = 'REVIEWED_BY_DESC',
  ScheduledTimeAsc = 'SCHEDULED_TIME_ASC',
  ScheduledTimeDesc = 'SCHEDULED_TIME_DESC',
  ShiftTimeAsc = 'SHIFT_TIME_ASC',
  ShiftTimeDesc = 'SHIFT_TIME_DESC',
  SubmissionByAsc = 'SUBMISSION_BY_ASC',
  SubmissionByDesc = 'SUBMISSION_BY_DESC',
  WorkerIdAsc = 'WORKER_ID_ASC',
  WorkerIdDesc = 'WORKER_ID_DESC',
  WorksiteIdAsc = 'WORKSITE_ID_ASC',
  WorksiteIdDesc = 'WORKSITE_ID_DESC'
}

export type TcUserAgreement = {
  __typename?: 'TcUserAgreement';
  agreement?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
};

/** Methods to use when ordering `TcUserAgreement`. */
export enum TcUserAgreementsOrderBy {
  Natural = 'NATURAL'
}

export type TcVacationAllotment = Node & {
  __typename?: 'TcVacationAllotment';
  collectiveAgreementCode: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  workDuration: Interval;
  workdays: Scalars['Int'];
};

/**
 * A condition to be used against `TcVacationAllotment` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type TcVacationAllotmentCondition = {
  /** Checks for equality with the object’s `collectiveAgreementCode` field. */
  collectiveAgreementCode?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `TcVacationAllotment` object types. All fields are combined with a logical ‘and.’ */
export type TcVacationAllotmentFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TcVacationAllotmentFilter>>;
  /** Filter by the object’s `collectiveAgreementCode` field. */
  collectiveAgreementCode?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TcVacationAllotmentFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TcVacationAllotmentFilter>>;
};

/** An input for mutations affecting `TcVacationAllotment` */
export type TcVacationAllotmentInput = {
  collectiveAgreementCode: Scalars['String'];
  workDuration: IntervalInput;
  workdays: Scalars['Int'];
};

/** Represents an update to a `TcVacationAllotment`. Fields that are set will be updated. */
export type TcVacationAllotmentPatch = {
  collectiveAgreementCode?: InputMaybe<Scalars['String']>;
  workDuration?: InputMaybe<IntervalInput>;
  workdays?: InputMaybe<Scalars['Int']>;
};

/** Methods to use when ordering `TcVacationAllotment`. */
export enum TcVacationAllotmentsOrderBy {
  CollectiveAgreementCodeAsc = 'COLLECTIVE_AGREEMENT_CODE_ASC',
  CollectiveAgreementCodeDesc = 'COLLECTIVE_AGREEMENT_CODE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type TcVpUnion = {
  __typename?: 'TcVpUnion';
  actualDate?: Maybe<Scalars['Date']>;
  amount?: Maybe<Scalars['BigFloat']>;
  assetCode?: Maybe<Scalars['String']>;
  classCode?: Maybe<Scalars['String']>;
  companyNo?: Maybe<Scalars['Int']>;
  craftCode?: Maybe<Scalars['String']>;
  earningCode?: Maybe<Scalars['String']>;
  employeeNo?: Maybe<Scalars['Int']>;
  equipmentUsage?: Maybe<Scalars['Float']>;
  explanation?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  jobCode?: Maybe<Scalars['String']>;
  phaseCode?: Maybe<Scalars['String']>;
};

/** Methods to use when ordering `TcVpUnion`. */
export enum TcVpUnionsOrderBy {
  Natural = 'NATURAL'
}

export type TcWork = Node & {
  __typename?: 'TcWork';
  /** Earning Code 13 - Abnormal working conditions (hazard pay applicable to some jobs typically performed by bridge crews) */
  abnormal: Scalars['Boolean'];
  accomplishmentQty?: Maybe<Scalars['BigFloat']>;
  /** Reads a single `AuthUser` that is related to this `TcWork`. */
  accomplishmentUser?: Maybe<AuthUser>;
  accomplishmentUserId?: Maybe<Scalars['Int']>;
  /** Reads a single `OpsBlock` that is related to this `TcWork`. */
  block?: Maybe<OpsBlock>;
  blockId?: Maybe<Scalars['Int']>;
  classCode: Scalars['String'];
  duration: Scalars['Int'];
  endGeog?: Maybe<GeographyPoint>;
  endGeom?: Maybe<GeometryPoint>;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  phaseId: Scalars['Int'];
  /** Reads a single `ImapRoadFeature` that is related to this `TcWork`. */
  road?: Maybe<ImapRoadFeature>;
  roadId?: Maybe<Scalars['Int']>;
  sequenceNo: Scalars['Int'];
  /** Reads a single `ImapRestarea` that is related to this `TcWork`. */
  site?: Maybe<ImapRestarea>;
  siteId?: Maybe<Scalars['Int']>;
  startGeog?: Maybe<GeographyPoint>;
  startGeom?: Maybe<GeometryPoint>;
  /** Reads a single `ImapRoadStructure` that is related to this `TcWork`. */
  structure?: Maybe<ImapRoadStructure>;
  structureId?: Maybe<Scalars['Int']>;
  taskNo?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `TcEquipment`. */
  tcEquipments: Array<TcEquipment>;
  /** Reads and enables pagination through a set of `TcMaterial`. */
  tcMaterials: Array<TcMaterial>;
  /** Reads a single `TcTimecard` that is related to this `TcWork`. */
  timecard?: Maybe<TcTimecard>;
  timecardId: Scalars['Int'];
};


export type TcWorkTcEquipmentsArgs = {
  condition?: InputMaybe<TcEquipmentCondition>;
  filter?: InputMaybe<TcEquipmentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcEquipmentOrderBy>>;
};


export type TcWorkTcMaterialsArgs = {
  condition?: InputMaybe<TcMaterialCondition>;
  filter?: InputMaybe<TcMaterialFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TcMaterialsOrderBy>>;
};

/** A condition to be used against `TcWork` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type TcWorkCondition = {
  /** Checks for equality with the object’s `abnormal` field. */
  abnormal?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `accomplishmentUserId` field. */
  accomplishmentUserId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `blockId` field. */
  blockId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `phaseId` field. */
  phaseId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `roadId` field. */
  roadId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `siteId` field. */
  siteId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `structureId` field. */
  structureId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `timecardId` field. */
  timecardId?: InputMaybe<Scalars['Int']>;
};

export type TcWorkExtended = {
  __typename?: 'TcWorkExtended';
  accomplishmentQty?: Maybe<Scalars['BigFloat']>;
  activityCode?: Maybe<Scalars['String']>;
  actualDate?: Maybe<Scalars['Date']>;
  bmisStructureName?: Maybe<Scalars['String']>;
  bmisStructureNumber?: Maybe<Scalars['String']>;
  centerPoint?: Maybe<GeometryInterface>;
  endGeog?: Maybe<GeographyPoint>;
  estValue?: Maybe<Scalars['BigFloat']>;
  id?: Maybe<Scalars['Int']>;
  linearPath?: Maybe<GeometryInterface>;
  notes?: Maybe<Scalars['String']>;
  rfiHighwayDescription?: Maybe<Scalars['String']>;
  rfiHighwayName?: Maybe<Scalars['String']>;
  roadPath?: Maybe<GeometryInterface>;
  startGeog?: Maybe<GeographyPoint>;
};

/** Methods to use when ordering `TcWorkExtended`. */
export enum TcWorkExtendedsOrderBy {
  Natural = 'NATURAL'
}

/** A filter to be used against `TcWork` object types. All fields are combined with a logical ‘and.’ */
export type TcWorkFilter = {
  /** Filter by the object’s `abnormal` field. */
  abnormal?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `accomplishmentUserId` field. */
  accomplishmentUserId?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TcWorkFilter>>;
  /** Filter by the object’s `blockId` field. */
  blockId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TcWorkFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TcWorkFilter>>;
  /** Filter by the object’s `phaseId` field. */
  phaseId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `roadId` field. */
  roadId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `siteId` field. */
  siteId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `structureId` field. */
  structureId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `timecardId` field. */
  timecardId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `TcWork` */
export type TcWorkInput = {
  /** Earning Code 13 - Abnormal working conditions (hazard pay applicable to some jobs typically performed by bridge crews) */
  abnormal?: InputMaybe<Scalars['Boolean']>;
  accomplishmentQty?: InputMaybe<Scalars['BigFloat']>;
  accomplishmentUserId?: InputMaybe<Scalars['Int']>;
  blockId?: InputMaybe<Scalars['Int']>;
  classCode: Scalars['String'];
  duration: Scalars['Int'];
  endGeog?: InputMaybe<Scalars['GeoJSON']>;
  endGeom?: InputMaybe<Scalars['GeoJSON']>;
  notes?: InputMaybe<Scalars['String']>;
  phaseId: Scalars['Int'];
  roadId?: InputMaybe<Scalars['Int']>;
  sequenceNo: Scalars['Int'];
  siteId?: InputMaybe<Scalars['Int']>;
  startGeog?: InputMaybe<Scalars['GeoJSON']>;
  startGeom?: InputMaybe<Scalars['GeoJSON']>;
  structureId?: InputMaybe<Scalars['Int']>;
  taskNo?: InputMaybe<Scalars['String']>;
  timecardId: Scalars['Int'];
};

export type TcWorkLeave = Node & {
  __typename?: 'TcWorkLeave';
  duration: Scalars['Int'];
  earningCode?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  sequenceNo: Scalars['Int'];
  sick: Scalars['Boolean'];
  /** Reads a single `TcTimecard` that is related to this `TcWorkLeave`. */
  timecard?: Maybe<TcTimecard>;
  timecardId: Scalars['Int'];
};

/**
 * A condition to be used against `TcWorkLeave` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TcWorkLeaveCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `timecardId` field. */
  timecardId?: InputMaybe<Scalars['Int']>;
};

export type TcWorkLeaveEc = {
  __typename?: 'TcWorkLeaveEc';
  classCode?: Maybe<Scalars['String']>;
  companyNo?: Maybe<Scalars['Int']>;
  craftCode?: Maybe<Scalars['String']>;
  earningCode?: Maybe<Scalars['String']>;
  employeeNo?: Maybe<Scalars['Int']>;
  entryDate?: Maybe<Scalars['Date']>;
  explanation?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['BigFloat']>;
  jobCode?: Maybe<Scalars['String']>;
  phaseCode?: Maybe<Scalars['String']>;
  timecardId?: Maybe<Scalars['Int']>;
};

export type TcWorkLeaveEcMaterialized = {
  __typename?: 'TcWorkLeaveEcMaterialized';
  classCode?: Maybe<Scalars['String']>;
  companyNo?: Maybe<Scalars['Int']>;
  craftCode?: Maybe<Scalars['String']>;
  earningCode?: Maybe<Scalars['String']>;
  employeeNo?: Maybe<Scalars['Int']>;
  entryDate?: Maybe<Scalars['Date']>;
  explanation?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['BigFloat']>;
  jobCode?: Maybe<Scalars['String']>;
  phaseCode?: Maybe<Scalars['String']>;
  timecardId?: Maybe<Scalars['Int']>;
};

/** Methods to use when ordering `TcWorkLeaveEcMaterialized`. */
export enum TcWorkLeaveEcMaterializedsOrderBy {
  Natural = 'NATURAL'
}

/** Methods to use when ordering `TcWorkLeaveEc`. */
export enum TcWorkLeaveEcsOrderBy {
  Natural = 'NATURAL'
}

/** A filter to be used against `TcWorkLeave` object types. All fields are combined with a logical ‘and.’ */
export type TcWorkLeaveFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TcWorkLeaveFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TcWorkLeaveFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TcWorkLeaveFilter>>;
  /** Filter by the object’s `timecardId` field. */
  timecardId?: InputMaybe<IntFilter>;
};

export type TcWorkLeaveGeuSickCompensation = {
  __typename?: 'TcWorkLeaveGeuSickCompensation';
  duration?: Maybe<Scalars['Int']>;
  earningCode?: Maybe<Scalars['String']>;
  explanation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  timecardId?: Maybe<Scalars['Int']>;
};

/** Methods to use when ordering `TcWorkLeaveGeuSickCompensation`. */
export enum TcWorkLeaveGeuSickCompensationsOrderBy {
  Natural = 'NATURAL'
}

/** An input for mutations affecting `TcWorkLeave` */
export type TcWorkLeaveInput = {
  duration: Scalars['Int'];
  earningCode?: InputMaybe<Scalars['String']>;
  sequenceNo: Scalars['Int'];
  sick?: InputMaybe<Scalars['Boolean']>;
  timecardId: Scalars['Int'];
};

/** Represents an update to a `TcWorkLeave`. Fields that are set will be updated. */
export type TcWorkLeavePatch = {
  duration?: InputMaybe<Scalars['Int']>;
  earningCode?: InputMaybe<Scalars['String']>;
  sequenceNo?: InputMaybe<Scalars['Int']>;
  sick?: InputMaybe<Scalars['Boolean']>;
  timecardId?: InputMaybe<Scalars['Int']>;
};

/** Methods to use when ordering `TcWorkLeave`. */
export enum TcWorkLeavesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TimecardIdAsc = 'TIMECARD_ID_ASC',
  TimecardIdDesc = 'TIMECARD_ID_DESC'
}

/** Represents an update to a `TcWork`. Fields that are set will be updated. */
export type TcWorkPatch = {
  /** Earning Code 13 - Abnormal working conditions (hazard pay applicable to some jobs typically performed by bridge crews) */
  abnormal?: InputMaybe<Scalars['Boolean']>;
  accomplishmentQty?: InputMaybe<Scalars['BigFloat']>;
  accomplishmentUserId?: InputMaybe<Scalars['Int']>;
  blockId?: InputMaybe<Scalars['Int']>;
  classCode?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  endGeog?: InputMaybe<Scalars['GeoJSON']>;
  endGeom?: InputMaybe<Scalars['GeoJSON']>;
  notes?: InputMaybe<Scalars['String']>;
  phaseId?: InputMaybe<Scalars['Int']>;
  roadId?: InputMaybe<Scalars['Int']>;
  sequenceNo?: InputMaybe<Scalars['Int']>;
  siteId?: InputMaybe<Scalars['Int']>;
  startGeog?: InputMaybe<Scalars['GeoJSON']>;
  startGeom?: InputMaybe<Scalars['GeoJSON']>;
  structureId?: InputMaybe<Scalars['Int']>;
  taskNo?: InputMaybe<Scalars['String']>;
  timecardId?: InputMaybe<Scalars['Int']>;
};

/** Methods to use when ordering `TcWorkSummary`. */
export enum TcWorkSummariesOrderBy {
  Natural = 'NATURAL'
}

export type TcWorkSummary = {
  __typename?: 'TcWorkSummary';
  accomplishmentQty?: Maybe<Scalars['BigFloat']>;
  duration?: Maybe<Scalars['BigInt']>;
  jobCode?: Maybe<Scalars['String']>;
  phaseCode?: Maybe<Scalars['String']>;
  timecardId?: Maybe<Scalars['Int']>;
};

export type TcWorkSummaryText = {
  __typename?: 'TcWorkSummaryText';
  phases?: Maybe<Scalars['String']>;
  timecardId?: Maybe<Scalars['Int']>;
};

/** Methods to use when ordering `TcWorkSummaryText`. */
export enum TcWorkSummaryTextsOrderBy {
  Natural = 'NATURAL'
}

export type TcWorkTest = Node & {
  __typename?: 'TcWorkTest';
  description: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  timecardId: Scalars['Int'];
};

/**
 * A condition to be used against `TcWorkTest` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TcWorkTestCondition = {
  /** Checks for equality with the object’s `timecardId` field. */
  timecardId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `TcWorkTest` object types. All fields are combined with a logical ‘and.’ */
export type TcWorkTestFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TcWorkTestFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<TcWorkTestFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TcWorkTestFilter>>;
  /** Filter by the object’s `timecardId` field. */
  timecardId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `TcWorkTest` */
export type TcWorkTestInput = {
  description: Scalars['String'];
  timecardId: Scalars['Int'];
};

/** Represents an update to a `TcWorkTest`. Fields that are set will be updated. */
export type TcWorkTestPatch = {
  description?: InputMaybe<Scalars['String']>;
  timecardId?: InputMaybe<Scalars['Int']>;
};

/** Methods to use when ordering `TcWorkTest`. */
export enum TcWorkTestsOrderBy {
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TimecardIdAsc = 'TIMECARD_ID_ASC',
  TimecardIdDesc = 'TIMECARD_ID_DESC'
}

/**
 * Bump work after lunch break.
 * Since lunch is only an unworked break during a scheduled shift, assume it is in the middle of the scheduled portion and bump everything else accordingly.
 * This will ensure that OT and shift premiums are attributed to the correct activities worked in those times.
 */
export type TcWorkTime = Node & {
  __typename?: 'TcWorkTime';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  proportion?: Maybe<Scalars['Float']>;
  sequenceNo: Scalars['Int'];
  /** Reads a single `TcTimecard` that is related to this `TcWorkTime`. */
  timecard?: Maybe<TcTimecard>;
  timecardId: Scalars['Int'];
  workRange: DatetimeRange;
};

/** All input for the `tcWorkTimeCalc` mutation. */
export type TcWorkTimeCalcInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  targetTimecardIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

/** The output of our `tcWorkTimeCalc` mutation. */
export type TcWorkTimeCalcPayload = {
  __typename?: 'TcWorkTimeCalcPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  results?: Maybe<Array<Maybe<TcWorkTimeCalcRecord>>>;
};

/** The return type of our `tcWorkTimeCalc` mutation. */
export type TcWorkTimeCalcRecord = {
  __typename?: 'TcWorkTimeCalcRecord';
  proportion?: Maybe<Scalars['Float']>;
  sequenceNo?: Maybe<Scalars['Int']>;
  timecardId?: Maybe<Scalars['Int']>;
  workRange?: Maybe<DatetimeRange>;
};

/**
 * A condition to be used against `TcWorkTime` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TcWorkTimeCondition = {
  /** Checks for equality with the object’s `timecardId` field. */
  timecardId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `workRange` field. */
  workRange?: InputMaybe<DatetimeRangeInput>;
};

/** A filter to be used against `TcWorkTime` object types. All fields are combined with a logical ‘and.’ */
export type TcWorkTimeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TcWorkTimeFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<TcWorkTimeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TcWorkTimeFilter>>;
  /** Filter by the object’s `timecardId` field. */
  timecardId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `workRange` field. */
  workRange?: InputMaybe<DatetimeRangeFilter>;
};

/** An input for mutations affecting `TcWorkTime` */
export type TcWorkTimeInput = {
  proportion?: InputMaybe<Scalars['Float']>;
  sequenceNo: Scalars['Int'];
  timecardId: Scalars['Int'];
  workRange: DatetimeRangeInput;
};

/** Represents an update to a `TcWorkTime`. Fields that are set will be updated. */
export type TcWorkTimePatch = {
  proportion?: InputMaybe<Scalars['Float']>;
  sequenceNo?: InputMaybe<Scalars['Int']>;
  timecardId?: InputMaybe<Scalars['Int']>;
  workRange?: InputMaybe<DatetimeRangeInput>;
};

/** All input for the `tcWorkTimeRefresh` mutation. */
export type TcWorkTimeRefreshInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  targetTimecardIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

/** The output of our `tcWorkTimeRefresh` mutation. */
export type TcWorkTimeRefreshPayload = {
  __typename?: 'TcWorkTimeRefreshPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** Methods to use when ordering `TcWorkTime`. */
export enum TcWorkTimesOrderBy {
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TimecardIdAsc = 'TIMECARD_ID_ASC',
  TimecardIdDesc = 'TIMECARD_ID_DESC',
  WorkRangeAsc = 'WORK_RANGE_ASC',
  WorkRangeDesc = 'WORK_RANGE_DESC'
}

export type TcWorkVp = {
  __typename?: 'TcWorkVp';
  assetCode?: Maybe<Scalars['String']>;
  classCode?: Maybe<Scalars['String']>;
  earningCode?: Maybe<Scalars['String']>;
  equipmentUsage?: Maybe<Scalars['Float']>;
  explanation?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  jobCode?: Maybe<Scalars['String']>;
  phaseCode?: Maybe<Scalars['String']>;
};

/** Methods to use when ordering `TcWorkVp`. */
export enum TcWorkVpsOrderBy {
  Natural = 'NATURAL'
}

/** Methods to use when ordering `TcWork`. */
export enum TcWorksOrderBy {
  AbnormalAsc = 'ABNORMAL_ASC',
  AbnormalDesc = 'ABNORMAL_DESC',
  AccomplishmentUserIdAsc = 'ACCOMPLISHMENT_USER_ID_ASC',
  AccomplishmentUserIdDesc = 'ACCOMPLISHMENT_USER_ID_DESC',
  BlockIdAsc = 'BLOCK_ID_ASC',
  BlockIdDesc = 'BLOCK_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PhaseIdAsc = 'PHASE_ID_ASC',
  PhaseIdDesc = 'PHASE_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RoadIdAsc = 'ROAD_ID_ASC',
  RoadIdDesc = 'ROAD_ID_DESC',
  SiteIdAsc = 'SITE_ID_ASC',
  SiteIdDesc = 'SITE_ID_DESC',
  StructureIdAsc = 'STRUCTURE_ID_ASC',
  StructureIdDesc = 'STRUCTURE_ID_DESC',
  TimecardIdAsc = 'TIMECARD_ID_ASC',
  TimecardIdDesc = 'TIMECARD_ID_DESC'
}

export type TemperatureForecast = {
  __typename?: 'TemperatureForecast';
  apex: Apex;
  value: Scalars['Float'];
};

export enum Tendency {
  Falling = 'FALLING',
  Rising = 'RISING',
  Steady = 'STEADY'
}

export type TimeSpace = {
  gpsTolerance?: InputMaybe<Scalars['Int']>;
  interval?: InputMaybe<Scalars['String']>;
  position: InputPoint;
  roadClassId?: InputMaybe<Scalars['ID']>;
};

export type TimecardContentInput = {
  leave?: InputMaybe<TimecardLeaveInput>;
  work?: InputMaybe<TimecardWorkInput>;
};

export type TimecardEquipment = {
  asset: Scalars['ID'];
  duration: Scalars['Float'];
};

export type TimecardHighlights = {
  __typename?: 'TimecardHighlights';
  dt?: Maybe<Scalars['Float']>;
  dtHoliday?: Maybe<Scalars['Boolean']>;
  holiday?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lieuDay?: Maybe<Scalars['Boolean']>;
  missingMeal?: Maybe<Scalars['Int']>;
  otMealEntitlement?: Maybe<Scalars['Int']>;
  premiums: Array<Premium>;
  shortfall?: Maybe<Scalars['Float']>;
  st?: Maybe<Scalars['Float']>;
  th?: Maybe<Scalars['Float']>;
};

export type TimecardInput = {
  actualEnd: Scalars['DateTime'];
  actualStart: Scalars['DateTime'];
  injured: Scalars['Boolean'];
  mealCount: Scalars['Int'];
  notes?: InputMaybe<Scalars['String']>;
  otCompensationMethod: CompensationMethod;
  scheduledEnd?: InputMaybe<Scalars['DateTime']>;
  scheduledStart?: InputMaybe<Scalars['DateTime']>;
  worker: Scalars['ID'];
  works?: InputMaybe<Array<TimecardContentInput>>;
  worksite: Scalars['ID'];
};

export type TimecardLeaveInput = {
  duration: Scalars['Float'];
  earningCode?: InputMaybe<Scalars['String']>;
  sick?: InputMaybe<Scalars['Boolean']>;
};

export type TimecardLocation = {
  block?: InputMaybe<Scalars['ID']>;
  restArea?: InputMaybe<Scalars['ID']>;
  road?: InputMaybe<Scalars['ID']>;
  startPosition?: InputMaybe<Array<Scalars['Float']>>;
  structure?: InputMaybe<Scalars['ID']>;
};

export type TimecardMaterial = {
  material: Scalars['ID'];
  qty: Scalars['Float'];
  source: Scalars['ID'];
};

export type TimecardWorkInput = {
  abnormal: Scalars['Boolean'];
  accomplishmentQty?: InputMaybe<Scalars['Float']>;
  accomplishmentWorker?: InputMaybe<Scalars['ID']>;
  classCode: Scalars['String'];
  duration: Scalars['Float'];
  endPosition?: InputMaybe<Array<Scalars['Float']>>;
  equipment?: InputMaybe<Array<TimecardEquipment>>;
  location?: InputMaybe<TimecardLocation>;
  materials?: InputMaybe<Array<TimecardMaterial>>;
  notes?: InputMaybe<Scalars['String']>;
  phase: Scalars['ID'];
  taskNo?: InputMaybe<Scalars['String']>;
};

export enum Trinary {
  All = 'ALL',
  None = 'NONE',
  Some = 'SOME'
}

export type TrinaryInput = {
  key: Scalars['Int'];
  state: Trinary;
};

/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['UUID']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['UUID']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['UUID']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['UUID']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['UUID']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['UUID']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['UUID']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['UUID']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['UUID']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['UUID']>>;
};

export type Uom = Node & {
  __typename?: 'Uom';
  description: Scalars['String'];
  id: Scalars['Int'];
  motiCode: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `OpsActivity`. */
  opsActivities: Array<OpsActivity>;
  /** Reads and enables pagination through a set of `OpsMaterial`. */
  opsMaterials: Array<OpsMaterial>;
  uomCode: Scalars['String'];
  viewpointCode: Scalars['String'];
};


export type UomOpsActivitiesArgs = {
  condition?: InputMaybe<OpsActivityCondition>;
  filter?: InputMaybe<OpsActivityFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsActivitiesOrderBy>>;
};


export type UomOpsMaterialsArgs = {
  condition?: InputMaybe<OpsMaterialCondition>;
  filter?: InputMaybe<OpsMaterialFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OpsMaterialsOrderBy>>;
};

/** A condition to be used against `Uom` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type UomCondition = {
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `motiCode` field. */
  motiCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `uomCode` field. */
  uomCode?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `Uom` object types. All fields are combined with a logical ‘and.’ */
export type UomFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UomFilter>>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `motiCode` field. */
  motiCode?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UomFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UomFilter>>;
  /** Filter by the object’s `uomCode` field. */
  uomCode?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `Uom` */
export type UomInput = {
  description: Scalars['String'];
  motiCode: Scalars['String'];
  uomCode: Scalars['String'];
  viewpointCode: Scalars['String'];
};

/** Represents an update to a `Uom`. Fields that are set will be updated. */
export type UomPatch = {
  description?: InputMaybe<Scalars['String']>;
  motiCode?: InputMaybe<Scalars['String']>;
  uomCode?: InputMaybe<Scalars['String']>;
  viewpointCode?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `Uom`. */
export enum UomsOrderBy {
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MotiCodeAsc = 'MOTI_CODE_ASC',
  MotiCodeDesc = 'MOTI_CODE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UomCodeAsc = 'UOM_CODE_ASC',
  UomCodeDesc = 'UOM_CODE_DESC'
}

/** All input for the `updateAssetBreadcrumbByAssetIdAndGeogAndDatetime` mutation. */
export type UpdateAssetBreadcrumbByAssetIdAndGeogAndDatetimeInput = {
  assetId: Scalars['Int'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  datetime: Scalars['Datetime'];
  geog: Scalars['GeoJSON'];
  /** An object where the defined keys will be set on the `AssetBreadcrumb` being updated. */
  patch: AssetBreadcrumbPatch;
};

/** All input for the `updateAssetBreadcrumbByNodeId` mutation. */
export type UpdateAssetBreadcrumbByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AssetBreadcrumb` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `AssetBreadcrumb` being updated. */
  patch: AssetBreadcrumbPatch;
};

/** All input for the `updateAssetBreadcrumb` mutation. */
export type UpdateAssetBreadcrumbInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `AssetBreadcrumb` being updated. */
  patch: AssetBreadcrumbPatch;
};

/** The output of our update `AssetBreadcrumb` mutation. */
export type UpdateAssetBreadcrumbPayload = {
  __typename?: 'UpdateAssetBreadcrumbPayload';
  /** Reads a single `Asset` that is related to this `AssetBreadcrumb`. */
  asset?: Maybe<Asset>;
  /** The `AssetBreadcrumb` that was updated by this mutation. */
  assetBreadcrumb?: Maybe<AssetBreadcrumb>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `ImapRoadClass` that is related to this `AssetBreadcrumb`. */
  roadClass?: Maybe<ImapRoadClass>;
  /** Reads a single `ImapRoadFeature` that is related to this `AssetBreadcrumb`. */
  roadFeature?: Maybe<ImapRoadFeature>;
  /** Reads a single `ImapRoadSurface` that is related to this `AssetBreadcrumb`. */
  roadSurface?: Maybe<ImapRoadSurface>;
  /** Reads a single `OpsYard` that is related to this `AssetBreadcrumb`. */
  yard?: Maybe<OpsYard>;
};

/** All input for the `updateAssetByAssetCode` mutation. */
export type UpdateAssetByAssetCodeInput = {
  assetCode: Scalars['String'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Asset` being updated. */
  patch: AssetPatch;
};

/** All input for the `updateAssetByNodeId` mutation. */
export type UpdateAssetByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Asset` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Asset` being updated. */
  patch: AssetPatch;
};

/** All input for the `updateAssetCategoryByNodeId` mutation. */
export type UpdateAssetCategoryByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AssetCategory` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `AssetCategory` being updated. */
  patch: AssetCategoryPatch;
};

/** All input for the `updateAssetCategory` mutation. */
export type UpdateAssetCategoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `AssetCategory` being updated. */
  patch: AssetCategoryPatch;
  prefix: Scalars['String'];
};

/** The output of our update `AssetCategory` mutation. */
export type UpdateAssetCategoryPayload = {
  __typename?: 'UpdateAssetCategoryPayload';
  /** The `AssetCategory` that was updated by this mutation. */
  assetCategory?: Maybe<AssetCategory>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateAsset` mutation. */
export type UpdateAssetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Asset` being updated. */
  patch: AssetPatch;
};

/** The output of our update `Asset` mutation. */
export type UpdateAssetPayload = {
  __typename?: 'UpdateAssetPayload';
  /** The `Asset` that was updated by this mutation. */
  asset?: Maybe<Asset>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateAssetTypeByDescription` mutation. */
export type UpdateAssetTypeByDescriptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  /** An object where the defined keys will be set on the `AssetType` being updated. */
  patch: AssetTypePatch;
};

/** All input for the `updateAssetTypeByNodeId` mutation. */
export type UpdateAssetTypeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AssetType` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `AssetType` being updated. */
  patch: AssetTypePatch;
};

/** All input for the `updateAssetType` mutation. */
export type UpdateAssetTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `AssetType` being updated. */
  patch: AssetTypePatch;
};

/** The output of our update `AssetType` mutation. */
export type UpdateAssetTypePayload = {
  __typename?: 'UpdateAssetTypePayload';
  /** The `AssetType` that was updated by this mutation. */
  assetType?: Maybe<AssetType>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateAssetWingUnderbodyByNodeId` mutation. */
export type UpdateAssetWingUnderbodyByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AssetWingUnderbody` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `AssetWingUnderbody` being updated. */
  patch: AssetWingUnderbodyPatch;
};

/** All input for the `updateAssetWingUnderbody` mutation. */
export type UpdateAssetWingUnderbodyInput = {
  assetId: Scalars['Int'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  effective: Scalars['Datetime'];
  /** An object where the defined keys will be set on the `AssetWingUnderbody` being updated. */
  patch: AssetWingUnderbodyPatch;
};

/** The output of our update `AssetWingUnderbody` mutation. */
export type UpdateAssetWingUnderbodyPayload = {
  __typename?: 'UpdateAssetWingUnderbodyPayload';
  /** Reads a single `Asset` that is related to this `AssetWingUnderbody`. */
  asset?: Maybe<Asset>;
  /** The `AssetWingUnderbody` that was updated by this mutation. */
  assetWingUnderbody?: Maybe<AssetWingUnderbody>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateAuthSessionTokenByNodeId` mutation. */
export type UpdateAuthSessionTokenByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AuthSessionToken` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `AuthSessionToken` being updated. */
  patch: AuthSessionTokenPatch;
};

/** All input for the `updateAuthSessionToken` mutation. */
export type UpdateAuthSessionTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `AuthSessionToken` being updated. */
  patch: AuthSessionTokenPatch;
  sessionId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** The output of our update `AuthSessionToken` mutation. */
export type UpdateAuthSessionTokenPayload = {
  __typename?: 'UpdateAuthSessionTokenPayload';
  /** The `AuthSessionToken` that was updated by this mutation. */
  authSessionToken?: Maybe<AuthSessionToken>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AuthUser` that is related to this `AuthSessionToken`. */
  user?: Maybe<AuthUser>;
};

/** All input for the `updateAuthUserByEmail` mutation. */
export type UpdateAuthUserByEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  /** An object where the defined keys will be set on the `AuthUser` being updated. */
  patch: AuthUserPatch;
};

/** All input for the `updateAuthUserByEmployeeNo` mutation. */
export type UpdateAuthUserByEmployeeNoInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  employeeNo: Scalars['Int'];
  /** An object where the defined keys will be set on the `AuthUser` being updated. */
  patch: AuthUserPatch;
};

/** All input for the `updateAuthUserByNodeId` mutation. */
export type UpdateAuthUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AuthUser` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `AuthUser` being updated. */
  patch: AuthUserPatch;
};

/** All input for the `updateAuthUser` mutation. */
export type UpdateAuthUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `AuthUser` being updated. */
  patch: AuthUserPatch;
};

/** The output of our update `AuthUser` mutation. */
export type UpdateAuthUserPayload = {
  __typename?: 'UpdateAuthUserPayload';
  /** The `AuthUser` that was updated by this mutation. */
  authUser?: Maybe<AuthUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `OpsYard` that is related to this `AuthUser`. */
  homeYard?: Maybe<OpsYard>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateBecBiogeoclimaticByNodeId` mutation. */
export type UpdateBecBiogeoclimaticByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BecBiogeoclimatic` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `BecBiogeoclimatic` being updated. */
  patch: BecBiogeoclimaticPatch;
};

/** All input for the `updateBecBiogeoclimatic` mutation. */
export type UpdateBecBiogeoclimaticInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `BecBiogeoclimatic` being updated. */
  patch: BecBiogeoclimaticPatch;
};

/** The output of our update `BecBiogeoclimatic` mutation. */
export type UpdateBecBiogeoclimaticPayload = {
  __typename?: 'UpdateBecBiogeoclimaticPayload';
  /** The `BecBiogeoclimatic` that was updated by this mutation. */
  becBiogeoclimatic?: Maybe<BecBiogeoclimatic>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateBiForecastByNodeId` mutation. */
export type UpdateBiForecastByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BiForecast` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `BiForecast` being updated. */
  patch: BiForecastPatch;
};

/** All input for the `updateBiForecast` mutation. */
export type UpdateBiForecastInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `BiForecast` being updated. */
  patch: BiForecastPatch;
};

/** The output of our update `BiForecast` mutation. */
export type UpdateBiForecastPayload = {
  __typename?: 'UpdateBiForecastPayload';
  /** The `BiForecast` that was updated by this mutation. */
  biForecast?: Maybe<BiForecast>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateBiForecastPermissionByNodeId` mutation. */
export type UpdateBiForecastPermissionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BiForecastPermission` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `BiForecastPermission` being updated. */
  patch: BiForecastPermissionPatch;
};

/** All input for the `updateBiForecastPermission` mutation. */
export type UpdateBiForecastPermissionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `BiForecastPermission` being updated. */
  patch: BiForecastPermissionPatch;
};

/** The output of our update `BiForecastPermission` mutation. */
export type UpdateBiForecastPermissionPayload = {
  __typename?: 'UpdateBiForecastPermissionPayload';
  /** The `BiForecastPermission` that was updated by this mutation. */
  biForecastPermission?: Maybe<BiForecastPermission>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `BiForecast` that is related to this `BiForecastPermission`. */
  forecast?: Maybe<BiForecast>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateBiForecastPhaseByNodeId` mutation. */
export type UpdateBiForecastPhaseByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BiForecastPhase` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `BiForecastPhase` being updated. */
  patch: BiForecastPhasePatch;
};

/** All input for the `updateBiForecastPhase` mutation. */
export type UpdateBiForecastPhaseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `BiForecastPhase` being updated. */
  patch: BiForecastPhasePatch;
};

/** The output of our update `BiForecastPhase` mutation. */
export type UpdateBiForecastPhasePayload = {
  __typename?: 'UpdateBiForecastPhasePayload';
  /** The `BiForecastPhase` that was updated by this mutation. */
  biForecastPhase?: Maybe<BiForecastPhase>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `BiForecast` that is related to this `BiForecastPhase`. */
  forecast?: Maybe<BiForecast>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateCollisionByNodeId` mutation. */
export type UpdateCollisionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Collision` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Collision` being updated. */
  patch: CollisionPatch;
};

/** All input for the `updateCollision` mutation. */
export type UpdateCollisionInput = {
  caseNo: Scalars['String'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Collision` being updated. */
  patch: CollisionPatch;
};

/** The output of our update `Collision` mutation. */
export type UpdateCollisionPayload = {
  __typename?: 'UpdateCollisionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Collision` that was updated by this mutation. */
  collision?: Maybe<Collision>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateDtcFmiByDescription` mutation. */
export type UpdateDtcFmiByDescriptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  /** An object where the defined keys will be set on the `DtcFmi` being updated. */
  patch: DtcFmiPatch;
};

/** All input for the `updateDtcFmiByNodeId` mutation. */
export type UpdateDtcFmiByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `DtcFmi` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `DtcFmi` being updated. */
  patch: DtcFmiPatch;
};

/** All input for the `updateDtcFmi` mutation. */
export type UpdateDtcFmiInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  code: Scalars['Int'];
  /** An object where the defined keys will be set on the `DtcFmi` being updated. */
  patch: DtcFmiPatch;
};

/** The output of our update `DtcFmi` mutation. */
export type UpdateDtcFmiPayload = {
  __typename?: 'UpdateDtcFmiPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `DtcFmi` that was updated by this mutation. */
  dtcFmi?: Maybe<DtcFmi>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateDtcObdByNodeId` mutation. */
export type UpdateDtcObdByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `DtcObd` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `DtcObd` being updated. */
  patch: DtcObdPatch;
};

/** All input for the `updateDtcObd` mutation. */
export type UpdateDtcObdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
  /** An object where the defined keys will be set on the `DtcObd` being updated. */
  patch: DtcObdPatch;
};

/** The output of our update `DtcObd` mutation. */
export type UpdateDtcObdPayload = {
  __typename?: 'UpdateDtcObdPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `DtcObd` that was updated by this mutation. */
  dtcObd?: Maybe<DtcObd>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateDtcSpnByNodeId` mutation. */
export type UpdateDtcSpnByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `DtcSpn` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `DtcSpn` being updated. */
  patch: DtcSpnPatch;
};

/** All input for the `updateDtcSpn` mutation. */
export type UpdateDtcSpnInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  code: Scalars['Int'];
  /** An object where the defined keys will be set on the `DtcSpn` being updated. */
  patch: DtcSpnPatch;
};

/** The output of our update `DtcSpn` mutation. */
export type UpdateDtcSpnPayload = {
  __typename?: 'UpdateDtcSpnPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `DtcSpn` that was updated by this mutation. */
  dtcSpn?: Maybe<DtcSpn>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateGisAtlasZoneByNodeId` mutation. */
export type UpdateGisAtlasZoneByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `GisAtlasZone` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `GisAtlasZone` being updated. */
  patch: GisAtlasZonePatch;
};

/** All input for the `updateGisAtlasZone` mutation. */
export type UpdateGisAtlasZoneInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `GisAtlasZone` being updated. */
  patch: GisAtlasZonePatch;
};

/** The output of our update `GisAtlasZone` mutation. */
export type UpdateGisAtlasZonePayload = {
  __typename?: 'UpdateGisAtlasZonePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GisAtlasZone` that was updated by this mutation. */
  gisAtlasZone?: Maybe<GisAtlasZone>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateImapCulvertByNodeId` mutation. */
export type UpdateImapCulvertByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapCulvert` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ImapCulvert` being updated. */
  patch: ImapCulvertPatch;
};

/** All input for the `updateImapCulvert` mutation. */
export type UpdateImapCulvertInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `ImapCulvert` being updated. */
  patch: ImapCulvertPatch;
};

/** The output of our update `ImapCulvert` mutation. */
export type UpdateImapCulvertPayload = {
  __typename?: 'UpdateImapCulvertPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapCulvert` that was updated by this mutation. */
  imapCulvert?: Maybe<ImapCulvert>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateImapDrainageByNodeId` mutation. */
export type UpdateImapDrainageByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapDrainage` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ImapDrainage` being updated. */
  patch: ImapDrainagePatch;
};

/** All input for the `updateImapDrainage` mutation. */
export type UpdateImapDrainageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `ImapDrainage` being updated. */
  patch: ImapDrainagePatch;
};

/** The output of our update `ImapDrainage` mutation. */
export type UpdateImapDrainagePayload = {
  __typename?: 'UpdateImapDrainagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapDrainage` that was updated by this mutation. */
  imapDrainage?: Maybe<ImapDrainage>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateImapGuardrailByNodeId` mutation. */
export type UpdateImapGuardrailByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapGuardrail` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ImapGuardrail` being updated. */
  patch: ImapGuardrailPatch;
};

/** All input for the `updateImapGuardrail` mutation. */
export type UpdateImapGuardrailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `ImapGuardrail` being updated. */
  patch: ImapGuardrailPatch;
};

/** The output of our update `ImapGuardrail` mutation. */
export type UpdateImapGuardrailPayload = {
  __typename?: 'UpdateImapGuardrailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapGuardrail` that was updated by this mutation. */
  imapGuardrail?: Maybe<ImapGuardrail>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateImapHighwayProfileByNodeId` mutation. */
export type UpdateImapHighwayProfileByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapHighwayProfile` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ImapHighwayProfile` being updated. */
  patch: ImapHighwayProfilePatch;
};

/** All input for the `updateImapHighwayProfile` mutation. */
export type UpdateImapHighwayProfileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `ImapHighwayProfile` being updated. */
  patch: ImapHighwayProfilePatch;
};

/** The output of our update `ImapHighwayProfile` mutation. */
export type UpdateImapHighwayProfilePayload = {
  __typename?: 'UpdateImapHighwayProfilePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapHighwayProfile` that was updated by this mutation. */
  imapHighwayProfile?: Maybe<ImapHighwayProfile>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateImapLandmarkByNodeId` mutation. */
export type UpdateImapLandmarkByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapLandmark` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ImapLandmark` being updated. */
  patch: ImapLandmarkPatch;
};

/** All input for the `updateImapLandmark` mutation. */
export type UpdateImapLandmarkInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `ImapLandmark` being updated. */
  patch: ImapLandmarkPatch;
};

/** The output of our update `ImapLandmark` mutation. */
export type UpdateImapLandmarkPayload = {
  __typename?: 'UpdateImapLandmarkPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapLandmark` that was updated by this mutation. */
  imapLandmark?: Maybe<ImapLandmark>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateImapRestareaByNodeId` mutation. */
export type UpdateImapRestareaByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapRestarea` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ImapRestarea` being updated. */
  patch: ImapRestareaPatch;
};

/** All input for the `updateImapRestarea` mutation. */
export type UpdateImapRestareaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `ImapRestarea` being updated. */
  patch: ImapRestareaPatch;
};

/** The output of our update `ImapRestarea` mutation. */
export type UpdateImapRestareaPayload = {
  __typename?: 'UpdateImapRestareaPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapRestarea` that was updated by this mutation. */
  imapRestarea?: Maybe<ImapRestarea>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateImapRetainingWallByNodeId` mutation. */
export type UpdateImapRetainingWallByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapRetainingWall` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ImapRetainingWall` being updated. */
  patch: ImapRetainingWallPatch;
};

/** All input for the `updateImapRetainingWall` mutation. */
export type UpdateImapRetainingWallInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `ImapRetainingWall` being updated. */
  patch: ImapRetainingWallPatch;
};

/** The output of our update `ImapRetainingWall` mutation. */
export type UpdateImapRetainingWallPayload = {
  __typename?: 'UpdateImapRetainingWallPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapRetainingWall` that was updated by this mutation. */
  imapRetainingWall?: Maybe<ImapRetainingWall>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateImapRoadClassByHwyMaintenanceClassId` mutation. */
export type UpdateImapRoadClassByHwyMaintenanceClassIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  hwyMaintenanceClassId: Scalars['Int'];
  /** An object where the defined keys will be set on the `ImapRoadClass` being updated. */
  patch: ImapRoadClassPatch;
};

/** All input for the `updateImapRoadClassByNodeId` mutation. */
export type UpdateImapRoadClassByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapRoadClass` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ImapRoadClass` being updated. */
  patch: ImapRoadClassPatch;
};

/** All input for the `updateImapRoadClassFeatureByNodeId` mutation. */
export type UpdateImapRoadClassFeatureByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapRoadClassFeature` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ImapRoadClassFeature` being updated. */
  patch: ImapRoadClassFeaturePatch;
};

/** All input for the `updateImapRoadClassFeature` mutation. */
export type UpdateImapRoadClassFeatureInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ImapRoadClassFeature` being updated. */
  patch: ImapRoadClassFeaturePatch;
  roadClassId: Scalars['Int'];
};

/** The output of our update `ImapRoadClassFeature` mutation. */
export type UpdateImapRoadClassFeaturePayload = {
  __typename?: 'UpdateImapRoadClassFeaturePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapRoadClassFeature` that was updated by this mutation. */
  imapRoadClassFeature?: Maybe<ImapRoadClassFeature>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `ImapRoadClass` that is related to this `ImapRoadClassFeature`. */
  roadClass?: Maybe<ImapRoadClass>;
  /** Reads a single `ImapRoadFeature` that is related to this `ImapRoadClassFeature`. */
  roadFeature?: Maybe<ImapRoadFeature>;
};

/** All input for the `updateImapRoadClass` mutation. */
export type UpdateImapRoadClassInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `ImapRoadClass` being updated. */
  patch: ImapRoadClassPatch;
};

/** The output of our update `ImapRoadClass` mutation. */
export type UpdateImapRoadClassPayload = {
  __typename?: 'UpdateImapRoadClassPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapRoadClass` that was updated by this mutation. */
  imapRoadClass?: Maybe<ImapRoadClass>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateImapRoadFeatureByNodeId` mutation. */
export type UpdateImapRoadFeatureByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapRoadFeature` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ImapRoadFeature` being updated. */
  patch: ImapRoadFeaturePatch;
};

/** All input for the `updateImapRoadFeatureByRfiHighwayName` mutation. */
export type UpdateImapRoadFeatureByRfiHighwayNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ImapRoadFeature` being updated. */
  patch: ImapRoadFeaturePatch;
  rfiHighwayName: Scalars['String'];
};

/** All input for the `updateImapRoadFeatureByRoadFeatureInvntryId` mutation. */
export type UpdateImapRoadFeatureByRoadFeatureInvntryIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ImapRoadFeature` being updated. */
  patch: ImapRoadFeaturePatch;
  roadFeatureInvntryId: Scalars['Int'];
};

/** All input for the `updateImapRoadFeature` mutation. */
export type UpdateImapRoadFeatureInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `ImapRoadFeature` being updated. */
  patch: ImapRoadFeaturePatch;
};

/** The output of our update `ImapRoadFeature` mutation. */
export type UpdateImapRoadFeaturePayload = {
  __typename?: 'UpdateImapRoadFeaturePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapRoadFeature` that was updated by this mutation. */
  imapRoadFeature?: Maybe<ImapRoadFeature>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateImapRoadStructureByNodeId` mutation. */
export type UpdateImapRoadStructureByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapRoadStructure` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ImapRoadStructure` being updated. */
  patch: ImapRoadStructurePatch;
};

/** All input for the `updateImapRoadStructure` mutation. */
export type UpdateImapRoadStructureInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `ImapRoadStructure` being updated. */
  patch: ImapRoadStructurePatch;
};

/** The output of our update `ImapRoadStructure` mutation. */
export type UpdateImapRoadStructurePayload = {
  __typename?: 'UpdateImapRoadStructurePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapRoadStructure` that was updated by this mutation. */
  imapRoadStructure?: Maybe<ImapRoadStructure>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateImapRoadSurfaceByNodeId` mutation. */
export type UpdateImapRoadSurfaceByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapRoadSurface` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ImapRoadSurface` being updated. */
  patch: ImapRoadSurfacePatch;
};

/** All input for the `updateImapRoadSurfaceByRoadSurfaceTypeId` mutation. */
export type UpdateImapRoadSurfaceByRoadSurfaceTypeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ImapRoadSurface` being updated. */
  patch: ImapRoadSurfacePatch;
  roadSurfaceTypeId: Scalars['Int'];
};

/** All input for the `updateImapRoadSurfaceFeatureByNodeId` mutation. */
export type UpdateImapRoadSurfaceFeatureByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapRoadSurfaceFeature` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ImapRoadSurfaceFeature` being updated. */
  patch: ImapRoadSurfaceFeaturePatch;
};

/** All input for the `updateImapRoadSurfaceFeature` mutation. */
export type UpdateImapRoadSurfaceFeatureInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ImapRoadSurfaceFeature` being updated. */
  patch: ImapRoadSurfaceFeaturePatch;
  roadSurfaceId: Scalars['Int'];
};

/** The output of our update `ImapRoadSurfaceFeature` mutation. */
export type UpdateImapRoadSurfaceFeaturePayload = {
  __typename?: 'UpdateImapRoadSurfaceFeaturePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapRoadSurfaceFeature` that was updated by this mutation. */
  imapRoadSurfaceFeature?: Maybe<ImapRoadSurfaceFeature>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `ImapRoadFeature` that is related to this `ImapRoadSurfaceFeature`. */
  roadFeature?: Maybe<ImapRoadFeature>;
  /** Reads a single `ImapRoadSurface` that is related to this `ImapRoadSurfaceFeature`. */
  roadSurface?: Maybe<ImapRoadSurface>;
};

/** All input for the `updateImapRoadSurface` mutation. */
export type UpdateImapRoadSurfaceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `ImapRoadSurface` being updated. */
  patch: ImapRoadSurfacePatch;
};

/** The output of our update `ImapRoadSurface` mutation. */
export type UpdateImapRoadSurfacePayload = {
  __typename?: 'UpdateImapRoadSurfacePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapRoadSurface` that was updated by this mutation. */
  imapRoadSurface?: Maybe<ImapRoadSurface>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateImapSafetyByNodeId` mutation. */
export type UpdateImapSafetyByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapSafety` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ImapSafety` being updated. */
  patch: ImapSafetyPatch;
};

/** All input for the `updateImapSafety` mutation. */
export type UpdateImapSafetyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `ImapSafety` being updated. */
  patch: ImapSafetyPatch;
};

/** The output of our update `ImapSafety` mutation. */
export type UpdateImapSafetyPayload = {
  __typename?: 'UpdateImapSafetyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapSafety` that was updated by this mutation. */
  imapSafety?: Maybe<ImapSafety>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateImapSignByNodeId` mutation. */
export type UpdateImapSignByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ImapSign` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ImapSign` being updated. */
  patch: ImapSignPatch;
};

/** All input for the `updateImapSign` mutation. */
export type UpdateImapSignInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `ImapSign` being updated. */
  patch: ImapSignPatch;
};

/** The output of our update `ImapSign` mutation. */
export type UpdateImapSignPayload = {
  __typename?: 'UpdateImapSignPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ImapSign` that was updated by this mutation. */
  imapSign?: Maybe<ImapSign>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateOpsActivityByActivityCode` mutation. */
export type UpdateOpsActivityByActivityCodeInput = {
  activityCode: Scalars['String'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `OpsActivity` being updated. */
  patch: OpsActivityPatch;
};

/** All input for the `updateOpsActivityByNodeId` mutation. */
export type UpdateOpsActivityByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OpsActivity` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `OpsActivity` being updated. */
  patch: OpsActivityPatch;
};

/** All input for the `updateOpsActivity` mutation. */
export type UpdateOpsActivityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `OpsActivity` being updated. */
  patch: OpsActivityPatch;
};

/** The output of our update `OpsActivity` mutation. */
export type UpdateOpsActivityPayload = {
  __typename?: 'UpdateOpsActivityPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `OpsActivity` that was updated by this mutation. */
  opsActivity?: Maybe<OpsActivity>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Uom` that is related to this `OpsActivity`. */
  uom?: Maybe<Uom>;
};

/** All input for the `updateOpsActivityRateByActivityIdAndServiceAreaAndStartDate` mutation. */
export type UpdateOpsActivityRateByActivityIdAndServiceAreaAndStartDateInput = {
  activityId: Scalars['Int'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `OpsActivityRate` being updated. */
  patch: OpsActivityRatePatch;
  serviceArea: Scalars['String'];
  startDate: Scalars['Date'];
};

/** All input for the `updateOpsActivityRateByNodeId` mutation. */
export type UpdateOpsActivityRateByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OpsActivityRate` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `OpsActivityRate` being updated. */
  patch: OpsActivityRatePatch;
};

/** All input for the `updateOpsActivityRate` mutation. */
export type UpdateOpsActivityRateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `OpsActivityRate` being updated. */
  patch: OpsActivityRatePatch;
};

/** The output of our update `OpsActivityRate` mutation. */
export type UpdateOpsActivityRatePayload = {
  __typename?: 'UpdateOpsActivityRatePayload';
  /** Reads a single `OpsActivity` that is related to this `OpsActivityRate`. */
  activity?: Maybe<OpsActivity>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `OpsActivityRate` that was updated by this mutation. */
  opsActivityRate?: Maybe<OpsActivityRate>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateOpsBlockByNodeId` mutation. */
export type UpdateOpsBlockByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OpsBlock` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `OpsBlock` being updated. */
  patch: OpsBlockPatch;
};

/** All input for the `updateOpsBlockByServiceAreaAndBlockCode` mutation. */
export type UpdateOpsBlockByServiceAreaAndBlockCodeInput = {
  blockCode: Scalars['String'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `OpsBlock` being updated. */
  patch: OpsBlockPatch;
  serviceArea: Scalars['String'];
};

/** All input for the `updateOpsBlock` mutation. */
export type UpdateOpsBlockInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `OpsBlock` being updated. */
  patch: OpsBlockPatch;
};

/** The output of our update `OpsBlock` mutation. */
export type UpdateOpsBlockPayload = {
  __typename?: 'UpdateOpsBlockPayload';
  /** Reads a single `OpsBlockType` that is related to this `OpsBlock`. */
  blockType?: Maybe<OpsBlockType>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `OpsBlock` that was updated by this mutation. */
  opsBlock?: Maybe<OpsBlock>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateOpsBlockRoadByNodeId` mutation. */
export type UpdateOpsBlockRoadByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OpsBlockRoad` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `OpsBlockRoad` being updated. */
  patch: OpsBlockRoadPatch;
};

/** All input for the `updateOpsBlockRoad` mutation. */
export type UpdateOpsBlockRoadInput = {
  blockId: Scalars['Int'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `OpsBlockRoad` being updated. */
  patch: OpsBlockRoadPatch;
  roadFeatureId: Scalars['Int'];
};

/** The output of our update `OpsBlockRoad` mutation. */
export type UpdateOpsBlockRoadPayload = {
  __typename?: 'UpdateOpsBlockRoadPayload';
  /** Reads a single `OpsBlock` that is related to this `OpsBlockRoad`. */
  block?: Maybe<OpsBlock>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `OpsBlockRoad` that was updated by this mutation. */
  opsBlockRoad?: Maybe<OpsBlockRoad>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `ImapRoadFeature` that is related to this `OpsBlockRoad`. */
  roadFeature?: Maybe<ImapRoadFeature>;
};

/** All input for the `updateOpsBlockTypeByDescription` mutation. */
export type UpdateOpsBlockTypeByDescriptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  /** An object where the defined keys will be set on the `OpsBlockType` being updated. */
  patch: OpsBlockTypePatch;
};

/** All input for the `updateOpsBlockTypeByNodeId` mutation. */
export type UpdateOpsBlockTypeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OpsBlockType` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `OpsBlockType` being updated. */
  patch: OpsBlockTypePatch;
};

/** All input for the `updateOpsBlockType` mutation. */
export type UpdateOpsBlockTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `OpsBlockType` being updated. */
  patch: OpsBlockTypePatch;
};

/** The output of our update `OpsBlockType` mutation. */
export type UpdateOpsBlockTypePayload = {
  __typename?: 'UpdateOpsBlockTypePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `OpsBlockType` that was updated by this mutation. */
  opsBlockType?: Maybe<OpsBlockType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateOpsGroupByDescription` mutation. */
export type UpdateOpsGroupByDescriptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  /** An object where the defined keys will be set on the `OpsGroup` being updated. */
  patch: OpsGroupPatch;
};

/** All input for the `updateOpsGroupByNodeId` mutation. */
export type UpdateOpsGroupByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OpsGroup` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `OpsGroup` being updated. */
  patch: OpsGroupPatch;
};

/** All input for the `updateOpsGroup` mutation. */
export type UpdateOpsGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `OpsGroup` being updated. */
  patch: OpsGroupPatch;
};

/** The output of our update `OpsGroup` mutation. */
export type UpdateOpsGroupPayload = {
  __typename?: 'UpdateOpsGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `OpsGroupType` that is related to this `OpsGroup`. */
  groupType?: Maybe<OpsGroupType>;
  /** The `OpsGroup` that was updated by this mutation. */
  opsGroup?: Maybe<OpsGroup>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateOpsGroupTypeByDescription` mutation. */
export type UpdateOpsGroupTypeByDescriptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  /** An object where the defined keys will be set on the `OpsGroupType` being updated. */
  patch: OpsGroupTypePatch;
};

/** All input for the `updateOpsGroupTypeByNodeId` mutation. */
export type UpdateOpsGroupTypeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OpsGroupType` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `OpsGroupType` being updated. */
  patch: OpsGroupTypePatch;
};

/** All input for the `updateOpsGroupType` mutation. */
export type UpdateOpsGroupTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `OpsGroupType` being updated. */
  patch: OpsGroupTypePatch;
};

/** The output of our update `OpsGroupType` mutation. */
export type UpdateOpsGroupTypePayload = {
  __typename?: 'UpdateOpsGroupTypePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `OpsGroupType` that was updated by this mutation. */
  opsGroupType?: Maybe<OpsGroupType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateOpsMaterialByMaterialCode` mutation. */
export type UpdateOpsMaterialByMaterialCodeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  materialCode: Scalars['String'];
  /** An object where the defined keys will be set on the `OpsMaterial` being updated. */
  patch: OpsMaterialPatch;
};

/** All input for the `updateOpsMaterialByNodeId` mutation. */
export type UpdateOpsMaterialByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OpsMaterial` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `OpsMaterial` being updated. */
  patch: OpsMaterialPatch;
};

/** All input for the `updateOpsMaterial` mutation. */
export type UpdateOpsMaterialInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `OpsMaterial` being updated. */
  patch: OpsMaterialPatch;
};

/** The output of our update `OpsMaterial` mutation. */
export type UpdateOpsMaterialPayload = {
  __typename?: 'UpdateOpsMaterialPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `OpsMaterial` that was updated by this mutation. */
  opsMaterial?: Maybe<OpsMaterial>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Uom` that is related to this `OpsMaterial`. */
  uom?: Maybe<Uom>;
};

/** All input for the `updateOpsMembershipCustomByNodeId` mutation. */
export type UpdateOpsMembershipCustomByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OpsMembershipCustom` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `OpsMembershipCustom` being updated. */
  patch: OpsMembershipCustomPatch;
};

/** All input for the `updateOpsMembershipCustom` mutation. */
export type UpdateOpsMembershipCustomInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  groupId: Scalars['Int'];
  /** An object where the defined keys will be set on the `OpsMembershipCustom` being updated. */
  patch: OpsMembershipCustomPatch;
  userId: Scalars['Int'];
};

/** The output of our update `OpsMembershipCustom` mutation. */
export type UpdateOpsMembershipCustomPayload = {
  __typename?: 'UpdateOpsMembershipCustomPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `OpsGroup` that is related to this `OpsMembershipCustom`. */
  group?: Maybe<OpsGroup>;
  /** The `OpsMembershipCustom` that was updated by this mutation. */
  opsMembershipCustom?: Maybe<OpsMembershipCustom>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AuthUser` that is related to this `OpsMembershipCustom`. */
  user?: Maybe<AuthUser>;
};

/** All input for the `updateOpsScheduleByNodeId` mutation. */
export type UpdateOpsScheduleByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OpsSchedule` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `OpsSchedule` being updated. */
  patch: OpsSchedulePatch;
};

/** All input for the `updateOpsSchedule` mutation. */
export type UpdateOpsScheduleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `OpsSchedule` being updated. */
  patch: OpsSchedulePatch;
};

/** The output of our update `OpsSchedule` mutation. */
export type UpdateOpsSchedulePayload = {
  __typename?: 'UpdateOpsSchedulePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `OpsSchedule` that was updated by this mutation. */
  opsSchedule?: Maybe<OpsSchedule>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `OpsShiftPattern` that is related to this `OpsSchedule`. */
  shiftPattern?: Maybe<OpsShiftPattern>;
  /** Reads a single `AuthUser` that is related to this `OpsSchedule`. */
  user?: Maybe<AuthUser>;
};

/** All input for the `updateOpsShiftPatternByDescription` mutation. */
export type UpdateOpsShiftPatternByDescriptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  /** An object where the defined keys will be set on the `OpsShiftPattern` being updated. */
  patch: OpsShiftPatternPatch;
};

/** All input for the `updateOpsShiftPatternByNodeId` mutation. */
export type UpdateOpsShiftPatternByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OpsShiftPattern` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `OpsShiftPattern` being updated. */
  patch: OpsShiftPatternPatch;
};

/** All input for the `updateOpsShiftPattern` mutation. */
export type UpdateOpsShiftPatternInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `OpsShiftPattern` being updated. */
  patch: OpsShiftPatternPatch;
};

/** The output of our update `OpsShiftPattern` mutation. */
export type UpdateOpsShiftPatternPayload = {
  __typename?: 'UpdateOpsShiftPatternPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `OpsShiftPattern` that was updated by this mutation. */
  opsShiftPattern?: Maybe<OpsShiftPattern>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateOpsShiftWeekByNodeId` mutation. */
export type UpdateOpsShiftWeekByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OpsShiftWeek` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `OpsShiftWeek` being updated. */
  patch: OpsShiftWeekPatch;
};

/** All input for the `updateOpsShiftWeek` mutation. */
export type UpdateOpsShiftWeekInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `OpsShiftWeek` being updated. */
  patch: OpsShiftWeekPatch;
};

/** The output of our update `OpsShiftWeek` mutation. */
export type UpdateOpsShiftWeekPayload = {
  __typename?: 'UpdateOpsShiftWeekPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `OpsShiftWeek` that was updated by this mutation. */
  opsShiftWeek?: Maybe<OpsShiftWeek>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `OpsShiftPattern` that is related to this `OpsShiftWeek`. */
  shiftPattern?: Maybe<OpsShiftPattern>;
};

/** All input for the `updateOpsSupervisorByNodeId` mutation. */
export type UpdateOpsSupervisorByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OpsSupervisor` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `OpsSupervisor` being updated. */
  patch: OpsSupervisorPatch;
};

/** All input for the `updateOpsSupervisor` mutation. */
export type UpdateOpsSupervisorInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `OpsSupervisor` being updated. */
  patch: OpsSupervisorPatch;
  supervisorId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** The output of our update `OpsSupervisor` mutation. */
export type UpdateOpsSupervisorPayload = {
  __typename?: 'UpdateOpsSupervisorPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `OpsSupervisor` that was updated by this mutation. */
  opsSupervisor?: Maybe<OpsSupervisor>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AuthUser` that is related to this `OpsSupervisor`. */
  supervisor?: Maybe<AuthUser>;
  /** Reads a single `AuthUser` that is related to this `OpsSupervisor`. */
  user?: Maybe<AuthUser>;
};

/** All input for the `updateOpsYardByDescription` mutation. */
export type UpdateOpsYardByDescriptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  /** An object where the defined keys will be set on the `OpsYard` being updated. */
  patch: OpsYardPatch;
};

/** All input for the `updateOpsYardByNodeId` mutation. */
export type UpdateOpsYardByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OpsYard` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `OpsYard` being updated. */
  patch: OpsYardPatch;
};

/** All input for the `updateOpsYard` mutation. */
export type UpdateOpsYardInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `OpsYard` being updated. */
  patch: OpsYardPatch;
};

/** The output of our update `OpsYard` mutation. */
export type UpdateOpsYardPayload = {
  __typename?: 'UpdateOpsYardPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `OpsYard` that was updated by this mutation. */
  opsYard?: Maybe<OpsYard>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateRwisForecastByNodeId` mutation. */
export type UpdateRwisForecastByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `RwisForecast` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `RwisForecast` being updated. */
  patch: RwisForecastPatch;
};

/** All input for the `updateRwisForecastByStationIdAndIssueTime` mutation. */
export type UpdateRwisForecastByStationIdAndIssueTimeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  issueTime: Scalars['Datetime'];
  /** An object where the defined keys will be set on the `RwisForecast` being updated. */
  patch: RwisForecastPatch;
  stationId: Scalars['Int'];
};

/** All input for the `updateRwisForecast` mutation. */
export type UpdateRwisForecastInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `RwisForecast` being updated. */
  patch: RwisForecastPatch;
};

/** The output of our update `RwisForecast` mutation. */
export type UpdateRwisForecastPayload = {
  __typename?: 'UpdateRwisForecastPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `RwisForecast` that was updated by this mutation. */
  rwisForecast?: Maybe<RwisForecast>;
  /** Reads a single `RwisStation` that is related to this `RwisForecast`. */
  station?: Maybe<RwisStation>;
};

/** All input for the `updateRwisForecastTargetByNodeId` mutation. */
export type UpdateRwisForecastTargetByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `RwisForecastTarget` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `RwisForecastTarget` being updated. */
  patch: RwisForecastTargetPatch;
};

/** All input for the `updateRwisForecastTarget` mutation. */
export type UpdateRwisForecastTargetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  forecastId: Scalars['Int'];
  /** An object where the defined keys will be set on the `RwisForecastTarget` being updated. */
  patch: RwisForecastTargetPatch;
  targetTime: Scalars['Datetime'];
};

/** The output of our update `RwisForecastTarget` mutation. */
export type UpdateRwisForecastTargetPayload = {
  __typename?: 'UpdateRwisForecastTargetPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `RwisForecast` that is related to this `RwisForecastTarget`. */
  forecast?: Maybe<RwisForecast>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `RwisForecastTarget` that was updated by this mutation. */
  rwisForecastTarget?: Maybe<RwisForecastTarget>;
};

/** All input for the `updateRwisReadingByNodeId` mutation. */
export type UpdateRwisReadingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `RwisReading` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `RwisReading` being updated. */
  patch: RwisReadingPatch;
};

/** All input for the `updateRwisReading` mutation. */
export type UpdateRwisReadingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `RwisReading` being updated. */
  patch: RwisReadingPatch;
  readingTime: Scalars['Datetime'];
  stationId: Scalars['Int'];
};

/** The output of our update `RwisReading` mutation. */
export type UpdateRwisReadingPayload = {
  __typename?: 'UpdateRwisReadingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `RwisReading` that was updated by this mutation. */
  rwisReading?: Maybe<RwisReading>;
  /** Reads a single `RwisStation` that is related to this `RwisReading`. */
  station?: Maybe<RwisStation>;
};

/** All input for the `updateRwisStationByNodeId` mutation. */
export type UpdateRwisStationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `RwisStation` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `RwisStation` being updated. */
  patch: RwisStationPatch;
};

/** All input for the `updateRwisStationByStationName` mutation. */
export type UpdateRwisStationByStationNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `RwisStation` being updated. */
  patch: RwisStationPatch;
  stationName: Scalars['String'];
};

/** All input for the `updateRwisStation` mutation. */
export type UpdateRwisStationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `RwisStation` being updated. */
  patch: RwisStationPatch;
};

/** The output of our update `RwisStation` mutation. */
export type UpdateRwisStationPayload = {
  __typename?: 'UpdateRwisStationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `RwisStation` that was updated by this mutation. */
  rwisStation?: Maybe<RwisStation>;
};

/** All input for the `updateSdCompanyByDescription` mutation. */
export type UpdateSdCompanyByDescriptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  /** An object where the defined keys will be set on the `SdCompany` being updated. */
  patch: SdCompanyPatch;
};

/** All input for the `updateSdCompanyByNodeId` mutation. */
export type UpdateSdCompanyByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SdCompany` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `SdCompany` being updated. */
  patch: SdCompanyPatch;
};

/** All input for the `updateSdCompany` mutation. */
export type UpdateSdCompanyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `SdCompany` being updated. */
  patch: SdCompanyPatch;
};

/** The output of our update `SdCompany` mutation. */
export type UpdateSdCompanyPayload = {
  __typename?: 'UpdateSdCompanyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SdCompany` that was updated by this mutation. */
  sdCompany?: Maybe<SdCompany>;
};

/** All input for the `updateSdFormByNodeId` mutation. */
export type UpdateSdFormByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SdForm` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `SdForm` being updated. */
  patch: SdFormPatch;
};

/** All input for the `updateSdForm` mutation. */
export type UpdateSdFormInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `SdForm` being updated. */
  patch: SdFormPatch;
};

/** All input for the `updateSdFormItemByNodeId` mutation. */
export type UpdateSdFormItemByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SdFormItem` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `SdFormItem` being updated. */
  patch: SdFormItemPatch;
};

/** All input for the `updateSdFormItem` mutation. */
export type UpdateSdFormItemInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  itemId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `SdFormItem` being updated. */
  patch: SdFormItemPatch;
};

/** The output of our update `SdFormItem` mutation. */
export type UpdateSdFormItemPayload = {
  __typename?: 'UpdateSdFormItemPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `SdFormType` that is related to this `SdFormItem`. */
  documentTemplate?: Maybe<SdFormType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SdFormItem` that was updated by this mutation. */
  sdFormItem?: Maybe<SdFormItem>;
};

/** The output of our update `SdForm` mutation. */
export type UpdateSdFormPayload = {
  __typename?: 'UpdateSdFormPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `SdCompany` that is related to this `SdForm`. */
  creatingCompany?: Maybe<SdCompany>;
  /** Reads a single `SdLocation` that is related to this `SdForm`. */
  location?: Maybe<SdLocation>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SdForm` that was updated by this mutation. */
  sdForm?: Maybe<SdForm>;
};

/** All input for the `updateSdFormTypeByDocumentTemplateId` mutation. */
export type UpdateSdFormTypeByDocumentTemplateIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  documentTemplateId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `SdFormType` being updated. */
  patch: SdFormTypePatch;
};

/** All input for the `updateSdFormTypeByNodeId` mutation. */
export type UpdateSdFormTypeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SdFormType` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `SdFormType` being updated. */
  patch: SdFormTypePatch;
};

/** All input for the `updateSdFormType` mutation. */
export type UpdateSdFormTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `SdFormType` being updated. */
  patch: SdFormTypePatch;
};

/** The output of our update `SdFormType` mutation. */
export type UpdateSdFormTypePayload = {
  __typename?: 'UpdateSdFormTypePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `SdCompany` that is related to this `SdFormType`. */
  creatingCompany?: Maybe<SdCompany>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SdFormType` that was updated by this mutation. */
  sdFormType?: Maybe<SdFormType>;
};

/** All input for the `updateSdFormWorkerValueByNodeId` mutation. */
export type UpdateSdFormWorkerValueByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SdFormWorkerValue` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `SdFormWorkerValue` being updated. */
  patch: SdFormWorkerValuePatch;
};

/** All input for the `updateSdFormWorkerValue` mutation. */
export type UpdateSdFormWorkerValueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  employeeId: Scalars['UUID'];
  formId: Scalars['UUID'];
  itemId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `SdFormWorkerValue` being updated. */
  patch: SdFormWorkerValuePatch;
};

/** The output of our update `SdFormWorkerValue` mutation. */
export type UpdateSdFormWorkerValuePayload = {
  __typename?: 'UpdateSdFormWorkerValuePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `SdFormItem` that is related to this `SdFormWorkerValue`. */
  item?: Maybe<SdFormItem>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SdFormWorkerValue` that was updated by this mutation. */
  sdFormWorkerValue?: Maybe<SdFormWorkerValue>;
};

/** All input for the `updateSdLocationByNodeId` mutation. */
export type UpdateSdLocationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SdLocation` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `SdLocation` being updated. */
  patch: SdLocationPatch;
};

/** All input for the `updateSdLocation` mutation. */
export type UpdateSdLocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `SdLocation` being updated. */
  patch: SdLocationPatch;
};

/** The output of our update `SdLocation` mutation. */
export type UpdateSdLocationPayload = {
  __typename?: 'UpdateSdLocationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `SdCompany` that is related to this `SdLocation`. */
  creatingCompany?: Maybe<SdCompany>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SdLocation` that was updated by this mutation. */
  sdLocation?: Maybe<SdLocation>;
};

/** All input for the `updateSdSignatureByNodeId` mutation. */
export type UpdateSdSignatureByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SdSignature` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `SdSignature` being updated. */
  patch: SdSignaturePatch;
};

/** All input for the `updateSdSignature` mutation. */
export type UpdateSdSignatureInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `SdSignature` being updated. */
  patch: SdSignaturePatch;
};

/** The output of our update `SdSignature` mutation. */
export type UpdateSdSignaturePayload = {
  __typename?: 'UpdateSdSignaturePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `SdCompany` that is related to this `SdSignature`. */
  creatingCompany?: Maybe<SdCompany>;
  /** Reads a single `SdWorker` that is related to this `SdSignature`. */
  employee?: Maybe<SdWorker>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SdSignature` that was updated by this mutation. */
  sdSignature?: Maybe<SdSignature>;
  /** Reads a single `SdWorker` that is related to this `SdSignature`. */
  sdWorkerByCreatedBy?: Maybe<SdWorker>;
  /** Reads a single `SdWorker` that is related to this `SdSignature`. */
  sdWorkerByLastModifiedBy?: Maybe<SdWorker>;
};

/** All input for the `updateSdWorkerByNodeId` mutation. */
export type UpdateSdWorkerByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SdWorker` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `SdWorker` being updated. */
  patch: SdWorkerPatch;
};

/** All input for the `updateSdWorker` mutation. */
export type UpdateSdWorkerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `SdWorker` being updated. */
  patch: SdWorkerPatch;
};

/** The output of our update `SdWorker` mutation. */
export type UpdateSdWorkerPayload = {
  __typename?: 'UpdateSdWorkerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `SdCompany` that is related to this `SdWorker`. */
  employer?: Maybe<SdCompany>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SdWorker` that was updated by this mutation. */
  sdWorker?: Maybe<SdWorker>;
};

/** All input for the `updateTcCollectiveAgreementByNodeId` mutation. */
export type UpdateTcCollectiveAgreementByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcCollectiveAgreement` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TcCollectiveAgreement` being updated. */
  patch: TcCollectiveAgreementPatch;
};

/** All input for the `updateTcCollectiveAgreement` mutation. */
export type UpdateTcCollectiveAgreementInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Each collective agreement is associated to a service area, but potentially also limited by StdCraft */
  id: Scalars['String'];
  /** An object where the defined keys will be set on the `TcCollectiveAgreement` being updated. */
  patch: TcCollectiveAgreementPatch;
};

/** The output of our update `TcCollectiveAgreement` mutation. */
export type UpdateTcCollectiveAgreementPayload = {
  __typename?: 'UpdateTcCollectiveAgreementPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcCollectiveAgreement` that was updated by this mutation. */
  tcCollectiveAgreement?: Maybe<TcCollectiveAgreement>;
};

/** All input for the `updateTcCompanyAgreementByNodeId` mutation. */
export type UpdateTcCompanyAgreementByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcCompanyAgreement` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TcCompanyAgreement` being updated. */
  patch: TcCompanyAgreementPatch;
};

/** All input for the `updateTcCompanyAgreement` mutation. */
export type UpdateTcCompanyAgreementInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyNo: Scalars['Int'];
  /** An object where the defined keys will be set on the `TcCompanyAgreement` being updated. */
  patch: TcCompanyAgreementPatch;
};

/** The output of our update `TcCompanyAgreement` mutation. */
export type UpdateTcCompanyAgreementPayload = {
  __typename?: 'UpdateTcCompanyAgreementPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcCompanyAgreement` that was updated by this mutation. */
  tcCompanyAgreement?: Maybe<TcCompanyAgreement>;
};

/** All input for the `updateTcCompanyByNodeId` mutation. */
export type UpdateTcCompanyByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcCompany` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TcCompany` being updated. */
  patch: TcCompanyPatch;
};

/** All input for the `updateTcCompany` mutation. */
export type UpdateTcCompanyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyNo: Scalars['Int'];
  /** An object where the defined keys will be set on the `TcCompany` being updated. */
  patch: TcCompanyPatch;
};

/** The output of our update `TcCompany` mutation. */
export type UpdateTcCompanyPayload = {
  __typename?: 'UpdateTcCompanyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcCompany` that was updated by this mutation. */
  tcCompany?: Maybe<TcCompany>;
};

/** All input for the `updateTcEarningCodeRateByNodeId` mutation. */
export type UpdateTcEarningCodeRateByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcEarningCodeRate` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TcEarningCodeRate` being updated. */
  patch: TcEarningCodeRatePatch;
};

/** All input for the `updateTcEarningCodeRate` mutation. */
export type UpdateTcEarningCodeRateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  collectiveAgreement: Scalars['String'];
  earningCode: Scalars['String'];
  /** An object where the defined keys will be set on the `TcEarningCodeRate` being updated. */
  patch: TcEarningCodeRatePatch;
};

/** The output of our update `TcEarningCodeRate` mutation. */
export type UpdateTcEarningCodeRatePayload = {
  __typename?: 'UpdateTcEarningCodeRatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcEarningCodeRate` that was updated by this mutation. */
  tcEarningCodeRate?: Maybe<TcEarningCodeRate>;
};

/** All input for the `updateTcEquipmentByNodeId` mutation. */
export type UpdateTcEquipmentByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcEquipment` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TcEquipment` being updated. */
  patch: TcEquipmentPatch;
};

/** All input for the `updateTcEquipment` mutation. */
export type UpdateTcEquipmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `TcEquipment` being updated. */
  patch: TcEquipmentPatch;
};

/** The output of our update `TcEquipment` mutation. */
export type UpdateTcEquipmentPayload = {
  __typename?: 'UpdateTcEquipmentPayload';
  /** Reads a single `Asset` that is related to this `TcEquipment`. */
  asset?: Maybe<Asset>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcEquipment` that was updated by this mutation. */
  tcEquipment?: Maybe<TcEquipment>;
  /** Reads a single `TcWork` that is related to this `TcEquipment`. */
  tcWork?: Maybe<TcWork>;
};

/** All input for the `updateTcHolidayByNodeId` mutation. */
export type UpdateTcHolidayByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcHoliday` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TcHoliday` being updated. */
  patch: TcHolidayPatch;
};

/** All input for the `updateTcHoliday` mutation. */
export type UpdateTcHolidayInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `TcHoliday` being updated. */
  patch: TcHolidayPatch;
};

/** The output of our update `TcHoliday` mutation. */
export type UpdateTcHolidayPayload = {
  __typename?: 'UpdateTcHolidayPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcHoliday` that was updated by this mutation. */
  tcHoliday?: Maybe<TcHoliday>;
};

/** All input for the `updateTcLeaveByNodeId` mutation. */
export type UpdateTcLeaveByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcLeave` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TcLeave` being updated. */
  patch: TcLeavePatch;
};

/** All input for the `updateTcLeave` mutation. */
export type UpdateTcLeaveInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `TcLeave` being updated. */
  patch: TcLeavePatch;
};

/** The output of our update `TcLeave` mutation. */
export type UpdateTcLeavePayload = {
  __typename?: 'UpdateTcLeavePayload';
  /** Reads a single `AuthUser` that is related to this `TcLeave`. */
  authUserByReviewedBy?: Maybe<AuthUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `TcHoliday` that is related to this `TcLeave`. */
  holiday?: Maybe<TcHoliday>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcLeave` that was updated by this mutation. */
  tcLeave?: Maybe<TcLeave>;
  /** Reads a single `AuthUser` that is related to this `TcLeave`. */
  user?: Maybe<AuthUser>;
};

/** All input for the `updateTcMaterialByNodeId` mutation. */
export type UpdateTcMaterialByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcMaterial` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TcMaterial` being updated. */
  patch: TcMaterialPatch;
};

/** All input for the `updateTcMaterial` mutation. */
export type UpdateTcMaterialInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `TcMaterial` being updated. */
  patch: TcMaterialPatch;
};

/** The output of our update `TcMaterial` mutation. */
export type UpdateTcMaterialPayload = {
  __typename?: 'UpdateTcMaterialPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `OpsMaterial` that is related to this `TcMaterial`. */
  opsMaterialByMaterialCode?: Maybe<OpsMaterial>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcMaterial` that was updated by this mutation. */
  tcMaterial?: Maybe<TcMaterial>;
  /** Reads a single `TcWork` that is related to this `TcMaterial`. */
  tcWork?: Maybe<TcWork>;
};

/** All input for the `updateTcRejectionByNodeId` mutation. */
export type UpdateTcRejectionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcRejection` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TcRejection` being updated. */
  patch: TcRejectionPatch;
};

/** All input for the `updateTcRejection` mutation. */
export type UpdateTcRejectionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `TcRejection` being updated. */
  patch: TcRejectionPatch;
};

/** The output of our update `TcRejection` mutation. */
export type UpdateTcRejectionPayload = {
  __typename?: 'UpdateTcRejectionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AuthUser` that is related to this `TcRejection`. */
  reviewer?: Maybe<AuthUser>;
  /** The `TcRejection` that was updated by this mutation. */
  tcRejection?: Maybe<TcRejection>;
  /** Reads a single `TcTimecard` that is related to this `TcRejection`. */
  timecard?: Maybe<TcTimecard>;
};

/** All input for the `updateTcTimecardByNodeId` mutation. */
export type UpdateTcTimecardByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcTimecard` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TcTimecard` being updated. */
  patch: TcTimecardPatch;
};

/** All input for the `updateTcTimecard` mutation. */
export type UpdateTcTimecardInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `TcTimecard` being updated. */
  patch: TcTimecardPatch;
};

/** The output of our update `TcTimecard` mutation. */
export type UpdateTcTimecardPayload = {
  __typename?: 'UpdateTcTimecardPayload';
  /** Reads a single `AuthUser` that is related to this `TcTimecard`. */
  authUserByApprovedBy?: Maybe<AuthUser>;
  /** Reads a single `AuthUser` that is related to this `TcTimecard`. */
  authUserByReviewedBy?: Maybe<AuthUser>;
  /** Reads a single `AuthUser` that is related to this `TcTimecard`. */
  authUserBySubmissionBy?: Maybe<AuthUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcTimecard` that was updated by this mutation. */
  tcTimecard?: Maybe<TcTimecard>;
  /** Reads a single `AuthUser` that is related to this `TcTimecard`. */
  worker?: Maybe<AuthUser>;
  /** Reads a single `OpsYard` that is related to this `TcTimecard`. */
  worksite?: Maybe<OpsYard>;
};

/** All input for the `updateTcVacationAllotmentByNodeId` mutation. */
export type UpdateTcVacationAllotmentByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcVacationAllotment` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TcVacationAllotment` being updated. */
  patch: TcVacationAllotmentPatch;
};

/** All input for the `updateTcVacationAllotment` mutation. */
export type UpdateTcVacationAllotmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  collectiveAgreementCode: Scalars['String'];
  /** An object where the defined keys will be set on the `TcVacationAllotment` being updated. */
  patch: TcVacationAllotmentPatch;
  workDuration: IntervalInput;
  workdays: Scalars['Int'];
};

/** The output of our update `TcVacationAllotment` mutation. */
export type UpdateTcVacationAllotmentPayload = {
  __typename?: 'UpdateTcVacationAllotmentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcVacationAllotment` that was updated by this mutation. */
  tcVacationAllotment?: Maybe<TcVacationAllotment>;
};

/** All input for the `updateTcWorkByNodeId` mutation. */
export type UpdateTcWorkByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcWork` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TcWork` being updated. */
  patch: TcWorkPatch;
};

/** All input for the `updateTcWork` mutation. */
export type UpdateTcWorkInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `TcWork` being updated. */
  patch: TcWorkPatch;
};

/** All input for the `updateTcWorkLeaveByNodeId` mutation. */
export type UpdateTcWorkLeaveByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcWorkLeave` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TcWorkLeave` being updated. */
  patch: TcWorkLeavePatch;
};

/** All input for the `updateTcWorkLeave` mutation. */
export type UpdateTcWorkLeaveInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `TcWorkLeave` being updated. */
  patch: TcWorkLeavePatch;
};

/** The output of our update `TcWorkLeave` mutation. */
export type UpdateTcWorkLeavePayload = {
  __typename?: 'UpdateTcWorkLeavePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcWorkLeave` that was updated by this mutation. */
  tcWorkLeave?: Maybe<TcWorkLeave>;
  /** Reads a single `TcTimecard` that is related to this `TcWorkLeave`. */
  timecard?: Maybe<TcTimecard>;
};

/** The output of our update `TcWork` mutation. */
export type UpdateTcWorkPayload = {
  __typename?: 'UpdateTcWorkPayload';
  /** Reads a single `AuthUser` that is related to this `TcWork`. */
  accomplishmentUser?: Maybe<AuthUser>;
  /** Reads a single `OpsBlock` that is related to this `TcWork`. */
  block?: Maybe<OpsBlock>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `ImapRoadFeature` that is related to this `TcWork`. */
  road?: Maybe<ImapRoadFeature>;
  /** Reads a single `ImapRestarea` that is related to this `TcWork`. */
  site?: Maybe<ImapRestarea>;
  /** Reads a single `ImapRoadStructure` that is related to this `TcWork`. */
  structure?: Maybe<ImapRoadStructure>;
  /** The `TcWork` that was updated by this mutation. */
  tcWork?: Maybe<TcWork>;
  /** Reads a single `TcTimecard` that is related to this `TcWork`. */
  timecard?: Maybe<TcTimecard>;
};

/** All input for the `updateTcWorkTestByNodeId` mutation. */
export type UpdateTcWorkTestByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcWorkTest` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TcWorkTest` being updated. */
  patch: TcWorkTestPatch;
};

/** All input for the `updateTcWorkTest` mutation. */
export type UpdateTcWorkTestInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `TcWorkTest` being updated. */
  patch: TcWorkTestPatch;
  timecardId: Scalars['Int'];
};

/** The output of our update `TcWorkTest` mutation. */
export type UpdateTcWorkTestPayload = {
  __typename?: 'UpdateTcWorkTestPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcWorkTest` that was updated by this mutation. */
  tcWorkTest?: Maybe<TcWorkTest>;
};

/** All input for the `updateTcWorkTimeByNodeId` mutation. */
export type UpdateTcWorkTimeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TcWorkTime` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TcWorkTime` being updated. */
  patch: TcWorkTimePatch;
};

/** All input for the `updateTcWorkTime` mutation. */
export type UpdateTcWorkTimeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `TcWorkTime` being updated. */
  patch: TcWorkTimePatch;
  sequenceNo: Scalars['Int'];
  timecardId: Scalars['Int'];
  workRange: DatetimeRangeInput;
};

/** The output of our update `TcWorkTime` mutation. */
export type UpdateTcWorkTimePayload = {
  __typename?: 'UpdateTcWorkTimePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TcWorkTime` that was updated by this mutation. */
  tcWorkTime?: Maybe<TcWorkTime>;
  /** Reads a single `TcTimecard` that is related to this `TcWorkTime`. */
  timecard?: Maybe<TcTimecard>;
};

/** All input for the `updateUomByDescription` mutation. */
export type UpdateUomByDescriptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  /** An object where the defined keys will be set on the `Uom` being updated. */
  patch: UomPatch;
};

/** All input for the `updateUomByMotiCode` mutation. */
export type UpdateUomByMotiCodeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  motiCode: Scalars['String'];
  /** An object where the defined keys will be set on the `Uom` being updated. */
  patch: UomPatch;
};

/** All input for the `updateUomByNodeId` mutation. */
export type UpdateUomByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Uom` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Uom` being updated. */
  patch: UomPatch;
};

/** All input for the `updateUomByUomCode` mutation. */
export type UpdateUomByUomCodeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Uom` being updated. */
  patch: UomPatch;
  uomCode: Scalars['String'];
};

/** All input for the `updateUom` mutation. */
export type UpdateUomInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Uom` being updated. */
  patch: UomPatch;
};

/** The output of our update `Uom` mutation. */
export type UpdateUomPayload = {
  __typename?: 'UpdateUomPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Uom` that was updated by this mutation. */
  uom?: Maybe<Uom>;
};

/** All input for the `updateVehicleDiagnosticByAssetIdAndObdCodeAndDatetime` mutation. */
export type UpdateVehicleDiagnosticByAssetIdAndObdCodeAndDatetimeInput = {
  assetId: Scalars['Int'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  datetime: Scalars['Datetime'];
  obdCode: Scalars['String'];
  /** An object where the defined keys will be set on the `VehicleDiagnostic` being updated. */
  patch: VehicleDiagnosticPatch;
};

/** All input for the `updateVehicleDiagnosticByAssetIdAndSpnCodeAndFmiCodeAndDatetime` mutation. */
export type UpdateVehicleDiagnosticByAssetIdAndSpnCodeAndFmiCodeAndDatetimeInput = {
  assetId: Scalars['Int'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  datetime: Scalars['Datetime'];
  fmiCode: Scalars['Int'];
  /** An object where the defined keys will be set on the `VehicleDiagnostic` being updated. */
  patch: VehicleDiagnosticPatch;
  spnCode: Scalars['Int'];
};

/** All input for the `updateVehicleDiagnosticByNodeId` mutation. */
export type UpdateVehicleDiagnosticByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `VehicleDiagnostic` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `VehicleDiagnostic` being updated. */
  patch: VehicleDiagnosticPatch;
};

/** All input for the `updateVehicleDiagnostic` mutation. */
export type UpdateVehicleDiagnosticInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `VehicleDiagnostic` being updated. */
  patch: VehicleDiagnosticPatch;
};

/** The output of our update `VehicleDiagnostic` mutation. */
export type UpdateVehicleDiagnosticPayload = {
  __typename?: 'UpdateVehicleDiagnosticPayload';
  /** Reads a single `Asset` that is related to this `VehicleDiagnostic`. */
  asset?: Maybe<Asset>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `VehicleDiagnostic` that was updated by this mutation. */
  vehicleDiagnostic?: Maybe<VehicleDiagnostic>;
};

/** All input for the `updateVehicleServiceByNodeId` mutation. */
export type UpdateVehicleServiceByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `VehicleService` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `VehicleService` being updated. */
  patch: VehicleServicePatch;
};

/** All input for the `updateVehicleService` mutation. */
export type UpdateVehicleServiceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `VehicleService` being updated. */
  patch: VehicleServicePatch;
};

/** The output of our update `VehicleService` mutation. */
export type UpdateVehicleServicePayload = {
  __typename?: 'UpdateVehicleServicePayload';
  /** Reads a single `Asset` that is related to this `VehicleService`. */
  asset?: Maybe<Asset>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `VehicleService` that was updated by this mutation. */
  vehicleService?: Maybe<VehicleService>;
};

/** All input for the `updateVehicleStatusByNodeId` mutation. */
export type UpdateVehicleStatusByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `VehicleStatus` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `VehicleStatus` being updated. */
  patch: VehicleStatusPatch;
};

/** All input for the `updateVehicleStatus` mutation. */
export type UpdateVehicleStatusInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `VehicleStatus` being updated. */
  patch: VehicleStatusPatch;
  statusId: Scalars['Int'];
};

/** The output of our update `VehicleStatus` mutation. */
export type UpdateVehicleStatusPayload = {
  __typename?: 'UpdateVehicleStatusPayload';
  /** Reads a single `Asset` that is related to this `VehicleStatus`. */
  asset?: Maybe<Asset>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `VehicleStatus` that was updated by this mutation. */
  vehicleStatus?: Maybe<VehicleStatus>;
};

/** All input for the `updateVpJobOverrideByNodeId` mutation. */
export type UpdateVpJobOverrideByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `VpJobOverride` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `VpJobOverride` being updated. */
  patch: VpJobOverridePatch;
};

/** All input for the `updateVpJobOverride` mutation. */
export type UpdateVpJobOverrideInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyNo: Scalars['Int'];
  jobCode: Scalars['String'];
  /** An object where the defined keys will be set on the `VpJobOverride` being updated. */
  patch: VpJobOverridePatch;
};

/** The output of our update `VpJobOverride` mutation. */
export type UpdateVpJobOverridePayload = {
  __typename?: 'UpdateVpJobOverridePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `VpJobOverride` that was updated by this mutation. */
  vpJobOverride?: Maybe<VpJobOverride>;
};

/** All input for the `updateVpPhaseOverrideByNodeId` mutation. */
export type UpdateVpPhaseOverrideByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `VpPhaseOverride` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `VpPhaseOverride` being updated. */
  patch: VpPhaseOverridePatch;
};

/** All input for the `updateVpPhaseOverride` mutation. */
export type UpdateVpPhaseOverrideInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyNo: Scalars['Int'];
  jobCode: Scalars['String'];
  /** An object where the defined keys will be set on the `VpPhaseOverride` being updated. */
  patch: VpPhaseOverridePatch;
  phaseCode: Scalars['String'];
};

/** The output of our update `VpPhaseOverride` mutation. */
export type UpdateVpPhaseOverridePayload = {
  __typename?: 'UpdateVpPhaseOverridePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `VpPhaseOverride` that was updated by this mutation. */
  vpPhaseOverride?: Maybe<VpPhaseOverride>;
};

export type UpsertOpsGroupResponse = {
  __typename?: 'UpsertOpsGroupResponse';
  error?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  success: Scalars['Boolean'];
};

export type UserInput = {
  active: Scalars['Boolean'];
  companyNo: Scalars['Int'];
  email?: InputMaybe<Scalars['String']>;
  employeeNo: Scalars['Int'];
  fullname: Scalars['String'];
  groups: Array<Scalars['Int']>;
  homeYardId: Scalars['Int'];
  isAdmin: Scalars['Boolean'];
  isSupervisor: Scalars['Boolean'];
  reviewers?: InputMaybe<Array<Scalars['Int']>>;
  schedule?: InputMaybe<ScheduleInput>;
  supervisors?: InputMaybe<Array<Scalars['Int']>>;
  temporaryPassword?: InputMaybe<Scalars['String']>;
};

export type VehicleDiagnostic = Node & {
  __typename?: 'VehicleDiagnostic';
  /** Reads a single `Asset` that is related to this `VehicleDiagnostic`. */
  asset?: Maybe<Asset>;
  assetId: Scalars['Int'];
  cleared: Scalars['Boolean'];
  datetime: Scalars['Datetime'];
  fmiCode?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  obdCode?: Maybe<Scalars['String']>;
  spnCode?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `VehicleDiagnostic` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type VehicleDiagnosticCondition = {
  /** Checks for equality with the object’s `assetId` field. */
  assetId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `fmiCode` field. */
  fmiCode?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `obdCode` field. */
  obdCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `spnCode` field. */
  spnCode?: InputMaybe<Scalars['Int']>;
};

export type VehicleDiagnosticDetail = Obd | Spn;

/** A filter to be used against `VehicleDiagnostic` object types. All fields are combined with a logical ‘and.’ */
export type VehicleDiagnosticFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<VehicleDiagnosticFilter>>;
  /** Filter by the object’s `assetId` field. */
  assetId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `fmiCode` field. */
  fmiCode?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<VehicleDiagnosticFilter>;
  /** Filter by the object’s `obdCode` field. */
  obdCode?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<VehicleDiagnosticFilter>>;
  /** Filter by the object’s `spnCode` field. */
  spnCode?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `VehicleDiagnostic` */
export type VehicleDiagnosticInput = {
  assetId: Scalars['Int'];
  cleared?: InputMaybe<Scalars['Boolean']>;
  datetime?: InputMaybe<Scalars['Datetime']>;
  fmiCode?: InputMaybe<Scalars['Int']>;
  obdCode?: InputMaybe<Scalars['String']>;
  spnCode?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `VehicleDiagnostic`. Fields that are set will be updated. */
export type VehicleDiagnosticPatch = {
  assetId?: InputMaybe<Scalars['Int']>;
  cleared?: InputMaybe<Scalars['Boolean']>;
  datetime?: InputMaybe<Scalars['Datetime']>;
  fmiCode?: InputMaybe<Scalars['Int']>;
  obdCode?: InputMaybe<Scalars['String']>;
  spnCode?: InputMaybe<Scalars['Int']>;
};

export type VehicleDiagnosticSummary = {
  __typename?: 'VehicleDiagnosticSummary';
  asset_code: Scalars['String'];
  cleared?: Maybe<Scalars['Boolean']>;
  datetime: Scalars['DateTime'];
  diagnostic: VehicleDiagnosticDetail;
};

/** Methods to use when ordering `VehicleDiagnostic`. */
export enum VehicleDiagnosticsOrderBy {
  AssetIdAsc = 'ASSET_ID_ASC',
  AssetIdDesc = 'ASSET_ID_DESC',
  FmiCodeAsc = 'FMI_CODE_ASC',
  FmiCodeDesc = 'FMI_CODE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  ObdCodeAsc = 'OBD_CODE_ASC',
  ObdCodeDesc = 'OBD_CODE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SpnCodeAsc = 'SPN_CODE_ASC',
  SpnCodeDesc = 'SPN_CODE_DESC'
}

export type VehicleService = Node & {
  __typename?: 'VehicleService';
  /** Reads a single `Asset` that is related to this `VehicleService`. */
  asset?: Maybe<Asset>;
  assetId: Scalars['Int'];
  datetime: Scalars['Datetime'];
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  serviceHours: Scalars['Int'];
  serviceOdometer: Scalars['Int'];
  serviceType: Scalars['String'];
};

/**
 * A condition to be used against `VehicleService` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type VehicleServiceCondition = {
  /** Checks for equality with the object’s `assetId` field. */
  assetId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `VehicleService` object types. All fields are combined with a logical ‘and.’ */
export type VehicleServiceFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<VehicleServiceFilter>>;
  /** Filter by the object’s `assetId` field. */
  assetId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<VehicleServiceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<VehicleServiceFilter>>;
};

/** An input for mutations affecting `VehicleService` */
export type VehicleServiceInput = {
  assetId: Scalars['Int'];
  datetime?: InputMaybe<Scalars['Datetime']>;
  serviceHours: Scalars['Int'];
  serviceOdometer: Scalars['Int'];
  serviceType: Scalars['String'];
};

/** Represents an update to a `VehicleService`. Fields that are set will be updated. */
export type VehicleServicePatch = {
  assetId?: InputMaybe<Scalars['Int']>;
  datetime?: InputMaybe<Scalars['Datetime']>;
  serviceHours?: InputMaybe<Scalars['Int']>;
  serviceOdometer?: InputMaybe<Scalars['Int']>;
  serviceType?: InputMaybe<Scalars['String']>;
};

export type VehicleServiceProps = {
  asset_code: Scalars['String'];
  datetime: Scalars['DateTime'];
  service_hours: Scalars['Float'];
  service_odometer: Scalars['Float'];
  service_type: VehicleServiceType;
};

export type VehicleServiceSummary = {
  __typename?: 'VehicleServiceSummary';
  asset_code: Scalars['String'];
  datetime: Scalars['DateTime'];
  service_hours?: Maybe<Scalars['Float']>;
  service_odometer?: Maybe<Scalars['Float']>;
  service_type: VehicleServiceType;
};

export enum VehicleServiceType {
  Cvi = 'CVI',
  Major = 'MAJOR',
  Minor = 'MINOR'
}

/** Methods to use when ordering `VehicleService`. */
export enum VehicleServicesOrderBy {
  AssetIdAsc = 'ASSET_ID_ASC',
  AssetIdDesc = 'ASSET_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type VehicleStatus = Node & {
  __typename?: 'VehicleStatus';
  /** Reads a single `Asset` that is related to this `VehicleStatus`. */
  asset?: Maybe<Asset>;
  assetId: Scalars['Int'];
  datetime: Scalars['Datetime'];
  engineHours?: Maybe<Scalars['Float']>;
  fuel?: Maybe<Scalars['Float']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  odometer?: Maybe<Scalars['Float']>;
  statusId: Scalars['Int'];
};

/**
 * A condition to be used against `VehicleStatus` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type VehicleStatusCondition = {
  /** Checks for equality with the object’s `assetId` field. */
  assetId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `statusId` field. */
  statusId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `VehicleStatus` object types. All fields are combined with a logical ‘and.’ */
export type VehicleStatusFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<VehicleStatusFilter>>;
  /** Filter by the object’s `assetId` field. */
  assetId?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<VehicleStatusFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<VehicleStatusFilter>>;
  /** Filter by the object’s `statusId` field. */
  statusId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `VehicleStatus` */
export type VehicleStatusInput = {
  assetId: Scalars['Int'];
  datetime?: InputMaybe<Scalars['Datetime']>;
  engineHours?: InputMaybe<Scalars['Float']>;
  fuel?: InputMaybe<Scalars['Float']>;
  odometer?: InputMaybe<Scalars['Float']>;
};

/** Represents an update to a `VehicleStatus`. Fields that are set will be updated. */
export type VehicleStatusPatch = {
  assetId?: InputMaybe<Scalars['Int']>;
  datetime?: InputMaybe<Scalars['Datetime']>;
  engineHours?: InputMaybe<Scalars['Float']>;
  fuel?: InputMaybe<Scalars['Float']>;
  odometer?: InputMaybe<Scalars['Float']>;
};

export type VehicleStatusSummary = {
  __typename?: 'VehicleStatusSummary';
  asset_code: Scalars['String'];
  engine_hours?: Maybe<Scalars['Float']>;
  odometer?: Maybe<Scalars['Float']>;
};

/** Methods to use when ordering `VehicleStatus`. */
export enum VehicleStatusesOrderBy {
  AssetIdAsc = 'ASSET_ID_ASC',
  AssetIdDesc = 'ASSET_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StatusIdAsc = 'STATUS_ID_ASC',
  StatusIdDesc = 'STATUS_ID_DESC'
}

export type VehicleSummary = {
  __typename?: 'VehicleSummary';
  asset_code: Scalars['String'];
  diagnostics: Array<VehicleDiagnosticSummary>;
  engine_hours?: Maybe<Scalars['Float']>;
  last_cvi?: Maybe<Scalars['DateTime']>;
  last_major?: Maybe<Scalars['DateTime']>;
  last_minor?: Maybe<Scalars['DateTime']>;
  odometer?: Maybe<Scalars['Float']>;
};

export type Visit = {
  __typename?: 'Visit';
  asset_code: Scalars['String'];
  asset_id: Scalars['ID'];
  datetime: Scalars['DateTime'];
};

export type VpBallinaProbation = {
  __typename?: 'VpBallinaProbation';
  daysWorked?: Maybe<Scalars['Int']>;
  employeeNo?: Maybe<Scalars['Int']>;
  latestEntry?: Maybe<Scalars['Date']>;
};

/** Methods to use when ordering `VpBallinaProbation`. */
export enum VpBallinaProbationsOrderBy {
  Natural = 'NATURAL'
}

export type VpCraft = {
  __typename?: 'VpCraft';
  companyNo?: Maybe<Scalars['Int']>;
  craftCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `VpCraftClass`. */
  vpCraftClassesByCompanyNoAndCraftCode: Array<VpCraftClass>;
};


export type VpCraftVpCraftClassesByCompanyNoAndCraftCodeArgs = {
  condition?: InputMaybe<VpCraftClassCondition>;
  filter?: InputMaybe<VpCraftClassFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VpCraftClassesOrderBy>>;
};

export type VpCraftClass = {
  __typename?: 'VpCraftClass';
  classCode?: Maybe<Scalars['String']>;
  companyNo?: Maybe<Scalars['Int']>;
  craftCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['BigFloat']>;
  /** Reads a single `VpCraft` that is related to this `VpCraftClass`. */
  vpCraftByCompanyNoAndCraftCode?: Maybe<VpCraft>;
};

/**
 * A condition to be used against `VpCraftClass` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type VpCraftClassCondition = {
  /** Checks for equality with the object’s `companyNo` field. */
  companyNo?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `VpCraftClass` object types. All fields are combined with a logical ‘and.’ */
export type VpCraftClassFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<VpCraftClassFilter>>;
  /** Filter by the object’s `companyNo` field. */
  companyNo?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<VpCraftClassFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<VpCraftClassFilter>>;
};

/** Methods to use when ordering `VpCraftClass`. */
export enum VpCraftClassesOrderBy {
  CompanyNoAsc = 'COMPANY_NO_ASC',
  CompanyNoDesc = 'COMPANY_NO_DESC',
  Natural = 'NATURAL'
}

/** A condition to be used against `VpCraft` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type VpCraftCondition = {
  /** Checks for equality with the object’s `companyNo` field. */
  companyNo?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `VpCraft` object types. All fields are combined with a logical ‘and.’ */
export type VpCraftFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<VpCraftFilter>>;
  /** Filter by the object’s `companyNo` field. */
  companyNo?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<VpCraftFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<VpCraftFilter>>;
};

/** Methods to use when ordering `VpCraft`. */
export enum VpCraftsOrderBy {
  CompanyNoAsc = 'COMPANY_NO_ASC',
  CompanyNoDesc = 'COMPANY_NO_DESC',
  Natural = 'NATURAL'
}

export type VpEmployee = {
  __typename?: 'VpEmployee';
  active?: Maybe<Scalars['Boolean']>;
  companyNo?: Maybe<Scalars['Int']>;
  craftCode?: Maybe<Scalars['String']>;
  defaultClassCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  employeeCode?: Maybe<Scalars['String']>;
  employeeNo?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `VpEmployee` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type VpEmployeeCondition = {
  /** Checks for equality with the object’s `active` field. */
  active?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `companyNo` field. */
  companyNo?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `employeeNo` field. */
  employeeNo?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `VpEmployee` object types. All fields are combined with a logical ‘and.’ */
export type VpEmployeeFilter = {
  /** Filter by the object’s `active` field. */
  active?: InputMaybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<VpEmployeeFilter>>;
  /** Filter by the object’s `companyNo` field. */
  companyNo?: InputMaybe<IntFilter>;
  /** Filter by the object’s `employeeNo` field. */
  employeeNo?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<VpEmployeeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<VpEmployeeFilter>>;
};

/** Methods to use when ordering `VpEmployee`. */
export enum VpEmployeesOrderBy {
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  CompanyNoAsc = 'COMPANY_NO_ASC',
  CompanyNoDesc = 'COMPANY_NO_DESC',
  EmployeeNoAsc = 'EMPLOYEE_NO_ASC',
  EmployeeNoDesc = 'EMPLOYEE_NO_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL'
}

export type VpEquipment = {
  __typename?: 'VpEquipment';
  assetCode?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  companyNo?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  plateNo?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

/** Methods to use when ordering `VpEquipment`. */
export enum VpEquipmentOrderBy {
  Natural = 'NATURAL'
}

export type VpJob = {
  __typename?: 'VpJob';
  companyNo?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  jobCode?: Maybe<Scalars['String']>;
  originalJobCode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  /** Reads and enables pagination through a set of `VpPhase`. */
  vpPhasesByCompanyNoAndJobCode: Array<VpPhase>;
};


export type VpJobVpPhasesByCompanyNoAndJobCodeArgs = {
  condition?: InputMaybe<VpPhaseCondition>;
  filter?: InputMaybe<VpPhaseFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VpPhasesOrderBy>>;
};

/** A condition to be used against `VpJob` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type VpJobCondition = {
  /** Checks for equality with the object’s `companyNo` field. */
  companyNo?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `VpJob` object types. All fields are combined with a logical ‘and.’ */
export type VpJobFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<VpJobFilter>>;
  /** Filter by the object’s `companyNo` field. */
  companyNo?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<VpJobFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<VpJobFilter>>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<IntFilter>;
};

export type VpJobOverride = Node & {
  __typename?: 'VpJobOverride';
  companyNo: Scalars['Int'];
  jobCode: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  status: Scalars['Int'];
};

/**
 * A condition to be used against `VpJobOverride` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type VpJobOverrideCondition = {
  /** Checks for equality with the object’s `companyNo` field. */
  companyNo?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `VpJobOverride` object types. All fields are combined with a logical ‘and.’ */
export type VpJobOverrideFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<VpJobOverrideFilter>>;
  /** Filter by the object’s `companyNo` field. */
  companyNo?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<VpJobOverrideFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<VpJobOverrideFilter>>;
};

/** An input for mutations affecting `VpJobOverride` */
export type VpJobOverrideInput = {
  companyNo: Scalars['Int'];
  jobCode: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `VpJobOverride`. Fields that are set will be updated. */
export type VpJobOverridePatch = {
  companyNo?: InputMaybe<Scalars['Int']>;
  jobCode?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
};

/** Methods to use when ordering `VpJobOverride`. */
export enum VpJobOverridesOrderBy {
  CompanyNoAsc = 'COMPANY_NO_ASC',
  CompanyNoDesc = 'COMPANY_NO_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** Methods to use when ordering `VpJob`. */
export enum VpJobsOrderBy {
  CompanyNoAsc = 'COMPANY_NO_ASC',
  CompanyNoDesc = 'COMPANY_NO_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC'
}

export type VpLocation = {
  __typename?: 'VpLocation';
  active?: Maybe<Scalars['Boolean']>;
  companyNo?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  locationCode?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `VpLocation` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type VpLocationCondition = {
  /** Checks for equality with the object’s `active` field. */
  active?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `locationCode` field. */
  locationCode?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `VpLocation` object types. All fields are combined with a logical ‘and.’ */
export type VpLocationFilter = {
  /** Filter by the object’s `active` field. */
  active?: InputMaybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<VpLocationFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `locationCode` field. */
  locationCode?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<VpLocationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<VpLocationFilter>>;
};

/** Methods to use when ordering `VpLocation`. */
export enum VpLocationsOrderBy {
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LocationCodeAsc = 'LOCATION_CODE_ASC',
  LocationCodeDesc = 'LOCATION_CODE_DESC',
  Natural = 'NATURAL'
}

export type VpPhase = {
  __typename?: 'VpPhase';
  active?: Maybe<Scalars['Boolean']>;
  companyNo?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  jobCode?: Maybe<Scalars['String']>;
  jobStatus?: Maybe<Scalars['Int']>;
  originalPhaseCode?: Maybe<Scalars['String']>;
  phaseCode?: Maybe<Scalars['String']>;
  uom?: Maybe<Scalars['String']>;
  /** Reads a single `VpJob` that is related to this `VpPhase`. */
  vpJobByCompanyNoAndJobCode?: Maybe<VpJob>;
};

/** A condition to be used against `VpPhase` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type VpPhaseCondition = {
  /** Checks for equality with the object’s `active` field. */
  active?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `companyNo` field. */
  companyNo?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `VpPhase` object types. All fields are combined with a logical ‘and.’ */
export type VpPhaseFilter = {
  /** Filter by the object’s `active` field. */
  active?: InputMaybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<VpPhaseFilter>>;
  /** Filter by the object’s `companyNo` field. */
  companyNo?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<VpPhaseFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<VpPhaseFilter>>;
};

export type VpPhaseOverride = Node & {
  __typename?: 'VpPhaseOverride';
  active?: Maybe<Scalars['Boolean']>;
  companyNo: Scalars['Int'];
  jobCode: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  phaseCode: Scalars['String'];
};

/**
 * A condition to be used against `VpPhaseOverride` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type VpPhaseOverrideCondition = {
  /** Checks for equality with the object’s `companyNo` field. */
  companyNo?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `VpPhaseOverride` object types. All fields are combined with a logical ‘and.’ */
export type VpPhaseOverrideFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<VpPhaseOverrideFilter>>;
  /** Filter by the object’s `companyNo` field. */
  companyNo?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<VpPhaseOverrideFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<VpPhaseOverrideFilter>>;
};

/** An input for mutations affecting `VpPhaseOverride` */
export type VpPhaseOverrideInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  companyNo: Scalars['Int'];
  jobCode: Scalars['String'];
  phaseCode: Scalars['String'];
};

/** Represents an update to a `VpPhaseOverride`. Fields that are set will be updated. */
export type VpPhaseOverridePatch = {
  active?: InputMaybe<Scalars['Boolean']>;
  companyNo?: InputMaybe<Scalars['Int']>;
  jobCode?: InputMaybe<Scalars['String']>;
  phaseCode?: InputMaybe<Scalars['String']>;
};

/** Methods to use when ordering `VpPhaseOverride`. */
export enum VpPhaseOverridesOrderBy {
  CompanyNoAsc = 'COMPANY_NO_ASC',
  CompanyNoDesc = 'COMPANY_NO_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** Methods to use when ordering `VpPhase`. */
export enum VpPhasesOrderBy {
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  CompanyNoAsc = 'COMPANY_NO_ASC',
  CompanyNoDesc = 'COMPANY_NO_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL'
}

export type WeatherCurrent = {
  __typename?: 'WeatherCurrent';
  airQuality?: Maybe<Scalars['Float']>;
  condition?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  dewpoint: Scalars['Float'];
  humidex?: Maybe<Scalars['Int']>;
  humidity: Scalars['Float'];
  pressure: Pressure;
  temperature: Scalars['Float'];
  visibility?: Maybe<Scalars['Float']>;
  wind: Wind;
  windChill?: Maybe<Scalars['Float']>;
};

export type WeatherForecast = {
  __typename?: 'WeatherForecast';
  date: Scalars['DateTime'];
  description: Scalars['String'];
  period: Scalars['String'];
  temperature: TemperatureForecast;
};

export type WeatherStation = {
  __typename?: 'WeatherStation';
  current?: Maybe<WeatherCurrent>;
  forecasts: Array<WeatherForecast>;
  gov_id: Scalars['String'];
  name: Scalars['String'];
  updated: Scalars['DateTime'];
  warnings: Array<Scalars['String']>;
};

export type Wind = {
  __typename?: 'Wind';
  direction?: Maybe<Scalars['String']>;
  gust?: Maybe<Scalars['Int']>;
  speed: Scalars['Int'];
};

export type WorkDetail = {
  __typename?: 'WorkDetail';
  accomplishment?: Maybe<Scalars['Float']>;
  hours: Scalars['Float'];
  phaseId: Scalars['ID'];
  workDate: Scalars['DateTime'];
  workId: Scalars['ID'];
  workerId: Scalars['ID'];
  yardId: Scalars['ID'];
};

export type WorkId = {
  __typename?: 'WorkID';
  ActionItemId: Scalars['ID'];
  Comments?: Maybe<Scalars['String']>;
  TaskID?: Maybe<Scalars['String']>;
  activity: Scalars['String'];
  by?: Maybe<Scalars['String']>;
  location?: Maybe<Point>;
  qty?: Maybe<Scalars['Float']>;
  season?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  uom?: Maybe<Scalars['String']>;
};

export type WorkReport = {
  __typename?: 'WorkReport';
  data: WorkReportData;
  id: RecordId;
};

export type WorkReportData = {
  __typename?: 'WorkReportData';
  accomplishment?: Maybe<Scalars['Float']>;
  activity?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  end?: Maybe<Point>;
  end_date?: Maybe<Scalars['DateTime']>;
  highway?: Maybe<Scalars['String']>;
  site?: Maybe<Scalars['String']>;
  start?: Maybe<Point>;
  start_date?: Maybe<Scalars['DateTime']>;
  structure?: Maybe<Scalars['String']>;
};

export type WorkReportSubmission = {
  data: WorkReportSubmissionData;
  id: RecordSubmissionId;
};

export type WorkReportSubmissionData = {
  accomplishment?: InputMaybe<Scalars['Float']>;
  activity?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<InputPoint>;
  end_date?: InputMaybe<Scalars['DateTime']>;
  highway?: InputMaybe<Scalars['String']>;
  site?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<InputPoint>;
  start_date?: InputMaybe<Scalars['DateTime']>;
  structure?: InputMaybe<Scalars['String']>;
};

export type WorkSummary = {
  __typename?: 'WorkSummary';
  accomplishment: Scalars['Float'];
  hours: Scalars['Float'];
  phaseId: Scalars['ID'];
};

export type WrRecordFull = {
  __typename?: 'WrRecordFull';
  accomplishment?: Maybe<Scalars['Float']>;
  activityId?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  endLat?: Maybe<Scalars['Float']>;
  endLng?: Maybe<Scalars['Float']>;
  highwayId?: Maybe<Scalars['String']>;
  recordNumber?: Maybe<Scalars['String']>;
  recordid?: Maybe<Scalars['Int']>;
  saveDate?: Maybe<Scalars['Datetime']>;
  sessionid?: Maybe<Scalars['Int']>;
  siteId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  startLat?: Maybe<Scalars['Float']>;
  startLng?: Maybe<Scalars['Float']>;
  structureId?: Maybe<Scalars['String']>;
  userid?: Maybe<Scalars['Int']>;
  wrId?: Maybe<Scalars['Int']>;
};

/** An input for mutations affecting `WrRecordFull` */
export type WrRecordFullInput = {
  accomplishment?: InputMaybe<Scalars['Float']>;
  activityId?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  endLat?: InputMaybe<Scalars['Float']>;
  endLng?: InputMaybe<Scalars['Float']>;
  highwayId?: InputMaybe<Scalars['String']>;
  recordNumber?: InputMaybe<Scalars['String']>;
  recordid?: InputMaybe<Scalars['Int']>;
  saveDate?: InputMaybe<Scalars['Datetime']>;
  sessionid?: InputMaybe<Scalars['Int']>;
  siteId?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  startLat?: InputMaybe<Scalars['Float']>;
  startLng?: InputMaybe<Scalars['Float']>;
  structureId?: InputMaybe<Scalars['String']>;
  userid?: InputMaybe<Scalars['Int']>;
  wrId?: InputMaybe<Scalars['Int']>;
};

/** Methods to use when ordering `WrRecordFull`. */
export enum WrRecordFullsOrderBy {
  Natural = 'NATURAL'
}

export type YardReport = {
  __typename?: 'YardReport';
  yardId: Scalars['ID'];
  yardSummaries: Array<YardSummary>;
};

export type YardSummary = {
  __typename?: 'YardSummary';
  date: Scalars['String'];
  dt: Scalars['Float'];
  shortfall: Scalars['Float'];
  st: Scalars['Float'];
  th: Scalars['Float'];
};

export type YardWorkSummary = {
  __typename?: 'YardWorkSummary';
  work: Array<WorkSummary>;
  yardId: Scalars['ID'];
};

export type DiagnosticSummariesQueryVariables = Exact<{ [key: string]: never; }>;


export type DiagnosticSummariesQuery = { __typename?: 'Query', vehicleDiagnosticSummaries: Array<{ __typename?: 'VehicleDiagnosticSummary', asset_code: string, datetime: any, cleared?: boolean | null, diagnostic: { __typename: 'OBD', obd_code: string, obd_description?: string | null } | { __typename: 'SPN', spn_code: string, spn_name?: string | null, spn_description?: string | null, fmi?: { __typename?: 'FMI', fmi_code: string, fmi_description?: string | null } | null } }> };

export type VehicleSummariesQueryVariables = Exact<{ [key: string]: never; }>;


export type VehicleSummariesQuery = { __typename?: 'Query', vehicleSummaries: Array<{ __typename?: 'VehicleSummary', asset_code: string, engine_hours?: number | null, last_cvi?: any | null, last_major?: any | null, last_minor?: any | null, odometer?: number | null, diagnostics: Array<{ __typename?: 'VehicleDiagnosticSummary', cleared?: boolean | null, datetime: any, diagnostic: { __typename?: 'OBD', obd_code: string, obd_description?: string | null } | { __typename?: 'SPN', spn_code: string, spn_name?: string | null, spn_description?: string | null, fmi?: { __typename?: 'FMI', fmi_code: string, fmi_description?: string | null } | null } }> }> };


export const DiagnosticSummariesDocument = gql`
    query diagnosticSummaries {
  vehicleDiagnosticSummaries {
    asset_code
    datetime
    cleared
    diagnostic {
      __typename
      ... on SPN {
        fmi {
          fmi_code
          fmi_description
        }
        spn_code
        spn_name
        spn_description
      }
      ... on OBD {
        obd_code
        obd_description
      }
    }
  }
}
    `;

/**
 * __useDiagnosticSummariesQuery__
 *
 * To run a query within a React component, call `useDiagnosticSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiagnosticSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiagnosticSummariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDiagnosticSummariesQuery(baseOptions?: Apollo.QueryHookOptions<DiagnosticSummariesQuery, DiagnosticSummariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DiagnosticSummariesQuery, DiagnosticSummariesQueryVariables>(DiagnosticSummariesDocument, options);
      }
export function useDiagnosticSummariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiagnosticSummariesQuery, DiagnosticSummariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DiagnosticSummariesQuery, DiagnosticSummariesQueryVariables>(DiagnosticSummariesDocument, options);
        }
export type DiagnosticSummariesQueryHookResult = ReturnType<typeof useDiagnosticSummariesQuery>;
export type DiagnosticSummariesLazyQueryHookResult = ReturnType<typeof useDiagnosticSummariesLazyQuery>;
export type DiagnosticSummariesQueryResult = Apollo.QueryResult<DiagnosticSummariesQuery, DiagnosticSummariesQueryVariables>;
export const VehicleSummariesDocument = gql`
    query vehicleSummaries {
  vehicleSummaries {
    asset_code
    engine_hours
    last_cvi
    last_major
    last_minor
    odometer
    diagnostics {
      cleared
      datetime
      diagnostic {
        ... on SPN {
          fmi {
            fmi_code
            fmi_description
          }
          spn_code
          spn_name
          spn_description
        }
        ... on OBD {
          obd_code
          obd_description
        }
      }
    }
  }
}
    `;

/**
 * __useVehicleSummariesQuery__
 *
 * To run a query within a React component, call `useVehicleSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleSummariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useVehicleSummariesQuery(baseOptions?: Apollo.QueryHookOptions<VehicleSummariesQuery, VehicleSummariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VehicleSummariesQuery, VehicleSummariesQueryVariables>(VehicleSummariesDocument, options);
      }
export function useVehicleSummariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehicleSummariesQuery, VehicleSummariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VehicleSummariesQuery, VehicleSummariesQueryVariables>(VehicleSummariesDocument, options);
        }
export type VehicleSummariesQueryHookResult = ReturnType<typeof useVehicleSummariesQuery>;
export type VehicleSummariesLazyQueryHookResult = ReturnType<typeof useVehicleSummariesLazyQuery>;
export type VehicleSummariesQueryResult = Apollo.QueryResult<VehicleSummariesQuery, VehicleSummariesQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GeographyGeometry": [
      "GeographyPoint"
    ],
    "GeographyInterface": [
      "GeographyPoint"
    ],
    "GeometryGeometry": [
      "GeometryGeometryCollection",
      "GeometryLineString",
      "GeometryMultiLineString",
      "GeometryMultiPoint",
      "GeometryMultiPolygon",
      "GeometryPoint",
      "GeometryPolygon"
    ],
    "GeometryGeometryM": [
      "GeometryGeometryCollectionM",
      "GeometryLineStringM",
      "GeometryMultiLineStringM",
      "GeometryMultiPointM",
      "GeometryMultiPolygonM",
      "GeometryPointM",
      "GeometryPolygonM"
    ],
    "GeometryGeometryZ": [
      "GeometryGeometryCollectionZ",
      "GeometryLineStringZ",
      "GeometryMultiLineStringZ",
      "GeometryMultiPointZ",
      "GeometryMultiPolygonZ",
      "GeometryPointZ",
      "GeometryPolygonZ"
    ],
    "GeometryGeometryZM": [
      "GeometryGeometryCollectionZM",
      "GeometryLineStringZM",
      "GeometryMultiLineStringZM",
      "GeometryMultiPointZM",
      "GeometryMultiPolygonZM",
      "GeometryPointZM",
      "GeometryPolygonZM"
    ],
    "GeometryInterface": [
      "GeometryGeometryCollection",
      "GeometryGeometryCollectionM",
      "GeometryGeometryCollectionZ",
      "GeometryGeometryCollectionZM",
      "GeometryLineString",
      "GeometryLineStringM",
      "GeometryLineStringZ",
      "GeometryLineStringZM",
      "GeometryMultiLineString",
      "GeometryMultiLineStringM",
      "GeometryMultiLineStringZ",
      "GeometryMultiLineStringZM",
      "GeometryMultiPoint",
      "GeometryMultiPointM",
      "GeometryMultiPointZ",
      "GeometryMultiPointZM",
      "GeometryMultiPolygon",
      "GeometryMultiPolygonM",
      "GeometryMultiPolygonZ",
      "GeometryMultiPolygonZM",
      "GeometryPoint",
      "GeometryPointM",
      "GeometryPointZ",
      "GeometryPointZM",
      "GeometryPolygon",
      "GeometryPolygonM",
      "GeometryPolygonZ",
      "GeometryPolygonZM"
    ],
    "Node": [
      "Asset",
      "AssetBreadcrumb",
      "AssetCategory",
      "AssetType",
      "AssetWingUnderbody",
      "AuthSessionToken",
      "AuthUser",
      "BecBiogeoclimatic",
      "BiForecast",
      "BiForecastPermission",
      "BiForecastPhase",
      "Collision",
      "DtcFmi",
      "DtcObd",
      "DtcSpn",
      "GisAtlasZone",
      "ImapCulvert",
      "ImapDrainage",
      "ImapGuardrail",
      "ImapHighwayProfile",
      "ImapLandmark",
      "ImapRestarea",
      "ImapRetainingWall",
      "ImapRoadClass",
      "ImapRoadClassFeature",
      "ImapRoadFeature",
      "ImapRoadStructure",
      "ImapRoadSurface",
      "ImapRoadSurfaceFeature",
      "ImapSafety",
      "ImapSign",
      "OpsActivity",
      "OpsActivityRate",
      "OpsBlock",
      "OpsBlockRoad",
      "OpsBlockType",
      "OpsGroup",
      "OpsGroupType",
      "OpsMaterial",
      "OpsMembershipCustom",
      "OpsSchedule",
      "OpsShiftPattern",
      "OpsShiftWeek",
      "OpsSupervisor",
      "OpsYard",
      "RwisForecast",
      "RwisForecastTarget",
      "RwisReading",
      "RwisStation",
      "SdCompany",
      "SdForm",
      "SdFormItem",
      "SdFormType",
      "SdFormWorkerValue",
      "SdLocation",
      "SdSignature",
      "SdWorker",
      "TcCollectiveAgreement",
      "TcCompany",
      "TcCompanyAgreement",
      "TcEarningCodeRate",
      "TcEquipment",
      "TcHoliday",
      "TcLeave",
      "TcMaterial",
      "TcRejection",
      "TcTimecard",
      "TcVacationAllotment",
      "TcWork",
      "TcWorkLeave",
      "TcWorkTest",
      "TcWorkTime",
      "Uom",
      "VehicleDiagnostic",
      "VehicleService",
      "VehicleStatus",
      "VpJobOverride",
      "VpPhaseOverride"
    ],
    "VehicleDiagnosticDetail": [
      "OBD",
      "SPN"
    ]
  }
};
      export default result;
    