import { useMemo, FC } from "react"
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client"
import { useCredentials } from "./AuthProvider"

export const GraphProvider: FC = ({ children }) => {
	const token = useCredentials()?.credentials?.authToken

	const client = useMemo(() => {
		const headers = token ? { Authorization: `Bearer ${token}` } : undefined
		return new ApolloClient({ cache: new InMemoryCache(), uri: "https://hmm.eag.tools/graphql", headers })
	}, [token])

	return <ApolloProvider client={client}>{children}</ApolloProvider>
}
