import axios from "axios"
import { Configuration, PublicClientApplication, AuthenticationResult } from "@azure/msal-browser"
import { credentialsKey } from "./LoginExperience"
import { Profile } from "./AuthProvider"

export type AuthToken = {
	aud: string | string[]
	exp: number
	fullname: string
	email?: string
	employee_no: number
	company_no: number
	iat: number
	iss: "auth.ngoc.ca"
	scopes: string[]
	yard: number
	sid: number
	sub: string
	uid: number
	ver: number
}

export const jwtDecode = (jwt: string) => {
	const payload = atob(jwt.split(".")[1])
	// const payload = Buffer.from(jwt.split(".")[1], "base64").toString()
	return JSON.parse(payload)
}

const clientId = process.env.REACT_APP_APP_ID!

const msalConfig: Configuration = {
	auth: {
		clientId,
		authority: `https://login.microsoftonline.com/organizations`,
		// clientCapabilities: [],
		// TODO set up logout route to handle this
		// postLogoutRedirectUri: `${window.location.origin}/logout`,
		redirectUri: window.location.origin,
	},
	cache: {
		cacheLocation: "localStorage",
		// storeAuthStateInCookie: true,
	},
}

export const msal = new PublicClientApplication(msalConfig)

export const handleAuthenticationResult = async (result: AuthenticationResult | null) => {
	if (result === null) return
	const { idToken, account } = result
	if (account === null) return
	const headers = { Authorization: `Bearer ${idToken}` }
	const authResp = await axios.post("https://auth.eag.tools/login", undefined, { headers })
	const credentials = handleLoginToken(authResp.data.token) ?? null
	return credentials
}

export const handleLoginToken = (authToken: string) => {
	const resp = jwtDecode(authToken) as AuthToken
	if (!resp) return
	const { uid, sid, sub: username, fullname, scopes, email, employee_no, company_no, yard } = resp
	const user: Profile = {
		userid: uid.toString(),
		sessionid: sid,
		username,
		fullname,
		email,
		scopes,
		employee_no,
		company_no,
		home_yard_id: yard,
	}
	const credentials = { user, authToken }
	localStorage.setItem(credentialsKey, JSON.stringify(credentials))
	return credentials
}
