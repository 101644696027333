import { useState, createContext, FC, Dispatch, SetStateAction, useContext } from "react"

export type Profile = {
	username: string
	userid: string
	sessionid: number
	email?: string | null
	employee_no: number
	company_no: number
	fullname: string
	scopes: string[]
	home_yard_id: number
}
export type Credentials = {
	user: Profile
	authToken: string
}
export type CredentialsProps = {
	credentials?: Credentials | null
	setCredentials: Dispatch<SetStateAction<Credentials | null | undefined>>
}
const AuthContext = createContext<CredentialsProps>(null as any)
AuthContext.displayName = "AuthContext"

export const useCredentials = () => useContext(AuthContext)

export const AuthProvider: FC = ({ children }) => {
	const [credentials, setCredentials] = useState<Credentials | null | undefined>(undefined)
	return <AuthContext.Provider value={{ credentials, setCredentials }}>{children}</AuthContext.Provider>
}
