import { useState, FormEvent } from "react"
import { TextField, Button } from "@mui/material"
import { LocalizationProvider, DatePicker } from "@mui/lab"
import DateAdapter from "@mui/lab/AdapterDateFns"
import { DtcType } from "../graphql"

type ServiceFormProps = {
	assetCode: string
	type: DtcType
	hours: {
		min: number
		max: number
	}
	odometer: {
		min: number
		max: number | null
	}
	minDate?: Date
	onSubmit: (hours: number, odometer: number, date: Date) => void
	onClose: () => void
}

export const ServiceForm = ({ assetCode, type, hours, odometer, minDate, onSubmit, onClose }: ServiceFormProps) => {
	const [serviceHours, setServiceHours] = useState(Math.floor(hours.max).toString())
	const [serviceOdometer, setServiceOdometer] = useState(Math.floor(odometer.max ?? 0).toString())
	const [serviceDate, setServiceDate] = useState<Date | null>(() => new Date())

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		try {
			onSubmit(parseInt(serviceHours), parseInt(serviceOdometer), serviceDate!)
		} catch (err) {
			alert("Please complete entire form")
		}
	}

	return (
		<div className="service-form" style={{ margin: "1em" }}>
			<form onSubmit={handleSubmit}>
				<h2 style={{ margin: "0 auto 0.5em auto", textAlign: "center" }}>
					{assetCode} {type} Service
				</h2>
				<TextField
					label="Engine Hours"
					type="number"
					inputProps={{ ...hours, step: 1 }}
					fullWidth
					value={serviceHours}
					onChange={e => setServiceHours(e.target.value)}
				/>
				<br />
				<TextField
					label="Odometer"
					type="number"
					inputProps={{ ...odometer, step: 1 }}
					fullWidth
					value={serviceOdometer}
					onChange={e => setServiceOdometer(e.target.value)}
				/>
				<br />
				<LocalizationProvider
					dateAdapter={DateAdapter}
					dateFormats={{
						fullDate: "yyyy-LLL-dd",
						fullDateTime12h: "yyyy-LLL-dd hh:mm a",
						fullDateTime24h: "yyyy-LLL-dd HH:mm",
					}}
				>
					<DatePicker
						renderInput={params => <TextField {...params} />}
						value={serviceDate}
						onChange={setServiceDate}
						minDate={minDate}
						disableFuture
						label="Service Date"
					/>
				</LocalizationProvider>
				<div style={{ marginTop: "0.5em", textAlign: "center" }}>
					<Button type="submit" variant="contained" color="primary" style={{ margin: "0.5em" }}>
						Save
					</Button>
					<Button type="button" variant="outlined" color="secondary" style={{ margin: "0.5em" }} onClick={onClose}>
						Cancel
					</Button>
				</div>
			</form>
		</div>
	)
}
