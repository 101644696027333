import { useEffect } from "react"
import { LoginForm } from "./LoginForm"
import { msal, handleAuthenticationResult } from "./msal"
import { Dashboard } from "../Dashboard"
import { Credentials, useCredentials } from "./AuthProvider"
import { GraphProvider } from "./GraphProvider"

export const credentialsKey = "credentials"

export const scopes: string[] = []
export const LoginExperience = () => {
	const { credentials, setCredentials } = useCredentials()
	useEffect(() => {
		// if a user is already laoded, done
		if (credentials?.user) return

		// checks if we are coming back from a Microsoft login
		const handleLogin = async () => {
			const result = await msal.handleRedirectPromise()
			const credentials = await handleAuthenticationResult(result)
			if (credentials !== undefined) {
				setCredentials(credentials)
			}
		}

		// check cache for a saved user
		const credentialString = localStorage.getItem(credentialsKey)
		if (credentialString) {
			const cachedCredentials = JSON.parse(credentialString) as Credentials
			setCredentials(cachedCredentials)
			return
		} else {
			setCredentials(null)
		}
		handleLogin()
	}, [credentials, setCredentials])

	if (credentials === undefined) return null
	if (credentials === null) return <LoginForm />
	return (
		<GraphProvider>
			<Dashboard />
		</GraphProvider>
	)
}
