import { useMemo } from "react"
import { VehicleSummary } from "../Dashboard"
import { Obd, Spn } from "../graphql"
import { ObdDiagnosticView } from "./ObdDiagnosticView"
import { GenericDiagnostic, SpnDiagnosticView } from "./SpnDiagnosticView"

export type VehicleDiagnosticSummary = VehicleSummary["diagnostics"][number]

export const compareDiagnostics = (
	{ diagnostic: a }: VehicleDiagnosticSummary,
	{ diagnostic: b }: VehicleDiagnosticSummary
) => {
	return a.__typename === "SPN" && b.__typename === "SPN"
		? a.spn_code.localeCompare(b.spn_code)
		: a.__typename === "SPN"
		? 1
		: b.__typename === "SPN"
		? -1
		: (a as Obd).obd_code.localeCompare((b as Obd).obd_code)
}

type Props = {
	diagnostics: VehicleDiagnosticSummary[]
}
export const DiagnosticsView = ({ diagnostics }: Props) => {
	const sortedDiagnostics = useMemo(
		() => [...diagnostics].sort(compareDiagnostics).sort((a, b) => b.datetime.localeCompare(a.datetime)),
		[diagnostics]
	)

	const handleClear = async (d: VehicleDiagnosticSummary) => {
		// const body = {...d}
		// const resp = await fetch(`https://avl.ngoc.ca/api/diagnostics?${serialize({sessionid})}`, {
		// 	method: 'put',
		// 	headers: {
		// 		'content-type': 'application/json',
		// 	},
		// 	body: JSON.stringify(body)
		// })
		// console.log(resp)
	}

	return (
		<article style={{ fontSize: "16pt" }}>
			{sortedDiagnostics.map(d =>
				d.diagnostic.__typename === "SPN" ? (
					<SpnDiagnosticView
						diagnostic={d as GenericDiagnostic<Spn>}
						key={d.datetime.toString() + (d.diagnostic as Spn).spn_code}
						onClear={() => handleClear(d)}
					/>
				) : (
					<ObdDiagnosticView
						diagnostic={d as GenericDiagnostic<Obd>}
						key={d.datetime.toString() + (d.diagnostic as Obd).obd_code}
						onClear={() => handleClear(d)}
					/>
				)
			)}
		</article>
	)
}
