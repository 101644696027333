import { useState } from "react"
import { addMonths, format } from "date-fns"
import { Dialog } from "@mui/material"
import { VehicleService, DtcType } from "../graphql"
import { EngineButton } from "./EngineButton"
import { ActionButton } from "./ActionButton"
import { ServiceForm } from "./ServiceForm"
import { DiagnosticsView } from "./DiagnosticsView"
import serviceConfig from "../serviceConfig.json"
import { VehicleSummary } from "../Dashboard"

const { minor: minorConfig, major: majorConfig } = serviceConfig

const styles = {
	bad: "red",
	warn: "orange",
	good: "white",
} as const

const getWarnState = (odometer: number | undefined | null, next: number, tolerance: number) =>
	!odometer ? "good" : odometer + tolerance < next ? "good" : odometer > next + tolerance ? "bad" : "warn"

const getNextCvi = (cvi?: Date) => {
	if (!cvi) return addMonths(new Date(), -1)
	return addMonths(cvi, 6)
}

const getNextService = (tolerance: number, interval: number, last = 0) =>
	Math.floor((last + tolerance) / interval) * interval + interval

const getCviWarnState = (due: Date) => {
	const now = Date.now()
	const today = format(now, "yyyy-mm")
	if (today === format(due, "yyyy-mm")) return "warn"
	if (due.getTime() - now > 0) return "good"
	return "bad"
}

const fmt = (n?: number | null) => n?.toLocaleString("en-CA", { maximumFractionDigits: 0 })

// type VehicleSummaryRowProps = {
// 	asset_no:string
// 	engine_hours:number
// 	odometer:number|null
// 	last_minor?:Service<'minor'>
// 	last_major?:Service<'major'>
// 	last_cvi?:Service<'cvi'>
// 	diagnostics:VehicleDiagnostics
// }

// export type Service<T extends DtcType> = {
// 	asset_no:string
// 	service_type:T
// 	datetime:string
// 	service_hours:number
// 	service_odometer:number
// }

export const VehicleSummaryRow = ({
	asset_code,
	engine_hours,
	odometer,
	last_minor,
	last_major,
	last_cvi,
	diagnostics,
}: VehicleSummary) => {
	const [activeService, setActiveService] = useState<VehicleService>()
	const [diagnosticsOpen, setDiagnosticsOpen] = useState(false)

	// const getService = (service_type: VehicleServiceType, service?: VehicleService) => {
	// 	return service
	// 		? { ...service }
	// 		: {
	// 				asset_code,
	// 				datetime: new Date(2000, 1, 1),
	// 				service_hours: 0,
	// 				service_odometer: 0,
	// 				service_type,
	// 		  }
	// }
	// const startMinor = () => setActiveService(getService(VehicleServiceType.Minor, last_minor))
	// const startMajor = () => setActiveService(getService(VehicleServiceType.Major, last_major))
	// const startCvi = () => setActiveService(getService(VehicleServiceType.Cvi, last_cvi))
	const startMinor = () => setActiveService(undefined)
	const startMajor = () => setActiveService(undefined)
	const startCvi = () => setActiveService(undefined)
	const handleClose = () => setActiveService(undefined)

	const lastMinor = last_minor?.service_odometer || 0
	const lastMajor = last_major?.service_odometer || 0
	const lastService = Math.max(lastMinor, lastMajor)
	// const nextMinor = minorConfig.interval + minorConfig.tolerance + lastService - odometer!
	// const nextMajor = majorConfig.interval + majorConfig.tolerance + lastMajor - odometer!
	const nextMinor = getNextService(minorConfig.tolerance, minorConfig.interval, lastService)
	const nextMajor = getNextService(majorConfig.tolerance, majorConfig.interval, lastMajor)

	const nextCvi = getNextCvi(last_cvi?.datetime)

	return (
		<>
			<tr>
				<th>{asset_code}</th>
				<td>{engine_hours ? fmt(engine_hours) : "?"}</td>
				<td>{fmt(odometer)}</td>
				<td style={{ color: styles[getWarnState(odometer, nextMinor, minorConfig.tolerance)] }}>
					<ActionButton value={fmt(nextMinor)} actionValue="Update" onClick={startMinor} />
				</td>
				<td style={{ color: styles[getWarnState(odometer, nextMajor, majorConfig.tolerance)] }}>
					<ActionButton value={fmt(nextMajor)} actionValue="Update" onClick={startMajor} />
				</td>
				{asset_code.startsWith("0") ? (
					<td></td>
				) : (
					<td style={{ color: styles[getCviWarnState(nextCvi)], textAlign: "center" }}>
						<ActionButton value={format(nextCvi, "MMM")} actionValue="Update" onClick={startCvi} />
					</td>
				)}
				<td>{diagnostics.length > 0 && <EngineButton onClick={() => setDiagnosticsOpen(true)} />}</td>
			</tr>

			{activeService && (
				<Dialog open onClose={handleClose}>
					<ServiceForm
						assetCode={activeService.asset?.assetCode!}
						type={activeService.serviceType as DtcType}
						hours={{ min: activeService.serviceHours, max: engine_hours! }}
						odometer={{ min: activeService.serviceOdometer, max: odometer! }}
						minDate={new Date(activeService.datetime)}
						onClose={handleClose}
						onSubmit={(service_hours, service_odometer, datetime) => {
							console.log({
								asset_code,
								service_type: activeService.serviceType,
								service_hours,
								service_odometer,
								datetime,
							})
							handleClose()
						}}
					/>
				</Dialog>
			)}

			{diagnosticsOpen && (
				<Dialog open onClose={() => setDiagnosticsOpen(false)}>
					<DiagnosticsView diagnostics={diagnostics} />
				</Dialog>
			)}
		</>
	)
}
